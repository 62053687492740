import React from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error: typeof error === "object" ? error.message : String(error),
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary:", error);
    this.setState({
      error: typeof error === "object" ? error.message : String(error),
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      const errorMessage = this.state.error || "An unexpected error occurred";

      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="bg-white p-8 rounded-lg shadow-md max-w-lg w-full">
            <div className="flex items-center justify-center mb-6">
              <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center">
                <svg
                  className="w-6 h-6 text-red-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
            </div>
            <h1 className="text-xl font-semibold text-center text-gray-900 mb-4">
              Something went wrong
            </h1>
            <p className="text-gray-600 text-center mb-6">
              {String(errorMessage)}
            </p>
            <div className="flex justify-center">
              <button
                onClick={() => window.location.reload()}
                className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-300"
              >
                Reload Page
              </button>
            </div>
            {this.state.errorInfo && (
              <details className="mt-6 p-4 bg-gray-50 rounded-lg">
                <summary className="text-sm text-gray-600 cursor-pointer">
                  Technical Details
                </summary>
                <pre className="mt-2 text-xs text-gray-500 overflow-auto">
                  {this.state.errorInfo.componentStack}
                </pre>
              </details>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
