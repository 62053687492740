import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FaBed, FaBath, FaRuler, FaHeart, FaRegHeart } from "react-icons/fa";
import Pagination from "./Pagination";
import SortOptions from "./SortOptions";

const ResultsContainer = ({
  filteredProperties,
  currentItems,
  itemsPerPage,
  currentPage,
  paginate,
  showLiked,
  setShowLiked,
  sortOption,
  sortOrder,
  setSortOption,
  setSortOrder,
  getStatusColor,
}) => {
  const [likedProperties, setLikedProperties] = useState(new Set());

  const toggleLike = (id) => {
    setLikedProperties((prev) => {
      const newLikedProperties = new Set(prev);
      if (newLikedProperties.has(id)) {
        newLikedProperties.delete(id);
      } else {
        newLikedProperties.add(id);
      }
      return newLikedProperties;
    });
  };

  const handleToggle = () => {
    setShowLiked((prev) => !prev);
  };

  // Filter currentItems based on showLiked
  const displayedItems = showLiked
    ? currentItems.filter((property) => likedProperties.has(property.id))
    : currentItems;

  // Update pagination when displayedItems changes
  useEffect(() => {
    paginate(1); // Reset to first page when switching between all and liked items
  }, [showLiked]);

  return (
    <section className="flex-grow bg-white rounded-lg shadow-md p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">
          {displayedItems.length} Results
        </h2>
        <div className="flex items-center">
          <label className="flex items-center mr-4">
            <input
              type="checkbox"
              checked={showLiked}
              onChange={handleToggle}
              className="hidden"
            />
            <span
              style={{
                width: "50px",
                height: "24px",
                display: "flex",
                alignItems: "center",
                backgroundColor: showLiked ? "#4f46e5" : "#d1d5db",
                borderRadius: "12px",
                padding: "2px",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transform: showLiked ? "translateX(26px)" : "translateX(0)",
                  transition: "transform 0.3s",
                  position: "absolute",
                  top: "2px",
                  left: "2px",
                }}
              />
            </span>
            <span className="ml-2 text-gray-700">
              {showLiked ? "Show Liked" : "Show All"}
            </span>
          </label>
          <SortOptions
            sortOption={sortOption}
            sortOrder={sortOrder}
            setSortOption={setSortOption}
            setSortOrder={setSortOrder}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {displayedItems.map((property) => (
          <div
            key={property.id}
            className="bg-white rounded-lg shadow-md overflow-hidden relative"
          >
            <img
              src={property.image}
              alt={property.title}
              className="w-full h-48 object-cover"
            />
            <div
              className="absolute top-2 right-2 text-red-500"
              onClick={() => toggleLike(property.id)}
              style={{ cursor: "pointer" }}
            >
              {likedProperties.has(property.id) ? (
                <FaHeart size={24} />
              ) : (
                <FaRegHeart size={24} />
              )}
            </div>
            <div className="p-4">
              <h3 className="font-semibold text-lg mb-2">{property.title}</h3>
              <p className="text-gray-600 mb-2">{property.address}</p>
              <p className="text-xl font-bold mb-2">
                ${property.price.toLocaleString()}
              </p>
              <div className="flex justify-between text-gray-600 mb-2">
                <span>
                  <FaBed className="inline mr-1" /> {property.beds}
                </span>
                <span>
                  <FaBath className="inline mr-1" /> {property.baths}
                </span>
                <span>
                  <FaRuler className="inline mr-1" /> {property.size} ft²
                </span>
              </div>
              <div
                className="text-center py-1 rounded-full text-sm font-semibold"
                style={{ backgroundColor: getStatusColor(property.status) }}
              >
                {property.status}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={displayedItems.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </section>
  );
};

ResultsContainer.propTypes = {
  filteredProperties: PropTypes.array.isRequired,
  currentItems: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  showLiked: PropTypes.bool.isRequired,
  setShowLiked: PropTypes.func.isRequired,
  sortOption: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  setSortOption: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  getStatusColor: PropTypes.func.isRequired,
};

export default ResultsContainer;
