import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  TrendingUp,
  History,
  Timeline,
  Assessment,
  ShowChart,
  Compare,
  AttachMoney,
  CalendarToday,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Area,
  AreaChart,
  Tooltip as RechartsTooltip,
} from "recharts";

const PricingHistory = ({ propertyId, address }) => {
  const [timeRange, setTimeRange] = useState("5Y");
  const [chartType, setChartType] = useState("price");

  // ATTOM API Integration (commented out)
  /*
  const fetchPriceHistory = async () => {
    try {
      const response = await fetch(
        `https://api.attomdata.com/property/history?address=${address}`,
        {
          headers: {
            'apikey': import.meta.env.VITE_ATTOM_API_KEY,
            'Accept': 'application/json'
          }
        }
      );
      const propertyHistoryResponse = await response.json();

      const marketTrendsResponse = await fetch(
        `https://api.attomdata.com/market/trends?address=${address}`,
        {
          headers: {
            'apikey': import.meta.env.VITE_ATTOM_API_KEY,
            'Accept': 'application/json'
          }
        }
      );
      const marketTrendsData = await marketTrendsResponse.json();

      return {
        propertyHistory: propertyHistoryResponse.propertyHistory,
        marketTrends: marketTrendsData.marketTrends,
        comparables: propertyHistoryResponse.comparables,
      };
    } catch (error) {
      console.error('Error fetching price history:', error);
      return null;
    }
  };
  */

  // Demo pricing data
  const pricingData = {
    propertyHistory: [
      { date: "2024-01", price: 425000, event: "Current Value" },
      { date: "2023-06", price: 410000, event: "Appraisal" },
      { date: "2023-01", price: 395000, event: "Market Value" },
      { date: "2022-06", price: 380000, event: "Market Value" },
      { date: "2022-01", price: 365000, event: "Sale" },
      { date: "2021-06", price: 350000, event: "Market Value" },
      { date: "2021-01", price: 335000, event: "Market Value" },
      { date: "2020-06", price: 320000, event: "Sale" },
    ],
    marketComparison: {
      property: [
        { date: "2020-06", value: 100 },
        { date: "2021-01", value: 105 },
        { date: "2021-06", value: 109 },
        { date: "2022-01", value: 114 },
        { date: "2022-06", value: 119 },
        { date: "2023-01", value: 123 },
        { date: "2023-06", value: 128 },
        { date: "2024-01", value: 133 },
      ],
      neighborhood: [
        { date: "2020-06", value: 100 },
        { date: "2021-01", value: 104 },
        { date: "2021-06", value: 108 },
        { date: "2022-01", value: 112 },
        { date: "2022-06", value: 116 },
        { date: "2023-01", value: 120 },
        { date: "2023-06", value: 124 },
        { date: "2024-01", value: 128 },
      ],
      city: [
        { date: "2020-06", value: 100 },
        { date: "2021-01", value: 103 },
        { date: "2021-06", value: 106 },
        { date: "2022-01", value: 109 },
        { date: "2022-06", value: 112 },
        { date: "2023-01", value: 115 },
        { date: "2023-06", value: 118 },
        { date: "2024-01", value: 121 },
      ],
    },
    metrics: {
      totalAppreciation: 32.8,
      annualizedReturn: 12.5,
      volatility: "Low",
      pricePerSqFt: {
        current: 285,
        historical: [
          { date: "2020-06", value: 215 },
          { date: "2024-01", value: 285 },
        ],
        comparable: {
          neighborhood: 275,
          difference: "+3.6%",
        },
      },
      forecast: {
        oneYear: 5.2,
        threeYear: 15.8,
        confidence: "High",
      },
    },
    comparables: {
      recent: [
        {
          address: "234 Nearby St",
          price: 415000,
          date: "2024-01-15",
          sqft: 1850,
          pricePerSqFt: 224,
          daysOnMarket: 25,
        },
        {
          address: "456 Close Ave",
          price: 432000,
          date: "2024-01-10",
          sqft: 1920,
          pricePerSqFt: 225,
          daysOnMarket: 18,
        },
        {
          address: "789 Adjacent Rd",
          price: 419000,
          date: "2024-01-05",
          sqft: 1880,
          pricePerSqFt: 223,
          daysOnMarket: 30,
        },
      ],
    },
  };

  const formatPrice = (price) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);

  const getTimeRangeData = (data) => {
    const ranges = {
      "1Y": 2,
      "3Y": 6,
      "5Y": 8,
      ALL: data.length,
    };
    return data.slice(-ranges[timeRange]);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 2,
            border: 1,
            borderColor: "grey.300",
            borderRadius: 1,
            boxShadow: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontFamily: "Lato, sans-serif" }}
          >
            {label}
          </Typography>
          {payload.map((entry) => (
            <Typography
              key={entry.name}
              variant="body2"
              sx={{
                color: entry.color,
                fontFamily: "Lato, sans-serif",
              }}
            >
              {entry.name}: {formatPrice(entry.value)}
            </Typography>
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <Card sx={{ mb: 4, borderRadius: 2 }}>
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontFamily: "Lato, sans-serif",
            fontWeight: 600,
            color: "primary.main",
            mb: 3,
          }}
        >
          <History /> Price History & Market Analysis
        </Typography>

        {/* Controls */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <ToggleButtonGroup
            value={timeRange}
            exclusive
            onChange={(e, value) => value && setTimeRange(value)}
            size="small"
          >
            <ToggleButton value="1Y">1Y</ToggleButton>
            <ToggleButton value="3Y">3Y</ToggleButton>
            <ToggleButton value="5Y">5Y</ToggleButton>
            <ToggleButton value="ALL">ALL</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            value={chartType}
            exclusive
            onChange={(e, value) => value && setChartType(value)}
            size="small"
          >
            <ToggleButton value="price">Price</ToggleButton>
            <ToggleButton value="comparison">Comparison</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Main Chart */}
        <Box sx={{ height: 400, mb: 4 }}>
          <ResponsiveContainer width="100%" height="100%">
            {chartType === "price" ? (
              <AreaChart data={getTimeRangeData(pricingData.propertyHistory)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis
                  tickFormatter={(value) =>
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                      notation: "compact",
                    }).format(value)
                  }
                />
                <RechartsTooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="price"
                  name="Price"
                  stroke="#2196f3"
                  fill="#2196f3"
                  fillOpacity={0.3}
                />
              </AreaChart>
            ) : (
              <LineChart
                data={getTimeRangeData(pricingData.marketComparison.property)}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <RechartsTooltip />
                <Line
                  type="monotone"
                  dataKey="value"
                  name="Property"
                  stroke="#2196f3"
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  data={getTimeRangeData(
                    pricingData.marketComparison.neighborhood
                  )}
                  dataKey="value"
                  name="Neighborhood"
                  stroke="#4caf50"
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  data={getTimeRangeData(pricingData.marketComparison.city)}
                  dataKey="value"
                  name="City"
                  stroke="#ff9800"
                  strokeWidth={2}
                />
              </LineChart>
            )}
          </ResponsiveContainer>
        </Box>

        {/* Key Metrics */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                p: 3,
                bgcolor: "primary.light",
                borderRadius: 2,
                height: "100%",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Lato, sans-serif",
                  color: "primary.main",
                  fontWeight: 600,
                }}
              >
                {pricingData.metrics.totalAppreciation}%
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Lato, sans-serif", color: "text.secondary" }}
              >
                Total Appreciation
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Lato, sans-serif",
                  color: "success.main",
                  mt: 1,
                }}
              >
                {pricingData.metrics.annualizedReturn}% Annualized Return
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "grey.100",
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "text.secondary",
                    }}
                  >
                    Price per Sq.Ft
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "primary.main",
                      fontWeight: 600,
                    }}
                  >
                    ${pricingData.metrics.pricePerSqFt.current}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "success.main",
                    }}
                  >
                    {pricingData.metrics.pricePerSqFt.comparable.difference} vs
                    Neighborhood
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "grey.100",
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "text.secondary",
                    }}
                  >
                    Forecast (1 Year)
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "primary.main",
                      fontWeight: 600,
                    }}
                  >
                    +{pricingData.metrics.forecast.oneYear}%
                  </Typography>
                  <Chip
                    label={pricingData.metrics.forecast.confidence}
                    color="success"
                    size="small"
                    sx={{ mt: 1 }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Comparable Sales */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
            }}
          >
            <Compare /> Recent Comparable Sales
          </Typography>
          <Grid container spacing={2}>
            {pricingData.comparables.recent.map((comp) => (
              <Grid item xs={12} md={4} key={comp.address}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "grey.100",
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      fontWeight: 600,
                      mb: 1,
                    }}
                  >
                    {comp.address}
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "text.secondary",
                        }}
                      >
                        Price
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "primary.main",
                          fontWeight: 600,
                        }}
                      >
                        {formatPrice(comp.price)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "text.secondary",
                        }}
                      >
                        Price/Sq.Ft
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "primary.main",
                          fontWeight: 600,
                        }}
                      >
                        ${comp.pricePerSqFt}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "text.secondary",
                        }}
                      >
                        Square Feet
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Lato, sans-serif" }}
                      >
                        {comp.sqft.toLocaleString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "text.secondary",
                        }}
                      >
                        Days Listed
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Lato, sans-serif" }}
                      >
                        {comp.daysOnMarket}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mt: 2,
                    }}
                  >
                    <CalendarToday
                      fontSize="small"
                      sx={{ color: "text.secondary" }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "text.secondary",
                      }}
                    >
                      Sold on {new Date(comp.date).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Market Value Forecast */}
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
            }}
          >
            <ShowChart /> Value Forecast
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "success.light",
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: "text.secondary",
                    mb: 1,
                  }}
                >
                  3-Year Forecast
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: "success.main",
                    fontWeight: 600,
                  }}
                >
                  +{pricingData.metrics.forecast.threeYear}%
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: "success.dark",
                    mt: 1,
                  }}
                >
                  Estimated Growth
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "grey.100",
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "text.secondary",
                      }}
                    >
                      Current Value
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "primary.main",
                        fontWeight: 600,
                      }}
                    >
                      {formatPrice(pricingData.propertyHistory[0].price)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "text.secondary",
                      }}
                    >
                      1-Year Forecast
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "success.main",
                        fontWeight: 600,
                      }}
                    >
                      {formatPrice(
                        pricingData.propertyHistory[0].price *
                          (1 + pricingData.metrics.forecast.oneYear / 100)
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "text.secondary",
                      }}
                    >
                      Forecast Confidence
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <Chip
                        label={pricingData.metrics.forecast.confidence}
                        color="success"
                        sx={{ fontFamily: "Lato, sans-serif" }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

PricingHistory.propTypes = {
  propertyId: PropTypes.string,
  address: PropTypes.string,
  priceHistory: PropTypes.array,
};

PricingHistory.defaultProps = {
  propertyId: "",
  address: "",
  priceHistory: [],
};

export default PricingHistory;
