import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../api";
import { Link, useNavigate } from "react-router-dom";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { useLikeListings } from "../hooks/useLikeListings";
import {
  Squares2X2Icon,
  ListBulletIcon,
  AdjustmentsHorizontalIcon,
  PlusIcon,
  FunnelIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useInView } from "react-intersection-observer";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import Modal from "react-modal";
import { motion } from "framer-motion";

import {
  setSelectedProperty,
  addToDashboard,
  removeFromDashboard,
} from "../redux/actions/dashboardActions";

const MySaves = () => {
  const [filteredListings, setFilteredListings] = useState([]);
  const [filters, setFilters] = useState({
    propertyType: "",
    bedrooms: "",
    bathrooms: "",
    squareFootage: "",
    status: "",
    minPrice: "",
    maxPrice: "",
  });
  const [sortBy, setSortBy] = useState("price");
  const [viewMode, setViewMode] = useState("grid");
  const [compareListings, setCompareListings] = useState([]);
  const [quickViewListing, setQuickViewListing] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardListings = useSelector(
    (state) => state.dashboard.dashboardListings
  );

  const currentUser = useSelector((state) => state.user.currentUser);
  const { likedListings, fetchLikeListings, fetchLikedListings, toggleLike } =
    useLikeListings();
  const notyf = new Notyf({
    duration: 3000,
    position: { x: "right", y: "top" },
  });

  useEffect(() => {
    if (currentUser) {
      fetchLikedListings();
    }
  }, [currentUser, fetchLikeListings]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    applyFilters();
  }, [likedListings, filters, sortBy]);

  const applyFilters = () => {
    let filtered = likedListings.filter((listing) => {
      return (
        (!filters.propertyType ||
          listing.propertyType === filters.propertyType) &&
        (!filters.bedrooms || listing.bedrooms >= parseInt(filters.bedrooms)) &&
        (!filters.bathrooms ||
          listing.bathrooms >= parseInt(filters.bathrooms)) &&
        (!filters.squareFootage ||
          listing.squareFootage >= parseInt(filters.squareFootage)) &&
        (!filters.status || listing.status === filters.status) &&
        (!filters.minPrice || listing.price >= parseInt(filters.minPrice)) &&
        (!filters.maxPrice || listing.price <= parseInt(filters.maxPrice))
      );
    });

    filtered.sort((a, b) => {
      if (sortBy === "price") return a.price - b.price;
      if (sortBy === "bedrooms") return b.bedrooms - a.bedrooms;
      if (sortBy === "squareFootage") return b.squareFootage - a.squareFootage;
      return 0;
    });

    setFilteredListings(filtered);
    setPage(1);
    setHasMore(true);
  };

  const saveFilter = async () => {
    try {
      await api.post("/api/user/save-filter", {
        userId: currentUser._id,
        filters,
      });
      notyf.success("Filter saved successfully");
    } catch (error) {
      console.error("Error saving filter:", error);
      notyf.error("Failed to save filter. Please try again.");
    }
  };

  const handleRemoveLike = async (listingId) => {
    try {
      await toggleLike(listingId);
      notyf.success("Listing removed from saved listings");
    } catch (error) {
      console.error("Error removing like:", error);
      notyf.error("Failed to remove listing. Please try again.");
    }
  };

  const handleCompare = (listing) => {
    if (compareListings.find((l) => l._id === listing._id)) {
      setCompareListings(compareListings.filter((l) => l._id !== listing._id));
    } else if (compareListings.length < 3) {
      setCompareListings([...compareListings, listing]);
    } else {
      notyf.error("You can compare up to 3 listings at a time");
    }
  };

  const handleQuickView = (listing) => {
    setQuickViewListing(listing);
  };

  const closeQuickView = () => {
    setQuickViewListing(null);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle("dark");
  };

  const loadMore = useCallback(() => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore]);

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView, loadMore]);

  const displayedListings = filteredListings.slice(0, page * 9);
  const handleAddToDashboard = (listing) => {
    if (dashboardListings.find((l) => l._id === listing._id)) {
      dispatch(removeFromDashboard(listing._id));
      notyf.success("Listing removed from Real Estate Dashboard");
    } else {
      dispatch(addToDashboard(listing));
      notyf.success("Listing added to Real Estate Dashboard");
    }
  };

  const handleListingClick = (listing) => {
    dispatch(setSelectedProperty(listing));
    navigate("/real-estate-dashboard");
  };
  return (
    <div className={`container mx-auto px-4 py-8 ${isDarkMode ? "dark" : ""}`}>
      <h1 className="text-3xl font-bold mb-6 dark:text-white">
        My Saved Listings
      </h1>

      <motion.div
        className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-6"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-xl font-semibold mb-4 dark:text-white">Filters</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex items-center">
            <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400" />
            <input
              type="text"
              name="propertyType"
              placeholder="Property Type"
              value={filters.propertyType}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full dark:bg-gray-700 dark:text-white"
            />
          </div>
          <div className="flex items-center">
            <Squares2X2Icon className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400" />
            <input
              type="number"
              name="bedrooms"
              placeholder="Bedrooms"
              value={filters.bedrooms}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full dark:bg-gray-700 dark:text-white"
            />
          </div>
          <div className="flex items-center">
            <Squares2X2Icon className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400" />
            <input
              type="number"
              name="bathrooms"
              placeholder="Bathrooms"
              value={filters.bathrooms}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full dark:bg-gray-700 dark:text-white"
            />
          </div>
          <div className="flex items-center">
            <Squares2X2Icon className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400" />
            <input
              type="number"
              name="squareFootage"
              placeholder="Square Footage"
              value={filters.squareFootage}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full dark:bg-gray-700 dark:text-white"
            />
          </div>
          <div className="flex items-center">
            <FunnelIcon className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400" />
            <input
              type="text"
              name="status"
              placeholder="Status"
              value={filters.status}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full dark:bg-gray-700 dark:text-white"
            />
          </div>
          <div className="flex items-center">
            <PlusIcon className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400" />
            <input
              type="number"
              name="minPrice"
              placeholder="Min Price"
              value={filters.minPrice}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full dark:bg-gray-700 dark:text-white"
            />
          </div>
          <div className="flex items-center">
            <PlusIcon className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400" />
            <input
              type="number"
              name="maxPrice"
              placeholder="Max Price"
              value={filters.maxPrice}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full dark:bg-gray-700 dark:text-white"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <div>
            <label htmlFor="sortBy" className="mr-2 dark:text-white">
              Sort by:
            </label>
            <select
              id="sortBy"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="p-2 border rounded dark:bg-gray-700 dark:text-white"
            >
              <option value="price">Price</option>
              <option value="bedrooms">Bedrooms</option>
              <option value="squareFootage">Square Footage</option>
            </select>
          </div>
          <div>
            <button
              onClick={() => setViewMode(viewMode === "grid" ? "list" : "grid")}
              className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
            >
              {viewMode === "grid" ? (
                <ListBulletIcon className="h-5 w-5" />
              ) : (
                <Squares2X2Icon className="h-5 w-5" />
              )}
            </button>
            <button
              onClick={applyFilters}
              className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
            >
              Apply Filters
            </button>
            <button
              onClick={saveFilter}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
              Save Filter
            </button>
          </div>
        </div>
      </motion.div>

      <TransitionGroup
        className={`${
          viewMode === "grid"
            ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            : "space-y-4"
        }`}
      >
        {displayedListings.map((listing) => (
          <CSSTransition key={listing._id} timeout={500} classNames="fade">
            <motion.div
              className={`bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden relative ${
                viewMode === "list" ? "flex" : ""
              }`}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <button
                onClick={() => handleAddToDashboard(listing)}
                className={`absolute top-2 right-12 p-2 rounded-full ${
                  dashboardListings.find((l) => l._id === listing._id)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                } hover:bg-blue-600 hover:text-white`}
                title={
                  dashboardListings.find((l) => l._id === listing._id)
                    ? "Remove from Real Estate Dashboard"
                    : "Add to Real Estate Dashboard"
                }
              >
                <ChartBarIcon className="h-6 w-6" />
              </button>
              <img
                src={listing.imageUrls[0]}
                alt={listing.name}
                className={`${
                  viewMode === "grid" ? "w-full h-48" : "w-1/3 h-full"
                } object-cover cursor-pointer`}
                onClick={() => handleListingClick(listing)}
              />
              <div className={`p-4 ${viewMode === "list" ? "flex-1" : ""}`}>
                <Link
                  to={`/listing/${listing._id}`}
                  className="text-xl font-semibold text-blue-600 hover:underline dark:text-blue-400"
                >
                  {listing.name}
                </Link>
                <p className="text-gray-600 dark:text-gray-300">
                  ${listing.price.toLocaleString()}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {listing.address}
                </p>
                <div className="mt-2">
                  <span className="text-sm bg-gray-200 dark:bg-gray-700 rounded-full px-2 py-1 mr-2">
                    {listing.bedrooms} beds
                  </span>
                  <span className="text-sm bg-gray-200 dark:bg-gray-700 rounded-full px-2 py-1 mr-2">
                    {listing.bathrooms} baths
                  </span>
                  <span className="text-sm bg-gray-200 dark:bg-gray-700 rounded-full px-2 py-1">
                    {listing.squareFootage} sqft
                  </span>
                </div>
                <div className="mt-2">
                  {listing.status && (
                    <span
                      className={`text-sm rounded-full px-2 py-1 ${getStatusColor(
                        listing.status
                      )}`}
                    >
                      {listing.status}
                    </span>
                  )}
                </div>
                <button
                  onClick={() => handleCompare(listing)}
                  className={`mt-2 px-2 py-1 rounded ${
                    compareListings.find((l) => l._id === listing._id)
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  {compareListings.find((l) => l._id === listing._id)
                    ? "Remove from Compare"
                    : "Add to Compare"}
                </button>
              </div>
            </motion.div>
          </CSSTransition>
        ))}
      </TransitionGroup>

      {hasMore && (
        <div ref={ref} className="flex justify-center mt-8">
          <div className="loader"></div>
        </div>
      )}

      <Modal
        isOpen={quickViewListing !== null}
        onRequestClose={closeQuickView}
        contentLabel="Quick View"
        className="modal"
        overlayClassName="overlay"
      >
        {quickViewListing && (
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 dark:text-white">
              {quickViewListing.name}
            </h2>
            <img
              src={quickViewListing.imageUrls[0]}
              alt={quickViewListing.name}
              className="w-full h-64 object-cover mb-4"
            />
            <p className="text-xl font-semibold dark:text-white">
              ${quickViewListing.price.toLocaleString()}
            </p>
            <p className="text-gray-600 dark:text-gray-300">
              {quickViewListing.address}
            </p>
            <div className="mt-4">
              <p className="dark:text-white">
                {quickViewListing.bedrooms} beds | {quickViewListing.bathrooms}{" "}
                baths | {quickViewListing.squareFootage} sqft
              </p>
              <p className="mt-2 dark:text-white">
                Status:{" "}
                <span
                  className={`px-2 py-1 rounded-full ${getStatusColor(
                    quickViewListing.status
                  )}`}
                >
                  {quickViewListing.status}
                </span>
              </p>
            </div>
            <button
              onClick={closeQuickView}
              className="mt-6 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        )}
      </Modal>

      {compareListings.length > 0 && (
        <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 p-4 shadow-md">
          <h3 className="text-lg font-semibold mb-2 dark:text-white">
            Compare Listings
          </h3>
          <div className="flex space-x-4">
            {compareListings.map((listing) => (
              <div key={listing._id} className="flex-1">
                <img
                  src={listing.imageUrls[0]}
                  alt={listing.name}
                  className="w-full h-32 object-cover"
                />
                <p className="mt-2 font-semibold dark:text-white">
                  {listing.name}
                </p>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  ${listing.price.toLocaleString()}
                </p>
              </div>
            ))}
          </div>
          <button
            onClick={() => setCompareListings([])}
            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Clear Comparison
          </button>
        </div>
      )}

      <div className="fixed bottom-4 right-4 flex flex-col space-y-2">
        <button
          onClick={() => {
            /* Add new filter logic */
          }}
          className="bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600"
          title="Add new filter"
        >
          <PlusIcon className="h-6 w-6" />
        </button>
        <button
          onClick={() => {
            /* View saved filters logic */
          }}
          className="bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600"
          title="View saved filters"
        >
          <FunnelIcon className="h-6 w-6" />
        </button>
      </div>

      <div className="fixed top-4 right-4">
        <DarkModeSwitch
          checked={isDarkMode}
          onChange={toggleDarkMode}
          size={30}
        />
      </div>
    </div>
  );
};

const getStatusColor = (status) => {
  if (!status) return "bg-gray-500 text-white"; // Default color if status is undefined
  switch (status.toLowerCase()) {
    case "for sale":
      return "bg-green-500 text-white";
    case "pending":
      return "bg-yellow-500 text-white";
    case "sold":
      return "bg-red-500 text-white";
    default:
      return "bg-gray-500 text-white";
  }
};

export default MySaves;
