import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  LinearProgress,
} from "@mui/material";
import {
  Psychology,
  TrendingUp,
  AttachMoney,
  Assessment,
  Group,
  Warning,
} from "@mui/icons-material";

// eslint-disable-next-line no-unused-vars
const AIInsights = ({ listing, userProfile }) => {
  // ATTOM API Integration (commented out)
  /*
  const fetchPropertyInsights = async () => {
    try {
      const attomResponse = await fetch(
        `https://api.attomdata.com/property/detailwithschools?address=${listing.address}`,
        {
          headers: {
            'apikey': import.meta.env.VITE_ATTOM_API_KEY,
            'Accept': 'application/json'
          }
        }
      );
      const attomData = await attomResponse.json();
      
      // Process ATTOM data for AI analysis
      const propertyData = {
        assessedValue: attomData.property[0].assessment.assessed.assdttlvalue,
        lastSalePrice: attomData.property[0].sale.amount,
        yearBuilt: attomData.property[0].summary.yearBuilt,
        lotSize: attomData.property[0].lot.lotsize1,
        schools: attomData.property[0].school,
        avm: attomData.property[0].avm.amount,
      };

      return propertyData;
    } catch (error) {
      console.error('Error fetching ATTOM data:', error);
      return null;
    }
  };

  // Anthropic API Integration
  const getAIAnalysis = async (propertyData) => {
    try {
      const response = await fetch('https://api.anthropic.com/v1/complete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': import.meta.env.VITE_ANTHROPIC_API_KEY,
        },
        body: JSON.stringify({
          prompt: `Analyze this property investment opportunity:
            Location: ${listing.address}
            Price: ${listing.price}
            Property Data: ${JSON.stringify(propertyData)}
            Current Market Conditions: [market_data]
            Investment Goals: ${userProfile?.investmentPreferences || 'Not specified'}`,
          max_tokens_to_sample: 500,
          model: "claude-2",
        })
      });
      const aiAnalysis = await response.json();
      return aiAnalysis.completion;
    } catch (error) {
      console.error('Error getting AI analysis:', error);
      return null;
    }
  };
  */

  // Demo insights data
  const insights = {
    marketPosition: {
      score: 85,
      level: "Strong",
      factors: [
        { name: "Price Competitiveness", value: 90 },
        { name: "Location Desirability", value: 85 },
        { name: "Market Demand", value: 82 },
      ],
    },
    investmentPotential: {
      score: 78,
      level: "High",
      projectedGrowth: "12.5%",
      riskLevel: "Moderate",
      recommendations: [
        "Consider Long-Term Hold Strategy",
        "Potential For Value-Add Improvements",
        "Strong Rental Market Opportunity",
      ],
    },
    propertyAnalysis: {
      condition: "Good",
      uniqueFeatures: [
        "Recently Renovated Kitchen",
        "Smart Home Features",
        "Energy Efficient Appliances",
      ],
      improvements: [
        { item: "Bathroom Update", costEstimate: "$8,000" },
        { item: "HVAC Replacement", costEstimate: "$6,000" },
      ],
    },
    demographicTrends: {
      populationGrowth: "+5.2%",
      medianIncome: "$75,000",
      employmentGrowth: "+3.8%",
    },
    riskAssessment: {
      overall: "Low",
      factors: {
        market: "Low",
        property: "Low",
        financial: "Moderate",
        regulatory: "Low",
      },
    },
  };

  return (
    <Card sx={{ mb: 4, borderRadius: 2, bgcolor: "#fff" }}>
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontFamily: "Lato, sans-serif",
            fontWeight: 600,
            color: "#1565c0", // Darker blue
            mb: 3,
          }}
        >
          <Psychology /> AI Investment Analysis
        </Typography>

        {/* Market Position */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <TrendingUp /> Market Position
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#1565c0", // Darker blue
                    fontWeight: "bold",
                    fontFamily: "Lato, sans-serif",
                  }}
                >
                  {insights.marketPosition.score}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#333", // Darker text color
                    fontFamily: "Lato, sans-serif",
                  }}
                >
                  Market Score
                </Typography>
                <Chip
                  label={insights.marketPosition.level}
                  sx={{ 
                    mt: 1, 
                    bgcolor: "#ed6c02", // Darker warning color
                    color: "#fff",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box>
                {insights.marketPosition.factors.map((factor) => (
                  <Box key={factor.name} sx={{ mb: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography sx={{ fontFamily: "Lato, sans-serif", color: "#333" }}>
                        {factor.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "#1565c0", // Darker blue
                        }}
                      >
                        {factor.value}%
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={factor.value}
                      sx={{
                        height: 8,
                        borderRadius: 4,
                        bgcolor: "#e0e0e0",
                        "& .MuiLinearProgress-bar": {
                          borderRadius: 4,
                          bgcolor: "#ed6c02", // Darker warning color
                        },
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Investment Potential */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <AttachMoney /> Investment Potential
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1565c0", // Darker blue
                    fontWeight: "bold",
                    fontFamily: "Lato, sans-serif",
                  }}
                >
                  {insights.investmentPotential.projectedGrowth}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ 
                    fontFamily: "Lato, sans-serif",
                    color: "#333", // Darker text color
                  }}
                >
                  Projected Growth
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "rgba(237, 108, 2, 0.1)", // Lighter shade of darker warning
                  borderRadius: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "#ed6c02", // Darker warning color
                    fontWeight: "bold",
                    fontFamily: "Lato, sans-serif",
                  }}
                >
                  {insights.investmentPotential.riskLevel}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ 
                    fontFamily: "Lato, sans-serif",
                    color: "#333", // Darker text color
                  }}
                >
                  Risk Level
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 1,
                    fontWeight: "bold",
                    fontFamily: "Lato, sans-serif",
                    color: "#1565c0", // Darker blue
                  }}
                >
                  Key Recommendations
                </Typography>
                {insights.investmentPotential.recommendations.map((rec) => (
                  <Chip
                    key={rec}
                    label={rec}
                    sx={{ 
                      m: 0.5, 
                      fontFamily: "Lato, sans-serif",
                      bgcolor: "#ed6c02", // Darker warning color
                      color: "#fff",
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Property Analysis */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <Assessment /> Property Analysis
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 2,
                    fontWeight: "bold",
                    fontFamily: "Lato, sans-serif",
                    color: "#1565c0", // Darker blue
                  }}
                >
                  Unique Features
                </Typography>
                {insights.propertyAnalysis.uniqueFeatures.map((feature) => (
                  <Chip
                    key={feature}
                    label={feature}
                    sx={{ 
                      m: 0.5, 
                      fontFamily: "Lato, sans-serif",
                      bgcolor: "#ed6c02", // Darker warning color
                      color: "#fff",
                    }}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "rgba(237, 108, 2, 0.1)", // Lighter shade of darker warning
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 2,
                    fontWeight: "bold",
                    fontFamily: "Lato, sans-serif",
                    color: "#ed6c02", // Darker warning color
                  }}
                >
                  Suggested Improvements
                </Typography>
                {insights.propertyAnalysis.improvements.map((imp) => (
                  <Box
                    key={imp.item}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ 
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}>
                      {imp.item}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#ed6c02", // Darker warning color
                        fontWeight: "bold",
                      }}
                    >
                      {imp.costEstimate}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Demographic Trends */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <Group /> Demographic Trends
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(insights.demographicTrends).map(([key, value]) => (
              <Grid item xs={12} sm={4} key={key}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                    borderRadius: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#1565c0", // Darker blue
                      fontWeight: "bold",
                      fontFamily: "Lato, sans-serif",
                    }}
                  >
                    {value}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ 
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}
                  >
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .split(" ")
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                      .join(" ")}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Risk Assessment */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <Warning /> Risk Assessment
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(insights.riskAssessment.factors).map(
              ([key, value]) => (
                <Grid item xs={12} sm={6} md={3} key={key}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: value === "Low" 
                        ? "rgba(21, 101, 192, 0.1)" // Lighter shade of darker blue
                        : "rgba(237, 108, 2, 0.1)", // Lighter shade of darker warning
                      borderRadius: 2,
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: value === "Low" 
                          ? "#1565c0" // Darker blue
                          : "#ed6c02", // Darker warning color
                        fontWeight: "bold",
                        fontFamily: "Lato, sans-serif",
                      }}
                    >
                      {value}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      {key.charAt(0).toUpperCase() + key.slice(1)} Risk
                    </Typography>
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

AIInsights.propTypes = {
  listing: PropTypes.shape({
    address: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
  userProfile: PropTypes.object,
};

export default AIInsights;
