import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Slider,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  Divider,
  Tooltip,
} from "@mui/material";
import { Calculate, AccountBalance, TrendingUp } from "@mui/icons-material";

const formatCurrency = (value) => {
  if (typeof window === 'undefined') return `$${value}`;
  return new window.Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const MortgageCalculator = ({
  propertyPrice = 351200,
  expectedRent = 2800,
}) => {
  const [loanAmount, setLoanAmount] = useState(propertyPrice * 0.8);
  const [downPayment, setDownPayment] = useState(propertyPrice * 0.2);
  const [interestRate, setInterestRate] = useState(3.5);
  const [loanTerm, setLoanTerm] = useState(30);
  const [includeRental, setIncludeRental] = useState(true);
  const [rentalIncome, setRentalIncome] = useState(expectedRent);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [monthlyExpenses, setMonthlyExpenses] = useState({
    taxes: (propertyPrice * 0.012) / 12,
    insurance: (propertyPrice * 0.005) / 12,
    maintenance: 200,
    pmi: 0,
  });

  useEffect(() => {
    calculateMortgage();
  }, [loanAmount, interestRate, loanTerm, monthlyExpenses]);

  const calculateMortgage = () => {
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;

    let pmi = 0;
    if (loanAmount / propertyPrice > 0.8) {
      pmi = (loanAmount * 0.01) / 12;
    }

    const principalAndInterest =
      (loanAmount *
        (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments))) /
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    const totalMonthlyPayment =
      principalAndInterest +
      monthlyExpenses.taxes +
      monthlyExpenses.insurance +
      monthlyExpenses.maintenance +
      pmi;

    setMonthlyPayment(totalMonthlyPayment);
    setMonthlyExpenses((prev) => ({ ...prev, pmi }));
  };

  const handleDownPaymentChange = (percent) => {
    const newDownPayment = propertyPrice * (percent / 100);
    setDownPayment(newDownPayment);
    setLoanAmount(propertyPrice - newDownPayment);
  };

  const getNetCashflow = () => {
    return includeRental ? rentalIncome - monthlyPayment : -monthlyPayment;
  };

  const getCashflowColor = () => {
    const cashflow = getNetCashflow();
    return cashflow > 0 ? "#2e7d32" : "#d32f2f"; // Darker success/error colors
  };

  return (
    <Card sx={{ borderRadius: 2, mb: 4, bgcolor: "#fff" }}>
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontFamily: "Lato, sans-serif",
            fontWeight: 600,
            color: "#1565c0", // Darker blue
            mb: 3,
          }}
        >
          <Calculate /> Investment Calculator
        </Typography>

        <Grid container spacing={4}>
          {/* Left Column - Inputs */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Typography gutterBottom sx={{ fontFamily: "Lato, sans-serif", color: "#333" }}>
                Purchase Price: {formatCurrency(propertyPrice)}
              </Typography>

              <Typography gutterBottom sx={{ fontFamily: "Lato, sans-serif", color: "#333" }}>
                Down Payment: {formatCurrency(downPayment)} (
                {((downPayment / propertyPrice) * 100).toFixed(1)}%)
              </Typography>
              <Slider
                value={(downPayment / propertyPrice) * 100}
                onChange={(_, newValue) => handleDownPaymentChange(newValue)}
                min={5}
                max={50}
                step={1}
                marks={[
                  { value: 5, label: "5%" },
                  { value: 20, label: "20%" },
                  { value: 50, label: "50%" },
                ]}
                sx={{
                  color: "#1565c0", // Darker blue
                  '& .MuiSlider-thumb': {
                    backgroundColor: "#ed6c02", // Darker warning color
                  },
                }}
              />
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography gutterBottom sx={{ fontFamily: "Lato, sans-serif", color: "#333" }}>
                Loan Term: {loanTerm} Years
              </Typography>
              <Slider
                value={loanTerm}
                onChange={(_, newValue) => setLoanTerm(newValue)}
                min={15}
                max={30}
                step={5}
                marks={[
                  { value: 15, label: "15y" },
                  { value: 20, label: "20y" },
                  { value: 25, label: "25y" },
                  { value: 30, label: "30y" },
                ]}
                sx={{
                  color: "#1565c0", // Darker blue
                  '& .MuiSlider-thumb': {
                    backgroundColor: "#ed6c02", // Darker warning color
                  },
                }}
              />
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography gutterBottom sx={{ fontFamily: "Lato, sans-serif", color: "#333" }}>
                Interest Rate: {interestRate}%
              </Typography>
              <Slider
                value={interestRate}
                onChange={(_, newValue) => setInterestRate(newValue)}
                min={2}
                max={8}
                step={0.125}
                marks={[
                  { value: 2, label: "2%" },
                  { value: 5, label: "5%" },
                  { value: 8, label: "8%" },
                ]}
                sx={{
                  color: "#1565c0", // Darker blue
                  '& .MuiSlider-thumb': {
                    backgroundColor: "#ed6c02", // Darker warning color
                  },
                }}
              />
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography gutterBottom sx={{ fontFamily: "Lato, sans-serif", color: "#333" }}>
                Monthly Expenses
              </Typography>
              <Grid container spacing={2}>
                {Object.entries(monthlyExpenses).map(([key, value]) => (
                  <Grid item xs={12} key={key}>
                    <TextField
                      fullWidth
                      label={key
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                        .split(" ")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(" ")}
                      type="number"
                      value={value}
                      onChange={(e) =>
                        setMonthlyExpenses((prev) => ({
                          ...prev,
                          [key]: Number(e.target.value),
                        }))
                      }
                      InputProps={{
                        startAdornment: "$",
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#1565c0', // Darker blue
                          },
                          '&:hover fieldset': {
                            borderColor: '#ed6c02', // Darker warning color
                          },
                        },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box sx={{ mb: 3 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeRental}
                    onChange={(e) => setIncludeRental(e.target.checked)}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: "#ed6c02", // Darker warning color
                        '& + .MuiSwitch-track': {
                          backgroundColor: "#1565c0", // Darker blue
                        },
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ fontFamily: "Lato, sans-serif", color: "#333" }}>
                    Include Rental Income
                  </Typography>
                }
              />
              {includeRental && (
                <TextField
                  fullWidth
                  label="Monthly Rental Income"
                  type="number"
                  value={rentalIncome}
                  onChange={(e) => setRentalIncome(Number(e.target.value))}
                  InputProps={{
                    startAdornment: "$",
                  }}
                  sx={{
                    mt: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1565c0', // Darker blue
                      },
                      '&:hover fieldset': {
                        borderColor: '#ed6c02', // Darker warning color
                      },
                    },
                  }}
                />
              )}
            </Box>
          </Grid>

          {/* Right Column - Results */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{ 
                  fontFamily: "Lato, sans-serif",
                  mb: 2,
                  color: "#1565c0", // Darker blue
                }}
              >
                Monthly Payment Breakdown
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontFamily: "Lato, sans-serif", color: "#333" }}>
                      Principal & Interest
                    </Typography>
                    <Typography
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        fontWeight: 600,
                        color: "#1565c0", // Darker blue
                      }}
                    >
                      {formatCurrency(
                        monthlyPayment -
                          Object.values(monthlyExpenses).reduce(
                            (a, b) => a + b,
                            0
                          )
                      )}
                    </Typography>
                  </Box>
                </Grid>
                {Object.entries(monthlyExpenses).map(([key, value]) => (
                  <Grid item xs={12} key={key}>
                    <Box
                      sx={{
                        p: 2,
                        bgcolor: "rgba(237, 108, 2, 0.1)", // Lighter shade of darker warning
                        borderRadius: 2,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontFamily: "Lato, sans-serif", color: "#333" }}>
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .trim()
                          .split(" ")
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                          .join(" ")}
                      </Typography>
                      <Typography sx={{ 
                        fontFamily: "Lato, sans-serif",
                        color: "#ed6c02", // Darker warning color
                      }}>
                        {formatCurrency(value)}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Divider sx={{ my: 3 }} />

            {/* Monthly Summary */}
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{ 
                  fontFamily: "Lato, sans-serif",
                  mb: 2,
                  color: "#1565c0", // Darker blue
                }}
              >
                Monthly Summary
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        fontWeight: 600,
                        color: "#333",
                      }}
                    >
                      Total Monthly Payment
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        fontWeight: 600,
                        color: "#1565c0", // Darker blue
                      }}
                    >
                      {formatCurrency(monthlyPayment)}
                    </Typography>
                  </Box>
                </Grid>

                {includeRental && (
                  <>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          p: 2,
                          bgcolor: "rgba(237, 108, 2, 0.1)", // Lighter shade of darker warning
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ fontFamily: "Lato, sans-serif", color: "#333" }}>
                          Monthly Rental Income
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            fontWeight: 600,
                            color: "#ed6c02", // Darker warning color
                          }}
                        >
                          {formatCurrency(rentalIncome)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          p: 2,
                          bgcolor: getNetCashflow() > 0
                            ? "rgba(46, 125, 50, 0.1)" // Lighter shade of darker success
                            : "rgba(211, 47, 47, 0.1)", // Lighter shade of darker error
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            fontWeight: 600,
                            color: "#333",
                          }}
                        >
                          Net Monthly Cashflow
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            fontWeight: 600,
                            color: getCashflowColor(),
                          }}
                        >
                          {formatCurrency(getNetCashflow())}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>

            {/* Investment Metrics */}
            <Box>
              <Typography
                variant="h6"
                sx={{ 
                  fontFamily: "Lato, sans-serif",
                  mb: 2,
                  color: "#1565c0", // Darker blue
                }}
              >
                Investment Metrics
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Tooltip title="Annual Return On Investment Based On Rental Income">
                    <Box
                      sx={{
                        p: 2,
                        bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                        borderRadius: 2,
                        textAlign: "center",
                      }}
                    >
                      <TrendingUp sx={{ fontSize: 32, color: "#1565c0" }} /> {/* Darker blue */}
                      <Typography
                        sx={{ 
                          fontFamily: "Lato, sans-serif",
                          mt: 1,
                          color: "#333",
                        }}
                      >
                        ROI
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "#1565c0", // Darker blue
                        }}
                      >
                        {(
                          ((rentalIncome * 12 - monthlyPayment * 12) /
                            downPayment) *
                          100
                        ).toFixed(1)}
                        %
                      </Typography>
                    </Box>
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title="Capitalization Rate">
                    <Box
                      sx={{
                        p: 2,
                        bgcolor: "rgba(237, 108, 2, 0.1)", // Lighter shade of darker warning
                        borderRadius: 2,
                        textAlign: "center",
                      }}
                    >
                      <AccountBalance sx={{ fontSize: 32, color: "#ed6c02" }} /> {/* Darker warning color */}
                      <Typography
                        sx={{ 
                          fontFamily: "Lato, sans-serif",
                          mt: 1,
                          color: "#333",
                        }}
                      >
                        Cap Rate
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "#ed6c02", // Darker warning color
                        }}
                      >
                        {(((rentalIncome * 12) / propertyPrice) * 100).toFixed(1)}%
                      </Typography>
                    </Box>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

MortgageCalculator.propTypes = {
  propertyPrice: PropTypes.number,
  expectedRent: PropTypes.number,
};

export default MortgageCalculator;
