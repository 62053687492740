import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Paper, Avatar } from "@mui/material";
import { format } from "date-fns";
import { Check, CheckCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

const MessageList = ({ messages, currentUser, otherParticipant }) => {
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const MessageStatus = ({ status }) => (
    <span className="ml-1 inline-flex">
      {status === "sent" && <Check className="w-3 h-3 text-gray-400" />}
      {status === "delivered" && (
        <div className="flex">
          <Check className="w-3 h-3 text-blue-400" />
          <Check className="w-3 h-3 text-blue-400 -ml-1" />
        </div>
      )}
      {status === "read" && (
        <div className="flex">
          <Check className="w-3 h-3 text-blue-600" />
          <Check className="w-3 h-3 text-blue-600 -ml-1" />
        </div>
      )}
    </span>
  );

  MessageStatus.propTypes = {
    status: PropTypes.oneOf(["sent", "delivered", "read"]).isRequired,
  };

  const formatRole = (role) => {
    if (!role) return '';
    return role
      .split(/(?=[A-Z])/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleUsernameClick = (username) => {
    navigate(`/profile/${username}`);
  };

  const renderRole = (participant) => {
    if (!participant?.role) {
      console.log('No role found for participant:', participant);
      return null;
    }
    return (
      <Typography
        variant="caption"
        sx={{
          px: 1,
          py: 0.5,
          bgcolor: "#dbeafe",
          color: "#1e40af",
          borderRadius: "9999px",
          fontFamily: "Lato, sans-serif"
        }}
      >
        {formatRole(participant.role)}
      </Typography>
    );
  };

  if (!messages || messages.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontFamily: "Lato, sans-serif"
        }}
      >
        <Typography variant="body1" sx={{ fontFamily: "Lato, sans-serif" }}>
          Select a conversation to start messaging
        </Typography>
      </Box>
    );
  }

  let lastDate = null;

  const sortedMessages = [...messages].sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  return (
    <Box
      sx={{
        height: "calc(100vh - 200px)",
        overflowY: "auto",
        p: 2,
        display: "flex",
        flexDirection: "column",
        fontFamily: "Lato, sans-serif"
      }}
    >
      {sortedMessages.map((message) => {
        if (!message._id || !message.content || !message.createdAt) return null;

        const isCurrentUser = message.senderId === currentUser._id;
        const messageDate = new Date(message.createdAt);
        const formattedDate = format(messageDate, "MMMM d, yyyy");
        const showDateDivider = lastDate !== formattedDate;
        lastDate = formattedDate;

        const participant = isCurrentUser ? currentUser : otherParticipant;

        return (
          <React.Fragment key={message._id}>
            {showDateDivider && (
              <Box sx={{ textAlign: "center", my: 2 }}>
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ fontFamily: "Lato, sans-serif" }}
                >
                  {formattedDate}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: isCurrentUser ? "flex-end" : "flex-start",
                mb: 2,
                width: "100%",
                gap: 1,
              }}
            >
              {!isCurrentUser && (
                <Box sx={{ position: 'relative' }}>
                  <Avatar
                    src={participant?.avatar}
                    alt={participant?.username}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: -2,
                      right: -2,
                      width: 8,
                      height: 8,
                      bgcolor: 'success.main',
                      borderRadius: '50%',
                      border: '2px solid white',
                    }}
                  />
                </Box>
              )}
              <Box sx={{ maxWidth: "70%" }}>
                {!isCurrentUser && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "text.secondary",
                        fontWeight: 500,
                        fontFamily: "Lato, sans-serif",
                        cursor: "pointer",
                        '&:hover': {
                          textDecoration: "underline",
                          color: "primary.main"
                        }
                      }}
                      onClick={() => handleUsernameClick(participant?.username)}
                    >
                      {participant?.username}
                    </Typography>
                    <CheckCircle sx={{ width: 14, height: 14, color: '#3b82f6' }} />
                    {renderRole(participant)}
                  </Box>
                )}
                <Paper
                  elevation={1}
                  sx={{
                    p: 1.5,
                    bgcolor: isCurrentUser ? "#dbeafe" : "#FFF",
                    borderRadius: 2,
                    wordBreak: "break-word",
                    fontFamily: "Lato, sans-serif"
                  }}
                >
                  <Typography 
                    variant="body1"
                    sx={{ fontFamily: "Lato, sans-serif" }}
                  >
                    {message.content}
                  </Typography>
                </Paper>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: isCurrentUser ? "flex-end" : "flex-start",
                    mt: 0.5,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ 
                      color: "text.secondary",
                      fontFamily: "Lato, sans-serif"
                    }}
                  >
                    {format(messageDate, "h:mm a")}
                  </Typography>
                  {isCurrentUser && (
                    <MessageStatus status={message.status || "sent"} />
                  )}
                </Box>
              </Box>
              {isCurrentUser && (
                <Box sx={{ position: 'relative' }}>
                  <Avatar
                    src={participant?.avatar}
                    alt={participant?.username}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: -2,
                      right: -2,
                      width: 8,
                      height: 8,
                      bgcolor: 'success.main',
                      borderRadius: '50%',
                      border: '2px solid white',
                    }}
                  />
                </Box>
              )}
            </Box>
          </React.Fragment>
        );
      })}
      <div ref={messagesEndRef} />
    </Box>
  );
};

MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      senderId: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      content: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      status: PropTypes.oneOf(["sent", "delivered", "read"]),
    })
  ).isRequired,
  currentUser: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    username: PropTypes.string,
    avatar: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  otherParticipant: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    username: PropTypes.string,
    avatar: PropTypes.string,
    role: PropTypes.string,
  }),
};

export default MessageList;
