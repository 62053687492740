import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import api from "../api";
import { setToken } from "../redux/user/userSlice";

const useListing = (listingId) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const hardcodedLatitude = 27.7172;
  const hardcodedLongitude = -82.3512;

  const handleAuthError = useCallback(
    (error) => {
      if (error.response?.status === 401) {
        console.warn("Unauthorized request. Token may be invalid or expired.");
        dispatch(setToken(null));
        localStorage.removeItem("authToken");
        navigate("/sign-in");
      }
    },
    [navigate, dispatch]
  );

  const extractCoordinates = useCallback((listing) => {
    if (
      typeof listing?.latitude === "number" &&
      typeof listing?.longitude === "number"
    ) {
      return { latitude: listing.latitude, longitude: listing.longitude };
    }
    return { latitude: hardcodedLatitude, longitude: hardcodedLongitude };
  }, []);

  const fetchListing = useCallback(async () => {
    if (!listingId) {
      console.warn("[Listing] No listing ID provided");
      setError("No listing ID provided");
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      console.log("[Listing] Fetching listing details for ID:", listingId);

      const response = await api.get(`/listing/${listingId}`);
      console.log("[Listing] API Response:", response);

      // Check if response.data exists (axios response structure)
      const listingData = response.data || response;

      // Handle different possible response structures
      const actualListingData = listingData.listing || listingData;

      if (!actualListingData) {
        throw new Error("No listing data received from server");
      }

      console.log("[Listing] Processing listing data:", actualListingData);

      const coords = extractCoordinates(actualListingData);
      const updatedListing = {
        ...actualListingData,
        latitude: coords.latitude,
        longitude: coords.longitude,
        owner: actualListingData.owner || actualListingData.userRef,
        price: actualListingData.price || 0,
        bedrooms: actualListingData.bedrooms || 0,
        bathrooms: actualListingData.bathrooms || 0,
        address: actualListingData.address || "Address not available",
        imageUrls: actualListingData.imageUrls || [],
        currentStatus: actualListingData.currentStatus || "Unknown",
      };

      console.log("[Listing] Processed listing data:", updatedListing);
      setListing(updatedListing);
      setError(null);
    } catch (err) {
      console.error("[Listing] Error in fetchListing:", err);

      if (err.response) {
        console.error("[Listing] Error response:", err.response);
      }

      if (err.message === "Unauthorized access") {
        handleAuthError(err);
      } else if (err.response?.status === 404) {
        setError(
          "Listing not found. It may have been removed or is no longer available."
        );
      } else {
        setError(
          err.message ||
            "Failed to load listing. Please try refreshing the page."
        );
      }
    } finally {
      setLoading(false);
    }
  }, [listingId, extractCoordinates, handleAuthError]);

  useEffect(() => {
    let mounted = true;

    const initData = async () => {
      if (!listingId || !mounted) return;
      await fetchListing();
    };

    initData();

    return () => {
      mounted = false;
    };
  }, [listingId, fetchListing]);

  return {
    listing,
    loading,
    error,
    refetch: fetchListing,
    coordinates: listing
      ? extractCoordinates(listing)
      : { latitude: null, longitude: null },
  };
};

export default useListing;
