import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

const WalkScoreDisplay = ({ scores, amenities, onAmenityClick }) => {
  if (!scores) return null;

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 font-lato">
      <h2 className="text-2xl font-semibold mb-6">Location Scores</h2>
      
      {/* Score Cards */}
      <div className="grid grid-cols-3 gap-4 mb-8">
        <ScoreCard
          title="Walk Score"
          score={scores.walkScore.score}
          description={scores.walkScore.description}
          icon="🚶‍♂️"
          color="bg-blue-500"
        />
        <ScoreCard
          title="Transit Score"
          score={scores.transitScore.score}
          description={scores.transitScore.description}
          icon="🚌"
          color="bg-green-500"
        />
        <ScoreCard
          title="Bike Score"
          score={scores.bikeScore.score}
          description={scores.bikeScore.description}
          icon="🚲"
          color="bg-purple-500"
        />
      </div>

      {/* Amenities Section */}
      {amenities && (
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Nearby Amenities</h3>
          <div className="grid grid-cols-2 gap-6">
            {Object.entries(amenities.categories).map(([type, data]) => (
              <AmenityCategory
                key={type}
                type={type}
                data={data}
                onAmenityClick={onAmenityClick}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ScoreCard = ({ title, score, description, icon, color }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="relative overflow-hidden rounded-lg p-4 bg-white shadow-md"
  >
    <div className="flex items-center justify-between mb-2">
      <span className="text-2xl">{icon}</span>
      <span className={`${color} text-white rounded-full px-3 py-1 text-sm`}>
        {score}/100
      </span>
    </div>
    <h3 className="font-semibold text-lg mb-1">{title}</h3>
    <p className="text-sm text-gray-600">{description}</p>
    <motion.div
      className={`absolute bottom-0 left-0 h-1 ${color}`}
      initial={{ width: 0 }}
      animate={{ width: `${score}%` }}
      transition={{ duration: 1, ease: "easeOut" }}
    />
  </motion.div>
);

const AmenityCategory = ({ type, data, onAmenityClick }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    className="bg-gray-50 rounded-lg p-4"
  >
    <h4 className="font-semibold text-lg mb-3 capitalize">
      {type.toLowerCase().replace('_', ' ')}
    </h4>
    <div className="space-y-2">
      {data.items.slice(0, 3).map((item, index) => (
        <motion.div
          key={item.name}
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: index * 0.1 }}
          className="flex items-center justify-between p-2 bg-white rounded-md cursor-pointer hover:bg-gray-100 transition-colors"
          onClick={() => onAmenityClick(item)}
        >
          <div>
            <p className="font-medium">{item.name}</p>
            <p className="text-sm text-gray-500">{item.distance.toFixed(2)} km</p>
          </div>
          {item.duration && (
            <span className="text-sm text-gray-600">
              {item.duration} min walk
            </span>
          )}
        </motion.div>
      ))}
    </div>
    {data.items.length > 3 && (
      <button
        className="mt-3 text-sm text-blue-600 hover:text-blue-800"
        onClick={() => onAmenityClick({ type, items: data.items })}
      >
        View all {data.items.length} locations
      </button>
    )}
  </motion.div>
);

WalkScoreDisplay.propTypes = {
  scores: PropTypes.shape({
    walkScore: PropTypes.shape({
      score: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired
    }),
    transitScore: PropTypes.shape({
      score: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired
    }),
    bikeScore: PropTypes.shape({
      score: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired
    })
  }),
  amenities: PropTypes.object,
  onAmenityClick: PropTypes.func
};

export default WalkScoreDisplay;
