import React from "react";
import { Footer } from "flowbite-react";
import "../App.css";

function Footered() {
  return (
    <Footer container className="custom-footer app-footer">
      <div className="footer-content">
        <Footer.Copyright href="#" by="homeleafs" year={2024} />
        <Footer.LinkGroup className="footer-links">
          <Footer.Link href="#">About</Footer.Link>
          <Footer.Link href="#">Privacy Policy</Footer.Link>
          <Footer.Link href="#">Licensing</Footer.Link>
          <Footer.Link href="#">Contact</Footer.Link>
        </Footer.LinkGroup>
      </div>
    </Footer>
  );
}

export default Footered;
