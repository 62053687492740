import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  Button,
  Tooltip,
  Divider,
} from "@mui/material";
import {
  HomeWork,
  TrendingUp,
  AttachMoney,
  Lock,
  Hotel,
  Weekend,
  CalendarMonth,
  RequestQuote,
} from "@mui/icons-material";
import { motion } from "framer-motion";

const formatCurrency = (value) => {
  if (!value && value !== 0) return "$0";
  return `$${value.toLocaleString()}`;
};

const AirbnbPotential = ({
  investmentMetrics,
  onPremiumFeatureClick = () => {},
}) => {
  // Demo data structure remains unchanged
  const defaultMetrics = {
    rentalProjections: {
      nightlyRate: 245,
      monthlyIncome: 5200,
      occupancyRate: 86,
      seasonalRates: {
        summer: { rate: 285, occupancy: 92 },
        fall: { rate: 225, occupancy: 78 },
        winter: { rate: 265, occupancy: 88 },
        spring: { rate: 235, occupancy: 82 },
      },
    },
    marketIndicators: {
      pricePerSqFt: 185,
      marketGrowth: 15.5,
      competitorAnalysis: {
        averageRate: 220,
        averageOccupancy: 78,
        revenueMultiplier: 2.4,
      },
    },
    performanceMetrics: {
      yearlyRevenue: 62400,
      roi: 8.5,
      cashOnCash: 12.3,
      capRate: 6.8,
    },
  };

  // Use provided metrics or fall back to demo data
  const metrics = investmentMetrics || defaultMetrics;

  // Safely access nested properties with fallback to demo values
  const nightlyRate =
    metrics.rentalProjections?.nightlyRate ??
    defaultMetrics.rentalProjections.nightlyRate;
  const monthlyIncome =
    metrics.rentalProjections?.monthlyIncome ??
    defaultMetrics.rentalProjections.monthlyIncome;
  const occupancyRate =
    metrics.rentalProjections?.occupancyRate ??
    defaultMetrics.rentalProjections.occupancyRate;
  const pricePerSqFt =
    metrics.marketIndicators?.pricePerSqFt ??
    defaultMetrics.marketIndicators.pricePerSqFt;
  const seasonalRates =
    metrics.rentalProjections?.seasonalRates ??
    defaultMetrics.rentalProjections.seasonalRates;
  const performanceMetrics =
    metrics.performanceMetrics ?? defaultMetrics.performanceMetrics;

  return (
    <Card sx={{ mb: 4, borderRadius: 2, bgcolor: "#fff" }}>
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontFamily: "Lato, sans-serif",
            fontWeight: 600,
            color: "#1565c0", // Darker blue
            mb: 3,
          }}
        >
          <HomeWork /> Short-Term Rental Analysis
        </Typography>

        {/* Key Metrics */}
        <Grid container spacing={2} sx={{ mb: 4 }}>
          {[
            {
              value: nightlyRate,
              label: "Average Nightly Rate",
              format: "currency",
              color: "#1565c0",
            },
            {
              value: monthlyIncome,
              label: "Monthly Revenue",
              format: "currency",
              color: "#2e7d32",
            },
            {
              value: occupancyRate,
              label: "Occupancy Rate",
              format: "percent",
              color: "#ed6c02",
            },
            {
              value: pricePerSqFt,
              label: "Price/Sq.Ft",
              format: "currency",
              color: "#1565c0",
            },
          ].map((metric) => (
            <Grid item xs={12} sm={6} key={metric.label}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: `rgba(${
                    metric.color === "#1565c0"
                      ? "21, 101, 192"
                      : metric.color === "#2e7d32"
                      ? "46, 125, 50"
                      : "237, 108, 2"
                  }, 0.1)`,
                  borderRadius: 2,
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: "#333",
                    mb: 1,
                  }}
                >
                  {metric.label}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    fontWeight: 600,
                    color: metric.color,
                    fontSize: "1.25rem",
                  }}
                >
                  {metric.format === "currency"
                    ? formatCurrency(metric.value)
                    : `${metric.value}%`}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Market Indicators */}
        <Grid container spacing={2} sx={{ mt: 3, mb: 4 }}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: "center" }}>
              <AttachMoney sx={{ fontSize: 32, color: "#1565c0" }} />
              <Typography
                variant="h6"
                sx={{ color: "#1565c0", fontSize: "1.1rem" }}
              >
                Best Price Range
              </Typography>
              <Typography variant="body2" sx={{ color: "#666" }}>
                Optimal For Market
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 1, fontWeight: 600, color: "#2e7d32" }}
              >
                {formatCurrency(monthlyIncome * 12)} / Year
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: "center" }}>
              <TrendingUp sx={{ fontSize: 32, color: "#2e7d32" }} />
              <Typography
                variant="h6"
                sx={{ color: "#2e7d32", fontSize: "1.1rem" }}
              >
                Growing Market
              </Typography>
              <Typography variant="body2" sx={{ color: "#666" }}>
                {metrics.marketIndicators?.marketGrowth ??
                  defaultMetrics.marketIndicators.marketGrowth}
                % YoY Increase
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 1, fontWeight: 600, color: "#2e7d32" }}
              >
                Strong Growth Potential
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: "center" }}>
              <Hotel sx={{ fontSize: 32, color: "#1565c0" }} />
              <Typography
                variant="h6"
                sx={{ color: "#1565c0", fontSize: "1.1rem" }}
              >
                High Demand
              </Typography>
              <Typography variant="body2" sx={{ color: "#666" }}>
                {occupancyRate}% Booking Rate
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 1, fontWeight: 600, color: "#2e7d32" }}
              >
                Above Market Average
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Seasonal Analysis */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            Seasonal Performance
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(seasonalRates).map(([season, data]) => (
              <Grid item xs={12} sm={6} key={season}>
                <Box sx={{ mb: 1 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}
                  >
                    <span>
                      {season.charAt(0).toUpperCase() + season.slice(1)}
                    </span>
                    <span>{formatCurrency(data.rate)}/night</span>
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={data.occupancy}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      mt: 1,
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor:
                          data.occupancy > 85
                            ? "#2e7d32" // Darker success color
                            : "#1565c0", // Darker blue
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ mt: 0.5, textAlign: "right", color: "#666" }}
                  >
                    {data.occupancy}% Occupancy
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Market Comparison */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            Market Comparison
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Tooltip
                title="Average Daily Rate Compared To Similar Properties"
                arrow
              >
                <Box sx={{ textAlign: "center" }}>
                  <Weekend sx={{ fontSize: 32, color: "#1565c0" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#2e7d32",
                      fontSize: "1.1rem",
                    }}
                  >
                    +
                    {(
                      (nightlyRate /
                        (metrics.marketIndicators?.competitorAnalysis
                          ?.averageRate ??
                          defaultMetrics.marketIndicators.competitorAnalysis
                            .averageRate) -
                        1) *
                      100
                    ).toFixed(1)}
                    % Above Market
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "Lato, sans-serif", color: "#666" }}
                  >
                    Daily Rate
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Tooltip title="Occupancy Rate Compared To Area Average" arrow>
                <Box sx={{ textAlign: "center" }}>
                  <CalendarMonth sx={{ fontSize: 32, color: "#1565c0" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#2e7d32",
                      fontSize: "1.1rem",
                    }}
                  >
                    {occupancyRate}% Occupancy
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "Lato, sans-serif", color: "#666" }}
                  >
                    vs{" "}
                    {metrics.marketIndicators?.competitorAnalysis
                      ?.averageOccupancy ??
                      defaultMetrics.marketIndicators.competitorAnalysis
                        .averageOccupancy}
                    % Market Avg
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Tooltip
                title="Revenue Potential Compared To Long-Term Rental"
                arrow
              >
                <Box sx={{ textAlign: "center" }}>
                  <RequestQuote sx={{ fontSize: 32, color: "#1565c0" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#2e7d32",
                      fontSize: "1.1rem",
                    }}
                  >
                    {metrics.marketIndicators?.competitorAnalysis
                      ?.revenueMultiplier ??
                      defaultMetrics.marketIndicators.competitorAnalysis
                        .revenueMultiplier}
                    x Revenue
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "Lato, sans-serif", color: "#666" }}
                  >
                    vs Long-Term Rental
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>

        {/* Return Metrics - Reorganized into 2x2 grid */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            Return Metrics
          </Typography>
          <Grid container spacing={2}>
            {[
              {
                label: "Annual Revenue",
                value: performanceMetrics.yearlyRevenue,
                format: "currency",
                color: "#1565c0", // Darker blue
              },
              {
                label: "ROI",
                value: performanceMetrics.roi,
                format: "percent",
                color: "#2e7d32", // Darker success color
              },
              {
                label: "Cash On Cash",
                value: performanceMetrics.cashOnCash,
                format: "percent",
                color: "#ed6c02", // Darker warning color
              },
              {
                label: "Cap Rate",
                value: performanceMetrics.capRate,
                format: "percent",
                color: "#1565c0", // Darker blue
              },
            ].map((metric, index) => (
              <Grid item xs={12} sm={6} key={metric.label}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: `rgba(${
                      metric.color === "#1565c0"
                        ? "21, 101, 192"
                        : metric.color === "#2e7d32"
                        ? "46, 125, 50"
                        : "237, 108, 2"
                    }, 0.1)`,
                    borderRadius: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#333",
                      mb: 1,
                    }}
                  >
                    {metric.label}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      fontWeight: 600,
                      color: metric.color,
                      fontSize: "1.25rem",
                    }}
                  >
                    {metric.format === "currency"
                      ? formatCurrency(metric.value)
                      : `${metric.value}%`}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Premium Feature Button */}
        <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={onPremiumFeatureClick}
            startIcon={<Lock />}
            sx={{
              mt: 2,
              borderRadius: 2,
              py: 1.5,
              textTransform: "none",
              fontFamily: "Lato, sans-serif",
              fontWeight: 600,
              bgcolor: "#1565c0", // Darker blue
              "&:hover": {
                bgcolor: "#1976d2",
              },
            }}
          >
            Unlock Complete Rental Analysis
          </Button>
        </motion.div>
      </CardContent>
    </Card>
  );
};

AirbnbPotential.propTypes = {
  investmentMetrics: PropTypes.shape({
    rentalProjections: PropTypes.shape({
      nightlyRate: PropTypes.number,
      monthlyIncome: PropTypes.number,
      occupancyRate: PropTypes.number,
      seasonalRates: PropTypes.shape({
        summer: PropTypes.shape({
          rate: PropTypes.number,
          occupancy: PropTypes.number,
        }),
        fall: PropTypes.shape({
          rate: PropTypes.number,
          occupancy: PropTypes.number,
        }),
        winter: PropTypes.shape({
          rate: PropTypes.number,
          occupancy: PropTypes.number,
        }),
        spring: PropTypes.shape({
          rate: PropTypes.number,
          occupancy: PropTypes.number,
        }),
      }),
    }),
    marketIndicators: PropTypes.shape({
      pricePerSqFt: PropTypes.number,
      marketGrowth: PropTypes.number,
      competitorAnalysis: PropTypes.shape({
        averageRate: PropTypes.number,
        averageOccupancy: PropTypes.number,
        revenueMultiplier: PropTypes.number,
      }),
    }),
    performanceMetrics: PropTypes.shape({
      yearlyRevenue: PropTypes.number,
      roi: PropTypes.number,
      cashOnCash: PropTypes.number,
      capRate: PropTypes.number,
    }),
  }),
  onPremiumFeatureClick: PropTypes.func,
};

export default AirbnbPotential;
