import { useState, useEffect, useCallback } from 'react';
import { getDummyPlaces, getDummyPlaceDetails } from '../config/googleMaps';

export function usePlacesData(initialLocation = null) {
  const [amenities, setAmenities] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Using dummy data instead of real API calls
  const fetchPlacesData = useCallback(async (location) => {
    if (!location || !location.lat || !location.lng) {
      setError('Invalid location data');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Using dummy data instead of real API call
      const dummyData = getDummyPlaces();
      setAmenities(dummyData);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching places data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPlaceDetails = useCallback(async (placeId) => {
    if (!placeId) return;

    setLoading(true);
    try {
      // Using dummy data instead of real API call
      const details = getDummyPlaceDetails(placeId);
      setSelectedPlace(details);
    } catch (err) {
      console.error('Error fetching place details:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (initialLocation) {
      fetchPlacesData(initialLocation);
    }
  }, [initialLocation, fetchPlacesData]);

  const refreshData = useCallback((location) => {
    if (location) {
      fetchPlacesData(location);
    }
  }, [fetchPlacesData]);

  return {
    amenities,
    selectedPlace,
    loading,
    error,
    fetchPlacesData,
    fetchPlaceDetails,
    refreshData
  };
}

// Real Implementation (commented out for reference)


// import { placesService } from '../services/placesService';

// export function usePlacesData(initialLocation = null) {
//   const [amenities, setAmenities] = useState(null);
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const fetchPlacesData = useCallback(async (location) => {
//     if (!location || !location.lat || !location.lng) {
//       setError('Invalid location data');
//       return;
//     }

//     setLoading(true);
//     setError(null);

//     try {
//       const amenitiesData = await placesService.getAllNearbyAmenities(location);
//       setAmenities(amenitiesData);
//     } catch (err) {
//       setError(err.message);
//       console.error('Error fetching places data:', err);
//     } finally {
//       setLoading(false);
//     }
//   }, []);

//   const fetchPlaceDetails = useCallback(async (placeId) => {
//     if (!placeId) return;

//     setLoading(true);
//     try {
//       const details = await placesService.getPlaceDetails(placeId);
//       setSelectedPlace(details);
//     } catch (err) {
//       console.error('Error fetching place details:', err);
//     } finally {
//       setLoading(false);
//     }
//   }, []);

//   // Initial fetch if location is provided
//   useEffect(() => {
//     if (initialLocation) {
//       fetchPlacesData(initialLocation);
//     }
//   }, [initialLocation, fetchPlacesData]);

//   const refreshData = useCallback((location) => {
//     if (location) {
//       fetchPlacesData(location);
//     }
//   }, [fetchPlacesData]);

//   // Calculate convenience scores
//   const convenienceScores = useCallback(() => {
//     if (!amenities) return null;

//     return {
//       essential: calculateCategoryScore(amenities.ESSENTIAL),
//       education: calculateCategoryScore(amenities.EDUCATION),
//       recreation: calculateCategoryScore(amenities.RECREATION),
//       transport: calculateCategoryScore(amenities.TRANSPORT),
//       overall: calculateOverallScore(amenities)
//     };
//   }, [amenities]);

//   return {
//     amenities,
//     selectedPlace,
//     loading,
//     error,
//     fetchPlacesData,
//     fetchPlaceDetails,
//     refreshData,
//     convenienceScores: convenienceScores()
//   };
// }

// // Helper functions for calculating scores
// function calculateCategoryScore(category) {
//   if (!category) return 0;

//   const FACTORS = {
//     COUNT: 0.4,
//     RATING: 0.3,
//     PROXIMITY: 0.3
//   };

//   const countScore = Math.min(category.count / 10, 1);
//   const ratingScore = category.averageRating / 5;
//   const proximityScore = calculateProximityScore(category.items);

//   return (
//     countScore * FACTORS.COUNT +
//     ratingScore * FACTORS.RATING +
//     proximityScore * FACTORS.PROXIMITY
//   ).toFixed(2);
// }

// function calculateProximityScore(items) {
//   if (!items.length) return 0;
  
//   // Calculate average distance from center point
//   const center = {
//     lat: items.reduce((sum, item) => sum + item.location.lat, 0) / items.length,
//     lng: items.reduce((sum, item) => sum + item.location.lng, 0) / items.length
//   };

//   const distances = items.map(item =>
//     calculateDistance(center, item.location)
//   );

//   const avgDistance = distances.reduce((sum, dist) => sum + dist, 0) / distances.length;
//   return Math.max(0, 1 - (avgDistance / 2)); // 2km as max reasonable distance
// }

// function calculateOverallScore(amenities) {
//   const weights = {
//     ESSENTIAL: 0.35,
//     EDUCATION: 0.25,
//     RECREATION: 0.2,
//     TRANSPORT: 0.2
//   };

//   let totalScore = 0;
//   let totalWeight = 0;

//   Object.entries(weights).forEach(([category, weight]) => {
//     if (amenities[category]) {
//       totalScore += calculateCategoryScore(amenities[category]) * weight;
//       totalWeight += weight;
//     }
//   });

//   return totalWeight > 0 ? (totalScore / totalWeight).toFixed(2) : 0;
// }

// function calculateDistance(point1, point2) {
//   const R = 6371; // Earth's radius in km
//   const dLat = toRad(point2.lat - point1.lat);
//   const dLng = toRad(point2.lng - point1.lng);
//   const a =
//     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//     Math.cos(toRad(point1.lat)) * Math.cos(toRad(point2.lat)) *
//     Math.sin(dLng / 2) * Math.sin(dLng / 2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//   return R * c;
// }

// function toRad(value) {
//   return value * Math.PI / 180;
// }
