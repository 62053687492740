import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UpgradeRole = () => {
  const { currentUser } = useSelector((state) => state.user);

  const roles = {
    homeowner: {
      description: "Access property listings and connect with real estate professionals",
      features: ["View property listings", "Contact agents", "Save favorite properties"]
    },
    realEstateAgent: {
      description: "List properties and connect with potential clients",
      features: ["List properties", "Receive client inquiries", "Access market analytics"]
    },
    realEstateBroker: {
      description: "Manage a team of agents and oversee transactions",
      features: ["Team management", "Transaction oversight", "Advanced analytics"]
    },
    realEstateInvestor: {
      description: "Access investment opportunities and market analysis",
      features: ["Investment analysis", "Market trends", "ROI calculations"]
    },
    loanOfficer: {
      description: "Connect with potential borrowers and process loan applications",
      features: ["Loan processing", "Client management", "Rate calculations"]
    },
    realEstateAttorney: {
      description: "Provide legal services for real estate transactions",
      features: ["Legal document review", "Transaction oversight", "Legal consulting"]
    },
    buyer: {
      description: "Search for properties and connect with agents",
      features: ["Property search", "Agent contact", "Save favorites"]
    }
  };

  const currentRole = currentUser?.role || 'guest';
  const roleInfo = roles[currentRole];

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          {/* Header */}
          <div className="bg-blue-600 px-6 py-8 text-white">
            <h1 className="text-3xl font-bold text-center">Your Current Role</h1>
            <p className="text-xl mt-2 text-center capitalize">{currentRole}</p>
          </div>

          {/* Current Role Info */}
          <div className="px-6 py-8">
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Current Features</h2>
              <p className="text-gray-600 mb-4">{roleInfo?.description}</p>
              <ul className="list-disc pl-5 space-y-2">
                {roleInfo?.features.map((feature, index) => (
                  <li key={index} className="text-gray-600">{feature}</li>
                ))}
              </ul>
            </div>

            {/* Role Change Notice */}
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-8">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Role Change Not Allowed
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>
                      For security and platform integrity, role changes are not permitted after initial registration. 
                      This ensures consistent user experiences and maintains platform security.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Support Section */}
            <div className="border-t pt-8">
              <h2 className="text-2xl font-semibold mb-4">Need Help?</h2>
              <p className="text-gray-600 mb-4">
                If you have questions about your role or need assistance, please contact our support team.
              </p>
              <Link
                to="/contact-support"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
              >
                Contact Support
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeRole;
