import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { signInSuccess } from "./redux/user/userSlice";
import { AuthContextProvider } from "./context/AuthContext";
import "./App.css";
import Home from "./pages/Home";
import SignIn from "./pages/Signin";
import SignUp from "./pages/SignUp";
import About from "./pages/About";
import RealEstateDashboard from "./components/RealEstateDashboard";
import Profile from "./pages/Profile";
import Pricing from "./pages/Pricing";
import AccountProfile from "./components/dashboard/AccountProfile";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Timeline from "./pages/Timeline";
import Notifications from "./pages/Notifications";
import PrivateRoute from "./components/PrivateRoute";
import CreateListing from "./pages/CreateListing";
import UpdateListing from "./pages/UpdateListing";
import Messages from "./pages/Messages";
import Listing from "./pages/Listing";
import Search from "./pages/Search";
import HomeownerRoutes from "./pages/HomeOwners";
import VerifyEmail from "./pages/VerifyEmail";
import MyListings from "./pages/MyListings";
import MySaves from "./pages/MySaves";
import Dashboard from "./pages/Dashboard";
import ForgotPassword from "./pages/ForgotPassword";
import UpgradeRole from "./pages/UpgradeRole";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import ErrorBoundary from "./components/ErrorBoundary";
import Leafs from "./components/Leafs";

function AuthWrapper({ children }) {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(
        signInSuccess({
          username: user.nickname,
          email: user.email,
          avatar: user.picture,
        })
      );
    }
  }, [isAuthenticated, user, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return children;
}

AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function App() {
  const auth0Config = {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
      scope: import.meta.env.VITE_AUTH0_SCOPE,
    },
    useRefreshTokens: import.meta.env.VITE_AUTH0_USE_REFRESH_TOKENS === "true",
    cacheLocation: import.meta.env.VITE_AUTH0_CACHE_LOCATION,
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Auth0Provider {...auth0Config}>
            <AuthContextProvider>
              <AuthWrapper>
                <div className="app-container">
                  <Header />
                  <main className="content-area">
                    <ErrorBoundary>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/sign-in" element={<SignIn />} />
                        <Route path="/sign-up" element={<SignUp />} />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="/verify-email" element={<VerifyEmail />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/search" element={<Search />} />
                        <Route
                          path="/listing/:listingId"
                          element={<Listing />}
                        />
                        <Route
                          path="/homeowners/*"
                          element={<HomeownerRoutes />}
                        />
                        <Route
                          path="/forgot-password"
                          element={<ForgotPassword />}
                        />
                        {/* Private routes */}
                        <Route element={<PrivateRoute />}>
                          <Route path="/timeline" element={<Timeline />} />
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route
                            path="dashboard/profile"
                            element={<AccountProfile />}
                          />
                          <Route
                            path="/upgrade-role"
                            element={<UpgradeRole />}
                          />
                          <Route
                            path="/notifications"
                            element={<Notifications />}
                          />
                          <Route
                            path="/profile/:username"
                            element={<Profile />}
                          />
                          <Route path="/my-listings" element={<MyListings />} />
                          <Route path="/my-saves" element={<MySaves />} />
                          <Route path="/messages" element={<Messages />} />
                          <Route
                            path="/real-estate-dashboard"
                            element={<RealEstateDashboard />}
                          />
                          <Route
                            path="/create-listing"
                            element={<CreateListing />}
                          />
                          <Route
                            path="/update-listing/:listingId"
                            element={<UpdateListing />}
                          />
                        </Route>
                        <Route
                          path="/investment-analysis/:listingId"
                          component={Leafs}
                        />
                      </Routes>
                    </ErrorBoundary>
                  </main>
                  <Footer className="app-footer" />
                </div>
              </AuthWrapper>
            </AuthContextProvider>
          </Auth0Provider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
