import { useCallback, useEffect, useState } from "react";

import { Notyf } from "notyf";
import { updateUser } from "../../../redux/user/userSlice";
import { useDispatch } from "react-redux";
import "notyf/notyf.min.css";
const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const useProfileForm = (initialData = {}, validationSchema = {}, section) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reset form when initialData changes
  useEffect(() => {
    setFormData(initialData);
    setErrors({});
    setIsDirty(false);
  }, [initialData]);

  // Validate a single field
  const validateField = useCallback(
    (name, value) => {
      if (!validationSchema[name]) return "";

      const validator = validationSchema[name];
      try {
        return validator(value, formData) || "";
      } catch (error) {
        return error.message;
      }
    },
    [validationSchema, formData]
  );

  // Validate entire form
  const validateForm = useCallback(() => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (validationSchema[key]) {
        const error = validateField(key, formData[key]);
        if (error) newErrors[key] = error;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, validationSchema, validateField]);

  // Handle field changes
  const handleChange = useCallback(
    (event) => {
      const { name, value, type, checked } = event.target;
      const newValue = type === "checkbox" ? checked : value;

      setFormData((prev) => ({
        ...prev,
        [name]: newValue,
      }));

      setIsDirty(true);

      // Clear error when user starts typing
      if (errors[name]) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    },
    [errors]
  );

  // Handle form submission
  const handleSubmit = useCallback(async () => {
    if (!validateForm()) {
      notyf.error("Please correct the errors in the form");
      return false;
    }

    setIsSubmitting(true);
    try {
      const userData = section ? { [section]: formData } : formData;
      const result = await dispatch(
        updateUser({
          userData,
        })
      ).unwrap();

      notyf.success("Profile updated successfully");
      setIsDirty(false);
      return true;
    } catch (error) {
      console.error("Update error:", error);
      notyf.error(error.message || "Failed to update profile");
      return false;
    } finally {
      setIsSubmitting(false);
    }
  }, [formData, validateForm, dispatch, section]);

  // Reset form to initial state
  const handleReset = useCallback(() => {
    setFormData(initialData);
    setErrors({});
    setIsDirty(false);
  }, [initialData]);

  // Update form data programmatically
  const setFieldValue = useCallback((name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsDirty(true);
  }, []);

  return {
    formData,
    errors,
    isDirty,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    validateField,
    validateForm,
  };
};
export default useProfileForm;
