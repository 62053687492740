import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Brain } from "lucide-react";

const AssistantContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const MetricBox = styled(Box)({
  padding: "16px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  marginBottom: "16px",
  transition: "transform 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const MetricLabel = styled(Typography)({
  fontSize: "0.9rem",
  color: "#64748B",
  marginBottom: "8px",
  fontFamily: "'Lato', sans-serif",
});

const MetricValue = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: "600",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
  "& svg": {
    width: "48px",
    height: "48px",
    color: "#6366F1",
  },
});

const AIInvestmentAssistant = ({ propertyData, financialData, rentalInputs, marketTrends }) => {
  return (
    <AssistantContainer elevation={0}>
      <IconWrapper>
        <Brain />
      </IconWrapper>
      <Title variant="h5">AI Investment Analysis</Title>
      
      <MetricBox>
        <MetricLabel>Estimated ROI</MetricLabel>
        <MetricValue>
          {financialData?.estimatedROI ? `${financialData.estimatedROI}%` : "N/A"}
        </MetricValue>
      </MetricBox>

      <MetricBox>
        <MetricLabel>Market Growth Potential</MetricLabel>
        <MetricValue>
          {marketTrends?.growthPotential ? `${marketTrends.growthPotential}%` : "N/A"}
        </MetricValue>
      </MetricBox>

      <MetricBox>
        <MetricLabel>Investment Score</MetricLabel>
        <MetricValue>
          {propertyData?.investmentScore ? `${propertyData.investmentScore}/100` : "N/A"}
        </MetricValue>
      </MetricBox>

      <MetricBox>
        <MetricLabel>Rental Yield</MetricLabel>
        <MetricValue>
          {rentalInputs?.longTermRent && propertyData?.price
            ? `${((rentalInputs.longTermRent * 12 / propertyData.price) * 100).toFixed(1)}%`
            : "N/A"}
        </MetricValue>
      </MetricBox>
    </AssistantContainer>
  );
};

AIInvestmentAssistant.propTypes = {
  propertyData: PropTypes.object,
  financialData: PropTypes.object,
  rentalInputs: PropTypes.object,
  marketTrends: PropTypes.object,
};

export default AIInvestmentAssistant;
