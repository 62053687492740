import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

/* eslint-disable no-unused-vars */
import {
  FaSearch,
  FaHeart,
  FaBed,
  FaBath,
  FaRuler,
  FaRobot,
  FaSave,
} from "react-icons/fa";
/* eslint-enable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// eslint-disable-next-line no-unused-vars
import MarkerClusterGroup from "react-leaflet-markercluster";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import debounce from "lodash/debounce";
import api from "../api";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

import {
  saveSearch,
  getSavedSearches,
  applySavedSearch,
  deleteSavedSearch,
} from "../services/savedSearchService";

import SearchBar from "../components/SearchPageSearchBar";
import FiltersContainer from "../components/FiltersContainer";
import ResultsContainer from "../components/ResultsContainer";
import MapView from "../components/MapView";
import PropertyList from "../components/PropertyList";
import Pagination from "../components/Pagination";
// eslint-disable-next-line no-unused-vars
import SortOptions from "../components/SortOptions";
import { addLikedListing, removeLikedListing } from "../redux/user/userSlice";
import { properties } from "../components/PropertyData";
import { useLikeListings } from "../hooks/useLikeListings";
import { useSelector } from "react-redux";

const SearchResultsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAIEnabled, setIsAIEnabled] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 1000000]);
  const [selectedType, setSelectedType] = useState("");
  const [bedrooms, setBedrooms] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [size, setSize] = useState([0, 5000]);
  const [showLiked, setShowLiked] = useState(false);
  const [sortOption, setSortOption] = useState("price");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [savedSearches, setSavedSearches] = useState([]);
  const [newSearchName, setNewSearchName] = useState("");
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { toggleLike, likedListings, fetchLikedListings } = useLikeListings();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.user.currentUser);
  const notyf = new Notyf({
    duration: 3000,
    position: { x: "right", y: "top" },
  });

  const handleLike = async (listingId) => {
    if (!currentUser) {
      notyf.error("Please log in to like listings");
      navigate("/sign-in");
      return;
    }
    try {
      const newLikeStatus = await toggleLike(listingId);
      setFilteredProperties((prevProperties) =>
        prevProperties.map((property) =>
          property._id === listingId
            ? { ...property, isLiked: newLikeStatus }
            : property
        )
      );
      if (newLikeStatus) {
        dispatch(addLikedListing(listingId));
      } else {
        dispatch(removeLikedListing(listingId));
      }
      notyf.success(
        newLikeStatus ? "Added to favorites" : "Removed from favorites"
      );
    } catch (error) {
      console.error("Error toggling like status:", error);
      notyf.error("Failed to update favorite status. Please try again.");
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "For Sale":
        return "rgba(173, 216, 230, 0.8)"; // Pastel Blue
      case "Quick Sale":
        return "rgba(255, 253, 208, 0.8)"; // Pastel Yellow
      case "Pre-Foreclosure":
        return "rgba(255, 204, 203, 0.8)"; // Pastel Red
      case "Open to Options":
        return "rgba(193, 225, 193, 0.8)"; // Pastel Green
      default:
        return "rgba(220, 220, 220, 0.8)"; // Pastel Gray
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchLikedListings();
    }
  }, [currentUser, fetchLikedListings]);

  const performSearch = useCallback(
    debounce(async (query, useAI) => {
      setLoading(true);
      setError(null);
      try {
        let endpoint = useAI ? "/api/listing/ai-search" : "/api/listing/search";
        const response = await api.get(endpoint, {
          params: {
            query,
            priceRange: priceRange.join(","),
            selectedType,
            bedrooms,
            bathrooms,
            size: size.join(","),
            selectedStatuses: selectedStatuses.join(","),
          },
        });
        const listings = response.data.results;

        const listingsWithLikeStatus = listings.map((listing) => ({
          ...listing,
          isLiked: likedListings.includes(listing._id),
        }));

        setFilteredProperties(listingsWithLikeStatus);
      } catch (err) {
        console.error("Search error:", err);
        setError("An error occurred while searching. Please try again.");
      } finally {
        setLoading(false);
      }
    }, 300),
    [
      priceRange,
      selectedType,
      bedrooms,
      bathrooms,
      size,
      selectedStatuses,
      likedListings,
    ]
  );

  useEffect(() => {
    if (searchTerm) {
      performSearch(searchTerm, isAIEnabled);
    }
  }, [searchTerm, isAIEnabled, performSearch]);

  const handleSearch = (term, useAI) => {
    setSearchTerm(term);
    setIsAIEnabled(useAI);
  };

  const filterProperties = useCallback(
    debounce(() => {
      const filtered = properties.filter(
        (property) =>
          (searchTerm === "" ||
            property.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            property.address
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) &&
          property.price >= priceRange[0] &&
          property.price <= priceRange[1] &&
          (selectedType === "" || property.type === selectedType) &&
          property.beds >= bedrooms &&
          property.baths >= bathrooms &&
          property.size >= size[0] &&
          property.size <= size[1] &&
          (selectedStatuses.length === 0 ||
            selectedStatuses.includes(property.status)) &&
          (!showLiked || property.isLiked)
      );
      setFilteredProperties(filtered);
    }, 300),
    [
      searchTerm,
      priceRange,
      selectedType,
      bedrooms,
      bathrooms,
      size,
      selectedStatuses,
      showLiked,
    ]
  );
  useEffect(() => {
    filterProperties();
  }, [filterProperties]);

  const sortedProperties = [...filteredProperties].sort((a, b) => {
    if (sortOption === "price") {
      return sortOrder === "asc" ? a.price - b.price : b.price - a.price;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedProperties.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const fetchSavedSearches = async () => {
    try {
      if (!currentUser) {
        console.log("User not logged in");
        return;
      }
      const searches = await getSavedSearches(currentUser._id);
      setSavedSearches(searches);
    } catch (error) {
      console.error("Error fetching saved searches:", error);
      notyf.error("Failed to fetch saved searches. Please try again.");
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchSavedSearches();
    }
  }, [currentUser]);

  const handleSaveSearch = async () => {
    if (!currentUser) {
      notyf.error("Please log in to save a search");
      navigate("/sign-in");
      return;
    }
    if (newSearchName.trim() !== "") {
      const criteria = {
        searchTerm,
        priceRange,
        selectedType,
        bedrooms,
        bathrooms,
        size,
        selectedStatuses,
      };

      try {
        const savedSearch = await saveSearch(
          newSearchName,
          criteria,
          currentUser._id
        );
        setSavedSearches((prevSearches) => [...prevSearches, savedSearch]);
        setNewSearchName("");
        setShowSaveSearchModal(false);
        notyf.success("Search saved successfully!");
      } catch (error) {
        console.error("Error saving search:", error);
        notyf.error("Failed to save search. Please try again.");
      }
    }
  };

  const handleApplySavedSearch = async (searchId) => {
    try {
      const criteria = await applySavedSearch(searchId, currentUser._id);
      setSearchTerm(criteria.searchTerm);
      setPriceRange(criteria.priceRange);
      setSelectedType(criteria.selectedType);
      setBedrooms(criteria.bedrooms);
      setBathrooms(criteria.bathrooms);
      setSize(criteria.size);
      setSelectedStatuses(criteria.selectedStatuses || []);
      notyf.success("Saved search applied successfully!");
    } catch (error) {
      console.error("Error applying saved search:", error);
      notyf.error("Failed to apply saved search. Please try again.");
    }
  };

  const handleDeleteSavedSearch = async (searchId) => {
    try {
      await deleteSavedSearch(searchId, currentUser._id);
      setSavedSearches(
        savedSearches.filter((search) => search._id !== searchId)
      );
      notyf.success("Saved search deleted successfully!");
    } catch (error) {
      console.error("Error deleting saved search:", error);
      notyf.error("Failed to delete saved search. Please try again.");
    }
  };

  const handleToggleLiked = () => {
    setShowLiked(!showLiked);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 mt-2">
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isAIEnabled={isAIEnabled}
        setIsAIEnabled={setIsAIEnabled}
        showSaveSearchModal={() => setShowSaveSearchModal(true)}
        onSearch={handleSearch}
      />

      <main className="flex-grow flex p-4 mt-2">
        <div className="flex flex-col md:flex-row w-full">
          <FiltersContainer
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            bedrooms={bedrooms}
            setBedrooms={setBedrooms}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            size={size}
            setSize={setSize}
            filterProperties={filterProperties}
            savedSearches={savedSearches}
            applySavedSearch={applySavedSearch}
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
          />

          <div className="flex-grow md:mx-4">
            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">{error}</p>}
            {!loading && !error && (
              <ResultsContainer
                filteredProperties={filteredProperties}
                currentItems={currentItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                paginate={paginate}
                showLiked={showLiked}
                setShowLiked={setShowLiked}
                sortOption={sortOption}
                sortOrder={sortOrder}
                setSortOption={setSortOption}
                setSortOrder={setSortOrder}
                getStatusColor={getStatusColor}
                handleLike={handleLike}
                handleToggleLiked={handleToggleLiked}
              >
                <PropertyList
                  properties={currentItems}
                  getStatusColor={getStatusColor}
                  handleLike={handleLike}
                  showLiked={showLiked}
                  keyPrefix="search-results"
                  likedListings={likedListings}
                />
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={filteredProperties.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </ResultsContainer>
            )}
          </div>

          <MapView filteredProperties={filteredProperties} />
        </div>
      </main>

      {showSaveSearchModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Save Search</h2>
            <input
              type="text"
              value={newSearchName}
              onChange={(e) => setNewSearchName(e.target.value)}
              placeholder="Enter search name"
              className="w-full p-2 border rounded mb-4"
            />
            <div className="flex justify-end">
              <button
                onClick={() => setShowSaveSearchModal(false)}
                className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveSearch}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {savedSearches.length > 0 && (
        <div className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-2">Saved Searches</h3>
          <ul>
            {savedSearches.map((search) => (
              <li key={search._id} className="mb-2">
                <button
                  onClick={() => handleApplySavedSearch(search._id)}
                  className="text-blue-500 hover:underline"
                >
                  {search.name}
                </button>
                <button
                  onClick={() => handleDeleteSavedSearch(search._id)}
                  className="ml-2 text-red-500 hover:underline"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchResultsPage;
