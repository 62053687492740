import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Snackbar } from "@mui/material";
import BgImage from "../assets/signin.svg";

export default function SignUp() {
  const [formData, setFormData] = useState({
    role: "homeowner",
    username: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [usernameAvailable, setUsernameAvailable] = useState(null);

  // Check if the user is already authenticated
  if (isAuthenticated) {
    // If authenticated, redirect to the appropriate page
    console.log("User is already authenticated. Redirecting...");
    navigate("/"); // Replace with the desired page
    return null; // Return early to prevent rendering the SignUp component
  }

  const handleChange = (e) => {
    console.log("handleChange triggered");
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleUsernameChange = async (e) => {
    console.log("handleUsernameChange triggered");
    const username = e.target.value;
    setFormData({ ...formData, username });

    if (username.length > 0) {
      try {
        const response = await fetch(
          `/api/auth/check-username?username=${username}`
        );
        const data = await response.json();
        setUsernameAvailable(data.available);
      } catch (error) {
        console.error("Error checking username availability:", error);
      }
    } else {
      setUsernameAvailable(null);
    }
  };

  const handleSubmit = async (e) => {
    console.log("handleSubmit triggered");
    e.preventDefault();
    try {
      console.log("Before fetch request");
      setLoading(true);
      const res = await fetch("/api/auth/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      console.log("After fetch request");

      const data = await res.json();
      console.log("Response data:", data);

      if (data.success === false) {
        setLoading(false);
        setError(data.message);
        console.error(data.message);
        return;
      }

      setLoading(false);
      setError(null);

      // Display a Snackbar notification
      setShowNotification(true);

      navigate("/sign-in");
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const handleAuth0SignUp = async () => {
    console.log("handleAuth0SignUp triggered");
    try {
      setLoading(true);
      await loginWithRedirect({
        screen_hint: "signup",
      });
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-gray-100 bg-cover bg-center"
      style={{ backgroundImage: `url(${BgImage})` }}
    >
      <div className="bg-white shadow-soft border-0 rounded-lg p-8 w-full max-w-md mx-auto">
        <div className="text-center mb-6">
          <h3 className="mb-2 text-2xl font-bold">Sign Up</h3>
          <p className="text-gray-500">Create your account</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <select
              value={formData.role}
              onChange={(e) =>
                handleChange({ target: { id: "role", value: e.target.value } })
              }
              className="bg-gray-100 focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-3 pl-4 pr-4 block w-full appearance-none leading-normal"
            >
              <option value="homeowner">Home Owner</option>
              <option value="realEstateAgent">Real Estate Agent</option>
              <option value="realEstateBroker">Real Estate Broker</option>
              <option value="realEstateInvestor">Real Estate Investor</option>
              <option value="loanOfficer">
                Loan Officer/Mortgage Specialist
              </option>
              <option value="realEstateAttorney">Real Estate Attorney</option>
              <option value="buyer">Buyer</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="mb-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Username"
                className="bg-gray-100 focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-3 pl-4 pr-4 block w-full appearance-none leading-normal"
                value={formData.username}
                onChange={handleUsernameChange}
              />
              {usernameAvailable === true && (
                <span className="text-green-500 absolute right-3 top-1/2 transform -translate-y-1/2">
                  <CheckIcon />
                </span>
              )}
              {usernameAvailable === false && (
                <span className="text-red-500 absolute right-3 top-1/2 transform -translate-y-1/2">
                  <ClearIcon />
                </span>
              )}
            </div>
          </div>
          <div className="mb-4">
            <input
              type="email"
              placeholder="Email"
              className="bg-gray-100 focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-3 pl-4 pr-4 block w-full appearance-none leading-normal"
              id="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className="bg-gray-100 focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-3 pl-4 pr-10 block w-full appearance-none leading-normal"
                autoComplete="current-password"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 focus:outline-none"
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="h-5 w-5"
                />
              </button>
            </div>
          </div>
          <button
            disabled={loading}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded w-full focus:outline-none focus:shadow-outline"
          >
            {loading ? "Loading..." : "Sign Up"}
          </button>
        </form>
        <div className="mt-4 text-center">
          <p className="text-gray-500">or sign up with</p>
          <button
            type="button"
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded w-full mt-4 focus:outline-none focus:shadow-outline"
            onClick={handleAuth0SignUp}
          >
            Sign Up with Auth0
          </button>
        </div>
        {error && <p className="text-red-500 mt-4">{error}</p>}
        <div className="mt-4 text-center">
          <p className="text-gray-500">
            Already have an account?{" "}
            <Link to="/sign-in" className="text-blue-500 hover:opacity-70">
              Sign in
            </Link>
          </p>
        </div>
        <Snackbar
          open={showNotification}
          autoHideDuration={5000}
          onClose={() => setShowNotification(false)}
          message="Sign up successful! Please check your email for a verification link."
        />
      </div>
    </div>
  );
}
