import React from "react";

const ProfileProgress = () => {
  // This is a placeholder. You'd typically calculate this based on user data
  const progress = 65;

  return (
    <div>
      <h3 className="text-lg font-semi-bold mb-2">Profile Progress</h3>
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div
          className="bg-green-600 h-2.5 rounded-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p className="text-sm text-gray-600 mt-2">Profile {progress}% complete</p>
      <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors">
        Complete Your Profile
      </button>
    </div>
  );
};

export default ProfileProgress;
