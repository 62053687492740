import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Card, CardContent } from "@mui/material";

// Dummy data for demo purposes
const dummyWalkScoreData = {
  walkScore: 85,
  walkDescription: "Very Walkable",
  transitScore: 72,
  transitDescription: "Excellent Transit",
  bikeScore: 68,
  bikeDescription: "Bikeable",
  scores: {
    walkScore: {
      score: 85,
      description: "Most errands can be accomplished on foot",
      details: [
        "Grocery stores within 5 minutes walking distance",
        "Multiple restaurants and cafes nearby",
        "Parks and recreation areas accessible by foot"
      ]
    },
    transitScore: {
      score: 72,
      description: "Transit is convenient for most trips",
      details: [
        "Bus stops within 0.2 miles",
        "Train station within 1 mile",
        "Multiple transit lines available"
      ]
    },
    bikeScore: {
      score: 68,
      description: "Biking is convenient for most trips",
      details: [
        "Bike lanes on most major roads",
        "Moderate hills",
        "Good bike sharing program"
      ]
    }
  }
};

const CachedWalkScoreWidget = ({ address }) => {
  // For demo purposes, we'll just return the dummy data immediately
  return (
    <Card sx={{ mt: 2, mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Walk Score
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box>
            <Typography variant="h4">{dummyWalkScoreData.walkScore}</Typography>
            <Typography variant="subtitle1">{dummyWalkScoreData.walkDescription}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{dummyWalkScoreData.transitScore}</Typography>
            <Typography variant="subtitle1">{dummyWalkScoreData.transitDescription}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{dummyWalkScoreData.bikeScore}</Typography>
            <Typography variant="subtitle1">{dummyWalkScoreData.bikeDescription}</Typography>
          </Box>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {address}
        </Typography>
      </CardContent>
    </Card>
  );
};

/* Real implementation (commented out for future use)
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import api from "../../api";
import { Box, CircularProgress, Typography, Card, CardContent } from "@mui/material";
import WalkScoreWidget from "./WalkScoreWidget";

const CachedWalkScoreWidget = ({ address, latitude, longitude }) => {
  const [cachedData, setCachedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCachedData = async () => {
      try {
        setLoading(true);
        const response = await api.get("/cached-walkscore", {
          params: { address, latitude, longitude },
        });
        console.log("Cached Walk Score data:", response);
        
        if (response?.success && response.data) {
          setCachedData(response.data);
        } else {
          console.warn("No Walk Score data available");
          setCachedData(null);
        }
      } catch (err) {
        console.error("Error fetching cached Walk Score data:", err);
        setCachedData(null);
      } finally {
        setLoading(false);
      }
    };

    if (address && latitude && longitude) {
      fetchCachedData();
    } else {
      setLoading(false);
    }
  }, [address, latitude, longitude]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (!cachedData) {
    return null;
  }

  return (
    <WalkScoreWidget
      address={address}
      latitude={latitude}
      longitude={longitude}
      cachedData={cachedData}
    />
  );
};
*/

CachedWalkScoreWidget.propTypes = {
  address: PropTypes.string.isRequired,
  // Keep these in PropTypes for future use
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
};

export default CachedWalkScoreWidget;
