// constants.js - Enhanced feedPosts array
export const feedPosts = [
  {
    id: 1,
    content:
      "Just closed on a stunning beachfront property! The views are absolutely breathtaking. If you're looking for luxury oceanfront living, I've got some amazing options available. #LuxuryRealEstate #OceanViews",
    images: [
      "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&fit=crop&w=800&q=80",
      "https://images.unsplash.com/photo-1506126613408-eca07ce68773?auto=format&fit=crop&w=800&q=80",
      "https://images.unsplash.com/photo-1499793983690-e29da59ef1c2?auto=format&fit=crop&w=800&q=80",
    ],
    timestamp: "2 hours ago",
    category: "Luxury",
    user: {
      username: "sarahrealty",
      name: "Sarah Johnson",
      avatar:
        "https://ui-avatars.com/api/?name=Sarah+Johnson&background=0D8ABC&color=fff",
      role: "Senior Real Estate Agent",
      verified: true,
      status: "online",
      badges: ["Top Producer", "Luxury Specialist"],
      stats: {
        deals: 150,
        rating: 4.9,
      },
    },
    stats: {
      likes: 128,
      comments: 32,
      shares: 15,
    },
    propertyDetails: {
      price: "$2,450,000",
      location: "Malibu, CA",
      specs: "4 beds • 5 baths • 3,500 sqft",
      type: "Luxury Villa",
    },
  },
  {
    id: 2,
    content: "Market Analysis: Seeing strong potential in the Atlanta suburbs. Property values up 15% YoY, rental demand increasing. Multiple properties just listed under market value. DM for details. #RealEstateInvesting #MarketAnalysis",
    timestamp: "3 hours ago",
    user: {
      username: "davidkim",
      name: "David Kim",
      avatar: "https://ui-avatars.com/api/?name=David+Kim&background=10B981&color=fff",
      role: "Real Estate Investor",
      verified: true,
      status: "online",
      badges: ["Investment Pro"],
      stats: {
        deals: 89,
        rating: 4.7,
      },
    },
    stats: {
      likes: 89,
      comments: 23,
      shares: 12,
    },
  },
  {
    id: 3,
    content: "🚨 Important Update: New regulations affecting foreclosure timelines in California. Key changes include extended notice periods and additional borrower protections. Check out my latest blog post for a detailed breakdown. #RealEstateLaw #Foreclosure",
    timestamp: "4 hours ago",
    user: {
      username: "emmarodriguez",
      name: "Emma Rodriguez",
      avatar: "https://ui-avatars.com/api/?name=Emma+Rodriguez&background=8B5CF6&color=fff",
      role: "Real Estate Attorney",
      verified: true,
      status: "away",
      badges: ["Legal Expert"],
      stats: {
        deals: 200,
        rating: 4.8,
      },
    },
    stats: {
      likes: 156,
      comments: 42,
      shares: 67,
    },
  },
  {
    id: 4,
    content: "📊 Mortgage Rate Update: Seeing rates stabilize around 6.5%. Great opportunity for refinancing or new purchases. Book a free consultation to discuss your options! #MortgageRates #HomeLoans",
    timestamp: "5 hours ago",
    user: {
      username: "jmartinez",
      name: "Jennifer Martinez",
      avatar: "https://ui-avatars.com/api/?name=Jennifer+Martinez&background=F59E0B&color=fff",
      role: "Loan Officer",
      verified: true,
      status: "online",
      badges: ["Finance Specialist"],
      stats: {
        deals: 120,
        rating: 4.9,
      },
    },
    stats: {
      likes: 67,
      comments: 15,
      shares: 9,
    },
  },
  {
    id: 5,
    content: "Just listed! Historic brownstone in Boston's Back Bay. Original features, modern updates. Open house this Sunday 2-4 PM. #BostonRealEstate #HistoricHomes",
    images: [
      "https://images.unsplash.com/photo-1568605114967-8130f3a36994?auto=format&fit=crop&w=800&q=80",
    ],
    timestamp: "6 hours ago",
    user: {
      username: "michaelchen",
      name: "Michael Chen",
      avatar: "https://ui-avatars.com/api/?name=Michael+Chen&background=DC2626&color=fff",
      role: "Luxury Real Estate Agent",
      verified: true,
      status: "online",
      badges: ["Historic Homes Specialist"],
      stats: {
        deals: 75,
        rating: 4.8,
      },
    },
    stats: {
      likes: 92,
      comments: 28,
      shares: 14,
    },
    propertyDetails: {
      price: "$3,200,000",
      location: "Back Bay, Boston",
      specs: "5 beds • 4 baths • 4,200 sqft",
      type: "Historic Brownstone",
    },
  },
  {
    id: 6,
    content: "Need advice: Just received a pre-foreclosure notice. Looking for guidance on loan modification options. Has anyone worked with a HUD-approved housing counselor? Would appreciate any recommendations in the Boston area. #HomeownerHelp #RealEstateAdvice",
    timestamp: "7 hours ago",
    user: {
      username: "mikejohnson",
      name: "Mike Johnson",
      avatar: "https://ui-avatars.com/api/?name=Mike+Johnson&background=6366F1&color=fff",
      role: "Homeowner",
      verified: false,
      status: "online",
      stats: {
        rating: 4.5,
      },
    },
    stats: {
      likes: 23,
      comments: 34,
      shares: 2,
    },
  },
  {
    id: 7,
    content: "Exciting development! Breaking ground on our new eco-friendly apartment complex in Portland. Solar panels, green roof, and EV charging stations. Pre-leasing starts next month! #SustainableLiving #GreenDevelopment",
    images: [
      "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&w=800&q=80",
    ],
    timestamp: "8 hours ago",
    user: {
      username: "alexgreen",
      name: "Alex Green",
      avatar: "https://ui-avatars.com/api/?name=Alex+Green&background=059669&color=fff",
      role: "Real Estate Developer",
      verified: true,
      status: "online",
      badges: ["Green Building Certified"],
      stats: {
        deals: 12,
        rating: 4.9,
      },
    },
    stats: {
      likes: 245,
      comments: 56,
      shares: 89,
    },
    propertyDetails: {
      price: "From $2,200/month",
      location: "Portland, OR",
      specs: "Studio-2 beds • 1-2 baths • 550-1,100 sqft",
      type: "Eco Apartments",
    },
  },
  {
    id: 8,
    content: "Market Insight: Commercial real estate in downtown Chicago showing strong recovery. Office occupancy rates up 25% from last year. Great time for investors to consider class A properties. #CommercialRealEstate #ChicagoRealEstate",
    timestamp: "9 hours ago",
    user: {
      username: "sophiawang",
      name: "Sophia Wang",
      avatar: "https://ui-avatars.com/api/?name=Sophia+Wang&background=7C3AED&color=fff",
      role: "Commercial Real Estate Analyst",
      verified: true,
      status: "away",
      badges: ["Market Expert"],
      stats: {
        deals: 45,
        rating: 4.8,
      },
    },
    stats: {
      likes: 167,
      comments: 43,
      shares: 76,
    },
  },
  {
    id: 9,
    content: "🎉 Just helped another first-time homebuyer close on their dream home! FHA loan, 3.5% down, below asking price. Don't let the market intimidate you - homeownership is possible! DM me to learn more. #FirstTimeHomeBuyer #RealEstateSuccess",
    images: [
      "https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?auto=format&fit=crop&w=800&q=80",
    ],
    timestamp: "10 hours ago",
    user: {
      username: "robertsmith",
      name: "Robert Smith",
      avatar: "https://ui-avatars.com/api/?name=Robert+Smith&background=DB2777&color=fff",
      role: "Real Estate Agent",
      verified: true,
      status: "online",
      badges: ["First-Time Buyer Specialist"],
      stats: {
        deals: 98,
        rating: 4.9,
      },
    },
    stats: {
      likes: 312,
      comments: 45,
      shares: 23,
    },
    propertyDetails: {
      price: "$285,000",
      location: "Phoenix, AZ",
      specs: "3 beds • 2 baths • 1,800 sqft",
      type: "Single Family Home",
    },
  },
  {
    id: 10,
    content: "Investment Opportunity: Multi-family property package in emerging Nashville neighborhood. 5 buildings, 28 units total, 95% occupancy. 8% cap rate. Serious investors only. #RealEstateInvestment #MultiFamilyHousing",
    timestamp: "11 hours ago",
    user: {
      username: "amandaharris",
      name: "Amanda Harris",
      avatar: "https://ui-avatars.com/api/?name=Amanda+Harris&background=9333EA&color=fff",
      role: "Investment Property Specialist",
      verified: true,
      status: "online",
      badges: ["Multi-Family Expert"],
      stats: {
        deals: 65,
        rating: 4.7,
      },
    },
    stats: {
      likes: 189,
      comments: 67,
      shares: 45,
    },
    propertyDetails: {
      price: "$4,850,000",
      location: "Nashville, TN",
      specs: "28 units • Mix of 1-2 beds",
      type: "Multi-Family",
    },
  },
  {
    id: 11,
    content: "Property Management Tip: Implementing smart home technology in our rental properties has reduced energy costs by 23% and increased tenant satisfaction. Top features: smart thermostats, keyless entry, and leak detection. #PropertyManagement #SmartHomes",
    timestamp: "12 hours ago",
    user: {
      username: "tomwilson",
      name: "Tom Wilson",
      avatar: "https://ui-avatars.com/api/?name=Tom+Wilson&background=4F46E5&color=fff",
      role: "Property Manager",
      verified: true,
      status: "online",
      badges: ["Tech Innovation"],
      stats: {
        deals: 150,
        rating: 4.8,
      },
    },
    stats: {
      likes: 145,
      comments: 38,
      shares: 52,
    },
  },
  {
    id: 12,
    content: "Renovation Complete! Before and after pics of our latest flip in San Diego. 45-day turnaround, 40% ROI projected. Key improvements: open concept layout, modern kitchen, smart home features. #HouseFlipping #BeforeAndAfter",
    images: [
      "https://images.unsplash.com/photo-1484154218962-a197022b5858?auto=format&fit=crop&w=800&q=80",
      "https://images.unsplash.com/photo-1505691938895-1758d7feb511?auto=format&fit=crop&w=800&q=80",
    ],
    timestamp: "13 hours ago",
    user: {
      username: "jessicabrown",
      name: "Jessica Brown",
      avatar: "https://ui-avatars.com/api/?name=Jessica+Brown&background=EA580C&color=fff",
      role: "House Flipper",
      verified: true,
      status: "online",
      badges: ["Renovation Expert"],
      stats: {
        deals: 32,
        rating: 4.9,
      },
    },
    stats: {
      likes: 278,
      comments: 89,
      shares: 34,
    },
    propertyDetails: {
      price: "$789,000",
      location: "San Diego, CA",
      specs: "3 beds • 2 baths • 1,950 sqft",
      type: "Renovated Home",
    },
  },
];

export const suggestedUsers = [
  {
    id: "1",
    name: "Jessica Martinez",
    role: "Luxury Real Estate Agent",
    avatar: "https://ui-avatars.com/api/?name=Jessica+Martinez&background=F59E0B&color=fff",
    status: "online",
    badges: ["Top Producer"],
    followers: 1200,
    listings: 45,
    deals: 78,
    rating: 4.9,
  },
  {
    id: "2",
    name: "Michael Chen",
    role: "Commercial Real Estate Specialist",
    avatar: "https://ui-avatars.com/api/?name=Michael+Chen&background=10B981&color=fff",
    status: "away",
    badges: ["Investment Pro"],
    followers: 980,
    listings: 32,
    deals: 92,
    rating: 4.8,
  },
  {
    id: "3",
    name: "Emily Brown",
    role: "Residential Property Expert",
    avatar: "https://ui-avatars.com/api/?name=Emily+Brown&background=8B5CF6&color=fff",
    status: "online",
    badges: ["Rising Star"],
    followers: 750,
    listings: 28,
    deals: 45,
    rating: 4.7,
  }
];

export const suggestedCategories = [
  {
    id: "luxury",
    name: "Luxury Properties",
    icon: "🏰",
    agents: [
      {
        id: "1",
        name: "Jessica Martinez",
        role: "Luxury Real Estate Agent",
        avatar:
          "https://ui-avatars.com/api/?name=Jessica+Martinez&background=F59E0B&color=fff",
        status: "online",
        badges: ["Top Producer"],
        followers: 1200,
        listings: 45,
        deals: 78,
        rating: 4.9,
      },
    ],
  },
  {
    id: "commercial",
    name: "Commercial Real Estate",
    icon: "🏢",
    agents: [],
  },
  {
    id: "investment",
    name: "Investment Properties",
    icon: "💰",
    agents: [],
  },
];

export const activityStatuses = {
  online: {
    color: "bg-green-400",
    text: "Online",
  },
  away: {
    color: "bg-yellow-400",
    text: "Away",
  },
  offline: {
    color: "bg-gray-400",
    text: "Offline",
  },
  busy: {
    color: "bg-red-400",
    text: "Busy",
  },
};
