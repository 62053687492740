// SearchPageSearchBar.jsx
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FaRobot, FaSave } from "react-icons/fa";

const SearchBar = ({
  searchTerm,
  setSearchTerm,
  isAIEnabled,
  setIsAIEnabled,
  showSaveSearchModal,
  onSearch,
}) => {
  const [ringColor, setRingColor] = useState("#FFC0CB");

  useEffect(() => {
    if (isAIEnabled) {
      const pastelColors = [
        "#AEC6CF",
        "#77DD77",
        "#FFB347",
        "#FDFD96",
        "#CFCFC4",
        "#FFB6C1",
        "#B39EB5",
      ];

      let index = 0;
      const intervalId = setInterval(() => {
        setRingColor(pastelColors[index]);
        index = (index + 1) % pastelColors.length;
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isAIEnabled]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchTerm, isAIEnabled);
  };

  return (
    <div className="bg-white p-4 shadow-md">
      <div className="container mx-auto flex items-center">
        <form onSubmit={handleSubmit} className="relative flex-grow">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 pr-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            style={{
              boxShadow: isAIEnabled ? `0 0 0 2px ${ringColor}` : "none",
              transition: "box-shadow 0.3s ease",
            }}
            placeholder={
              isAIEnabled
                ? "Search with AI using Natural Language"
                : "Search properties..."
            }
          />
          <button
            type="button"
            className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-500 ${
              isAIEnabled ? "text-blue-500" : ""
            }`}
            onClick={() => setIsAIEnabled(!isAIEnabled)}
          >
            <FaRobot size={20} />
          </button>
        </form>
        <button
          className="ml-4 bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300"
          onClick={showSaveSearchModal}
        >
          <FaSave className="inline-block mr-2" />
          Save Search
        </button>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  isAIEnabled: PropTypes.bool.isRequired,
  setIsAIEnabled: PropTypes.func.isRequired,
  showSaveSearchModal: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default SearchBar;
