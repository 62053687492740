import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { LocationOn } from "@mui/icons-material";

const PropertyMap = ({ address }) => {
  // For demo purposes, we'll show a placeholder card instead of the actual map
  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Box sx={{ 
          height: 200, 
          bgcolor: '#f5f5f5', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: 1
        }}>
          <LocationOn sx={{ fontSize: 40, color: '#2196f3', mb: 1 }} />
          <Typography variant="subtitle1" align="center" sx={{ px: 2 }}>
            {address}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Map view will be available here
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

/* Real implementation (commented out for future use)
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const PropertyMap = ({ latitude, longitude, address }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (!latitude || !longitude) return;

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [longitude, latitude],
      zoom: 15
    });

    // Add navigation controls
    map.current.addControl(new mapboxgl.NavigationControl());

    // Add marker
    new mapboxgl.Marker()
      .setLngLat([longitude, latitude])
      .setPopup(new mapboxgl.Popup().setHTML(`<p>${address}</p>`))
      .addTo(map.current);

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [latitude, longitude, address]);

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Box
          ref={mapContainer}
          sx={{
            height: 200,
            borderRadius: 1,
            overflow: 'hidden'
          }}
        />
      </CardContent>
    </Card>
  );
};
*/

PropertyMap.propTypes = {
  address: PropTypes.string.isRequired,
  // Keep these in PropTypes for future use
  latitude: PropTypes.number,
  longitude: PropTypes.number
};

export default PropertyMap;
