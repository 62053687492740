import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Paper, IconButton, CircularProgress } from "@mui/material";
import { Search, LocationOn } from "@mui/icons-material";
import { AddressAutofill } from "@mapbox/search-js-react";
import { styled } from "@mui/material/styles";

const SearchContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  marginBottom: "32px",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const SearchTitle = styled(Box)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const SearchWrapper = styled(Box)({
  position: "relative",
  width: "100%",
  maxWidth: "800px",
  margin: "0 auto",
});

const StyledInput = styled("input")(({ theme }) => ({
  width: "100%",
  padding: "16px 56px",
  fontSize: "1.1rem",
  border: "2px solid #E0E7FF",
  borderRadius: "12px",
  outline: "none",
  transition: "all 0.3s ease",
  fontFamily: "'Lato', sans-serif",
  textAlign: "center",
  "&:focus": {
    borderColor: theme.palette.primary.main,
    boxShadow: "0 0 0 4px rgba(99, 102, 241, 0.1)",
  },
  "&::placeholder": {
    color: "#94A3B8",
    fontFamily: "'Lato', sans-serif",
  },
}));

const LocationIcon = styled(LocationOn)(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(2),
  top: "50%",
  transform: "translateY(-50%)",
  color: "#94A3B8",
  fontSize: "24px",
}));

const SearchButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  padding: "12px",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    transform: "translateY(-50%) scale(1.05)",
  },
  "&.Mui-disabled": {
    backgroundColor: "#E2E8F0",
  },
}));

const SuggestionsList = styled(Box)({
  position: "absolute",
  top: "100%",
  left: 0,
  right: 0,
  maxHeight: "300px",
  overflowY: "auto",
  backgroundColor: "#fff",
  borderRadius: "12px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
  marginTop: "8px",
  fontFamily: "'Lato', sans-serif",
});

const SuggestionItem = styled("div")({
  padding: "16px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
  transition: "all 0.2s ease",
  fontFamily: "'Lato', sans-serif",
  "&:hover": {
    backgroundColor: "#F8FAFC",
  },
  "& .MuiSvgIcon-root": {
    color: "#94A3B8",
    fontSize: "20px",
  },
});

const PropertySearch = ({ onSearch, isLoading }) => {
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleAddressSelect = (location) => {
    if (!location || !location.features || !location.features[0]) {
      console.warn('Invalid location data received');
      return;
    }

    const feature = location.features[0];
    const formattedAddress = feature.place_name;
    setAddress(formattedAddress);
    setSelectedLocation(feature);
    setSuggestions([]);

    // Extract zipcode from context
    const zipCode = feature.context?.find(ctx => ctx.id.startsWith('postcode'))?.text
      || formattedAddress.match(/\d{5}(?:-\d{4})?/)?.[0];

    // Automatically trigger search with complete location data
    onSearch({
      address: formattedAddress,
      zipCode,
      coordinates: feature.geometry?.coordinates || null,
      placeData: feature
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setAddress(value);
    
    if (!value) {
      setSuggestions([]);
      setSelectedLocation(null);
    }
  };

  const handleSearchClick = () => {
    if (!address) return;

    if (selectedLocation) {
      const zipCode = selectedLocation.context?.find(ctx => ctx.id.startsWith('postcode'))?.text
        || address.match(/\d{5}(?:-\d{4})?/)?.[0];

      onSearch({
        address,
        zipCode,
        coordinates: selectedLocation.geometry?.coordinates || null,
        placeData: selectedLocation
      });
    } else {
      // If no location is selected, just search with the address
      onSearch({
        address,
        zipCode: address.match(/\d{5}(?:-\d{4})?/)?.[0] || null
      });
    }
  };

  return (
    <SearchContainer elevation={0}>
      <SearchTitle>Find Your Next Investment Property</SearchTitle>
      <SearchWrapper>
        <AddressAutofill
          accessToken={import.meta.env.VITE_MAPBOX_TOKEN}
          onSelect={handleAddressSelect}
        >
          <StyledInput
            placeholder="Enter property address..."
            value={address}
            onChange={handleInputChange}
            autoComplete="street-address"
          />
        </AddressAutofill>
        <LocationIcon />
        <SearchButton
          onClick={handleSearchClick}
          disabled={!address || isLoading}
          size="large"
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <Search />
          )}
        </SearchButton>
        {suggestions.length > 0 && (
          <SuggestionsList>
            {suggestions.map((suggestion, index) => (
              <SuggestionItem
                key={index}
                onClick={() => handleAddressSelect({ features: [suggestion] })}
              >
                <LocationOn />
                {suggestion.place_name}
              </SuggestionItem>
            ))}
          </SuggestionsList>
        )}
      </SearchWrapper>
    </SearchContainer>
  );
};

PropertySearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PropertySearch;