// Dummy implementation for development/testing
const dummyAmenities = {
  ESSENTIAL: {
    items: [
      {
        id: 'place1',
        name: "Local Grocery Store",
        type: "grocery_store",
        rating: 4.5,
        address: "123 Main St",
        location: { lat: 40.7128, lng: -74.0060 },
        openNow: true
      },
      {
        id: 'place2',
        name: "Downtown Pharmacy",
        type: "pharmacy",
        rating: 4.0,
        address: "456 Market St",
        location: { lat: 40.7129, lng: -74.0061 },
        openNow: true
      }
    ],
    count: 2,
    averageRating: 4.25
  },
  EDUCATION: {
    items: [
      {
        id: 'place3',
        name: "City High School",
        type: "school",
        rating: 4.2,
        address: "789 Education Ave",
        location: { lat: 40.7130, lng: -74.0062 },
        openNow: true
      }
    ],
    count: 1,
    averageRating: 4.2
  },
  RECREATION: {
    items: [
      {
        id: 'place4',
        name: "Central Park",
        type: "park",
        rating: 4.8,
        address: "321 Park Road",
        location: { lat: 40.7131, lng: -74.0063 },
        openNow: true
      }
    ],
    count: 1,
    averageRating: 4.8
  }
};

export const getDummyPlaces = () => dummyAmenities;

export const getDummyPlaceDetails = (placeId) => {
  for (const category of Object.values(dummyAmenities)) {
    const place = category.items.find(item => item.id === placeId);
    if (place) {
      return {
        ...place,
        photos: [],
        reviews: [
          {
            author_name: "John Doe",
            rating: 5,
            text: "Great location!",
            time: new Date().getTime()
          }
        ]
      };
    }
  }
  return null;
};

/* Real Implementation (commented out for reference)
let isGoogleMapsLoaded = false;

export const loadGoogleMapsApi = () => {
  if (isGoogleMapsLoaded) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    window.initGoogleMaps = () => {
      isGoogleMapsLoaded = true;
      resolve();
    };

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initGoogleMaps`;
    script.async = true;
    script.defer = true;
    script.onerror = (error) => reject(new Error('Failed to load Google Maps API'));
    
    document.head.appendChild(script);
  });
};

export const isGoogleMapsInitialized = () => isGoogleMapsLoaded;
*/
