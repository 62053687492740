import React from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { format, parseISO } from "date-fns";
import { CheckCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ConversationList = ({
  conversations = [],
  onSelectConversation,
  searchTerm = "",
  setSearchTerm,
  currentUser,
}) => {
  const navigate = useNavigate();

  const handleUsernameClick = (e) => {
    e.stopPropagation(); // Prevent conversation selection
    navigate('/profile/wesnerm+one@gmail.com');
  };

  // Rest of the component remains unchanged...
  const getOtherParticipant = (conversation) => {
    if (!conversation) return null;

    if (conversation.otherParticipant) {
      return conversation.otherParticipant;
    }

    if (!conversation.participants?.length) return null;

    if (currentUser?._id) {
      const other = conversation.participants.find(
        (p) => p?._id !== currentUser._id
      );
      if (other) return other;
    }

    return conversation.participants[0];
  };

  const filteredConversations = conversations
    .filter((conversation) => {
      if (!conversation) return false;

      const otherParticipant = getOtherParticipant(conversation);
      if (!otherParticipant) return false;

      const username = otherParticipant.username || "";
      const searchLower = (searchTerm || "").toLowerCase();

      return username.toLowerCase().includes(searchLower);
    })
    .sort((a, b) => {
      const dateA = a?.updatedAt
        ? new Date(a.updatedAt)
        : new Date(a?.lastMessage?.createdAt || 0);
      const dateB = b?.updatedAt
        ? new Date(b.updatedAt)
        : new Date(b?.lastMessage?.createdAt || 0);
      return dateB - dateA;
    });

  const getUnreadCount = (conversation) => {
    if (!conversation) return 0;
    if (
      conversation.unreadCount &&
      typeof conversation.unreadCount === "object"
    ) {
      return conversation.unreadCount[currentUser?._id || "demo-user"] || 0;
    }
    return conversation.unreadCount || 0;
  };

  const getLastMessagePreview = (conversation) => {
    if (!conversation?.lastMessage?.content) return "";

    const senderId = conversation.lastMessage.senderId;
    const isCurrentUserMessage = senderId === currentUser?._id;
    return isCurrentUserMessage
      ? `You: ${conversation.lastMessage.content}`
      : conversation.lastMessage.content;
  };

  const formatRole = (role) => {
    if (!role) return "";
    return role
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getLastMessageTime = (conversation) => {
    if (!conversation) return "";

    const messageDate =
      conversation?.lastMessage?.createdAt || conversation?.updatedAt;
    if (!messageDate) return "";

    try {
      const date = parseISO(messageDate);
      const now = new Date();
      const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

      if (diffInDays === 0) {
        return format(date, "h:mm a");
      } else if (diffInDays === 1) {
        return "Yesterday";
      } else if (diffInDays < 7) {
        return format(date, "EEEE");
      } else {
        return format(date, "MM/dd/yy");
      }
    } catch (error) {
      return conversation.formattedDate || "";
    }
  };

  const renderRole = (participant) => {
    if (!participant?.role) return null;
    return (
      <Typography
        variant="caption"
        sx={{
          px: 1,
          py: 0.5,
          bgcolor: "#dbeafe",
          color: "#1e40af",
          borderRadius: "9999px",
          fontFamily: "Lato, sans-serif",
        }}
      >
        {formatRole(participant.role)}
      </Typography>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{
        backgroundColor: "#f8f9fa",
        fontFamily: "Lato, sans-serif",
      }}
    >
      <Box p={2} sx={{ backgroundColor: "#fff" }}>
        <TextField
          fullWidth
          placeholder="Search conversations"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              fontFamily: "Lato, sans-serif",
            },
          }}
          size="small"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              fontFamily: "Lato, sans-serif",
            },
          }}
        />
      </Box>
      <List sx={{ overflow: "auto", flexGrow: 1 }}>
        {filteredConversations.map((conversation) => {
          if (!conversation?._id) return null;

          const otherParticipant = getOtherParticipant(conversation);
          if (!otherParticipant) return null;

          const unreadCount = getUnreadCount(conversation);
          const lastMessagePreview = getLastMessagePreview(conversation);
          const lastMessageTime = getLastMessageTime(conversation);

          return (
            <ListItem
              key={conversation._id}
              button
              onClick={() => onSelectConversation(conversation._id)}
              sx={{
                py: 2,
                borderBottom: "1px solid #e0e0e0",
                "&:hover": { backgroundColor: "#dbeafe" },
                bgcolor: unreadCount > 0 ? "#dbeafe" : "#fff",
                fontFamily: "Lato, sans-serif",
              }}
            >
              <Box sx={{ position: "relative", mr: 2 }}>
                <Avatar
                  src={otherParticipant?.avatar}
                  alt={otherParticipant?.username || ""}
                  sx={{ width: 48, height: 48 }}
                >
                  {otherParticipant?.username?.charAt(0).toUpperCase()}
                </Avatar>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: -2,
                    right: -2,
                    width: 8,
                    height: 8,
                    bgcolor: "success.main",
                    borderRadius: "50%",
                    border: "2px solid white",
                  }}
                />
              </Box>
              <ListItemText
                primary={
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: unreadCount > 0 ? 600 : 400,
                          fontFamily: "Lato, sans-serif",
                          cursor: "pointer",
                          "&:hover": {
                            color: "#2563eb",
                            textDecoration: "underline",
                          },
                        }}
                        onClick={handleUsernameClick}
                      >
                        {otherParticipant?.username || "Unknown User"}
                      </Typography>
                      <CheckCircle className="w-4 h-4 text-blue-500" />
                      {renderRole(otherParticipant)}
                    </Box>
                    {lastMessageTime && (
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          fontWeight: unreadCount > 0 ? 600 : 400,
                          fontFamily: "Lato, sans-serif",
                        }}
                      >
                        {lastMessageTime}
                      </Typography>
                    )}
                  </Box>
                }
                secondary={
                  lastMessagePreview && (
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: "block",
                        fontWeight: unreadCount > 0 ? 600 : 400,
                        fontFamily: "Lato, sans-serif",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          display: "inline-block",
                          width: "70%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontFamily: "Lato, sans-serif",
                        }}
                      >
                        {lastMessagePreview}
                      </Box>
                      {unreadCount > 0 && (
                        <Box
                          component="span"
                          sx={{
                            float: "right",
                            bgcolor: "#dbeafe",
                            color: "#1e40af",
                            borderRadius: "50%",
                            width: 20,
                            height: 20,
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "0.75rem",
                            fontFamily: "Lato, sans-serif",
                          }}
                        >
                          {unreadCount}
                        </Box>
                      )}
                    </Typography>
                  )
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

ConversationList.propTypes = {
  conversations: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      participants: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          username: PropTypes.string,
          avatar: PropTypes.string,
          role: PropTypes.string,
        })
      ),
      otherParticipant: PropTypes.shape({
        _id: PropTypes.string,
        username: PropTypes.string,
        avatar: PropTypes.string,
        role: PropTypes.string,
      }),
      lastMessage: PropTypes.shape({
        content: PropTypes.string,
        createdAt: PropTypes.string,
        senderId: PropTypes.string,
      }),
      updatedAt: PropTypes.string,
      unreadCount: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    })
  ),
  onSelectConversation: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    _id: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
  }),
};

ConversationList.defaultProps = {
  conversations: [],
  searchTerm: "",
  currentUser: null,
};

export default ConversationList;
