import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const useListingActions = (listing) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const handlePremiumFeatureClick = () => {
    setIsAuthModalOpen(true);
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    notyf.success("Listing link copied to clipboard");
  };

  const handleViewInvestmentAnalysis = (investmentMetrics) => {
    if (!currentUser) {
      setIsAuthModalOpen(true);
      return;
    }

    navigate("/dashboard/real-estate-dashboard", {
      state: {
        propertyData: {
          ...listing,
          address: listing.address,
          zipCode: listing.zipCode || listing.address.match(/\d{5}/)?.[0],
          financialData: {
            estimatedValue: listing.price,
            monthlyRent: listing.rentalPrice,
            expenses: [
              { name: "Property Tax", value: listing.propertyTax || 0 },
              { name: "Insurance", value: listing.insurance || 0 },
              { name: "Maintenance", value: listing.maintenance || 0 },
            ],
            investmentMetrics,
          },
        },
      },
    });
  };

  return {
    isAuthModalOpen,
    setIsAuthModalOpen,
    handlePremiumFeatureClick,
    handleShare,
    handleViewInvestmentAnalysis,
  };
};

export default useListingActions;
