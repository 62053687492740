export const defaultInvestmentMetrics = {
  roi: {
    value: 12.5,
    trend: "up",
    details: {
      cashOnCash: 8.2,
      totalReturn: 15.7,
      projectedAppreciation: 5.8,
    },
  },
  flipPotential: {
    score: 85,
    estimatedProfit: 75000,
    timeToFlip: "6-8 months",
    rehabCost: 45000,
  },
  rentalProjections: {
    monthlyRent: 2800,
    annualIncome: 33600,
    occupancyRate: 95,
    netOperatingIncome: 28560,
  },
  marketIndicators: {
    demandScore: 8.5,
    competitionLevel: "Medium",
    pricePerSqFt: 185,
    marketAppreciation: 4.2,
  },
};
