import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Select } from "flowbite-react";

function MyListings() {
  const { currentUser, loading, error } = useSelector((state) => state.user);
  const [showListingsError, setShowListingsError] = useState(false);
  const [userListings, setUserListings] = useState([]);
  const [displayedListings, setDisplayedListings] = useState([]);
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [hasMoreListings, setHasMoreListings] = useState(false);

  // Fetch listings on page load
  useEffect(() => {
    const fetchListings = async () => {
      try {
        setShowListingsError(false);
        const res = await fetch(`/api/user/listings/${currentUser._id}`);
        const data = await res.json();
        if (data.success === false) {
          setShowListingsError(true);
          return;
        }
        setUserListings(data);
        setDisplayedListings(data.slice(0, resultsPerPage));
        setHasMoreListings(data.length > resultsPerPage);
      } catch (error) {
        setShowListingsError(true);
      }
    };
    fetchListings();
  }, [currentUser._id, resultsPerPage]);

  const handleListingDelete = async (listingId) => {
    try {
      const res = await fetch(`/api/listing/delete/${listingId}`, {
        method: "DELETE",
      });
      const data = await res.json();
      if (data.success === false) {
        console.log(data.message);
        return;
      }

      setUserListings((prev) =>
        prev.filter((listing) => listing._id !== listingId)
      );
      setDisplayedListings((prev) =>
        prev.filter((listing) => listing._id !== listingId)
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleLoadMore = () => {
    const nextIndex = displayedListings.length + resultsPerPage;
    const nextListings = userListings.slice(0, nextIndex);
    setDisplayedListings(nextListings);
    setHasMoreListings(nextListings.length < userListings.length);
  };

  const handleResultsPerPageChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setResultsPerPage(value);
  };

  return (
    <div className="p-3 max-w-lg mx-auto">
      {userListings && userListings.length > 0 && (
        <div className="flex flex-col gap-4">
          <h1 className="text-center mt-7 text-2xl font-semibold">
            Your Listings
          </h1>
          <Select
            className="mb-4"
            value={resultsPerPage}
            onChange={handleResultsPerPageChange}
          >
            <option value={5}>5 Results</option>
            <option value={10}>10 Results</option>
            <option value={15}>15 Results</option>
            <option value={20}>20 Results</option>
            <option value={userListings.length}>All</option>
          </Select>
          {displayedListings.map((listing) => (
            <div
              key={listing._id}
              className="border rounded-lg p-3 flex justify-between items-center gap-4"
            >
              <Link to={`/listing/${listing._id}`}>
                <img
                  src={listing.imageUrls[0]}
                  alt="listing cover"
                  className="h-16 w-16 object-contain"
                />
              </Link>
              <Link
                className="text-slate-700 font-semibold  hover:underline truncate flex-1"
                to={`/listing/${listing._id}`}
              >
                <p>{listing.name}</p>
              </Link>

              <div className="flex flex-col item-center">
                <button
                  onClick={() => handleListingDelete(listing._id)}
                  className="text-red-700 uppercase"
                >
                  Delete
                </button>

                <Link to={`/update-listing/${listing._id}`}>
                  <button className="text-green-700 uppercase">Update</button>
                </Link>
                <Link to={`/update-listing/${listing._id}`}>
                  <button className="text-green-700 uppercase">Share</button>
                </Link>
              </div>
            </div>
          ))}
          {hasMoreListings && (
            <button
              className="bg-slate-700 text-white rounded-lg p-3 uppercase hover:opacity-95"
              onClick={handleLoadMore}
            >
              Show More
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default MyListings;
