import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  // Card,
  // CardContent,
  Avatar,
  Button,
  TextField,
  Box,
  Modal,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { CheckCircle, Edit } from "@mui/icons-material";
import { addNewMessage } from "../../redux/user/userSlice";
import api from "../../api";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import useSocketConnection from "../../hooks/useSocketConnection"; // Add this import

const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const ContactAgent = ({ owner, isOwner, onEdit }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const { socket } = useSocketConnection(); // Add this hook

  if (!owner) return null;

  const handleSendMessage = async () => {
    if (!currentUser) {
      notyf.error("Please log in to send a message");
      return;
    }

    try {
      const response = await api.post("/message/send", {
        senderId: currentUser._id,
        recipientId: owner._id,
        content: messageContent,
      });

      if (response.success) {
        // Only emit if socket exists
        if (socket) {
          socket.emit("sendMessage", response.message);
        }
        dispatch(addNewMessage(response.message));
        notyf.success("Message sent successfully");
        setMessageContent("");
        setShowMessageModal(false);
      } else {
        throw new Error(response.message || "Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      notyf.error("Failed to send message. Please try again.");
    }
  };

  const handleOpenModal = () => {
    setShowMessageModal(true);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
    setMessageContent("");
  };

  return (
    <Box
      sx={{
        position: "relative",
        paddingTop: "80px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.paper",
        borderRadius: 2,
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          height: "80px",
          width: "100%",
          background: `url(${
            owner.coverPhoto ||
            "https://images.pexels.com/photos/1323206/pexels-photo-1323206.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=100"
          }) center/cover`,
          position: "absolute",
          top: 0,
        }}
      />
      <Avatar
        component={Link}
        to={`/profile/${owner.username}`}
        src={owner.avatar}
        alt={owner.username}
        role={owner.role}
        sx={{
          width: 96,
          height: 96,
          border: "4px solid white",
          position: "absolute",
          top: "32px",
          left: "50%",
          transform: "translateX(-50%)",
          cursor: "pointer",
        }}
      />
      <Box sx={{ p: 3, width: "100%", textAlign: "center" }}>
        <Typography variant="h6" sx={{ mt: 2 }}>
          {owner.username}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {owner.username} | {owner.role}
        </Typography>
        <Chip
          icon={<CheckCircle />}
          label="Verified"
          color="success"
          size="small"
          sx={{ mt: 1, mb: 2 }}
        />
        {isOwner ? (
          <Button
            variant="contained"
            fullWidth
            startIcon={<Edit />}
            onClick={onEdit}
            sx={{
              mb: 2,
              borderRadius: "50px",
              textTransform: "none",
              fontSize: "16px",
              py: 1,
            }}
          >
            Edit listing
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              fullWidth
              sx={{
                mb: 2,
                borderRadius: "50px",
                textTransform: "none",
                fontSize: "16px",
                py: 1,
              }}
              onClick={handleOpenModal}
            >
              Send a message
            </Button>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderRadius: "50px",
                textTransform: "none",
                fontSize: "16px",
                py: 1,
              }}
            >
              Book a viewing
            </Button>
          </>
        )}
      </Box>

      {/* Message Modal */}
      <Modal
        open={showMessageModal}
        onClose={handleCloseModal}
        aria-labelledby="message-modal"
        aria-describedby="message-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: 400 },
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Message {owner.username}
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="Type your message here..."
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleSendMessage}
              disabled={!messageContent.trim()}
            >
              Send Message
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

ContactAgent.propTypes = {
  owner: PropTypes.shape({
    _id: PropTypes.string,
    username: PropTypes.string.isRequired,
    role: PropTypes.string,
    avatar: PropTypes.string,
    coverPhoto: PropTypes.string,
  }),
  isOwner: PropTypes.bool,
  onEdit: PropTypes.func,
};

ContactAgent.defaultProps = {
  isOwner: false,
};

export default ContactAgent;
