import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const LoadingContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  backgroundColor: "#F8FAFC",
  fontFamily: "'Lato', sans-serif",
});

const LoadingText = styled(Typography)({
  marginTop: "24px",
  fontSize: "1.25rem",
  fontWeight: "500",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
  textAlign: "center",
});

const StyledCircularProgress = styled(CircularProgress)({
  color: "#6366F1",
  width: "60px !important",
  height: "60px !important",
});

const LoadingIndicator = () => {
  return (
    <LoadingContainer>
      <StyledCircularProgress />
      <LoadingText>
        Loading your investment insights...
      </LoadingText>
    </LoadingContainer>
  );
};

export default LoadingIndicator;
