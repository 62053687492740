// TrendingTopics.jsx
import React from "react";

const TrendingTopics = () => {
  const topics = [
    { tag: "RealEstate", posts: "1.2k" },
    { tag: "MarketUpdate", posts: "856" },
    { tag: "Investment", posts: "623" },
  ];

  return (
    <div className="bg-white rounded-xl shadow-sm p-4">
      <h2 className="font-semibold text-lg mb-3">Trending Topics</h2>
      <div className="space-y-2">
        {topics.map((topic) => (
          <div
            key={topic.tag}
            className="px-3 py-2 hover:bg-gray-50 rounded-lg cursor-pointer"
          >
            <p className="font-medium">#{topic.tag}</p>
            <p className="text-sm text-gray-500">{topic.posts} posts</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingTopics;
