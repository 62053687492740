import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Leafs = () => {
  const { listingId } = useParams();
  const [analysis, setAnalysis] = useState({
    purchasePrice: 300000,
    downPayment: 20,
    closingCosts: 9000,
    monthlyRent: 2000,
    vacancyRate: 5,
    propertyTaxes: 3000,
    insurance: 1200,
    maintenance: 2400,
    utilities: 100,
    hoaFees: 0,
    rehabCosts: {
      cosmetic: 5000,
      structural: 0,
      appliances: 2000,
    },
    cashFlow: [],
  });

  useEffect(() => {
    // Fetch listing data and perform initial calculations
    // This is a placeholder for API calls and data processing
    calculateCashFlow();
  }, [listingId]);

  const calculateCashFlow = () => {
    // Placeholder for cash flow calculation
    const cashFlow = Array.from({ length: 10 }, (_, i) => ({
      year: i + 1,
      income: analysis.monthlyRent * 12 * (1 - analysis.vacancyRate / 100),
      expenses:
        (analysis.propertyTaxes +
          analysis.insurance +
          analysis.maintenance +
          analysis.utilities +
          analysis.hoaFees) *
        12,
      netIncome: 0,
    }));

    cashFlow.forEach((year) => {
      year.netIncome = year.income - year.expenses;
    });

    setAnalysis((prev) => ({ ...prev, cashFlow }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAnalysis((prev) => ({ ...prev, [name]: parseFloat(value) || 0 }));
  };

  return (
    <div className="container mx-auto p-4">
      <Typography variant="h4" className="mb-6">
        Detailed Investment Analysis
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper className="p-4">
            <Typography variant="h6" className="mb-4">
              Property Details
            </Typography>
            <TextField
              fullWidth
              label="Purchase Price"
              name="purchasePrice"
              value={analysis.purchasePrice}
              onChange={handleInputChange}
              className="mb-4"
            />
            <TextField
              fullWidth
              label="Down Payment (%)"
              name="downPayment"
              value={analysis.downPayment}
              onChange={handleInputChange}
              className="mb-4"
            />
            <TextField
              fullWidth
              label="Closing Costs"
              name="closingCosts"
              value={analysis.closingCosts}
              onChange={handleInputChange}
              className="mb-4"
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper className="p-4">
            <Typography variant="h6" className="mb-4">
              Monthly Expenses
            </Typography>
            <TextField
              fullWidth
              label="Property Taxes (Annual)"
              name="propertyTaxes"
              value={analysis.propertyTaxes}
              onChange={handleInputChange}
              className="mb-4"
            />
            <TextField
              fullWidth
              label="Insurance (Annual)"
              name="insurance"
              value={analysis.insurance}
              onChange={handleInputChange}
              className="mb-4"
            />
            <TextField
              fullWidth
              label="Maintenance (Annual)"
              name="maintenance"
              value={analysis.maintenance}
              onChange={handleInputChange}
              className="mb-4"
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className="p-4">
            <Typography variant="h6" className="mb-4">
              Rental Income
            </Typography>
            <TextField
              fullWidth
              label="Monthly Rent"
              name="monthlyRent"
              value={analysis.monthlyRent}
              onChange={handleInputChange}
              className="mb-4"
            />
            <Typography gutterBottom>Vacancy Rate</Typography>
            <Slider
              value={analysis.vacancyRate}
              onChange={(_, newValue) =>
                setAnalysis((prev) => ({ ...prev, vacancyRate: newValue }))
              }
              aria-labelledby="vacancy-rate-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={20}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className="p-4">
            <Typography variant="h6" className="mb-4">
              Cash Flow Projection
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={analysis.cashFlow}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="income"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="expenses" stroke="#82ca9d" />
                <Line type="monotone" dataKey="netIncome" stroke="#ffc658" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>

      <Typography variant="body2" className="mt-8 text-gray-600">
        Note: This is a simplified analysis. Please consult with a financial
        advisor for personalized investment advice.
      </Typography>
    </div>
  );
};

export default Leafs;
