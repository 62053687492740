import React from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import PropertyMap from "./PropertyMap";
import ContactAgent from "./ContactAgent";
import InvestmentAnalysis from "./InvestmentAnalysis";
import AirbnbPotential from "./AirbnbPotential";
import SimilarListings from "./SimilarListings";

const ListingSidebar = ({
  listing,
  latitude,
  longitude,
  investmentMetrics,
  onPremiumFeatureClick,
}) => {
  return (
    <>
      {/* Map Card */}
      <Card
        sx={{
          mb: 4,
          borderRadius: 4,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease-in-out",
          overflow: "hidden",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <PropertyMap
          latitude={latitude}
          longitude={longitude}
          address={listing.address}
        />
      </Card>

      {/* Contact Agent Card */}
      <Card
        sx={{
          mb: 4,
          borderRadius: 4,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease-in-out",
          overflow: "hidden",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <ContactAgent owner={listing.owner} />
      </Card>

      {/* Investment Analysis Card */}
      <InvestmentAnalysis
        listing={listing}
        investmentMetrics={investmentMetrics}
        onPremiumFeatureClick={onPremiumFeatureClick}
      />

      {/* AirBnB Potential Card */}
      <AirbnbPotential
        investmentMetrics={investmentMetrics}
        onPremiumFeatureClick={onPremiumFeatureClick}
      />

      {/* Similar Listings Section */}
      {listing.similarListings && listing.similarListings.length > 0 && (
        <SimilarListings listings={listing.similarListings} />
      )}
    </>
  );
};

ListingSidebar.propTypes = {
  listing: PropTypes.shape({
    address: PropTypes.string.isRequired,
    owner: PropTypes.shape({
      _id: PropTypes.string,
      username: PropTypes.string.isRequired,
      role: PropTypes.oneOf([
        "homeowner",
        "realEstateAgent",
        "realEstateBroker",
        "realEstateInvestor",
        "loanOfficer",
        "realEstateAttorney",
        "buyer"
      ]).isRequired,
      avatar: PropTypes.string,
      coverPhoto: PropTypes.string,
    }).isRequired,
    similarListings: PropTypes.array,
  }).isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  investmentMetrics: PropTypes.object.isRequired,
  onPremiumFeatureClick: PropTypes.func.isRequired,
};

export default ListingSidebar;
