import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PieChart } from "lucide-react";

const AnalysisContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const MetricBox = styled(Box)({
  padding: "16px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  marginBottom: "16px",
  transition: "transform 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const MetricLabel = styled(Typography)({
  fontSize: "0.9rem",
  color: "#64748B",
  marginBottom: "8px",
  fontFamily: "'Lato', sans-serif",
});

const MetricValue = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: "600",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
  "& svg": {
    width: "48px",
    height: "48px",
    color: "#6366F1",
  },
});

const ROIAnalysis = ({ rentalInputs, financialData }) => {
  const calculateCapRate = () => {
    if (!rentalInputs?.longTermRent || !financialData?.propertyValue) return "N/A";
    const annualIncome = rentalInputs.longTermRent * 12;
    return ((annualIncome / financialData.propertyValue) * 100).toFixed(2);
  };

  const calculateGrossYield = () => {
    if (!rentalInputs?.longTermRent || !financialData?.purchasePrice) return "N/A";
    const annualIncome = rentalInputs.longTermRent * 12;
    return ((annualIncome / financialData.purchasePrice) * 100).toFixed(2);
  };

  return (
    <AnalysisContainer elevation={0}>
      <IconWrapper>
        <PieChart />
      </IconWrapper>
      <Title variant="h5">ROI Analysis</Title>

      <MetricBox>
        <MetricLabel>Cap Rate</MetricLabel>
        <MetricValue>
          {calculateCapRate()}%
        </MetricValue>
      </MetricBox>

      <MetricBox>
        <MetricLabel>Gross Yield</MetricLabel>
        <MetricValue>
          {calculateGrossYield()}%
        </MetricValue>
      </MetricBox>

      <MetricBox>
        <MetricLabel>5-Year Projection</MetricLabel>
        <MetricValue>
          {financialData?.fiveYearROI 
            ? `${financialData.fiveYearROI}%` 
            : "N/A"}
        </MetricValue>
      </MetricBox>

      <MetricBox>
        <MetricLabel>Break-Even Period</MetricLabel>
        <MetricValue>
          {financialData?.breakEvenPeriod 
            ? `${financialData.breakEvenPeriod} years` 
            : "N/A"}
        </MetricValue>
      </MetricBox>
    </AnalysisContainer>
  );
};

ROIAnalysis.propTypes = {
  rentalInputs: PropTypes.shape({
    longTermRent: PropTypes.number,
  }),
  financialData: PropTypes.shape({
    propertyValue: PropTypes.number,
    purchasePrice: PropTypes.number,
    fiveYearROI: PropTypes.number,
    breakEvenPeriod: PropTypes.number,
  }),
};

export default ROIAnalysis;
