import React, { useState, useEffect } from "react";
import axios from "axios";

const WeatherWidget = () => {
  const [weather, setWeather] = useState(null);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWeatherData = async (lat, lon) => {
      const apiKey = import.meta.env.VITE_OPENWEATHER_API_KEY;
      console.log("API Key:", apiKey); // Log the API key (remove in production)
      console.log(`Fetching weather for coordinates: ${lat}, ${lon}`);
      try {
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&appid=${apiKey}`;
        console.log("API URL:", url);
        const response = await axios.get(url);
        console.log("API Response:", response.data);
        setWeather(response.data);
        setLocation(response.data.name);
        setError(null);
      } catch (err) {
        console.error("Error fetching weather data:", err);
        setError("Failed to fetch weather data. Please try again later.");
      }
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Geolocation successful");
          const { latitude, longitude } = position.coords;
          fetchWeatherData(latitude, longitude);
        },
        (error) => {
          console.log("Geolocation error:", error);
          console.log("Using default location (San Francisco)");
          fetchWeatherData(37.7749, -122.4194); // San Francisco coordinates
        }
      );
    } else {
      console.log("Geolocation is not supported. Using default location.");
      fetchWeatherData(37.7749, -122.4194); // San Francisco coordinates
    }
  }, []);

  const getBackgroundColor = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) return "from-yellow-400 to-orange-500"; // Morning
    if (hour >= 12 && hour < 18) return "from-blue-400 to-blue-600"; // Afternoon
    if (hour >= 18 && hour < 21) return "from-orange-400 to-red-600"; // Evening
    return "from-indigo-800 to-blue-900"; // Night
  };

  if (error) {
    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">Error: </strong>
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  if (!weather) {
    return (
      <div
        className={`relative w-full h-[180px] bg-gradient-to-r ${getBackgroundColor()} rounded-xl overflow-hidden shadow-lg text-white flex items-center justify-center`}
      >
        <p className="text-2xl">Loading weather...</p>
      </div>
    );
  }

  const { temp, temp_min, temp_max } = weather.main;
  const { icon, description } = weather.weather[0];
  const date = new Date();

  return (
    <div
      className={`relative w-full h-[180px] bg-gradient-to-r ${getBackgroundColor()} rounded-xl overflow-hidden shadow-lg text-white`}
    >
      <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-10 rounded-full -mr-32 -mt-32"></div>
      <div className="relative z-10 p-6 h-full flex flex-col justify-between">
        <div className="flex justify-between items-start">
          <div>
            <p className="text-2xl font-light flex items-center">
              <img
                src={`http://openweathermap.org/img/wn/${icon}.png`}
                alt={description}
                className="w-10 h-10 mr-2"
              />
              {description}
            </p>
            <p className="text-6xl font-bold mt-2">{Math.round(temp)}°C</p>
          </div>
          <div className="text-right">
            <p className="text-xl font-light">
              {date.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
            <p className="text-sm mt-1">
              {date.toLocaleDateString([], {
                weekday: "short",
                month: "short",
                day: "numeric",
              })}
            </p>
          </div>
        </div>
        <div className="flex justify-between items-end">
          <p className="text-sm font-light">
            {Math.round(temp_min)}°C / {Math.round(temp_max)}°C
          </p>
          <p className="text-sm font-light flex items-center">
            <span className="text-lg mr-1">📍</span> {location}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WeatherWidget;
