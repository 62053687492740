import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Tabs } from "flowbite-react";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

import EditableSection from "./EditableSection";
import { Link } from "react-router-dom";

import { Notyf } from "notyf";
import ProfileCard from "./ProfileCard";
import useProfileForm from "./useProfileForm";
import "notyf/notyf.min.css";
const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const STATUS_COLORS = {
  active: "success",
  pending: "warning",
  sold: "light",
  draft: "dark",
};

const ListingsSection = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState("all");
  const [sortBy, setSortBy] = useState("date");
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get listings from user data or fetch them
  useEffect(() => {
    if (currentUser?.listings) {
      setListings(currentUser.listings);
      setLoading(false);
    }
  }, [currentUser]);

  // Filter listings based on active tab
  const filteredListings = listings.filter((listing) => {
    if (activeTab === "all") return true;
    return listing.status === activeTab;
  });

  // Sort listings based on selected criteria
  const sortedListings = [...filteredListings].sort((a, b) => {
    switch (sortBy) {
      case "price-high":
        return b.price - a.price;
      case "price-low":
        return a.price - b.price;
      case "views":
        return b.views - a.views;
      case "date":
      default:
        return new Date(b.createdAt) - new Date(a.createdAt);
    }
  });

  const handleDelete = async (listingId) => {
    if (window.confirm("Are you sure you want to delete this listing?")) {
      try {
        // Implement delete logic
        notyf.success("Listing deleted successfully");
      } catch (error) {
        notyf.error("Failed to delete listing");
      }
    }
  };

  // Stats summary component
  const StatsSummary = () => (
    <div className="grid grid-cols-4 gap-4 mb-6">
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <h4 className="text-sm text-gray-500">Total Listings</h4>
        <p className="text-2xl font-semibold">{listings.length}</p>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <h4 className="text-sm text-gray-500">Active Listings</h4>
        <p className="text-2xl font-semibold text-green-600">
          {listings.filter((l) => l.status === "active").length}
        </p>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <h4 className="text-sm text-gray-500">Total Views</h4>
        <p className="text-2xl font-semibold">
          {listings.reduce((sum, l) => sum + (l.views || 0), 0)}
        </p>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <h4 className="text-sm text-gray-500">Total Value</h4>
        <p className="text-2xl font-semibold">
          $
          {listings
            .reduce((sum, l) => sum + (l.price || 0), 0)
            .toLocaleString()}
        </p>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">My Listings</h2>
        <Link to="/create-listing">
          <Button size="sm" className="flex items-center">
            <PlusIcon className="h-4 w-4 mr-1" />
            New Listing
          </Button>
        </Link>
      </div>

      <StatsSummary />

      <ProfileCard>
        <div className="flex justify-between items-center mb-4">
          <Tabs value={activeTab} onChange={setActiveTab}>
            <Tabs.Item title="All" value="all" />
            <Tabs.Item title="Active" value="active" />
            <Tabs.Item title="Pending" value="pending" />
            <Tabs.Item title="Sold" value="sold" />
            <Tabs.Item title="Draft" value="draft" />
          </Tabs>

          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="border rounded-md px-3 py-1.5 text-sm"
          >
            <option value="date">Most Recent</option>
            <option value="price-high">Price: High to Low</option>
            <option value="price-low">Price: Low to High</option>
            <option value="views">Most Viewed</option>
          </select>
        </div>

        {loading ? (
          <div className="text-center py-8">Loading...</div>
        ) : sortedListings.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            No listings found in this category.
          </div>
        ) : (
          <div className="space-y-4">
            {sortedListings.map((listing) => (
              <div
                key={listing._id}
                className="flex items-center justify-between bg-white p-4 rounded-lg border"
              >
                <div className="flex items-center space-x-4">
                  <img
                    src={listing.imageUrls[0]}
                    alt={listing.title}
                    className="w-16 h-16 object-cover rounded"
                  />
                  <div>
                    <h3 className="font-medium">{listing.title}</h3>
                    <p className="text-sm text-gray-500">
                      ${listing.price.toLocaleString()} ·{" "}
                      <Badge color={STATUS_COLORS[listing.status]}>
                        {listing.status}
                      </Badge>
                    </p>
                    <p className="text-sm text-gray-500">
                      {listing.views} views · Listed{" "}
                      {new Date(listing.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <Link to={`/edit-listing/${listing._id}`}>
                    <Button size="sm" color="light">
                      <PencilIcon className="h-4 w-4" />
                    </Button>
                  </Link>
                  <Button
                    size="sm"
                    color="failure"
                    onClick={() => handleDelete(listing._id)}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ProfileCard>
    </div>
  );
};

export default ListingsSection;
