const initialState = {
  selectedProperty: null,
  isLoading: false,
  error: null,
  dashboardListings: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PROPERTY_DATA_START":
      return { ...state, isLoading: true, error: null };
    case "FETCH_PROPERTY_DATA_SUCCESS":
      return { ...state, isLoading: false, selectedProperty: action.payload };
    case "FETCH_PROPERTY_DATA_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    // ... other cases
    default:
      return state;
  }
};

// const dashboardReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case "SET_SELECTED_PROPERTY":
//       return {
//         ...state,
//         selectedProperty: action.payload,
//       };
//     case "CLEAR_SELECTED_PROPERTY":
//       return {
//         ...state,
//         selectedProperty: null,
//       };
//     case "ADD_TO_DASHBOARD":
//       return {
//         ...state,
//         dashboardListings: [...state.dashboardListings, action.payload],
//       };
//     case "REMOVE_FROM_DASHBOARD":
//       return {
//         ...state,
//         dashboardListings: state.dashboardListings.filter(
//           (listing) => listing._id !== action.payload
//         ),
//       };
//     default:
//       return state;
//   }
// };

export default dashboardReducer;
