import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const PlacesDisplay = ({ amenities, selectedPlace, onPlaceSelect, location }) => {
  const [activeCategory, setActiveCategory] = useState('ESSENTIAL');
  const [showMap, setShowMap] = useState(true);
  const [mapCenter, setMapCenter] = useState(location);

  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ]
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 font-lato">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Nearby Places</h2>
        <button
          onClick={() => setShowMap(!showMap)}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          {showMap ? 'Hide Map' : 'Show Map'}
        </button>
      </div>

      {/* Category Tabs */}
      <div className="flex space-x-4 mb-6 overflow-x-auto pb-2">
        {Object.keys(amenities || {}).map((category) => (
          <motion.button
            key={category}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setActiveCategory(category)}
            className={`px-4 py-2 rounded-lg whitespace-nowrap ${
              activeCategory === category
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {category.toLowerCase().replace('_', ' ')}
          </motion.button>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Map Section */}
        <AnimatePresence>
          {showMap && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="rounded-lg overflow-hidden"
            >
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={mapCenter}
                zoom={15}
                options={mapOptions}
              >
                {/* Property Location Marker */}
                <Marker
                  position={location}
                  icon={{
                    url: '/marker-home.png',
                    scaledSize: { width: 40, height: 40 }
                  }}
                />

                {/* Amenity Markers */}
                {amenities?.[activeCategory]?.items.map((place) => (
                  <Marker
                    key={place.id}
                    position={place.location}
                    onClick={() => onPlaceSelect(place)}
                  >
                    {selectedPlace?.id === place.id && (
                      <InfoWindow onCloseClick={() => onPlaceSelect(null)}>
                        <div className="p-2">
                          <h3 className="font-semibold">{place.name}</h3>
                          <p className="text-sm text-gray-600">{place.address}</p>
                          {place.rating && (
                            <div className="flex items-center mt-1">
                              <span className="text-yellow-500">★</span>
                              <span className="ml-1">{place.rating}</span>
                            </div>
                          )}
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
              </GoogleMap>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Places List */}
        <div className="space-y-4">
          <AnimatePresence mode="wait">
            {amenities?.[activeCategory]?.items.map((place) => (
              <motion.div
                key={place.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                className={`p-4 rounded-lg cursor-pointer transition-colors ${
                  selectedPlace?.id === place.id
                    ? 'bg-blue-50 border-2 border-blue-500'
                    : 'bg-gray-50 hover:bg-gray-100'
                }`}
                onClick={() => {
                  onPlaceSelect(place);
                  setMapCenter(place.location);
                }}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-semibold text-lg">{place.name}</h3>
                    <p className="text-sm text-gray-600">{place.address}</p>
                  </div>
                  {place.rating && (
                    <div className="flex items-center bg-white px-2 py-1 rounded-lg">
                      <span className="text-yellow-500">★</span>
                      <span className="ml-1 font-medium">{place.rating}</span>
                    </div>
                  )}
                </div>
                {place.openNow !== undefined && (
                  <p className={`text-sm mt-2 ${
                    place.openNow ? 'text-green-600' : 'text-red-600'
                  }`}>
                    {place.openNow ? 'Open Now' : 'Closed'}
                  </p>
                )}
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

PlacesDisplay.propTypes = {
  amenities: PropTypes.object,
  selectedPlace: PropTypes.object,
  onPlaceSelect: PropTypes.func.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired
};

export default PlacesDisplay;
