import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { Assessment } from "@mui/icons-material";

const QuickActions = ({ onViewInvestmentAnalysis }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: 2,
        mb: 4,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={onViewInvestmentAnalysis}
        startIcon={<Assessment />}
        sx={{
          borderRadius: 2,
          py: 1.5,
          px: 4,
          fontFamily: "'Lato', sans-serif",
          textTransform: "none",
          fontSize: "1.1rem",
          fontWeight: 600,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        View Investment Analysis
      </Button>
    </Box>
  );
};

QuickActions.propTypes = {
  onViewInvestmentAnalysis: PropTypes.func.isRequired,
};

export default QuickActions;
