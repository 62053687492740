import React from "react";
import PropTypes from "prop-types";
import { FaBed, FaBath, FaRuler, FaHeart, FaRegHeart } from "react-icons/fa";

const PropertyList = ({
  properties,
  getStatusColor,
  handleLike,
  keyPrefix,
  likedListings,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {properties.map((property) => (
        <div
          key={`${keyPrefix}-${property._id || property.id}`}
          className="bg-white rounded-lg shadow-md overflow-hidden relative"
        >
          <img
            src={property.image}
            alt={property.title}
            className="w-full h-48 object-cover"
          />
          <button
            onClick={() => handleLike(property._id)}
            className={`like-button ${
              likedListings.includes(property._id)
                ? "text-red-500"
                : "text-gray-500"
            }`}
          >
            {likedListings.includes(property._id) ? (
              <FaHeart />
            ) : (
              <FaRegHeart />
            )}
          </button>
          <div className="p-4">
            <h3 className="font-semibold text-lg mb-2">{property.title}</h3>
            <p className="text-gray-600 mb-2">{property.address}</p>
            <p className="text-xl font-bold mb-2">
              ${property.price.toLocaleString()}
            </p>
            <div className="flex justify-between text-gray-600 mb-2">
              <span>
                <FaBed className="inline mr-1" /> {property.beds}
              </span>
              <span>
                <FaBath className="inline mr-1" /> {property.baths}
              </span>
              <span>
                <FaRuler className="inline mr-1" /> {property.size} ft²
              </span>
            </div>
            <div
              className="text-center py-1 rounded-full text-sm font-semibold"
              style={{ backgroundColor: getStatusColor(property.status) }}
            >
              {property.status}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

PropertyList.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      beds: PropTypes.number.isRequired,
      baths: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    })
  ).isRequired,
  getStatusColor: PropTypes.func.isRequired,
  handleLike: PropTypes.func.isRequired,
  showLiked: PropTypes.bool.isRequired,
  keyPrefix: PropTypes.string,
  likedListings: PropTypes.arrayOf(PropTypes.string).isRequired,
};

PropertyList.defaultProps = {
  keyPrefix: "property",
};

export default PropertyList;
