/* global Intl */

/**
 * Format a number as currency
 * @param {number} value - The number to format
 * @param {string} [locale='en-US'] - The locale to use for formatting
 * @param {string} [currency='USD'] - The currency code
 * @returns {string} The formatted currency string
 */
export const formatCurrency = (value, locale = "en-US", currency = "USD") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

/**
 * Format a number as a percentage
 * @param {number} value - The number to format (e.g., 0.156 for 15.6%)
 * @param {number} [decimals=1] - Number of decimal places
 * @returns {string} The formatted percentage string
 */
export const formatPercentage = (value, decimals = 1) => {
  return `${(value * 100).toFixed(decimals)}%`;
};

/**
 * Format a number with commas
 * @param {number} value - The number to format
 * @returns {string} The formatted number string
 */
export const formatNumber = (value) => {
  return new Intl.NumberFormat().format(value);
};

/**
 * Format a date string
 * @param {string|Date} date - The date to format
 * @param {Object} options - Intl.DateTimeFormat options
 * @returns {string} The formatted date string
 */
export const formatDate = (
  date,
  options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }
) => {
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};

/**
 * Format a number as square feet
 * @param {number} value - The number to format
 * @returns {string} The formatted square feet string
 */
export const formatSquareFeet = (value) => {
  return `${formatNumber(value)} sq ft`;
};

/**
 * Format a number as acres
 * @param {number} value - The number to format
 * @returns {string} The formatted acres string
 */
export const formatAcres = (value) => {
  return `${value.toFixed(2)} acres`;
};

/**
 * Format a phone number
 * @param {string} phoneNumber - The phone number to format
 * @returns {string} The formatted phone number
 */
export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumber;
};

/**
 * Format an address
 * @param {Object} address - The address object
 * @returns {string} The formatted address string
 */
export const formatAddress = ({ street, city, state, zipCode, unit = "" }) => {
  const unitStr = unit ? ` Unit ${unit}` : "";
  return `${street}${unitStr}, ${city}, ${state} ${zipCode}`;
};
