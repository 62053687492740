import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressSection from "./componentsAP/AddressSection";
import FAQSection from "./componentsAP/FAQSection";
import ListingsSection from "./componentsAP/ListingsSection";
import { Notyf } from "notyf";
import PersonalInfoSection from "./componentsAP/PersonalInfoSection";
import ProfilePhotoSection from "./componentsAP/ProfilePhotoSection";
import ProfileSidebar from "./componentsAP/ProfileSidebar";
import SocialLinksSection from "./componentsAP/SocialLinksSection";
import StatsSection from "./componentsAP/StatsSection";
import TestimonialsSection from "./componentsAP/TestimonialsSection";
import { fetchUserData } from "../../redux/user/userSlice";
import "notyf/notyf.min.css";

const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const AccountProfile = () => {
  const dispatch = useDispatch();
  const { currentUser, error } = useSelector((state) => state.user);
  const [activeSection, setActiveSection] = useState("personal");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [profileProgress, setProfileProgress] = useState(0);

  // Fetch user data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchUserData()).unwrap();
        setIsInitialLoad(false);
      } catch (err) {
        notyf.error(err?.message || "Failed to load profile data");
      }
    };

    if (isInitialLoad) {
      fetchData();
    }
  }, [dispatch, isInitialLoad]);

  // Calculate profile completion
  useEffect(() => {
    if (currentUser) {
      const requiredFields = {
        photos: ["avatar"],
        personal: ["username", "email", "firstName", "lastName"],
        address: ["streetAddress", "city", "country", "postalCode"],
        social: ["socialLinks"],
        stats: ["stats"],
        listings: ["listings"],
        faqs: ["faqs"],
        testimonials: ["testimonials"],
      };

      let completedSections = 0;
      let totalSections = Object.keys(requiredFields).length;

      Object.entries(requiredFields).forEach(([_, fields]) => {
        const hasAllRequired = fields.every((field) => {
          const value = field
            .split(".")
            .reduce((obj, key) => obj?.[key], currentUser);
          return (
            value &&
            (typeof value === "object" ? Object.keys(value).length > 0 : true)
          );
        });
        if (hasAllRequired) completedSections++;
      });

      setProfileProgress((completedSections / totalSections) * 100);
    }
  }, [currentUser]);

  if (error) {
    const errorMessage = typeof error === 'object' ? error.message || JSON.stringify(error) : error.toString();
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="container mx-auto px-4">
          <div className="bg-white rounded-lg shadow-sm p-6 text-center">
            <h2 className="text-xl font-semibold text-red-600 mb-2">
              Error Loading Profile
            </h2>
            <p className="text-gray-600">{errorMessage}</p>
            <button
              onClick={() => setIsInitialLoad(true)}
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Retry
            </button>
          </div>
        </div>
      </div>
    );
  }

  const sections = {
    personal: PersonalInfoSection,
    address: AddressSection,
    social: SocialLinksSection,
    stats: StatsSection,
    listings: ListingsSection,
    faqs: FAQSection,
    testimonials: TestimonialsSection,
  };

  const ActiveSection = sections[activeSection];

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Sidebar */}
          <div className="lg:w-1/4">
            <ProfileSidebar
              activePage="profile"
              activeSection={activeSection}
              onSectionChange={setActiveSection}
              profileProgress={profileProgress}
            />
          </div>

          {/* Main Content */}
          <div className="lg:w-3/4 space-y-6">
            {/* Progress Banner */}
            {profileProgress < 100 && (
              <div className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded-lg">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-blue-800 font-medium">
                      Complete Your Profile
                    </h3>
                    <p className="text-blue-600 text-sm mt-1">
                      Your profile is {Math.round(profileProgress)}% complete.
                      Complete your profile to unlock all features.
                    </p>
                  </div>
                  <div className="w-32">
                    <div className="h-2 bg-blue-200 rounded-full">
                      <div
                        className="h-2 bg-blue-600 rounded-full transition-all duration-500"
                        style={{ width: `${profileProgress}%` }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Photo Section (Always visible at top) */}
            <ProfilePhotoSection />

            {/* Active Section */}
            {ActiveSection && (
              <div className="transition-all duration-300 ease-in-out">
                <ActiveSection />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountProfile;
