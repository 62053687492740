import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Typography,
  Switch,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  Check,
  Star,
  Rocket,
  Diamond,
  Assessment,
  People,
  TrendingUp,
  HeadsetMic,
  ExpandMore,
} from "@mui/icons-material";
import { motion } from "framer-motion";

const PricingPage = () => {
  const [annually, setAnnually] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const plans = [
    {
      name: "Basic",
      icon: <Star sx={{ fontSize: 40, color: "#60A5FA" }} />,
      price: annually ? "0" : "0",
      subtitle: "For Direct leads & Service Providers",
      description: "Essential tools to get started",
      highlight: false,
      features: [
        "Unlimited listing access",
        "Basic property analysis",
        "Document storage",
        "Status tracking",
        "Direct messaging",
        "Educational resources",
      ],
      buttonText: "Get Started",
      buttonVariant: "outlined",
    },
    {
      name: "Silver",
      icon: <Rocket sx={{ fontSize: 40, color: "#60A5FA" }} />,
      price: annually ? "499" : "599",
      subtitle: "For Growing Service Providers",
      description: "Advanced tools for serious professionals",
      highlight: false,
      features: [
        "10 Direct lead messaging/month",
        "Advanced property analysis",
        "Market trend reports",
        "AI-powered lead scoring",
        "Priority listing alerts",
        "Document templates library",
      ],
      buttonText: "Get Started",
      buttonVariant: "contained",
    },
    {
      name: "Gold",
      icon: <Diamond sx={{ fontSize: 40, color: "#60A5FA" }} />,
      price: annually ? "899" : "999",
      subtitle: "For Professional Teams",
      description: "Premium features for maximum impact",
      highlight: true,
      features: [
        "20 Direct lead messaging/month",
        "Investment opportunity scoring",
        "Advanced market analytics",
        "Multi-user access",
        "Priority support",
        "Document templates library",
      ],
      buttonText: "Get Started",
      buttonVariant: "contained",
    },
    {
      name: "Platinum",
      icon: <Assessment sx={{ fontSize: 40, color: "#60A5FA" }} />,
      price: annually ? "2499" : "2999",
      subtitle: "Enterprise Solution",
      description: "Custom solutions for large teams",
      highlight: false,
      features: [
        "50 Direct lead messaging/month",
        "Investment opportunity scoring",
        "Advanced market analytics",
        "Multi-user access",
        "Advanced property analysis",
        "Document templates library",
        "Market trend reports",
      ],
      buttonText: "Get Started",
      buttonVariant: "contained",
    },
  ];

  const faqs = [
    {
      question: "What types of properties can I find on HomeLeafs?",
      answer:
        "HomeLeafs specializes in connecting service providers with homeowners facing various situations, including foreclosure prevention, financial hardship, divorce property solutions, and more. Our platform covers residential properties across all price ranges and conditions.",
    },
    {
      question: "How does the messaging system work?",
      answer:
        "Each plan includes a specific number of direct lead messages per month. These are private, secure communications with homeowners who are actively seeking assistance. Messages don't roll over to the next month, and you'll receive notifications when new opportunities match your criteria.",
    },
    {
      question: "What's included in the property analysis tools?",
      answer:
        "Our property analysis tools include market value assessments, investment potential calculations, comparable property analysis, and neighborhood insights. Advanced tiers get access to more detailed analytics and investment scoring features.",
    },
    {
      question: "Can I upgrade or downgrade my plan?",
      answer:
        "Yes, you can change your plan at any time. Changes take effect at the start of your next billing cycle. When upgrading, you'll get immediate access to new features, with pricing prorated for the remainder of your current billing period.",
    },
    {
      question: "How do you verify homeowners and service providers?",
      answer:
        "We use a comprehensive verification process that includes identity verification, professional licensing checks, and background screening for service providers. This ensures a safe and trustworthy environment for all platform users.",
    },
  ];

  // Updated with more reliable logo sources
  const industryLogos = [
    {
      name: "Century 21",
      url: "https://www.century21.com/about-us/sites/default/files/logos/C21_Wordmark_RelentlessGold_4C.png",
    },
    {
      name: "RE/MAX",
      url: "../images/logos/remax.png",
    },
    {
      name: "Keller Williams",
      url: "./images/logos/kw.png",
    },
    {
      name: "Coldwell Banker",
      url: "./images/logos/coldwell.png",
    },
    {
      name: "Sotheby's",
      url: "./images/logos/sothebys.png",
    },
    {
      name: "Compass",
      url: "./images/logos/compass.png",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      {/* Hero Section */}
      <Box sx={{ textAlign: "center", mb: 8 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              fontSize: { xs: "2.5rem", md: "3.5rem" },
              fontFamily: "Lato, sans-serif",
              background: "linear-gradient(45deg, #60A5FA 30%, #93C5FD 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Connect with Ready-to-Act Homeowners
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mb: 4,
              fontFamily: "Lato, sans-serif",
              color: "#6B7280",
            }}
          >
            Choose your plan to start making a difference today
          </Typography>
        </motion.div>

        {/* Annual/Monthly Toggle */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 6 }}>
          <Typography sx={{ mr: 2, color: "#6B7280" }}>Monthly</Typography>
          <Switch
            checked={annually}
            onChange={() => setAnnually(!annually)}
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#60A5FA",
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "#93C5FD",
              },
            }}
          />
          <Typography sx={{ ml: 2, color: "#6B7280" }}>
            Annually{" "}
            <Chip
              label="Save 20%"
              size="small"
              sx={{
                ml: 1,
                backgroundColor: "#93C5FD",
                color: "#FFFFFF",
              }}
            />
          </Typography>
        </Box>

        {/* Trust Indicators */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 4, mb: 6 }}>
          <Tooltip title="Growing community of successful providers">
            <Box sx={{ textAlign: "center" }}>
              <People sx={{ fontSize: 40, color: "#60A5FA", mb: 1 }} />
              <Typography
                variant="h4"
                sx={{ color: "#60A5FA", fontWeight: "bold" }}
              >
                500+
              </Typography>
              <Typography variant="body2" sx={{ color: "#6B7280" }}>
                Active Service Providers
              </Typography>
            </Box>
          </Tooltip>

          <Tooltip title="Industry-leading performance">
            <Box sx={{ textAlign: "center" }}>
              <TrendingUp sx={{ fontSize: 40, color: "#60A5FA", mb: 1 }} />
              <Typography
                variant="h4"
                sx={{ color: "#60A5FA", fontWeight: "bold" }}
              >
                98%
              </Typography>
              <Typography variant="body2" sx={{ color: "#6B7280" }}>
                Success Rate
              </Typography>
            </Box>
          </Tooltip>

          <Tooltip title="AI-powered insights">
            <Box sx={{ textAlign: "center" }}>
              <HeadsetMic sx={{ fontSize: 40, color: "#60A5FA", mb: 1 }} />
              <Typography
                variant="h4"
                sx={{ color: "#60A5FA", fontWeight: "bold" }}
              >
                24/7
              </Typography>
              <Typography variant="body2" sx={{ color: "#6B7280" }}>
                Support
              </Typography>
            </Box>
          </Tooltip>
        </Box>

        {/* Pricing Cards */}
        <Grid container spacing={4} justifyContent="center">
          {plans.map((plan) => (
            <Grid item xs={12} sm={6} md={3} key={plan.name}>
              <motion.div
                whileHover={{ scale: plan.highlight ? 1.05 : 1.02 }}
                transition={{ duration: 0.2 }}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    borderRadius: 4,
                    boxShadow: plan.highlight
                      ? "0 8px 24px rgba(96, 165, 250, 0.15)"
                      : "0 4px 12px rgba(0,0,0,0.1)",
                    border: plan.highlight ? "2px solid #60A5FA" : "none",
                    backgroundColor: plan.highlight ? "#F3F4F6" : "#FFFFFF",
                  }}
                >
                  {plan.highlight && (
                    <Chip
                      label="Most Popular"
                      sx={{
                        position: "absolute",
                        top: -12,
                        right: 24,
                        fontWeight: "bold",
                        backgroundColor: "#60A5FA",
                        color: "#FFFFFF",
                      }}
                    />
                  )}

                  <CardContent sx={{ flexGrow: 1, p: 4, textAlign: "center" }}>
                    {plan.icon}
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 700,
                        my: 2,
                        color: plan.highlight ? "#60A5FA" : "#374151",
                      }}
                    >
                      {plan.name}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: 700,
                        mb: 2,
                        color: plan.highlight ? "#60A5FA" : "#374151",
                      }}
                    >
                      ${plan.price}/{annually ? "year" : "month"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 3, color: "#6B7280" }}
                    >
                      {plan.subtitle}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 3, color: "#6B7280" }}
                    >
                      {plan.description}
                    </Typography>

                    <List dense={true}>
                      {plan.features.map((feature) => (
                        <ListItem key={feature} disablePadding>
                          <ListItemIcon>
                            <Check sx={{ color: "#60A5FA" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={feature}
                            sx={{
                              "& .MuiListItemText-primary": {
                                color: "#374151",
                              },
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>

                    <Button
                      variant={plan.buttonVariant}
                      sx={{
                        mt: 3,
                        textTransform: "none",
                        width: "100%",
                        backgroundColor:
                          plan.buttonVariant === "contained"
                            ? "#60A5FA"
                            : "transparent",
                        color:
                          plan.buttonVariant === "contained"
                            ? "#FFFFFF"
                            : "#60A5FA",
                        borderColor: "#60A5FA",
                        "&:hover": {
                          backgroundColor:
                            plan.buttonVariant === "contained"
                              ? "#3B82F6"
                              : "rgba(96, 165, 250, 0.1)",
                          borderColor: "#3B82F6",
                        },
                      }}
                    >
                      {plan.buttonText}
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>

        {/* Industry Logos */}
        <Box sx={{ mt: 12, mb: 8 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 6,
              textAlign: "center",
              color: "#374151",
            }}
          >
            Trusted by Industry Leaders
          </Typography>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            {industryLogos.map((logo, index) => (
              <Grid item xs={6} sm={4} md={2} key={index}>
                <Box
                  component="img"
                  src={logo.url}
                  alt={logo.name}
                  sx={{
                    width: "100%",
                    maxWidth: 150,
                    height: "auto",
                    filter: "grayscale(100%)",
                    opacity: 0.7,
                    transition: "all 0.3s ease",
                    "&:hover": {
                      filter: "grayscale(0%)",
                      opacity: 1,
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* FAQ Section */}
        <Box sx={{ mt: 12 }}>
          <Typography
            variant="h4"
            sx={{
              mb: 6,
              fontFamily: "Lato, sans-serif",
              color: "#374151",
            }}
          >
            Frequently Asked Questions
          </Typography>
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{
                mb: 2,
                borderRadius: 2,
                "&:before": { display: "none" },
                boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                backgroundColor: "#F9FAFB",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ color: "#60A5FA" }} />}
                sx={{
                  backgroundColor: "#F9FAFB",
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "#F3F4F6",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    fontWeight: 600,
                    color: "#374151",
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: "#6B7280",
                  }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        {/* CTA Section */}
        <Box
          sx={{
            mt: 12,
            p: 8,
            borderRadius: 4,
            backgroundColor: "#F3F4F6",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 2,
              fontFamily: "Lato, sans-serif",
              color: "#374151",
            }}
          >
            Ready to Get Started?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 4,
              fontFamily: "Lato, sans-serif",
              color: "#6B7280",
            }}
          >
            Join the community of successful service providers making a
            difference.
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{
              py: 2,
              px: 4,
              borderRadius: 2,
              textTransform: "none",
              fontFamily: "Lato, sans-serif",
              fontWeight: 600,
              backgroundColor: "#60A5FA",
              "&:hover": {
                backgroundColor: "#3B82F6",
              },
            }}
          >
            Get Started Now
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default PricingPage;
