import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  signInStart,
  signInSuccess,
  signInFailure,
  clearToken,
} from "../redux/user/userSlice";
import BgImage from "../assets/signin.svg";

export default function SignIn() {
  const [formData, setFormData] = useState({
    emailOrUsername: "",
    password: "",
  });
  const [formError, setFormError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { loading, currentUser, error } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearToken());
    localStorage.removeItem("persist:root");
    dispatch(signInFailure(null));
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFormError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(signInStart());

      const apiUrl =
        import.meta.process.env.NODE_ENV === "production"
          ? "https://homeleafs.onrender.com/api/auth/signin"
          : "/api/auth/signin";

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          emailOrUsername: formData.emailOrUsername,
          password: formData.password,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to sign in");
      }

      if (rememberMe && data.token) {
        localStorage.setItem("authToken", data.token);
      }

      dispatch(signInSuccess(data));
      navigate("/dashboard");
    } catch (error) {
      console.error("Sign in error:", error);
      const errorMessage = error.message || "An error occurred during sign in";
      dispatch(signInFailure(errorMessage));
      setFormError(errorMessage);
    }
  };
  return (
    <div
      className="flex items-center justify-center min-h-screen bg-gray-100 -mt-24 bg-cover bg-center"
      style={{ backgroundImage: `url(${BgImage})` }}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-soft border-0 rounded-lg p-4 sm:p-6 lg:p-8 w-full max-w-md mx-auto">
          <div className="text-center mb-6">
            <h3 className="mb-2 text-xl sm:text-2xl font-bold">
              Sign in to our platform
            </h3>
            <p className="text-sm sm:text-base text-gray-500">
              Use your credentials to access your account.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="h-5 w-5 text-gray-500"
                  />
                </div>
                <input
                  type="text"
                  id="emailOrUsername"
                  className="bg-gray-100 focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-3 block w-full pl-10 text-sm sm:text-base"
                  placeholder="Enter email or username"
                  value={formData.emailOrUsername}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon
                    icon={faLock}
                    className="h-5 w-5 text-gray-500"
                  />
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="bg-gray-100 focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-3 block w-full pl-10 text-sm sm:text-base"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 focus:outline-none"
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faUser : faLock}
                    className="h-5 w-5"
                  />
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <input
                  id="rememberMe"
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600 rounded"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label
                  htmlFor="rememberMe"
                  className="ml-2 block text-gray-700 text-sm sm:text-base"
                >
                  Remember me
                </label>
              </div>
              <Link
                to="/forgot-password"
                className="text-blue-500 hover:text-blue-700 text-sm sm:text-base"
              >
                Lost password?
              </Link>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline text-sm sm:text-base"
            >
              {loading ? "Loading..." : "Sign in"}
            </button>
          </form>
          <div className="mt-4 text-center">
            <p className="text-gray-500 text-sm sm:text-base">or login with</p>
            <button
              type="button"
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded w-full mt-2 sm:mt-4 focus:outline-none focus:shadow-outline text-sm sm:text-base"
              onClick={handleAuth0SignIn}
            >
              Sign in with Auth0
            </button>
          </div>
          {formError && (
            <p className="text-red-500 mt-4 text-sm sm:text-base">
              {typeof formError === "string" ? formError : "An error occurred"}
            </p>
          )}
          {error && (
            <p className="text-red-500 mt-4 text-sm sm:text-base">
              {typeof error === "string" ? error : "An error occurred"}
            </p>
          )}
          <div className="mt-4 text-center">
            <p className="text-gray-500 text-sm sm:text-base">
              Not registered?{" "}
              <Link to="/sign-up" className="text-blue-500 hover:text-blue-700">
                Create account
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
