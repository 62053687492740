import React from "react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "flowbite-react";
import EditableSection from "./EditableSection";
import FormField from "./FormField";

import useEditMode from "./useEditMode";
import useProfileForm from "./useProfileForm";

import { Notyf } from "notyf";
import "notyf/notyf.min.css";
const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const validationSchema = {
  question: (value) => (!value.trim() ? "Question is required" : ""),
  answer: (value) => (!value.trim() ? "Answer is required" : ""),
};

const FAQSection = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);

  const {
    formData,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = useProfileForm(
    { faqs: currentUser?.faqs || [] },
    validationSchema,
    "faqs"
  );

  const { isEditing, handleEdit, handleCancel, handleSave } = useEditMode({
    onSave: handleSubmit,
    onCancel: handleReset,
  });

  const addFAQ = () => {
    setFieldValue("faqs", [...formData.faqs, { question: "", answer: "" }]);
  };

  const removeFAQ = (index) => {
    const newFAQs = formData.faqs.filter((_, i) => i !== index);
    setFieldValue("faqs", newFAQs);
  };

  const handleFAQChange = (index, field, value) => {
    const newFAQs = [...formData.faqs];
    newFAQs[index] = {
      ...newFAQs[index],
      [field]: value,
    };
    setFieldValue("faqs", newFAQs);
  };

  return (
    <EditableSection
      title="Frequently Asked Questions"
      onSave={handleSave}
      onEdit={handleEdit}
      onCancel={handleCancel}
      isEditing={isEditing}
    >
      <div className="space-y-6">
        {formData.faqs.length === 0 && !isEditing ? (
          <div className="text-center py-8 text-gray-500">
            No FAQs added yet. Click edit to add some.
          </div>
        ) : (
          <div className="space-y-6">
            {formData.faqs.map((faq, index) => (
              <div key={index} className="relative bg-gray-50 p-4 rounded-lg">
                {isEditing && (
                  <Button
                    color="failure"
                    size="sm"
                    className="absolute top-2 right-2"
                    onClick={() => removeFAQ(index)}
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </Button>
                )}

                <div className="space-y-4">
                  <FormField
                    label={`Question ${index + 1}`}
                    id={`question-${index}`}
                    value={faq.question}
                    onChange={(e) =>
                      handleFAQChange(index, "question", e.target.value)
                    }
                    error={errors[`faqs.${index}.question`]}
                    disabled={!isEditing}
                    required
                    placeholder="Enter your question"
                  />

                  <FormField
                    label={`Answer ${index + 1}`}
                    id={`answer-${index}`}
                    value={faq.answer}
                    onChange={(e) =>
                      handleFAQChange(index, "answer", e.target.value)
                    }
                    error={errors[`faqs.${index}.answer`]}
                    disabled={!isEditing}
                    required
                    multiline
                    rows={3}
                    placeholder="Enter your answer"
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {isEditing && (
          <Button
            onClick={addFAQ}
            className="w-full flex items-center justify-center"
          >
            <PlusIcon className="h-4 w-4 mr-1" />
            Add FAQ
          </Button>
        )}
      </div>
    </EditableSection>
  );
};

export default FAQSection;
