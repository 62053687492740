import axios from 'axios';
import { API_KEYS, API_ENDPOINTS } from '../config/apiConfig';

const attomAxios = axios.create({
  baseURL: API_ENDPOINTS.ATTOM.BASE_URL,
  headers: {
    'apikey': API_KEYS.ATTOM_API_KEY,
    'Accept': 'application/json'
  }
});

export const attomService = {
  /**
   * Get detailed property information
   * @param {string} address - Property address
   * @param {string} zipCode - Property ZIP code
   */
  async getPropertyDetails(address, zipCode) {
    try {
      const response = await attomAxios.get(API_ENDPOINTS.ATTOM.PROPERTY_DETAILS, {
        params: {
          address: address,
          postalcode: zipCode
        }
      });

      return transformPropertyData(response.data);
    } catch (error) {
      console.error('ATTOM API Error:', error);
      // Fallback to dummy data during development
      return import('./dummyDataService').then(module => 
        module.dummyDataService.getPropertyDetails('DUMMY_ID')
      );
    }
  },

  /**
   * Get property sales history
   * @param {string} propertyId - ATTOM property ID
   */
  async getPropertySalesHistory(propertyId) {
    try {
      const response = await attomAxios.get(API_ENDPOINTS.ATTOM.PROPERTY_SALES, {
        params: {
          propertyId: propertyId
        }
      });

      return transformSalesHistory(response.data);
    } catch (error) {
      console.error('ATTOM API Error:', error);
      return generateDummySalesHistory();
    }
  },

  /**
   * Get market trends for a specific area
   * @param {string} zipCode - Area ZIP code
   */
  async getMarketTrends(zipCode) {
    try {
      const response = await attomAxios.get(API_ENDPOINTS.ATTOM.MARKET_TRENDS, {
        params: {
          postalcode: zipCode
        }
      });

      return transformMarketTrends(response.data);
    } catch (error) {
      console.error('ATTOM API Error:', error);
      return import('./dummyDataService').then(module => 
        module.dummyDataService.getMarketTrends(zipCode)
      );
    }
  },

  /**
   * Get property valuation
   * @param {string} propertyId - ATTOM property ID
   */
  async getPropertyValue(propertyId) {
    try {
      const response = await attomAxios.get(API_ENDPOINTS.ATTOM.PROPERTY_VALUE, {
        params: {
          propertyId: propertyId
        }
      });

      return transformValuationData(response.data);
    } catch (error) {
      console.error('ATTOM API Error:', error);
      return generateDummyValuation();
    }
  }
};

// Data transformation functions
function transformPropertyData(rawData) {
  // Transform ATTOM API response to our application's format
  try {
    const property = rawData.property[0];
    return {
      id: property.identifier.attomId,
      address: {
        street: property.address.line1,
        city: property.address.locality,
        state: property.address.countrySubd,
        zipCode: property.address.postal1
      },
      details: {
        propertyType: property.summary.proptype,
        yearBuilt: property.summary.yearBuilt,
        bedrooms: property.building.rooms.beds,
        bathrooms: property.building.rooms.bathstotal,
        squareFootage: property.building.size.universalsize,
        lotSize: property.lot.lotsize1,
        lastSalePrice: property.sale.amount.saleamt,
        lastSaleDate: property.sale.amount.salerecdate
      },
      location: {
        latitude: property.location.latitude,
        longitude: property.location.longitude
      },
      assessment: {
        taxYear: property.assessment.tax.taxyear,
        taxAmount: property.assessment.tax.taxamt,
        assessedValue: property.assessment.assessed.assdttlvalue
      }
    };
  } catch (error) {
    console.error('Error transforming property data:', error);
    throw new Error('Invalid property data format');
  }
}

function transformSalesHistory(rawData) {
  try {
    return rawData.salesHistory.map(sale => ({
      date: sale.recordingDate,
      price: sale.amount,
      type: sale.type,
      buyer: sale.buyer,
      seller: sale.seller,
      document: {
        type: sale.documentType,
        number: sale.documentNumber
      }
    }));
  } catch (error) {
    console.error('Error transforming sales history:', error);
    return generateDummySalesHistory();
  }
}

function transformMarketTrends(rawData) {
  try {
    return {
      medianPrice: rawData.trends.medianPrice,
      pricePerSqFt: rawData.trends.pricePerSqFt,
      inventory: rawData.trends.inventory,
      daysOnMarket: rawData.trends.daysOnMarket,
      monthlyChange: rawData.trends.monthlyChange,
      yearlyChange: rawData.trends.yearlyChange,
      foreclosureRate: rawData.trends.foreclosureRate,
      marketHealthIndex: rawData.trends.marketHealthIndex
    };
  } catch (error) {
    console.error('Error transforming market trends:', error);
    return generateDummyMarketTrends();
  }
}

function transformValuationData(rawData) {
  try {
    return {
      estimatedValue: rawData.value.price,
      confidenceScore: rawData.value.confidenceScore,
      forecastedValue: rawData.value.forecastedPrice,
      valueRange: {
        low: rawData.value.priceLow,
        high: rawData.value.priceHigh
      },
      lastUpdated: rawData.value.lastUpdated
    };
  } catch (error) {
    console.error('Error transforming valuation data:', error);
    return generateDummyValuation();
  }
}

// Dummy data generators for development and error fallback
function generateDummySalesHistory() {
  const currentDate = new Date();
  const history = [];
  
  for (let i = 0; i < 5; i++) {
    const date = new Date(currentDate);
    date.setFullYear(date.getFullYear() - i * 2);
    
    history.push({
      date: date.toISOString(),
      price: 500000 - (i * 50000),
      type: 'SALE',
      buyer: 'Previous Owner ' + (i + 1),
      seller: 'Previous Owner ' + (i + 2),
      document: {
        type: 'WARRANTY DEED',
        number: `WD${Math.floor(Math.random() * 1000000)}`
      }
    });
  }
  
  return history;
}

function generateDummyMarketTrends() {
  return {
    medianPrice: 450000,
    pricePerSqFt: 250,
    inventory: 150,
    daysOnMarket: 30,
    monthlyChange: 0.5,
    yearlyChange: 5.2,
    foreclosureRate: 0.1,
    marketHealthIndex: 7.5
  };
}

function generateDummyValuation() {
  return {
    estimatedValue: 500000,
    confidenceScore: 85,
    forecastedValue: 525000,
    valueRange: {
      low: 475000,
      high: 525000
    },
    lastUpdated: new Date().toISOString()
  };
}
