import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AddressAutofill } from "@mapbox/search-js-react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { app } from "../firebase";
import {
  HomeIcon,
  MapPinIcon,
  BedDoubleIcon,
  DollarSignIcon,
  ImageIcon,
  TrashIcon,
  UploadIcon,
} from "lucide-react";

const CreateListing = () => {
  const { listingId } = useParams();
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("propertyDetails");
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState({
    imageUrls: [],
    currentStatus: "Pre-Foreclosure",
    name: "",
    description: "",
    address: {
      streetAndNumber: "",
      place: "",
      postcode: "",
      state: "",
      latitude: null,
      longitude: null,
    },
    keepAddressPrivate: false,
    type: "rent",
    bedrooms: 1,
    bathrooms: 1,
    currentPrice: 50000,
    offer: false,
    parking: false,
    furnished: false,
    propertyType: "Single-family home",
    outstandingMortgageBalance: "10000",
    liensOrDebts: false,
    receivedNotices: true,
    openedToLoanAssist: false,
    openedToShortSale: false,
    soughtLegalAdvice: false,
    offerDetails: "",
    foreclosureReasons: "",
    openToRefinancing: false,
    acceptableRefinanceTerms: "",
    willingToWorkOnRepaymentPlan: false,
    futureFinancialPlan: "",
    sqft: "",
    acres: "",
    yearBuilt: "",
    garage: "",
    regularPrice: 0,
    isVerified: false,
    monthlyPayment: "",
    monthsOwed: "",
    fundsNeeded: "",
  });

  const [imageUploadError, setImageUploadError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const sections = [
    { id: "propertyDetails", label: "Property Details", icon: <HomeIcon className="w-5 h-5" /> },
    { id: "location", label: "Location", icon: <MapPinIcon className="w-5 h-5" /> },
    { id: "specifications", label: "Specifications", icon: <BedDoubleIcon className="w-5 h-5" /> },
    { id: "financial", label: "Financial Details", icon: <DollarSignIcon className="w-5 h-5" /> },
    { id: "images", label: "Images", icon: <ImageIcon className="w-5 h-5" /> },
  ];

  const handleImageSubmit = () => {
    if (files.length > 0 && files.length + formData.imageUrls.length < 7) {
      setUploading(true);
      setImageUploadError(false);
      const promises = Array.from(files).map((file) => storeImage(file));

      Promise.all(promises)
        .then((urls) => {
          setFormData({
            ...formData,
            imageUrls: formData.imageUrls.concat(urls),
          });
          setImageUploadError(false);
          setUploading(false);
        })
        .catch((err) => {
          setImageUploadError("Image upload failed (2 mb max per image)");
          setUploading(false);
        });
    } else {
      setImageUploadError("You can only upload 6 images per listing");
    }
  };

  const storeImage = async (file) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage(app);
      const fileName = new Date().getTime() + file.name;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => reject(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleRemoveImage = (index) => {
    setFormData({
      ...formData,
      imageUrls: formData.imageUrls.filter((_, i) => i !== index),
    });
  };

  const handleChange = (e, field) => {
    const { id, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [id]: checked,
      }));
    } else if (field === "address") {
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [id]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };

  const handleAddressSelect = (location) => {
    const { features } = location;
    if (features && features.length > 0) {
      const feature = features[0];
      const [longitude, latitude] = feature.center;
      
      // Extract address components
      const streetNumber = feature.address || "";
      const street = feature.text || "";
      const streetAndNumber = `${streetNumber} ${street}`.trim();
      
      // Find city, state, and postal code from context
      let city = "", state = "", postcode = "";
      feature.context.forEach(item => {
        if (item.id.startsWith('place')) {
          city = item.text;
        } else if (item.id.startsWith('region')) {
          state = item.text;
        } else if (item.id.startsWith('postcode')) {
          postcode = item.text;
        }
      });

      setFormData(prev => ({
        ...prev,
        address: {
          streetAndNumber,
          place: city,
          state,
          postcode,
          latitude,
          longitude,
        }
      }));
    }
  };

  useEffect(() => {
    if (listingId) {
      const fetchListing = async () => {
        try {
          const response = await fetch(`/api/listing/${listingId}`);
          const data = await response.json();
          if (response.ok) {
            setFormData(data);
          } else {
            setError(data.message);
          }
        } catch (error) {
          setError("Failed to fetch listing");
        }
      };
      fetchListing();
    }
  }, [listingId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.imageUrls.length < 1) {
        setError("Minimum 1 image required");
        return;
      }
      setLoading(true);
      setError(false);

      const listingData = {
        ...formData,
        userRef: currentUser._id,
      };

      const url = listingId
        ? `/api/listing/update/${listingId}`
        : "/api/listing/create";
      const method = listingId ? "PUT" : "POST";

      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(listingData),
      });

      const data = await res.json();
      setLoading(false);
      if (data.success === false) {
        setError(data.message);
      } else {
        navigate(`/listing/${data._id}`);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const renderSection = () => {
    switch (activeSection) {
      case "propertyDetails":
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Property Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Property Type
                </label>
                <select
                  id="propertyType"
                  value={formData.propertyType}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="Single-family home">Single-family home</option>
                  <option value="Multi-family home">Multi-family home</option>
                  <option value="Apartment">Apartment</option>
                  <option value="Condominium">Condominium</option>
                  <option value="Townhouse">Townhouse</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Description
              </label>
              <textarea
                id="description"
                value={formData.description}
                onChange={handleChange}
                rows={4}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>
          </div>
        );

      case "location":
        return (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Street Address
              </label>
              <AddressAutofill 
                accessToken={import.meta.env.VITE_MAPBOX_TOKEN}
                onSelect={handleAddressSelect}
              >
                <input
                  type="text"
                  id="streetAndNumber"
                  name="address"
                  placeholder="Enter address"
                  value={formData.address.streetAndNumber}
                  onChange={(e) => handleChange(e, "address")}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                  autoComplete="street-address"
                />
              </AddressAutofill>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  City
                </label>
                <input
                  type="text"
                  id="place"
                  name="address"
                  value={formData.address.place}
                  onChange={(e) => handleChange(e, "address")}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                  autoComplete="address-level2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Postal Code
                </label>
                <input
                  type="text"
                  id="postcode"
                  name="address"
                  value={formData.address.postcode}
                  onChange={(e) => handleChange(e, "address")}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                  autoComplete="postal-code"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                State
              </label>
              <input
                type="text"
                id="state"
                name="address"
                value={formData.address.state}
                onChange={(e) => handleChange(e, "address")}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
                autoComplete="address-level1"
              />
            </div>
          </div>
        );

      case "specifications":
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Bedrooms
                </label>
                <input
                  type="number"
                  id="bedrooms"
                  value={formData.bedrooms}
                  onChange={handleChange}
                  min="0"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Bathrooms
                </label>
                <input
                  type="number"
                  id="bathrooms"
                  value={formData.bathrooms}
                  onChange={handleChange}
                  min="0"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Square Feet
                </label>
                <input
                  type="number"
                  id="sqft"
                  value={formData.sqft}
                  onChange={handleChange}
                  min="0"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Acres
                </label>
                <input
                  type="number"
                  id="acres"
                  value={formData.acres}
                  onChange={handleChange}
                  min="0"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>
            <div className="flex space-x-6">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="parking"
                  checked={formData.parking}
                  onChange={handleChange}
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                />
                <span className="text-sm text-gray-700">Parking Available</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="furnished"
                  checked={formData.furnished}
                  onChange={handleChange}
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                />
                <span className="text-sm text-gray-700">Furnished</span>
              </label>
            </div>
          </div>
        );

      case "financial":
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Regular Price
                </label>
                <input
                  type="number"
                  id="regularPrice"
                  value={formData.regularPrice}
                  onChange={handleChange}
                  min="0"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Monthly Payment
                </label>
                <input
                  type="number"
                  id="monthlyPayment"
                  value={formData.monthlyPayment}
                  onChange={handleChange}
                  min="0"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Outstanding Mortgage Balance
              </label>
              <input
                type="number"
                id="outstandingMortgageBalance"
                value={formData.outstandingMortgageBalance}
                onChange={handleChange}
                min="0"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <div className="space-y-4">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="liensOrDebts"
                  checked={formData.liensOrDebts}
                  onChange={handleChange}
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                />
                <span className="text-sm text-gray-700">
                  Property has liens or debts
                </span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="openedToLoanAssist"
                  checked={formData.openedToLoanAssist}
                  onChange={handleChange}
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                />
                <span className="text-sm text-gray-700">
                  Open to loan assistance
                </span>
              </label>
            </div>
          </div>
        );

      case "images":
        return (
          <div className="space-y-6">
            <div className="flex items-center gap-4">
              <input
                type="file"
                id="images"
                accept="image/*"
                multiple
                onChange={(e) => setFiles(e.target.files)}
                className="hidden"
              />
              <label
                htmlFor="images"
                className="cursor-pointer bg-white border-2 border-blue-500 text-blue-500 px-4 py-2 rounded-lg hover:bg-blue-50 transition-colors duration-200"
              >
                <UploadIcon className="w-5 h-5 inline-block mr-2" />
                Select Images
              </label>
              <button
                type="button"
                onClick={handleImageSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200"
                disabled={uploading}
              >
                {uploading ? "Uploading..." : "Upload"}
              </button>
            </div>
            {imageUploadError && (
              <p className="text-red-500 text-sm">{imageUploadError}</p>
            )}
            {formData.imageUrls.length > 0 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {formData.imageUrls.map((url, index) => (
                  <div key={url} className="relative group">
                    <img
                      src={url}
                      alt={`Property ${index + 1}`}
                      className="w-full h-48 object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveImage(index)}
                      className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                    >
                      <TrashIcon className="w-5 h-5 text-red-500" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <main className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            {listingId ? "Update Listing" : "Create a Listing"}
          </h1>
          <div className="space-x-4">
            <button
              onClick={() => navigate(-1)}
              className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              disabled={loading || uploading}
              className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 disabled:opacity-50"
            >
              {loading ? "Saving..." : "Save Listing"}
            </button>
          </div>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-red-600">{error}</p>
          </div>
        )}

        <div className="grid grid-cols-12 gap-8">
          {/* Navigation Sidebar */}
          <div className="col-span-3">
            <div className="bg-white rounded-xl shadow-sm p-4">
              <nav className="space-y-2">
                {sections.map((section) => (
                  <button
                    key={section.id}
                    onClick={() => setActiveSection(section.id)}
                    className={`w-full flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors duration-200 ${
                      activeSection === section.id
                        ? "bg-blue-50 text-blue-600"
                        : "text-gray-600 hover:bg-gray-50"
                    }`}
                  >
                    {section.icon}
                    <span>{section.label}</span>
                  </button>
                ))}
              </nav>
            </div>

            {/* Real-time Preview */}
            <div className="mt-6 bg-white rounded-xl shadow-sm p-4">
              <h3 className="text-lg font-semibold mb-4">Listing Preview</h3>
              <div className="space-y-4">
                {formData.imageUrls[0] && (
                  <img
                    src={formData.imageUrls[0]}
                    alt="Property preview"
                    className="w-full h-48 object-cover rounded-lg"
                  />
                )}
                <div className="p-4 bg-gray-50 rounded-lg">
                  <h4 className="font-medium text-lg">
                    {formData.name || "Property Name"}
                  </h4>
                  <p className="text-gray-600 text-sm mt-1">
                    {formData.address.streetAndNumber
                      ? `${formData.address.streetAndNumber}, ${formData.address.place}`
                      : "Address"}
                  </p>
                  <div className="mt-2">
                    <p className="text-2xl font-bold text-blue-600">
                      ${formData.regularPrice.toLocaleString() || "0"}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center justify-between text-sm text-gray-500">
                    <span>{formData.bedrooms} beds</span>
                    <span>{formData.bathrooms} baths</span>
                    <span>{formData.sqft} sqft</span>
                  </div>
                  {formData.description && (
                    <p className="mt-3 text-sm text-gray-600 line-clamp-3">
                      {formData.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="col-span-9">
            <div className="bg-white rounded-xl shadow-sm p-6">
              {renderSection()}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CreateListing;
