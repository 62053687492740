import React from "react";
import PropTypes from "prop-types";

const FiltersContainer = ({
  selectedType,
  setSelectedType,
  priceRange,
  setPriceRange,
  bedrooms,
  setBedrooms,
  bathrooms,
  setBathrooms,
  size,
  setSize,
  filterProperties,
  savedSearches,
  applySavedSearch,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  const propertyTypes = [
    "Single-family home",
    "Multi-family home",
    "Apartment",
    "Condominium",
    "Townhouse",
    "Office building",
    "Retail space",
    "Industrial building",
    "Hotel or motel",
    "Restaurant",
    "Agricultural land",
    "Vacant land",
    "Development land",
    "Other",
  ];

  const propertyStatuses = [
    "Pre-Foreclosure",
    "Foreclosed",
    "For Sale",
    "Bank-Owned",
    "Auction Scheduled",
    "Short Sale",
    "Pending Sale",
    "Under Contract",
    "Sold",
    "Deed in Lieu",
    "Loan Modification in Progress",
    "Forbearance",
    "Rent-to-Own",
    "REO (Real Estate Owned)",
    "Open to Offers",
    "Assumable Mortgage",
    "Redeemable",
    "Bankruptcy",
    "Eviction in Progress",
  ];

  return (
    <aside className="w-full md:w-72 bg-white p-4 rounded-lg shadow-md mr-0 md:mr-4 mb-4 md:mb-0">
      {savedSearches && savedSearches.length > 0 && (
        <div className="mb-6">
          <h3 className="font-medium mb-2">Saved Searches:</h3>
          <select
            onChange={(e) => applySavedSearch(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select a saved search</option>
            {savedSearches.map((search, index) => (
              <option key={index} value={search.name}>
                {search.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <h2 className="text-lg font-semibold mb-4">Filters:</h2>

      {/* Saved Searches Section */}
      {savedSearches && savedSearches.length > 0 && (
        <div className="mb-6">
          <h3 className="font-medium mb-2">Saved Searches:</h3>
          <select
            onChange={(e) => applySavedSearch(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select a saved search</option>
            {savedSearches.map((search, index) => (
              <option key={index} value={search.name}>
                {search.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Property Type */}
      <div className="mb-4">
        <h3 className="font-medium mb-2">Property Type:</h3>
        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">All Types</option>
          {propertyTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      {/* Price Range */}
      <div className="mb-4">
        <h3 className="font-medium mb-2">Price Range:</h3>
        <input
          type="range"
          min="0"
          max="1000000"
          step="10000"
          value={priceRange[1]}
          onChange={(e) => setPriceRange([0, parseInt(e.target.value)])}
          className="w-full"
        />
        <div className="flex justify-between text-sm text-gray-600">
          <span>$0</span>
          <span>${priceRange[1].toLocaleString()}</span>
        </div>
      </div>

      {/* Bedrooms */}
      <div className="mb-4">
        <h3 className="font-medium mb-2">Bedrooms:</h3>
        <select
          value={bedrooms}
          onChange={(e) => setBedrooms(parseInt(e.target.value))}
          className="w-full p-2 border rounded"
        >
          <option value="0">Any</option>
          <option value="1">1+</option>
          <option value="2">2+</option>
          <option value="3">3+</option>
          <option value="4">4+</option>
          <option value="5">5+</option>
        </select>
      </div>

      {/* Bathrooms */}
      <div className="mb-4">
        <h3 className="font-medium mb-2">Bathrooms:</h3>
        <select
          value={bathrooms}
          onChange={(e) => setBathrooms(parseInt(e.target.value))}
          className="w-full p-2 border rounded"
        >
          <option value="0">Any</option>
          <option value="1">1+</option>
          <option value="2">2+</option>
          <option value="3">3+</option>
          <option value="4">4+</option>
          <option value="5">5+</option>
        </select>
      </div>

      {/* Size */}
      <div className="mb-4">
        <h3 className="font-medium mb-2">Size (sq ft):</h3>
        <input
          type="range"
          min="0"
          max="5000"
          step="100"
          value={size[1]}
          onChange={(e) => setSize([0, parseInt(e.target.value)])}
          className="w-full"
        />
        <div className="flex justify-between text-sm text-gray-600">
          <span>0 ft²</span>
          <span>{size[1]} ft²</span>
        </div>
      </div>

      {/* Property Status */}
      <div className="mb-4">
        <h3 className="font-medium mb-2">Property Status:</h3>
        <div className="max-h-48 overflow-y-auto">
          {propertyStatuses.map((status, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={`status-${index}`}
                checked={selectedStatuses.includes(status)}
                onChange={() => {
                  if (selectedStatuses.includes(status)) {
                    setSelectedStatuses(
                      selectedStatuses.filter((s) => s !== status)
                    );
                  } else {
                    setSelectedStatuses([...selectedStatuses, status]);
                  }
                }}
                className="mr-2"
              />
              <label htmlFor={`status-${index}`} className="text-sm">
                {status}
              </label>
            </div>
          ))}
        </div>
      </div>

      <button
        onClick={filterProperties}
        className="w-full bg-blue-500 text-white py-2 rounded-full hover:bg-blue-600 transition duration-300"
      >
        Apply Filters
      </button>
    </aside>
  );
};

FiltersContainer.propTypes = {
  selectedType: PropTypes.string.isRequired,
  setSelectedType: PropTypes.func.isRequired,
  priceRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  setPriceRange: PropTypes.func.isRequired,
  bedrooms: PropTypes.number.isRequired,
  setBedrooms: PropTypes.func.isRequired,
  bathrooms: PropTypes.number.isRequired,
  setBathrooms: PropTypes.func.isRequired,
  size: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSize: PropTypes.func.isRequired,
  filterProperties: PropTypes.func.isRequired,
  savedSearches: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      // Add other properties of saved search as needed
    })
  ),
  applySavedSearch: PropTypes.func,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedStatuses: PropTypes.func.isRequired,
};

export default FiltersContainer;
