import React from "react";
import PropTypes from "prop-types";
import { suggestedUsers } from "./constants";

const SuggestedUser = ({ user, onFollow }) => (
  <div className="flex items-center gap-3 p-3 hover:bg-gray-50 rounded-lg transition-colors">
    <img src={user.avatar} alt={user.name} className="w-12 h-12 rounded-full" />
    <div className="flex-1">
      <h3 className="font-medium">{user.name}</h3>
      <p className="text-sm text-gray-500">{user.role}</p>
      <div className="flex items-center gap-4 mt-1 text-xs text-gray-500">
        <span>{user.followers} followers</span>
        <span>{user.listings} listings</span>
        {user.deals && <span>{user.deals} deals</span>}
        {user.rating && <span>⭐️ {user.rating}</span>}
      </div>
    </div>
    <button
      onClick={() => onFollow(user.id)}
      className="px-4 py-1.5 text-sm text-blue-500 font-medium hover:bg-blue-50 rounded-lg transition-colors"
    >
      Follow
    </button>
  </div>
);

SuggestedUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    followers: PropTypes.number.isRequired,
    listings: PropTypes.number.isRequired,
    deals: PropTypes.number,
    rating: PropTypes.number,
  }).isRequired,
  onFollow: PropTypes.func.isRequired,
};

const SuggestedConnections = ({ onFollow }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm p-4">
      <h2 className="font-semibold text-lg mb-3">Suggested Connections</h2>
      <div className="space-y-2">
        {suggestedUsers.map((user) => (
          <SuggestedUser key={user.id} user={user} onFollow={onFollow} />
        ))}
      </div>
    </div>
  );
};

SuggestedConnections.propTypes = {
  onFollow: PropTypes.func.isRequired,
};

export default SuggestedConnections;
