import { useState, useEffect, useCallback } from 'react';
import { censusService } from '../services/censusService';

export function useDemographicData(initialZipCode = '') {
  const [demographics, setDemographics] = useState(null);
  const [economicData, setEconomicData] = useState(null);
  const [housingStats, setHousingStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDemographicData = useCallback(async (zipCode) => {
    setLoading(true);
    setError(null);

    try {
      // Fetch all demographic data in parallel
      const [
        demographicsData,
        economicIndicators,
        housingData
      ] = await Promise.all([
        censusService.getDemographicsByZip(zipCode),
        censusService.getEconomicIndicators(zipCode),
        censusService.getHousingStats(zipCode)
      ]);

      setDemographics(demographicsData);
      setEconomicData(economicIndicators);
      setHousingStats(housingData);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching demographic data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  // Initial fetch if ZIP code is provided
  useEffect(() => {
    if (initialZipCode) {
      fetchDemographicData(initialZipCode);
    }
  }, [initialZipCode, fetchDemographicData]);

  const refreshData = useCallback((zipCode) => {
    if (zipCode) {
      fetchDemographicData(zipCode);
    }
  }, [fetchDemographicData]);

  return {
    demographics,
    economicData,
    housingStats,
    loading,
    error,
    fetchDemographicData,
    refreshData
  };
}
