import { useEffect, useRef, useCallback } from "react";
import io from "socket.io-client";
import { useSelector, useDispatch } from "react-redux";
import {
  updateUserOnlineStatus,
  addNewMessage,
  setHasNewMessages,
} from "../redux/user/userSlice";
import { debounce } from "lodash";

const SOCKET_URL =
  process.env.NODE_ENV === "production"
    ? window.location.origin
    : "http://localhost:3000";

// Socket.IO configuration
const SOCKET_OPTIONS = {
  transports: ["websocket", "polling"],
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  timeout: 10000,
  forceNew: true,
  autoConnect: false,
};
export const useSocketConnection = () => {
  const socketRef = useRef(null);
  const token = useSelector((state) => state.user.token);
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  const checkNewMessages = useCallback(
    debounce(() => {
      if (socketRef.current && socketRef.current.connected && currentUser) {
        socketRef.current.emit("checkNewMessages", currentUser._id);
      }
    }, 5000),
    [currentUser]
  );

  useEffect(() => {
    if (token && currentUser) {
      const socket = io(SOCKET_URL, {
        transports: ["websocket", "polling"],
        auth: { token },
      });

      socket.on("connect", () => {
        console.log("Socket connected successfully");
        socket.emit("userOnline", currentUser._id);
      });

      socket.on("newMessage", (message) => {
        console.log("New message received:", message);
        dispatch(
          addNewMessage({ conversationId: message.conversationId, message })
        );
        dispatch(setHasNewMessages(true));
        checkNewMessages();
      });

      socket.on("userStatusUpdate", ({ userId, status }) => {
        console.log(`User ${userId} status updated to ${status}`);
        dispatch(updateUserOnlineStatus({ userId, status }));
      });

      socketRef.current = socket;

      return () => {
        socket.disconnect();
      };
    }
  }, [token, currentUser, dispatch, checkNewMessages]);

  const sendMessage = useCallback((messageData) => {
    if (socketRef.current && socketRef.current.connected) {
      socketRef.current.emit("sendMessage", messageData, (error) => {
        if (error) {
          console.error("Error sending message:", error);
        }
      });
    } else {
      console.error("Socket is not connected. Unable to send message.");
    }
  }, []);

  return { socket: socketRef.current, sendMessage, checkNewMessages };
};

export default useSocketConnection;
