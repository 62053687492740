import React, { useState, Suspense } from "react";
import { useSelector } from "react-redux";
import { Link, Route, Routes } from "react-router-dom";
import WeatherWidget from "../components/dashboard/WeatherWidget";
import ProfileProgress from "../components/dashboard/ProfileProgress";
import RecentLikedListings from "../components/dashboard/RecentLikedListings";
import AccountProfile from "../components/dashboard/AccountProfile";
const RealEstateDashboard = React.lazy(() =>
  import("../components/RealEstateDashboard")
);

import {
  HomeIcon,
  UserIcon,
  ListBulletIcon,
  UsersIcon,
  CalendarIcon,
  Cog6ToothIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  QuestionMarkCircleIcon,
  ChatBubbleLeftRightIcon,
  ArrowRightOnRectangleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { ArrowPathIcon as RefreshIcon } from "@heroicons/react/24/outline";

const Dashboard = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [selectedSection, setSelectedSection] = useState("overview");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const menuItems = [
    { name: "overview", icon: HomeIcon, label: "Overview", to: "/dashboard" },
    {
      name: "realEstateDashboard",
      icon: ChartBarIcon,
      label: "Real Estate Dashboard",
      to: "/real-estate-dashboard",
    },
    {
      name: "profile",
      icon: UserIcon,
      label: "Account Profile",
      to: "/dashboard/profile",
    },
    {
      name: "messages",
      icon: ChatBubbleLeftRightIcon,
      label: "Messages",
      to: "/messages",
    },
    {
      name: "listings",
      icon: ListBulletIcon,
      label: "Listings",
      to: "/dashboard/listings",
    },
    {
      name: "network",
      icon: UsersIcon,
      label: "Network",
      to: "/dashboard/network",
    },
    {
      name: "calendar",
      icon: CalendarIcon,
      label: "Calendar",
      to: "/dashboard/calendar",
    },
    {
      name: "analytics",
      icon: ChartBarIcon,
      label: "Analytics",
      to: "/dashboard/analytics",
    },
    {
      name: "financialTools",
      icon: CurrencyDollarIcon,
      label: "Financial Tools",
      to: "/dashboard/financial-tools",
    },
    {
      name: "marketInsights",
      icon: GlobeAltIcon,
      label: "Market Insights",
      to: "/dashboard/market-insights",
    },
    {
      name: "settings",
      icon: Cog6ToothIcon,
      label: "Settings",
      to: "/dashboard/settings",
    },
    {
      name: "helpSupport",
      icon: QuestionMarkCircleIcon,
      label: "Help & Support",
      to: "/dashboard/help-support",
    },
  ];

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good Morning";
    if (hour < 18) return "Good Afternoon";
    return "Good Evening";
  };

  const currentDate = new Date().toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const handleRefresh = async () => {
    setIsRefreshing(true);
    setIsLoading(true);
    setError(null);
    try {
      // Simulating an API call
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // If using real API, replace the above line with actual API call
      // const data = await fetchRealEstateData(selectedProperty);
      // setRealEstateData(data);
    } catch (err) {
      setError("Failed to refresh data. Please try again.");
    } finally {
      setIsRefreshing(false);
      setIsLoading(false);
    }
  };

  const handlePropertySearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      // Simulating an API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // If using real API, replace the above line with actual API call
      // const property = await searchProperty(searchQuery);
      setSelectedProperty({ address: searchQuery, id: Date.now() }); // Mock property object
    } catch (err) {
      setError("Failed to find property. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen font-lato">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-12 gap-6">
          {/* Sidebar */}
          <div className="col-span-2 bg-white rounded-lg shadow-md overflow-y-auto max-h-[calc(100vh-2rem)]">
            <div className="py-4 px-2">
              <nav className="space-y-2">
                {menuItems.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className={`flex items-center w-full p-2 rounded-md ${
                      selectedSection === item.name
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-600 hover:bg-gray-100"
                    }`}
                    onClick={() => setSelectedSection(item.name)}
                  >
                    <item.icon className="h-5 w-5 mr-3" />
                    <span className="text-sm">{item.label}</span>
                  </Link>
                ))}
              </nav>
            </div>
            <div className="p-4 border-t">
              <Link to="/pricing">
                <button className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors mb-2 text-sm">
                  Upgrade to Pro
                </button>
              </Link>
              <button className="w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors flex items-center justify-center text-sm">
                <ArrowRightOnRectangleIcon className="h-4 w-4 mr-2" />
                Log Out
              </button>
            </div>
          </div>

          {/* Main Content */}
          <div className="col-span-7">
            <Suspense fallback={<div className="text-center">Loading...</div>}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <h1 className="text-5xl font-semi-bold text-center mb-6">
                        {getGreeting()}, {currentUser?.username}!
                      </h1>
                      <div className="flex items-center justify-center mb-6">
                        <CalendarIcon className="h-5 w-5 mr-2" />
                        <span className="text-2xl">{currentDate}</span>
                      </div>
                      <div className="space-y-6">
                        <div className="bg-white rounded-lg shadow-md p-4">
                          <WeatherWidget />
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-4">
                          <h3 className="text-lg font-semi-bold mb-2">
                            Real Estate Dashboard
                          </h3>
                          <p className="text-gray-600 mb-4">
                            Get insights into your real estate investments,
                            including ROI breakdowns, rental income analysis,
                            and market trends. Use this powerful tool to make
                            informed decisions about your property portfolio.
                          </p>
                          <Link
                            to="/real-estate-dashboard"
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                          >
                            View Real Estate Dashboard
                          </Link>
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-4">
                          <h3 className="text-lg font-semi-bold mb-2">
                            Goals & Milestones
                          </h3>
                          <div className="w-full bg-gray-200 rounded-full h-2.5">
                            <div
                              className="bg-blue-600 h-2.5 rounded-full"
                              style={{ width: "30%" }}
                            ></div>
                          </div>
                          <p className="text-sm text-gray-600 mt-2">Done 30%</p>
                          <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors">
                            View All Goals
                          </button>
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-4">
                          <ProfileProgress />
                        </div>
                        <div className="bg-white rounded-lg shadow-md p-4">
                          <h3 className="text-lg font-semi-bold mb-2">Notes</h3>
                          <textarea
                            className="w-full h-32 p-2 border rounded-lg"
                            placeholder="Add your notes here..."
                          ></textarea>
                        </div>
                      </div>
                    </>
                  }
                />
                <Route path="/profile" element={<AccountProfile />} />
                <Route
                  path="/real-estate-dashboard"
                  element={
                    <div>
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-semibold">
                          Real Estate Dashboard
                        </h2>
                        <button
                          onClick={handleRefresh}
                          className="flex items-center bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600 transition-colors"
                          disabled={isRefreshing}
                        >
                          <RefreshIcon className="h-5 w-5 mr-2" />
                          {isRefreshing ? "Refreshing..." : "Refresh Data"}
                        </button>
                      </div>
                      <form onSubmit={handlePropertySearch} className="mb-4">
                        <div className="flex items-center">
                          <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search for a property..."
                            className="flex-grow p-2 border rounded-l-lg"
                          />
                          <button
                            type="submit"
                            className="bg-blue-500 text-white p-2 rounded-r-lg hover:bg-blue-600 transition-colors"
                          >
                            <MagnifyingGlassIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </form>
                      {isLoading && (
                        <div className="text-center">Loading...</div>
                      )}
                      {error && (
                        <div className="text-red-500 mb-4">{error}</div>
                      )}
                      {selectedProperty && (
                        <div className="mb-4">
                          <h3 className="text-lg font-semibold">
                            Selected Property:
                          </h3>
                          <p>{selectedProperty.address}</p>
                        </div>
                      )}
                      <RealEstateDashboard
                        selectedProperty={selectedProperty}
                      />
                    </div>
                  }
                />
                {/* Add more routes for other sections as needed */}
              </Routes>
            </Suspense>
          </div>

          {/* Right Sidebar */}
          <div className="col-span-3">
            <div className="bg-white rounded-lg shadow-md overflow-hidden mb-6">
              <div className="h-24 bg-gray-300">
                {currentUser?.coverPhoto && (
                  <img
                    src={currentUser.coverPhoto}
                    alt="Cover"
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
              <div className="p-4 flex flex-col items-center -mt-12">
                {currentUser?.avatar && (
                  <img
                    src={currentUser.avatar}
                    alt="Avatar"
                    className="h-24 w-24 rounded-full object-cover border-4 border-white mb-2"
                  />
                )}
                <h2 className="text-xl font-semibold">
                  {currentUser?.username}
                </h2>
                <p className="text-gray-600">
                  @{currentUser?.username?.toLowerCase()}
                </p>
                <div className="flex items-center mt-2">
                  <p className="text-blue-500 mr-2">
                    {currentUser?.role || "homeowner"}
                  </p>
                  <Link to="/pricing">
                    <button className="text-sm text-gray-500 hover:text-gray-700">
                      Change Role
                    </button>
                  </Link>
                </div>
                <div className="flex mt-4 space-x-2">
                  <button className="bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600 transition-colors text-sm">
                    Create Listing
                  </button>
                  <button className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-600 transition-colors text-sm">
                    Schedule Viewing
                  </button>
                </div>
              </div>
            </div>

            <RecentLikedListings />
            {/* <ActivityFeed /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
