import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Modal,
  Chip,
} from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import {
  Assessment,
  Lock,
  TrendingUp,
  // HomeWork,
  CompareArrows,
  Construction,
  // AttachMoney,
  Timeline,
} from "@mui/icons-material";
import { motion } from "framer-motion";

const InvestmentAnalysis = ({
  listing,
  investmentMetrics,
  onPremiumFeatureClick,
}) => {
  const [importUrl, setImportUrl] = useState("");
  const [showImportModal, setShowImportModal] = useState(false);

  const [analysis] = useState({
    roiBreakdown: [
      { name: "Rental Income", value: 60 },
      { name: "Appreciation", value: 30 },
      { name: "Tax Benefits", value: 10 },
    ],
    rentalProjection: [
      { year: 1, income: 24000 },
      { year: 2, income: 24720 },
      { year: 3, income: 25462 },
    ],
    metrics: {
      capRate: 7.43,
      cashOnCashReturn: 7.59,
      grossRentMultiplier: 10.5,
      breakEvenOccupancy: 75,
    },
    flipPotential: {
      score: 85,
      estimatedProfit: 75000,
      rehabCost: 45000,
      timeToComplete: "4-6 months",
      riskLevel: "Moderate",
      marketDemand: "High",
      improvements: [
        "Kitchen renovation",
        "Bathroom updates",
        "Flooring replacement",
        "Paint and finishes",
      ],
    },
  });

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const handleImport = () => {
    console.log("Importing data from:", importUrl);
    setShowImportModal(false);
    setImportUrl("");
  };

  return (
    <Card
      sx={{
        mb: 4,
        borderRadius: 4,
        background: "white",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <CardContent>
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#2C3E50",
              fontWeight: 600,
              fontFamily: "Lato, sans-serif",
            }}
          >
            <Assessment sx={{ mr: 1, color: "#3B82F6" }} />
            Investment Analysis
          </Typography>
        </Box>

        {/* Import Section */}
        <Box sx={{ mb: 4 }}>
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<CompareArrows />}
              onClick={() => setShowImportModal(true)}
              sx={{
                mb: 1,
                borderRadius: "50px",
                textTransform: "none",
                fontSize: "16px",
                py: 1.5,
                fontFamily: "Lato, sans-serif",
              }}
            >
              Compare with Another Property
            </Button>
          </motion.div>
        </Box>

        {/* ROI Section */}
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Lato, sans-serif",
              display: "flex",
              alignItems: "center",
              color: "#4B5563",
              fontWeight: 500,
              mb: 2,
            }}
          >
            <TrendingUp sx={{ mr: 1, color: "#10B981" }} />
            Return on Investment
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={analysis.roiBreakdown}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                  >
                    {analysis.roiBreakdown.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h6"
                sx={{ fontFamily: "Lato, sans-serif", mb: 2 }}
              >
                Rental Income Projection
              </Typography>
              <Box sx={{ mb: 3 }}>
                {analysis.rentalProjection.map((year) => (
                  <Box key={year.year} sx={{ mb: 2 }}>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "Lato, sans-serif",
                        mb: 1,
                      }}
                    >
                      <span>Year {year.year}</span>
                      <span>${year.income.toLocaleString()}</span>
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={(year.income / 30000) * 100}
                      sx={{ height: 8, borderRadius: 4 }}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Flip Potential Section */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Lato, sans-serif",
              display: "flex",
              alignItems: "center",
              color: "#4B5563",
              fontWeight: 500,
              mb: 2,
            }}
          >
            <Construction sx={{ mr: 1, color: "#10B981" }} />
            Flip Potential
          </Typography>
          <Grid container spacing={2}>
            {[
              {
                label: "Flip Score",
                value: `${analysis.flipPotential.score}%`,
                color: "#1565c0",
                secondary: analysis.flipPotential.timeToComplete,
                icon: <Timeline sx={{ fontSize: 20 }} />,
              },
              {
                label: "Estimated Profit",
                value: `$${analysis.flipPotential.estimatedProfit.toLocaleString()}`,
                color: "#2e7d32",
              },
              {
                label: "Rehab Cost",
                value: `$${analysis.flipPotential.rehabCost.toLocaleString()}`,
                color: "#ed6c02",
              },
              {
                label: "Improvements",
                value: (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {analysis.flipPotential.improvements.map(
                      (improvement, index) => (
                        <Chip
                          key={index}
                          label={improvement}
                          size="small"
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            bgcolor: "rgba(21, 101, 192, 0.1)",
                            color: "#1565c0",
                          }}
                        />
                      )
                    )}
                  </Box>
                ),
                color: "#1565c0",
              },
            ].map((metric) => (
              <Grid item xs={12} sm={6} key={metric.label}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: `rgba(${
                      metric.color === "#1565c0"
                        ? "21, 101, 192"
                        : metric.color === "#2e7d32"
                        ? "46, 125, 50"
                        : "237, 108, 2"
                    }, 0.1)`,
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#333",
                      mb: 1,
                    }}
                  >
                    {metric.label}
                  </Typography>
                  {typeof metric.value === "string" ? (
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        fontWeight: 600,
                        color: metric.color,
                        fontSize: "1.25rem",
                      }}
                    >
                      {metric.value}
                    </Typography>
                  ) : (
                    metric.value
                  )}
                  {metric.secondary && (
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        color: "text.secondary",
                      }}
                    >
                      {metric.icon}
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "Lato, sans-serif" }}
                      >
                        {metric.secondary}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Key Metrics */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "Lato, sans-serif", mb: 2 }}
          >
            Key Metrics
          </Typography>
          <Grid container spacing={2}>
            {[
              {
                label: "Cap Rate",
                value: `${analysis.metrics.capRate}%`,
                color: "#1565c0",
              },
              {
                label: "Cash on Cash Return",
                value: `${analysis.metrics.cashOnCashReturn}%`,
                color: "#2e7d32",
              },
              {
                label: "Gross Rent Multiplier",
                value: analysis.metrics.grossRentMultiplier,
                color: "#ed6c02",
              },
              {
                label: "Break-even Occupancy",
                value: `${analysis.metrics.breakEvenOccupancy}%`,
                color: "#1565c0",
              },
            ].map((metric) => (
              <Grid item xs={12} sm={6} key={metric.label}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: `rgba(${
                      metric.color === "#1565c0"
                        ? "21, 101, 192"
                        : metric.color === "#2e7d32"
                        ? "46, 125, 50"
                        : "237, 108, 2"
                    }, 0.1)`,
                    borderRadius: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#333",
                      mb: 1,
                    }}
                  >
                    {metric.label}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      fontWeight: 600,
                      color: metric.color,
                      fontSize: "1.25rem",
                    }}
                  >
                    {metric.value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Premium Feature Button */}
        <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={onPremiumFeatureClick}
            startIcon={<Lock />}
            sx={{
              mt: 2,
              borderRadius: 2,
              py: 1.5,
              textTransform: "none",
              fontFamily: "Lato, sans-serif",
              fontWeight: 600,
              background: "linear-gradient(45deg, #2196F3 30%, #3f51b5 90%)",
              boxShadow: "0 3px 5px 2px rgba(33, 150, 243, .3)",
              "&:hover": {
                background: "linear-gradient(45deg, #1976D2 30%, #303f9f 90%)",
              },
            }}
          >
            See Full Investment Analysis
          </Button>
        </motion.div>

        {/* Import Modal */}
        <Modal
          open={showImportModal}
          onClose={() => setShowImportModal(false)}
          aria-labelledby="import-modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", sm: 500 },
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{ mb: 2, fontFamily: "Lato, sans-serif" }}
            >
              Import Property for Comparison
            </Typography>
            <TextField
              fullWidth
              label="Paste property URL from another website"
              placeholder="e.g., https://www.realtor.com/property/..."
              value={importUrl}
              onChange={(e) => setImportUrl(e.target.value)}
              sx={{ mb: 3 }}
            />
            <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
              <Button
                onClick={() => setShowImportModal(false)}
                sx={{ fontFamily: "Lato, sans-serif" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleImport}
                disabled={!importUrl.trim()}
                sx={{ fontFamily: "Lato, sans-serif" }}
              >
                Import Property
              </Button>
            </Box>
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
};

InvestmentAnalysis.propTypes = {
  listing: PropTypes.object.isRequired,
  investmentMetrics: PropTypes.object,
  onPremiumFeatureClick: PropTypes.func.isRequired,
};

export default InvestmentAnalysis;
