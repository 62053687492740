import React, { useState } from "react";
import { Badge, Button } from "flowbite-react";
import {
  CheckBadgeIcon,
  PlusIcon,
  StarIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { useDispatch, useSelector } from "react-redux";

import EditableSection from "./EditableSection";
import FormField from "./FormField";
import { Notyf } from "notyf";
import useEditMode from "./useEditMode";
import useProfileForm from "./useProfileForm";
import "notyf/notyf.min.css";
const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const validationSchema = {
  author: (value) => (!value.trim() ? "Author name is required" : ""),
  content: (value) => {
    if (!value.trim()) return "Testimonial content is required";
    if (value.length < 10) return "Testimonial must be at least 10 characters";
    return "";
  },
  rating: (value) => {
    if (!value) return "Rating is required";
    if (value < 1 || value > 5) return "Rating must be between 1 and 5";
    return "";
  },
};

const TestimonialsSection = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [sortBy, setSortBy] = useState("date");

  const {
    formData,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = useProfileForm(
    { testimonials: currentUser?.testimonials || [] },
    validationSchema,
    "testimonials"
  );

  const { isEditing, handleEdit, handleCancel, handleSave } = useEditMode({
    onSave: handleSubmit,
    onCancel: handleReset,
  });

  const addTestimonial = () => {
    setFieldValue("testimonials", [
      ...formData.testimonials,
      {
        author: "",
        content: "",
        rating: 5,
        date: new Date().toISOString(),
        verified: false,
        featured: false,
      },
    ]);
  };

  const removeTestimonial = (index) => {
    const newTestimonials = formData.testimonials.filter((_, i) => i !== index);
    setFieldValue("testimonials", newTestimonials);
  };

  const handleTestimonialChange = (index, field, value) => {
    const newTestimonials = [...formData.testimonials];
    newTestimonials[index] = {
      ...newTestimonials[index],
      [field]: value,
    };
    setFieldValue("testimonials", newTestimonials);
  };

  const toggleFeature = (index) => {
    const newTestimonials = [...formData.testimonials];
    newTestimonials[index] = {
      ...newTestimonials[index],
      featured: !newTestimonials[index].featured,
    };
    setFieldValue("testimonials", newTestimonials);
  };

  const getSortedTestimonials = () => {
    return [...formData.testimonials].sort((a, b) => {
      switch (sortBy) {
        case "rating":
          return b.rating - a.rating;
        case "featured":
          return (b.featured ? 1 : 0) - (a.featured ? 1 : 0);
        case "date":
        default:
          return new Date(b.date) - new Date(a.date);
      }
    });
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <StarIcon
        key={index}
        className={`h-5 w-5 ${
          index < rating ? "text-yellow-400 fill-current" : "text-gray-300"
        }`}
      />
    ));
  };

  return (
    <EditableSection
      title="Testimonials"
      onSave={handleSave}
      onEdit={handleEdit}
      onCancel={handleCancel}
      isEditing={isEditing}
    >
      <div className="space-y-6">
        {/* Stats Summary */}
        <div className="grid grid-cols-4 gap-4">
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h4 className="text-sm text-gray-500">Total</h4>
            <p className="text-2xl font-semibold">
              {formData.testimonials.length}
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h4 className="text-sm text-gray-500">Average Rating</h4>
            <p className="text-2xl font-semibold">
              {(
                formData.testimonials.reduce((acc, t) => acc + t.rating, 0) /
                  formData.testimonials.length || 0
              ).toFixed(1)}
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h4 className="text-sm text-gray-500">Verified</h4>
            <p className="text-2xl font-semibold">
              {formData.testimonials.filter((t) => t.verified).length}
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h4 className="text-sm text-gray-500">Featured</h4>
            <p className="text-2xl font-semibold">
              {formData.testimonials.filter((t) => t.featured).length}
            </p>
          </div>
        </div>

        {/* Sort Controls */}
        {formData.testimonials.length > 0 && (
          <div className="flex justify-end">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="border rounded-md px-3 py-1.5 text-sm"
              disabled={isEditing}
            >
              <option value="date">Most Recent</option>
              <option value="rating">Highest Rated</option>
              <option value="featured">Featured First</option>
            </select>
          </div>
        )}

        {/* Testimonials List */}
        {formData.testimonials.length === 0 && !isEditing ? (
          <div className="text-center py-8 text-gray-500">
            No testimonials added yet. Click edit to add some.
          </div>
        ) : (
          <div className="space-y-6">
            {getSortedTestimonials().map((testimonial, index) => (
              <div
                key={index}
                className="relative bg-white p-6 rounded-lg shadow-sm"
              >
                {isEditing && (
                  <div className="absolute top-4 right-4 flex space-x-2">
                    <Button
                      color={testimonial.featured ? "success" : "light"}
                      size="sm"
                      onClick={() => toggleFeature(index)}
                    >
                      <CheckBadgeIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      color="failure"
                      size="sm"
                      onClick={() => removeTestimonial(index)}
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </Button>
                  </div>
                )}

                <div className="space-y-4">
                  <div className="flex justify-between items-start">
                    <FormField
                      label="Author Name"
                      id={`author-${index}`}
                      value={testimonial.author}
                      onChange={(e) =>
                        handleTestimonialChange(index, "author", e.target.value)
                      }
                      error={errors[`testimonials.${index}.author`]}
                      disabled={!isEditing}
                      required
                      placeholder="Enter author name"
                    />

                    {!isEditing && (
                      <div className="flex items-center space-x-2">
                        {testimonial.verified && (
                          <Badge color="success" className="flex items-center">
                            <CheckBadgeIcon className="h-4 w-4 mr-1" />
                            Verified
                          </Badge>
                        )}
                        {testimonial.featured && (
                          <Badge color="warning" className="flex items-center">
                            Featured
                          </Badge>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="flex items-center space-x-2">
                    {isEditing ? (
                      <select
                        value={testimonial.rating}
                        onChange={(e) =>
                          handleTestimonialChange(
                            index,
                            "rating",
                            Number(e.target.value)
                          )
                        }
                        className="border rounded-md px-2 py-1"
                      >
                        {[1, 2, 3, 4, 5].map((rating) => (
                          <option key={rating} value={rating}>
                            {rating} Star{rating !== 1 ? "s" : ""}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div className="flex">
                        {renderStars(testimonial.rating)}
                      </div>
                    )}
                    <span className="text-sm text-gray-500">
                      {new Date(testimonial.date).toLocaleDateString()}
                    </span>
                  </div>

                  <FormField
                    label="Testimonial"
                    id={`content-${index}`}
                    value={testimonial.content}
                    onChange={(e) =>
                      handleTestimonialChange(index, "content", e.target.value)
                    }
                    error={errors[`testimonials.${index}.content`]}
                    disabled={!isEditing}
                    required
                    multiline
                    rows={3}
                    placeholder="Enter testimonial content"
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Add Testimonial Button */}
        {isEditing && (
          <Button
            onClick={addTestimonial}
            className="w-full flex items-center justify-center"
          >
            <PlusIcon className="h-4 w-4 mr-1" />
            Add Testimonial
          </Button>
        )}
      </div>
    </EditableSection>
  );
};

export default TestimonialsSection;
