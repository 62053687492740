import React from "react";
import PropTypes from "prop-types";
import { Card, Spinner } from "flowbite-react";

const ProfileCard = ({
  title,
  children,
  loading = false,
  error = null,
  headerActions = null,
  className = "",
}) => {
  return (
    <Card className={`mb-6 ${className}`}>
      <div className="relative">
        {/* Header */}
        {title && (
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
            {headerActions && (
              <div className="flex items-center space-x-2">{headerActions}</div>
            )}
          </div>
        )}

        {/* Loading Overlay */}
        {loading && (
          <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center z-10">
            <Spinner size="lg" />
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded">
            <p className="text-red-700 text-sm">{error}</p>
          </div>
        )}

        {/* Content */}
        <div className={loading ? "opacity-50 pointer-events-none" : ""}>
          {children}
        </div>
      </div>
    </Card>
  );
};

ProfileCard.propTypes = {
  title: PropTypes.string, // Made optional since not all cards need titles
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  headerActions: PropTypes.node,
  className: PropTypes.string,
};

export default ProfileCard;
