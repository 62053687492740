import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Tooltip,
  LinearProgress,
  Divider,
} from "@mui/material";
import {
  HandshakeOutlined,
  AccountBalance,
  Gavel,
  Home,
  MonetizationOn,
  AssignmentTurnedIn,
  People,
  CalendarToday,
  Loop,
  SupportAgent,
  MailOutline,
  LocalOffer,
  SaveAlt,
  Timeline,
  BusinessCenter,
  Help,
} from "@mui/icons-material";

const AssistanceOptions = ({ listing }) => {
  // Demo assistance options data structure remains unchanged
  const assistanceData = {
    currentStatus: {
      stage: "Pre-Foreclosure",
      monthsDelinquent: 3,
      nextDeadline: "2024-04-15",
      urgencyLevel: "Moderate",
    },
    loanModification: {
      isOpen: true,
      previouslyAttempted: true,
      preferredTerms: "30-Year Fixed",
      desiredPayment: 1800,
      lastAttemptDate: "2023-12-15",
      documents: {
        taxReturns: true,
        payStubs: true,
        bankStatements: true,
        hardshipLetter: true,
      },
    },
    refinancing: {
      isOpen: true,
      creditScore: 680,
      currentRate: 5.75,
      targetRate: "Below 5%",
      equity: 85000,
      previouslyAttempted: false,
    },
    repaymentPlan: {
      isOpen: true,
      maxMonthlyPayment: 2200,
      preferredDuration: "12-24 Months",
      canMakeDownPayment: true,
      downPaymentAmount: 5000,
    },
    shortSale: {
      isOpen: true,
      currentMarketValue: 425000,
      minimumAcceptableOffer: 385000,
      timeframe: "3-6 Months",
      hasRealtor: false,
    },
    deedInLieu: {
      isOpen: false,
      considered: true,
      preferredTimeframe: "Within 6 Months",
    },
    assistance: {
      governmentPrograms: {
        attempted: ["HAMP", "FHA-HAMP"],
        interested: ["State Housing Assistance"],
        eligible: true,
      },
      counseling: {
        receiving: true,
        agency: "HUD-Approved Housing Counselor",
        startDate: "2024-01-15",
      },
    },
    communication: {
      preferredMethod: "Send Message",
      bestTimeToContact: "Evenings",
      languages: ["English", "Spanish"],
      urgentContact: true,
    },
    additionalOptions: {
      rentBack: true,
      sellerFinancing: false,
      partialClaim: true,
      mortgageAssumption: true,
    },
  };

  const getUrgencyColor = (level) => {
    const colors = {
      High: "error",
      Moderate: "warning",
      Low: "success",
    };
    return colors[level] || "primary";
  };

  // Component JSX remains largely the same, but with updated typography and colors
  return (
    <Card sx={{ mb: 4, borderRadius: 2 }}>
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontFamily: "Lato, sans-serif",
            fontWeight: 600,
            color: "#1565c0", // Darker blue
            mb: 3,
          }}
        >
          <HandshakeOutlined /> Assistance & Options Available
        </Typography>

        {/* Current Status Section */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <Timeline /> Current Situation
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: "#1565c0", // Darker blue
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  {assistanceData.currentStatus.stage}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ 
                    fontFamily: "Lato, sans-serif",
                    mb: 2,
                    color: "#333", // Darker text color
                  }}
                >
                  {assistanceData.currentStatus.monthsDelinquent} Months Delinquent
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <CalendarToday fontSize="small" />
                  <Typography 
                    sx={{ 
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}
                  >
                    Next Deadline: {assistanceData.currentStatus.nextDeadline}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "grey.100",
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    fontWeight: 600,
                    mb: 2,
                    color: "#333", // Darker text color
                  }}
                >
                  Documentation Ready
                </Typography>
                <Grid container spacing={1}>
                  {Object.entries(
                    assistanceData.loanModification.documents
                  ).map(([doc, available]) => (
                    <Grid item xs={6} key={doc}>
                      <Chip
                        icon={available ? <AssignmentTurnedIn /> : <Help />}
                        label={doc
                          .replace(/([A-Z])/g, " $1")
                          .trim()
                          .split(" ")
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                          .join(" ")}
                        color={available ? "success" : "default"}
                        sx={{ 
                          width: "100%",
                          "& .MuiChip-label": {
                            color: "#333", // Darker text color
                          }
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Loan Modification Section */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <AccountBalance /> Loan Modification Options
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: assistanceData.loanModification.isOpen
                    ? "rgba(46, 125, 50, 0.1)" // Lighter shade of success
                    : "rgba(211, 47, 47, 0.1)", // Lighter shade of error
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: assistanceData.loanModification.isOpen
                      ? "#2e7d32" // Darker success color
                      : "#d32f2f", // Darker error color
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  {assistanceData.loanModification.isOpen
                    ? "Open To Loan Modification"
                    : "Not Seeking Loan Modification"}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      Preferred Terms
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      {assistanceData.loanModification.preferredTerms}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      Desired Payment
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      ${assistanceData.loanModification.desiredPayment}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "grey.100",
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    fontWeight: 600,
                    mb: 2,
                    color: "#333", // Darker text color
                  }}
                >
                  Previous Attempts
                </Typography>
                {assistanceData.loanModification.previouslyAttempted ? (
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      Last Attempt
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      {assistanceData.loanModification.lastAttemptDate}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ 
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}
                  >
                    No Previous Modification Attempts
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Refinancing Section */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <MonetizationOn /> Refinancing Potential
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      Credit Score
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#1565c0", // Darker blue
                        fontWeight: 600,
                      }}
                    >
                      {assistanceData.refinancing.creditScore}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      Current Rate
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#1565c0", // Darker blue
                        fontWeight: 600,
                      }}
                    >
                      {assistanceData.refinancing.currentRate}%
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                      mb: 1,
                    }}
                  >
                    Available Equity
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#1565c0", // Darker blue
                      fontWeight: 600,
                    }}
                  >
                    ${assistanceData.refinancing.equity.toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: assistanceData.refinancing.isOpen
                    ? "rgba(46, 125, 50, 0.1)" // Lighter shade of success
                    : "rgba(211, 47, 47, 0.1)", // Lighter shade of error
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: assistanceData.refinancing.isOpen
                      ? "#2e7d32" // Darker success color
                      : "#d32f2f", // Darker error color
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  {assistanceData.refinancing.isOpen
                    ? "Open To Refinancing"
                    : "Not Seeking Refinancing"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: "#333", // Darker text color
                    mb: 1,
                  }}
                >
                  Target Rate: {assistanceData.refinancing.targetRate}
                </Typography>
                {!assistanceData.refinancing.previouslyAttempted && (
                  <Chip
                    label="No Previous Refinancing Attempts"
                    color="primary"
                    sx={{ 
                      mt: 1,
                      bgcolor: "#1565c0", // Darker blue
                      color: "#fff",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Repayment Plan Section */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <Loop /> Repayment Plan Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: assistanceData.repaymentPlan.isOpen
                    ? "rgba(46, 125, 50, 0.1)" // Lighter shade of success
                    : "rgba(211, 47, 47, 0.1)", // Lighter shade of error
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: assistanceData.repaymentPlan.isOpen
                      ? "#2e7d32" // Darker success color
                      : "#d32f2f", // Darker error color
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  {assistanceData.repaymentPlan.isOpen
                    ? "Open To Repayment Plan"
                    : "Not Seeking Repayment Plan"}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      Maximum Monthly Payment
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        fontWeight: 600,
                        color: "#333", // Darker text color
                      }}
                    >
                      ${assistanceData.repaymentPlan.maxMonthlyPayment}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      Preferred Duration
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        fontWeight: 600,
                        color: "#333", // Darker text color
                      }}
                    >
                      {assistanceData.repaymentPlan.preferredDuration}
                    </Typography>
                  </Grid>
                </Grid>
                {assistanceData.repaymentPlan.canMakeDownPayment && (
                  <Box sx={{ mt: 2 }}>
                    <Chip
                      label={`Can Make Down Payment Of $${assistanceData.repaymentPlan.downPaymentAmount}`}
                      color="success"
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        bgcolor: "#2e7d32", // Darker success color
                        color: "#fff",
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    fontWeight: 600,
                    mb: 2,
                    color: "#1565c0", // Darker blue
                  }}
                >
                  Contact Preferences
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}
                  >
                    Preferred Method
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ 
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}
                  >
                    {assistanceData.communication.preferredMethod}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}
                  >
                    Best Time
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ 
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}
                  >
                    {assistanceData.communication.bestTimeToContact}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Short Sale & Exit Options */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <LocalOffer /> Exit Options
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "grey.100",
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    fontWeight: 600,
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "#333", // Darker text color
                  }}
                >
                  <Home /> Short Sale
                </Typography>
                {assistanceData.shortSale.isOpen && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "#333", // Darker text color
                        }}
                      >
                        Market Value
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ 
                          fontFamily: "Lato, sans-serif",
                          color: "#333", // Darker text color
                        }}
                      >
                        ${assistanceData.shortSale.currentMarketValue.toLocaleString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "#333", // Darker text color
                        }}
                      >
                        Minimum Offer
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ 
                          fontFamily: "Lato, sans-serif",
                          color: "#333", // Darker text color
                        }}
                      >
                        ${assistanceData.shortSale.minimumAcceptableOffer.toLocaleString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Chip
                        label={`Timeframe: ${assistanceData.shortSale.timeframe}`}
                        color="primary"
                        sx={{ 
                          mt: 1,
                          bgcolor: "#1565c0", // Darker blue
                          color: "#fff",
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                {!assistanceData.shortSale.isOpen && (
                  <Typography
                    variant="body2"
                    sx={{ 
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}
                  >
                    Not Currently Considering Short Sale
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "grey.100",
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    fontWeight: 600,
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "#333", // Darker text color
                  }}
                >
                  <SaveAlt /> Deed In Lieu
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      Status
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      {assistanceData.deedInLieu.isOpen
                        ? "Open To Deed In Lieu"
                        : "Not Currently Considering"}
                    </Typography>
                  </Grid>
                  {assistanceData.deedInLieu.isOpen && (
                    <Grid item xs={12}>
                      <Chip
                        label={`Preferred Timeframe: ${assistanceData.deedInLieu.preferredTimeframe}`}
                        color="primary"
                        sx={{ 
                          mt: 1,
                          bgcolor: "#1565c0", // Darker blue
                          color: "#fff",
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Government Programs & Counseling */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <BusinessCenter /> Assistance Programs
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(46, 125, 50, 0.1)", // Lighter shade of success
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    fontWeight: 600,
                    mb: 2,
                    color: "#2e7d32", // Darker success color
                  }}
                >
                  Programs Attempted/Interested
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                      mb: 1,
                    }}
                  >
                    Attempted Programs
                  </Typography>
                  {assistanceData.assistance.governmentPrograms.attempted.map(
                    (program) => (
                      <Chip
                        key={program}
                        label={program}
                        sx={{ 
                          m: 0.5,
                          color: "#333", // Darker text color
                        }}
                        color="default"
                      />
                    )
                  )}
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                      mb: 1,
                    }}
                  >
                    Interested In
                  </Typography>
                  {assistanceData.assistance.governmentPrograms.interested.map(
                    (program) => (
                      <Chip
                        key={program}
                        label={program}
                        sx={{ 
                          m: 0.5,
                          bgcolor: "#1565c0", // Darker blue
                          color: "#fff",
                        }}
                      />
                    )
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    fontWeight: 600,
                    mb: 2,
                    color: "#1565c0", // Darker blue
                  }}
                >
                  Housing Counseling Status
                </Typography>
                {assistanceData.assistance.counseling.receiving ? (
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#333", // Darker text color
                      }}
                    >
                      Current Agency
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        mb: 1,
                        color: "#333", // Darker text color
                      }}
                    >
                      {assistanceData.assistance.counseling.agency}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "text.secondary",
                      }}
                    >
                      Start Date
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: "Lato, sans-serif" }}
                    >
                      {assistanceData.assistance.counseling.startDate}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "Lato, sans-serif" }}
                  >
                    Not currently receiving counseling
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Additional Options */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
            }}
          >
            <Help /> Additional Options Considered
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(assistanceData.additionalOptions).map(
              ([key, value]) => (
                <Grid item xs={6} sm={3} key={key}>
                  <Tooltip title={getOptionDescription(key)} placement="top">
                    <Box
                      sx={{
                        p: 2,
                        bgcolor: "grey.100",
                        borderRadius: 2,
                        textAlign: "center",
                      }}
                    >
                      {getOptionIcon(key)}
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Lato, sans-serif",
                          color: "text.secondary",
                          mt: 1,
                        }}
                      >
                        {key.replace(/([A-Z])/g, " $1").trim()}
                      </Typography>
                      <Chip
                        label={value ? "Interested" : "Not Interested"}
                        color={value ? "success" : "default"}
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    </Box>
                  </Tooltip>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

// Helper functions
const getOptionIcon = (option) => {
  const icons = {
    rentBack: <Home sx={{ color: "primary.main" }} />,
    sellerFinancing: <MonetizationOn sx={{ color: "primary.main" }} />,
    partialClaim: <AssignmentTurnedIn sx={{ color: "primary.main" }} />,
    mortgageAssumption: <AccountBalance sx={{ color: "primary.main" }} />,
  };
  return icons[option] || <Help sx={{ color: "primary.main" }} />;
};

const getOptionDescription = (option) => {
  const descriptions = {
    rentBack: "Option to rent the property back after sale",
    sellerFinancing: "Willing to provide financing to the buyer",
    partialClaim: "FHA partial claim option",
    mortgageAssumption: "Allow qualified buyer to assume current mortgage",
  };
  return descriptions[option] || "";
};

AssistanceOptions.propTypes = {
  listing: PropTypes.shape({
    currentStatus: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
};

export default AssistanceOptions;

