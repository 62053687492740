import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from "../redux/user/userSlice";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(forgotPasswordStart());
      const res = await fetch("/api/auth/forgot-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await res.json();
      if (data.success === false) {
        dispatch(forgotPasswordFailure(data.message));
        setFormError(data.message);
        return;
      }
      dispatch(forgotPasswordSuccess());
      alert("Password reset email sent. Please check your email.");
    } catch (error) {
      dispatch(forgotPasswordFailure(error.message));
      setFormError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="p-3 max-w-lg mx-auto">
      <h1 className="text-3xl text-center font-semibold my-7">
        Forgot Password
      </h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <input
          type="email"
          placeholder="Email"
          className="border p-3 rounded-lg"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className="bg-slate-700 text-white p-3 rounded-lg uppercase hover:opacity-95">
          Reset Password
        </button>
      </form>
      {formError && <p className="text-red-500 mt-5">{formError}</p>}
    </div>
  );
}
