import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Box,
} from "@mui/material";
import { TrendingUp, Sort } from "@mui/icons-material";

const ComparativeMarketAnalysis = ({ propertyData }) => {
  const [comparableProperties, setComparableProperties] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [filterConfig, setFilterConfig] = useState({
    bedrooms: "all",
    bathrooms: "all",
  });

  useEffect(() => {
    // In a real scenario, you would fetch this data from an API
    const mockComparables = generateMockComparables(propertyData, 10);
    setComparableProperties(mockComparables);
  }, [propertyData]);

  const generateMockComparables = (subjectProperty, count) => {
    const basePrice = 350000;
    const baseSqFt = 2000;
    const baseLocation = subjectProperty?.address?.place || "Sample City";

    return Array.from({ length: count }, (_, i) => ({
      id: i + 1,
      address: `${1000 + i} Sample Street, ${baseLocation}`,
      salePrice: basePrice + (Math.random() - 0.5) * 100000,
      squareFeet: baseSqFt + (Math.random() - 0.5) * 500,
      bedrooms: Math.floor(2 + Math.random() * 3),
      bathrooms: Math.floor(2 + Math.random() * 2),
      saleDate: new Date(
        Date.now() - Math.random() * 90 * 24 * 60 * 60 * 1000
      ).toLocaleDateString(),
      pricePerSqFt: (
        (basePrice + (Math.random() - 0.5) * 100000) /
        (baseSqFt + (Math.random() - 0.5) * 500)
      ).toFixed(2),
    }));
  };

  const calculatePricePerSqFt = (price, sqft) => (price / sqft).toFixed(2);

  const sortedProperties = React.useMemo(() => {
    let sortableProperties = [...comparableProperties];
    if (sortConfig.key !== null) {
      sortableProperties.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProperties;
  }, [comparableProperties, sortConfig]);

  const filteredProperties = React.useMemo(() => {
    return sortedProperties.filter(
      (property) =>
        (filterConfig.bedrooms === "all" ||
          property.bedrooms === parseInt(filterConfig.bedrooms)) &&
        (filterConfig.bathrooms === "all" ||
          Math.floor(property.bathrooms) === parseInt(filterConfig.bathrooms))
    );
  }, [sortedProperties, filterConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <Card
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        background: "white",
        borderRadius: "16px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        mb: 4,
      }}
    >
      <CardContent>
        <Box className="flex items-center justify-between mb-6">
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              fontWeight: 600,
              color: "text.primary",
            }}
          >
            <TrendingUp sx={{ color: "primary.main" }} />
            Comparative Market Analysis
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              label="Filter by Bedrooms"
              value={filterConfig.bedrooms}
              onChange={(e) =>
                setFilterConfig({ ...filterConfig, bedrooms: e.target.value })
              }
              sx={{ bgcolor: "background.paper", borderRadius: 1 }}
            >
              <MenuItem value="all">All Bedrooms</MenuItem>
              {[2, 3, 4, 5].map((num) => (
                <MenuItem key={num} value={num}>
                  {num} Bedrooms
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              label="Filter by Bathrooms"
              value={filterConfig.bathrooms}
              onChange={(e) =>
                setFilterConfig({ ...filterConfig, bathrooms: e.target.value })
              }
              sx={{ bgcolor: "background.paper", borderRadius: 1 }}
            >
              <MenuItem value="all">All Bathrooms</MenuItem>
              {[1, 2, 3, 4].map((num) => (
                <MenuItem key={num} value={num}>
                  {num} Bathrooms
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          sx={{
            borderRadius: 2,
            boxShadow: "none",
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <Table>
            <TableHead sx={{ bgcolor: "grey.50" }}>
              <TableRow>
                {[
                  { key: "address", label: "Address" },
                  { key: "salePrice", label: "Sale Price" },
                  { key: "squareFeet", label: "Sq Ft" },
                  { key: "pricePerSqFt", label: "Price/Sq Ft" },
                  { key: "bedrooms", label: "Beds" },
                  { key: "bathrooms", label: "Baths" },
                  { key: "saleDate", label: "Sale Date" },
                ].map((column) => (
                  <TableCell
                    key={column.key}
                    onClick={() => requestSort(column.key)}
                    sx={{
                      cursor: "pointer",
                      fontWeight: 600,
                      "&:hover": { bgcolor: "grey.100" },
                      transition: "background-color 0.2s",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      {column.label}
                      {sortConfig.key === column.key && (
                        <Sort
                          sx={{
                            color: "primary.main",
                            transform:
                              sortConfig.direction === "descending"
                                ? "rotate(180deg)"
                                : "none",
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProperties.map((property, index) => (
                <TableRow
                  key={property.id}
                  sx={{
                    bgcolor: index % 2 === 0 ? "background.paper" : "grey.50",
                    "&:hover": { bgcolor: "action.hover" },
                    transition: "background-color 0.2s",
                  }}
                >
                  <TableCell>{property.address}</TableCell>
                  <TableCell>${property.salePrice.toLocaleString()}</TableCell>
                  <TableCell>{property.squareFeet.toLocaleString()}</TableCell>
                  <TableCell>
                    $
                    {calculatePricePerSqFt(
                      property.salePrice,
                      property.squareFeet
                    )}
                  </TableCell>
                  <TableCell>{property.bedrooms}</TableCell>
                  <TableCell>{property.bathrooms}</TableCell>
                  <TableCell>{property.saleDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {filteredProperties.length === 0 && (
          <Box
            sx={{
              textAlign: "center",
              py: 4,
              color: "text.secondary",
            }}
          >
            No properties match the selected filters
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

ComparativeMarketAnalysis.propTypes = {
  propertyData: PropTypes.shape({
    address: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        full: PropTypes.string,
        street: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        place: PropTypes.string,
      }),
    ]),
    financialData: PropTypes.object,
    marketTrends: PropTypes.array,
  }),
};

export default ComparativeMarketAnalysis;
