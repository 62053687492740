import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const HeroSection = () => {
  const [currentHashtag, setCurrentHashtag] = useState("#HomeLeafs");
  const hashtags = [
    "#Empower",
    "#Connect",
    "#Support",
    "#Innovate",
    "#HomeLeafs",
  ];
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHashtag((prev) => {
        const nextIndex = (hashtags.indexOf(prev) + 1) % hashtags.length;
        return hashtags[nextIndex];
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    // <div className="py-20 bg-gradient-to-r from-green-50 to-blue-50">
    <div className="py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <motion.h2
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            transition={{ duration: 0.5 }}
            className="text-base text-green-600 font-semibold tracking-wide uppercase"
          >
            Empowering Homeowners, Connecting Experts with Purpose
          </motion.h2>
          <motion.h1
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mt-2 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl"
          >
            Your Home, Our Network: Building Futures Together
          </motion.h1>
          <motion.p
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="max-w-xl mt-5 mx-auto text-xl text-gray-500"
          >
            Join a community where homeowners find innovative solutions and
            professionals make a meaningful impact. Together, we&apos;re
            redefining housing support.
          </motion.p>
          <motion.div
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="mt-8"
          >
            <a
              href="#"
              className="inline-block bg-green-500 text-white font-bold py-3 px-8 rounded-full hover:bg-green-600 transition duration-300"
            >
              Explore Your Options
            </a>
          </motion.div>
          <motion.div
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className="mt-6 text-green-500 font-semibold"
          >
            {currentHashtag}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
