import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DollarSign } from "lucide-react";

const BreakdownContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const ExpenseBox = styled(Box)({
  padding: "16px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  marginBottom: "16px",
  transition: "transform 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const ExpenseLabel = styled(Typography)({
  fontSize: "0.9rem",
  color: "#64748B",
  marginBottom: "8px",
  fontFamily: "'Lato', sans-serif",
});

const ExpenseValue = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: "600",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
  "& svg": {
    width: "48px",
    height: "48px",
    color: "#6366F1",
  },
});

const TotalExpenses = styled(Box)({
  marginTop: "auto",
  padding: "16px",
  backgroundColor: "#EEF2FF",
  borderRadius: "12px",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const ExpenseBreakdown = ({ expenses }) => {
  const totalExpenses = expenses.reduce((sum, expense) => sum + expense.value, 0);

  return (
    <BreakdownContainer elevation={0}>
      <IconWrapper>
        <DollarSign />
      </IconWrapper>
      <Title variant="h5">Expense Breakdown</Title>

      {expenses.map((expense, index) => (
        <ExpenseBox key={index}>
          <ExpenseLabel>{expense.name}</ExpenseLabel>
          <ExpenseValue>${expense.value.toLocaleString()}</ExpenseValue>
        </ExpenseBox>
      ))}

      <TotalExpenses>
        <ExpenseLabel>Total Monthly Expenses</ExpenseLabel>
        <ExpenseValue>${totalExpenses.toLocaleString()}</ExpenseValue>
      </TotalExpenses>
    </BreakdownContainer>
  );
};

ExpenseBreakdown.propTypes = {
  expenses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default ExpenseBreakdown;
