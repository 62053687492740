import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const AuthModal = ({ open, onClose, isLoggedIn }) => {
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 16,
          padding: 16,
          maxWidth: 400,
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          backdropFilter: "blur(4px)",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: "'Lato', sans-serif",
          fontSize: "1.5rem",
          fontWeight: 600,
          pb: 1,
        }}
      >
        {isLoggedIn ? "Upgrade Required" : "Sign In Required"}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            mb: 3,
            fontFamily: "'Lato', sans-serif",
          }}
        >
          {isLoggedIn
            ? "Upgrade your account to access premium features including detailed investment analysis, market comparisons, and more."
            : "Create an account or sign in to unlock full investment analysis capabilities."}
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              onClose();
              navigate(isLoggedIn ? "/pricing" : "/sign-in");
            }}
            sx={{
              borderRadius: 2,
              py: 1.5,
              textTransform: "none",
              fontFamily: "'Lato', sans-serif",
              fontWeight: 600,
            }}
          >
            {isLoggedIn ? "View Pricing" : "Sign In"}
          </Button>
          {!isLoggedIn && (
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                onClose();
                navigate("/sign-up");
              }}
              sx={{
                borderRadius: 2,
                py: 1.5,
                textTransform: "none",
                fontFamily: "'Lato', sans-serif",
                fontWeight: 600,
              }}
            >
              Create Account
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

AuthModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AuthModal;
