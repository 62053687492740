// ProfileCard.jsx
import React from "react";
import PropTypes from "prop-types";
import { MapPin, Mail, Calendar, Users } from "lucide-react";

const ProfileCard = ({ user }) => {
  if (!user) return null;

  // Safely extract email from user object
  const emailAddress = user.email?.address || user.email || "";

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      {/* Cover Image */}
      <div className="h-32 bg-gradient-to-r from-blue-500 to-purple-500">
        {user.coverPhoto && (
          <img
            src={user.coverPhoto}
            alt="Cover"
            className="w-full h-full object-cover"
          />
        )}
      </div>

      {/* Profile Info */}
      <div className="relative px-4 pb-4">
        <div className="absolute -top-12 left-4">
          <img
            src={user.avatar || "/assets/avatar.png"}
            alt={user.name}
            className="w-24 h-24 rounded-full border-4 border-white"
          />
        </div>

        <div className="pt-14">
          <h2 className="text-xl font-semibold">{user.name}</h2>
          <p className="text-gray-600">{user.role || "Member"}</p>

          <div className="mt-4 space-y-3">
            {user.location && (
              <div className="flex items-center text-gray-600">
                <MapPin className="w-4 h-4 mr-2" />
                <span>{user.location}</span>
              </div>
            )}
            {emailAddress && (
              <div className="flex items-center text-gray-600">
                <Mail className="w-4 h-4 mr-2" />
                <span>{emailAddress}</span>
              </div>
            )}
            {user.joinDate && (
              <div className="flex items-center text-gray-600">
                <Calendar className="w-4 h-4 mr-2" />
                <span>
                  Joined {new Date(user.joinDate).toLocaleDateString()}
                </span>
              </div>
            )}
            <div className="flex items-center text-gray-600">
              <Users className="w-4 h-4 mr-2" />
              <span>{user.followers?.length || 0} followers</span>
            </div>
          </div>

          {user.bio && <p className="mt-4 text-gray-700">{user.bio}</p>}
        </div>
      </div>
    </div>
  );
};

ProfileCard.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    avatar: PropTypes.string,
    coverPhoto: PropTypes.string,
    location: PropTypes.string,
    email: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        address: PropTypes.string,
      }),
    ]),
    joinDate: PropTypes.string,
    bio: PropTypes.string,
    followers: PropTypes.array,
  }),
};

export default ProfileCard;
