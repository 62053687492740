// QuickLinks.jsx
import React from "react";
import { Link } from "react-router-dom";

const QuickLinks = () => {
  const links = [
    { path: "/profile", label: "My Profile" },
    { path: "/messages", label: "Messages" },
    { path: "/saved", label: "Saved Posts" },
  ];

  return (
    <div className="bg-white rounded-xl shadow-sm p-4">
      <h2 className="font-semibold text-lg mb-3">Quick Links</h2>
      <nav className="space-y-2">
        {links.map((link) => (
          <Link
            key={link.path}
            to={link.path}
            className="block px-3 py-2 text-gray-700 hover:bg-gray-50 rounded-lg"
          >
            {link.label}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default QuickLinks;
