import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Typography,
  Paper,
  Chip,
  Grid,
  Button,
} from "@mui/material";
import {
  Favorite,
  FavoriteBorder,
  Share,
  Dashboard,
  NavigateNext,
  NavigateBefore,
} from "@mui/icons-material";
import { motion } from "framer-motion";

const ListingHero = ({
  listing,
  liked,
  inDashboard,
  onLike,
  onShare,
  onAddToDashboard,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? listing.imageUrls.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) =>
      prev === listing.imageUrls.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <Box sx={{ position: "relative", mb: 4 }}>
      {/* Main Image */}
      <Paper
        elevation={3}
        sx={{
          position: "relative",
          height: "600px",
          borderRadius: "16px",
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          src={listing.imageUrls[currentImageIndex]}
          alt={`Property ${currentImageIndex + 1}`}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />

        {/* Navigation Arrows */}
        <IconButton
          sx={{
            position: "absolute",
            left: 16,
            top: "50%",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9)" },
          }}
          onClick={handlePrevImage}
        >
          <NavigateBefore />
        </IconButton>
        <IconButton
          sx={{
            position: "absolute",
            right: 16,
            top: "50%",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9)" },
          }}
          onClick={handleNextImage}
        >
          <NavigateNext />
        </IconButton>

        {/* Thumbnail Navigation */}
        <Box
          sx={{
            position: "absolute",
            bottom: 16,
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            gap: 1,
            p: 1,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: 2,
          }}
        >
          {listing.imageUrls.map((_, index) => (
            <Box
              key={index}
              onClick={() => setCurrentImageIndex(index)}
              sx={{
                width: 40,
                height: 40,
                borderRadius: 1,
                backgroundImage: `url(${listing.imageUrls[index]})`,
                backgroundSize: "cover",
                cursor: "pointer",
                border:
                  index === currentImageIndex ? "2px solid #1976d2" : "none",
              }}
            />
          ))}
        </Box>

        {/* Action Buttons */}
        <Box
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            display: "flex",
            gap: 1,
          }}
        >
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <IconButton
              onClick={onLike}
              sx={{
                backgroundColor: "white",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9)" },
              }}
            >
              {liked ? <Favorite sx={{ color: "red" }} /> : <FavoriteBorder />}
            </IconButton>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <IconButton
              onClick={onShare}
              sx={{
                backgroundColor: "white",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9)" },
              }}
            >
              <Share />
            </IconButton>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <IconButton
              onClick={onAddToDashboard}
              sx={{
                backgroundColor: "white",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9)" },
              }}
            >
              <Dashboard color={inDashboard ? "primary" : "inherit"} />
            </IconButton>
          </motion.div>
        </Box>

        {/* Property Info Overlay */}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            background: "linear-gradient(transparent, rgba(0,0,0,0.8))",
            color: "white",
            p: 3,
          }}
        >
          <Typography
            variant="h4"
            sx={{ mb: 1, fontFamily: "Lato, sans-serif" }}
          >
            ${listing.price?.toLocaleString()}
          </Typography>
          <Typography
            variant="h6"
            sx={{ mb: 2, fontFamily: "Lato, sans-serif" }}
          >
            {listing.address}
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Chip
                label={`${listing.bedrooms} Beds`}
                sx={{
                  backgroundColor: "rgba(255,255,255,0.9)",
                  fontFamily: "Lato, sans-serif",
                }}
              />
            </Grid>
            <Grid item>
              <Chip
                label={`${listing.bathrooms} Baths`}
                sx={{
                  backgroundColor: "rgba(255,255,255,0.9)",
                  fontFamily: "Lato, sans-serif",
                }}
              />
            </Grid>
            <Grid item>
              <Chip
                label={listing.currentStatus}
                color="primary"
                sx={{ fontFamily: "Lato, sans-serif" }}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            fontFamily: "Lato, sans-serif",
            textTransform: "none",
            borderRadius: 2,
            backgroundColor: "rgba(255,255,255,0.9)",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "rgba(255,255,255,1)",
            },
          }}
        >
          View Details
        </Button>
      </Box>
    </Box>
  );
};

ListingHero.propTypes = {
  listing: PropTypes.shape({
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    price: PropTypes.number,
    address: PropTypes.string,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    currentStatus: PropTypes.string,
  }).isRequired,
  liked: PropTypes.bool.isRequired,
  inDashboard: PropTypes.bool.isRequired,
  onLike: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  onAddToDashboard: PropTypes.func.isRequired,
};

export default ListingHero;
