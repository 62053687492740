import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { Assessment, Lock } from "@mui/icons-material";
import PropertyDetails from "./PropertyDetails";
import FinancialDetails from "./FinancialDetails";
import AssistanceOptions from "./AssistanceOptions";
import ForeclosureInfo from "./ForeclosureInfo";
import CachedWalkScoreWidget from "./CachedWalkScoreWidget";
import PricingHistory from "./PricingHistory";

const ListingMainContent = ({ listing, onPremiumFeatureClick }) => {
  return (
    <>
      {/* Property Details Card */}
      <Card
        sx={{
          mb: 4,
          borderRadius: 4,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "'Lato', sans-serif",
              fontWeight: 600,
              mb: 3,
            }}
          >
            <Assessment sx={{ mr: 1, color: "primary.main" }} />
            Property Details
          </Typography>
          <PropertyDetails listing={listing} />
        </CardContent>
      </Card>

      {/* Financial Details Card */}
      <Card
        sx={{
          mb: 4,
          borderRadius: 4,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "'Lato', sans-serif",
              fontWeight: 600,
              mb: 3,
            }}
          >
            <Assessment sx={{ mr: 1, color: "primary.main" }} />
            Financial Overview
          </Typography>
          <FinancialDetails listing={listing} />
        </CardContent>
      </Card>

      {/* Assistance Options Card */}
      <Card
        sx={{
          mb: 4,
          borderRadius: 4,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "'Lato', sans-serif",
              fontWeight: 600,
              mb: 3,
            }}
          >
            <Assessment sx={{ mr: 1, color: "primary.main" }} />
            Assistance Programs
          </Typography>
          <AssistanceOptions listing={listing} />
          <Button
            variant="outlined"
            color="primary"
            onClick={onPremiumFeatureClick}
            startIcon={<Lock />}
            sx={{
              mt: 2,
              borderRadius: 2,
              textTransform: "none",
              fontFamily: "'Lato', sans-serif",
            }}
          >
            View All Available Programs
          </Button>
        </CardContent>
      </Card>

      {/* Foreclosure Information Card (if applicable) */}
      {listing.currentStatus === "Pre-Foreclosure" && (
        <Card
          sx={{
            mb: 4,
            borderRadius: 4,
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "translateY(-2px)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                fontFamily: "'Lato', sans-serif",
                fontWeight: 600,
                mb: 3,
              }}
            >
              <Assessment sx={{ mr: 1, color: "primary.main" }} />
              Foreclosure Details
            </Typography>
            <ForeclosureInfo listing={listing} />
            <Button
              variant="contained"
              color="primary"
              onClick={onPremiumFeatureClick}
              startIcon={<Lock />}
              sx={{
                mt: 2,
                borderRadius: 2,
                textTransform: "none",
                fontFamily: "'Lato', sans-serif",
              }}
            >
              Access Full Foreclosure Report
            </Button>
          </CardContent>
        </Card>
      )}

      {/* WalkScore Widget */}
      {listing && listing.latitude && listing.longitude && (
        <Card
          sx={{
            mb: 4,
            borderRadius: 4,
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "translateY(-2px)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                fontFamily: "'Lato', sans-serif",
                fontWeight: 600,
                mb: 3,
              }}
            >
              <Assessment sx={{ mr: 1, color: "primary.main" }} />
              Location Analysis
            </Typography>
            <CachedWalkScoreWidget
              address={listing.address}
              latitude={listing.latitude}
              longitude={listing.longitude}
              cachedData={listing.walkScoreData}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={onPremiumFeatureClick}
              startIcon={<Lock />}
              sx={{
                mt: 2,
                borderRadius: 2,
                textTransform: "none",
                fontFamily: "'Lato', sans-serif",
              }}
            >
              View Detailed Neighborhood Analysis
            </Button>
          </CardContent>
        </Card>
      )}

      {/* Price History Card */}
      <Card
        sx={{
          mb: 4,
          borderRadius: 4,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "'Lato', sans-serif",
              fontWeight: 600,
              mb: 3,
            }}
          >
            <Assessment sx={{ mr: 1, color: "primary.main" }} />
            Price History
          </Typography>
          <PricingHistory
            propertyId={listing._id || ""}
            address={listing.address || ""}
            priceHistory={listing.priceHistory || []}
          />
        </CardContent>
      </Card>
    </>
  );
};

ListingMainContent.propTypes = {
  listing: PropTypes.object.isRequired,
  onPremiumFeatureClick: PropTypes.func.isRequired,
};

export default ListingMainContent;
