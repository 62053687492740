import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Chip,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Bed, Bathtub } from "@mui/icons-material";

const SimilarListings = ({ listings }) => {
  if (!listings || listings.length === 0) {
    return null;
  }

  return (
    <Paper sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Similar Properties
      </Typography>
      <Grid container spacing={4}>
        {listings.map((listing) => (
          <Grid item xs={12} sm={6} md={3} key={listing.id}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                transition:
                  "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                },
              }}
            >
              <CardActionArea component={Link} to={`/listing/${listing.id}`}>
                <CardMedia
                  component="div"
                  sx={{
                    pt: "56.25%", // 16:9 aspect ratio
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={listing.imageUrls[0]}
                    alt={listing.name}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </CardMedia>
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      {listing.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2 }}
                    >
                      {listing.address}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      color="primary"
                      sx={{ mb: 2, fontWeight: "bold" }}
                    >
                      ${listing.price.toLocaleString()}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                      }}
                    >
                      <Chip
                        icon={<Bed />}
                        label={`${listing.bedrooms} Beds`}
                        size="small"
                      />
                      <Chip
                        icon={<Bathtub />}
                        label={`${listing.bathrooms} Baths`}
                        size="small"
                      />
                    </Box>
                    <Chip
                      label="For Sale"
                      color="primary"
                      sx={{ width: "100%" }}
                    />
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

SimilarListings.propTypes = {
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      bedrooms: PropTypes.number.isRequired,
      bathrooms: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
      imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
};

export default SimilarListings;
