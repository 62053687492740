import React, { useEffect, useState } from "react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "flowbite-react";
import EditableSection from "./EditableSection";
import FormField from "./FormField";

import { updateUser } from "../../../redux/user/userSlice";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const SOCIAL_PLATFORMS = {
  linkedin: {
    label: "LinkedIn",
    placeholder: "https://linkedin.com/in/username",
    pattern: /^https?:\/\/([\w]+\.)?linkedin\.com\/in\/[\w-]+\/?$/,
  },
  twitter: {
    label: "Twitter",
    placeholder: "https://twitter.com/username",
    pattern: /^https?:\/\/([\w]+\.)?twitter\.com\/[A-Za-z0-9_]{1,15}\/?$/,
  },
  facebook: {
    label: "Facebook",
    placeholder: "https://facebook.com/username",
    pattern: /^https?:\/\/([\w]+\.)?facebook\.com\/[A-Za-z0-9.]{1,}\/?$/,
  },
  instagram: {
    label: "Instagram",
    placeholder: "https://instagram.com/username",
    pattern: /^https?:\/\/([\w]+\.)?instagram\.com\/[A-Za-z0-9_.]{1,30}\/?$/,
  },
  youtube: {
    label: "YouTube",
    placeholder: "https://youtube.com/c/channelname",
    pattern:
      /^https?:\/\/([\w]+\.)?youtube\.com\/(c\/|channel\/)?[A-Za-z0-9-_]{1,}\/?$/,
  },
  website: {
    label: "Personal Website",
    placeholder: "https://yourwebsite.com",
    pattern: /^https?:\/\/[A-Za-z0-9-_.]+\.[A-Za-z]{2,}\/?.*$/,
  },
};

const SocialLinksSection = () => {
  const dispatch = useDispatch();
  const { currentUser, loading } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    socialLinks: Object.keys(SOCIAL_PLATFORMS).reduce(
      (acc, platform) => ({
        ...acc,
        [platform]: "",
      }),
      {}
    ),
    customLinks: [],
  });

  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (currentUser?.socialLinks) {
      setFormData({
        socialLinks: {
          ...Object.keys(SOCIAL_PLATFORMS).reduce(
            (acc, platform) => ({
              ...acc,
              [platform]: currentUser.socialLinks[platform] || "",
            }),
            {}
          ),
        },
        customLinks: currentUser.customLinks || [],
      });
    }
  }, [currentUser]);

  const validateUrl = (url, pattern) => {
    if (!url) return true; // Empty URLs are allowed
    return pattern ? pattern.test(url) : /^https?:\/\/.*$/.test(url);
  };

  const handleChange = (e, type = "social", index = null) => {
    const { name, value } = e.target;

    if (type === "social") {
      setFormData((prev) => ({
        ...prev,
        socialLinks: {
          ...prev.socialLinks,
          [name]: value,
        },
      }));

      // Validate URL
      const platform = SOCIAL_PLATFORMS[name];
      const error =
        value && !validateUrl(value, platform?.pattern)
          ? "Invalid URL format"
          : "";

      setErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    } else {
      // Handle custom link changes
      const newCustomLinks = [...formData.customLinks];
      newCustomLinks[index] = {
        ...newCustomLinks[index],
        [name]: value,
      };

      setFormData((prev) => ({
        ...prev,
        customLinks: newCustomLinks,
      }));

      // Validate custom URL
      if (name === "url") {
        const error = value && !validateUrl(value) ? "Invalid URL format" : "";
        setErrors((prev) => ({
          ...prev,
          [`customLink-${index}`]: error,
        }));
      }
    }
  };

  const addCustomLink = () => {
    setFormData((prev) => ({
      ...prev,
      customLinks: [...prev.customLinks, { label: "", url: "" }],
    }));
  };

  const removeCustomLink = (index) => {
    setFormData((prev) => ({
      ...prev,
      customLinks: prev.customLinks.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async () => {
    // Validate all URLs
    const newErrors = {};

    Object.entries(formData.socialLinks).forEach(([platform, url]) => {
      if (url && !validateUrl(url, SOCIAL_PLATFORMS[platform]?.pattern)) {
        newErrors[platform] = "Invalid URL format";
      }
    });

    formData.customLinks.forEach((link, index) => {
      if (link.url && !validateUrl(link.url)) {
        newErrors[`customLink-${index}`] = "Invalid URL format";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      notyf.error("Please correct the invalid URLs");
      return false;
    }

    setSaving(true);
    try {
      await dispatch(
        updateUser({
          userId: currentUser._id,
          userData: {
            socialLinks: formData.socialLinks,
            customLinks: formData.customLinks,
          },
        })
      ).unwrap();

      notyf.success("Social links updated successfully");
      return true;
    } catch (error) {
      console.error("Update error:", error);
      notyf.error(error.message || "Failed to update social links");
      return false;
    } finally {
      setSaving(false);
    }
  };

  return (
    <EditableSection title="Social Links" onSave={handleSubmit} saving={saving}>
      <div className="space-y-6">
        {/* Pre-defined social platforms */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.entries(SOCIAL_PLATFORMS).map(
            ([platform, { label, placeholder, pattern }]) => (
              <FormField
                key={platform}
                label={label}
                id={platform}
                name={platform}
                value={formData.socialLinks[platform]}
                onChange={(e) => handleChange(e, "social")}
                error={errors[platform]}
                placeholder={placeholder}
                helperText={`Enter your ${label} profile URL`}
              />
            )
          )}
        </div>

        {/* Custom links section */}
        <div className="mt-8">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">Custom Links</h3>
            <Button
              size="sm"
              onClick={addCustomLink}
              className="flex items-center"
            >
              <PlusIcon className="h-4 w-4 mr-1" />
              Add Custom Link
            </Button>
          </div>

          <div className="space-y-4">
            {formData.customLinks.map((link, index) => (
              <div key={index} className="flex space-x-4 items-start">
                <div className="flex-1">
                  <FormField
                    label="Link Label"
                    id={`custom-label-${index}`}
                    name="label"
                    value={link.label}
                    onChange={(e) => handleChange(e, "custom", index)}
                    placeholder="e.g., Portfolio, Blog"
                    required
                  />
                </div>
                <div className="flex-1">
                  <FormField
                    label="URL"
                    id={`custom-url-${index}`}
                    name="url"
                    value={link.url}
                    onChange={(e) => handleChange(e, "custom", index)}
                    error={errors[`customLink-${index}`]}
                    placeholder="https://"
                    required
                  />
                </div>
                <Button
                  color="failure"
                  size="sm"
                  className="mt-7"
                  onClick={() => removeCustomLink(index)}
                >
                  <XMarkIcon className="h-4 w-4" />
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </EditableSection>
  );
};

export default SocialLinksSection;
