import React, { useState, useEffect, useCallback } from "react";
import { UserIcon } from "@heroicons/react/24/solid";
import { FaSearch, FaBell } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Dropdown } from "flowbite-react";
import { checkNewMessages } from "../redux/user/userSlice";

import {
  updateUserOnlineStatus,
  addMessage,
  clearNewMessagesFlag,
  fetchUserData,
  signOutSuccess,
  setToken,
} from "../redux/user/userSlice.js";
import logoImage from "../assets/hl.png";
import styled from "styled-components";
import io from "socket.io-client";

const SOCKET_URL =
  import.meta.env.VITE_WEBSOCKET_URL || "http://localhost:3000";

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 25px;
  border: none;
  background-color: #f1f1f1;
  font-size: 1rem;
  color: #333;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }
`;

const SearchButton = styled.button`
  margin-left: -40px;
  border-radius: 25px;
  background-color: transparent;
  color: #333;
  border: none;
  cursor: pointer;
`;

const SignInButton = styled.li`
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #004494;
  }
`;

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.user.token);
  const currentUser = useSelector((state) => state.user.currentUser);

  const [searchTerm, setSearchTerm] = useState("");
  const [hasNewMessages, setHasNewMessages] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      console.log("Header: Stored token found:", storedToken);
      dispatch(setToken(storedToken)); // Set token in Redux state
    } else {
      console.log("Header: No stored token found");
    }
  }, [dispatch]);

  const refreshToken = useCallback(async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/auth/refresh-token`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("Header: Token refreshed successfully");
        dispatch(setToken(data.token));
        localStorage.setItem("authToken", data.token);
        return data.token;
      } else {
        throw new Error("Failed to refresh token");
      }
    } catch (error) {
      console.error("Header: Error refreshing token:", error);
      handleAuthError(error);
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          console.log("Header: Fetching user data with token:", token);
          await dispatch(fetchUserData());
        } else {
          console.log("Header: No token available, skipping user data fetch");
        }
      } catch (error) {
        console.error("Header: Error fetching user data:", error);
        if (error.message.includes("401") || error.message.includes("403")) {
          const newToken = await refreshToken();
          if (newToken) {
            await dispatch(fetchUserData());
          } else {
            handleAuthError(error);
          }
        } else {
          handleAuthError(error);
        }
      }
    };

    fetchData();
  }, [token, dispatch, refreshToken]);

  useEffect(() => {
    console.log("Header: Current user:", currentUser);
    console.log("Header: Current token:", token);
  }, [currentUser, token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("searchTerm", searchTerm);
    const searchQuery = urlParams.toString();
    navigate(`/search?${searchQuery}`);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchTermFromUrl = urlParams.get("searchTerm");
    if (searchTermFromUrl) {
      setSearchTerm(searchTermFromUrl);
    }
  }, []);

  useEffect(() => {
    if (currentUser && token) {
      const newSocket = io(SOCKET_URL, {
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        transports: ["websocket", "polling"],
        auth: { token },
        timeout: 20000,
      });

      setSocket(newSocket);

      return () => {
        newSocket.close();
      };
    }
  }, [currentUser, token]);

  useEffect(() => {
    if (socket && currentUser) {
      const connectSocket = () => {
        console.log("Attempting to connect socket...");

        socket.on("connect", () => {
          console.log("Socket connected successfully");
          socket.emit("userOnline", currentUser._id);
        });

        socket.on("connect_error", (error) => {
          console.error("Socket connection error:", error);
          // Handle reconnection logic here if needed
        });

        socket.on("disconnect", (reason) => {
          console.log("Socket disconnected:", reason);
        });

        socket.on("userStatusUpdate", ({ userId, status }) => {
          console.log(`User ${userId} status updated to ${status}`);
          dispatch(updateUserOnlineStatus({ userId, status }));
        });

        socket.on("newMessage", (message) => {
          console.log("New message received:", message);
          dispatch(addMessage({ chatId: message.conversationId, message }));
          setHasNewMessages(true);
          setHasNewNotifications(true);
        });

        socket.on("newNotification", (notification) => {
          console.log("New notification received:", notification);
          setHasNewNotifications(true);
        });

        if (!socket.connected) {
          socket.connect();
        }
      };

      connectSocket();

      return () => {
        console.log("Cleaning up socket connections...");
        socket.off("connect");
        socket.off("connect_error");
        socket.off("disconnect");
        socket.off("userStatusUpdate");
        socket.off("newMessage");
        socket.off("newNotification");
      };
    }
  }, [socket, currentUser, dispatch]);

  const checkNewMessagesHandler = useCallback(async () => {
    if (!currentUser || !currentUser._id || !token) {
      console.log("Skipping new message check: missing user data or token");
      return;
    }

    try {
      console.log("Checking for new messages");
      const result = await dispatch(checkNewMessages()).unwrap();
      if (result?.hasNewMessages) {
        setHasNewMessages(true);
      }
    } catch (error) {
      console.error("Error checking for new messages:", error);
      // Check if error is an object and has a status property
      if (error?.status === 401 || error?.status === 403) {
        try {
          const newToken = await refreshToken();
          if (newToken) {
            await dispatch(checkNewMessages()).unwrap();
          }
        } catch (refreshError) {
          handleAuthError(refreshError);
        }
      }
    }
  }, [currentUser, token, dispatch, refreshToken]);

  useEffect(() => {
    if (currentUser && token) {
      checkNewMessagesHandler();
      const intervalId = setInterval(checkNewMessagesHandler, 60000);
      return () => clearInterval(intervalId);
    }
  }, [currentUser, token, checkNewMessagesHandler]);

  const handleAuthError = (error) => {
    console.error("Authentication error:", error);
    // Check if error is an object and has a status property
    if (error?.status === 401 || error?.status === 403) {
      console.log("Unauthorized access detected, signing out");
      dispatch(signOutSuccess());
      localStorage.removeItem("authToken");
      navigate("/sign-in");
    }
  };

  const handleSignOut = () => {
    console.log("Signing out user");
    dispatch(signOutSuccess());
    localStorage.removeItem("authToken");
    navigate("/sign-in");
  };

  const handleSignIn = () => {
    console.log("Navigating to sign-in page");
    navigate("/sign-in");
  };

  const handleNotificationClick = () => {
    dispatch(clearNewMessagesFlag()); // Clear new messages flag
    setHasNewMessages(false); // Clear new messages state
    setHasNewNotifications(false);
    navigate("/notifications");
  };

  return (
    <header
      className="bg-gray-800 shadow-md text-white"
      style={{ fontFamily: "Lato, sans-serif", fontWeight: 300 }}
    >
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        <Link to="/">
          <div className="flex items-center space-x-2">
            <img src={logoImage} alt="Logo" className="h-15" />
          </div>
        </Link>
        <SearchContainer>
          <form onSubmit={handleSubmit} className="w-full">
            <SearchInput
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SearchButton type="submit">
              <FaSearch />
            </SearchButton>
          </form>
        </SearchContainer>
        <ul className="flex items-center space-x-6">
          <li>
            <Link to="/" className="hover:underline">
              Home
            </Link>
          </li>
          <li>
            <Link to="/listings" className="hover:underline">
              Listings
            </Link>
          </li>
          <li>
            <Link to="/group" className="hover:underline">
              Network
            </Link>
          </li>
          <li>
            <Link to="/resources" className="hover:underline">
              Resources
            </Link>
          </li>
          {currentUser ? (
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <div className="relative flex items-center">
                  <FaBell
                    className={`text-xl mr-4 cursor-pointer ${
                      hasNewNotifications ? "text-yellow-400" : ""
                    }`}
                    onClick={handleNotificationClick}
                  />
                  {hasNewNotifications && (
                    <span className="absolute top-0 right-0 h-3 w-3 bg-red-500 rounded-full"></span>
                  )}
                  <Avatar
                    alt="User settings"
                    img={currentUser?.avatar || "/default-avatar.png"}
                    rounded
                    className="cursor-pointer"
                    fallback={
                      <div className="bg-gray-200 rounded-full p-2">
                        <UserIcon className="h-6 w-6 text-gray-500" />
                      </div>
                    }
                  />
                </div>
              }
            >
              <Dropdown.Header>
                <span className="block text-sm">{currentUser.username}</span>
                <span className="block truncate text-sm font-medium">
                  {currentUser.email && currentUser.email.address}
                </span>
                <span className="block text-xs text-gray-500">
                  {currentUser.role}
                </span>
              </Dropdown.Header>
              <Link to={`/profile/${currentUser.username}`}>
                <Dropdown.Item>My Profile</Dropdown.Item>
              </Link>
              <Link to="/dashboard">
                <Dropdown.Item>Dashboard</Dropdown.Item>
              </Link>
              <Link to="/messages">
                <Dropdown.Item>
                  Messages{" "}
                  {hasNewMessages && (
                    <span className="inline-block h-2 w-2 bg-green-500 rounded-full ml-1"></span>
                  )}
                </Dropdown.Item>
              </Link>
              <Dropdown.Item>Your Network</Dropdown.Item>
              <Dropdown.Item>Find People/Service Providers</Dropdown.Item>
              <Link to="/create-listing">
                <Dropdown.Item>Create New Listing</Dropdown.Item>
              </Link>
              <Link to="/my-listings">
                <Dropdown.Item>Your Listings</Dropdown.Item>
              </Link>
              <Link to="/timeline">
                <Dropdown.Item>Activity Feed</Dropdown.Item>
              </Link>
              <Dropdown.Item>My Account</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
            </Dropdown>
          ) : (
            <SignInButton onClick={handleSignIn}>Sign In</SignInButton>
          )}
        </ul>
      </div>
    </header>
  );
}
