import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  HomeIcon,
  UserIcon,
  ListBulletIcon,
  UsersIcon,
  CalendarIcon,
  Cog6ToothIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  QuestionMarkCircleIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";

const menuItems = [
  { id: "personal", icon: UserIcon, label: "Personal Info", to: "#personal" },

  { id: "address", icon: HomeIcon, label: "Address", to: "#address" },
  { id: "social", icon: GlobeAltIcon, label: "Social Links", to: "#social" },
  { id: "stats", icon: ChartBarIcon, label: "Statistics", to: "#stats" },
  { id: "listings", icon: ListBulletIcon, label: "Listings", to: "#listings" },
  { id: "faqs", icon: QuestionMarkCircleIcon, label: "FAQs", to: "#faqs" },
  { id: "photos", icon: HomeIcon, label: "Photos", to: "#photos" },
  {
    id: "testimonials",
    icon: ChatBubbleLeftRightIcon,
    label: "Testimonials",
    to: "#testimonials",
  },
];

const ProfileSidebar = ({ activeSection, onSectionChange, className = "" }) => {
  const { currentUser } = useSelector((state) => state.user);
  const location = useLocation();

  return (
    <div
      className={`bg-white rounded-lg shadow-sm overflow-hidden ${className}`}
    >
      {/* User Info */}
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center space-x-3">
          <img
            src={currentUser?.avatar || "/default-avatar.png"}
            alt="Profile"
            className="h-10 w-10 rounded-full object-cover"
          />
          <div>
            <h3 className="font-medium text-gray-900">
              {currentUser?.username}
            </h3>
            <p className="text-sm text-gray-500">
              {currentUser?.email?.address}
            </p>
          </div>
        </div>
      </div>

      {/* Navigation */}
      <nav className="p-4">
        <div className="space-y-1">
          {menuItems.map((item) => {
            const isActive = activeSection === item.id;
            const Icon = item.icon;

            return (
              <a
                key={item.id}
                href={item.to}
                onClick={(e) => {
                  e.preventDefault();
                  onSectionChange(item.id);
                }}
                className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                  isActive
                    ? "bg-blue-50 text-blue-700"
                    : "text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                }`}
              >
                <Icon
                  className={`h-5 w-5 mr-3 ${
                    isActive ? "text-blue-700" : "text-gray-400"
                  }`}
                />
                {item.label}
              </a>
            );
          })}
        </div>
      </nav>

      {/* Progress Section */}
      <div className="p-4 border-t border-gray-200">
        <div className="mb-2 flex justify-between items-center">
          <span className="text-sm font-medium text-gray-700">
            Profile Completion
          </span>
          <span className="text-sm text-gray-500">4/8</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-blue-600 h-2 rounded-full transition-all duration-300"
            style={{ width: "50%" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

ProfileSidebar.propTypes = {
  activeSection: PropTypes.string.isRequired,
  onSectionChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ProfileSidebar;
