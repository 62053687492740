import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Home, GraduationCap, Car, TreePine, ShoppingBag, Heart } from "lucide-react";

const OverviewContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "24px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const MetricBox = styled(Box)({
  padding: "20px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  height: "100%",
  transition: "transform 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const MetricLabel = styled(Typography)({
  fontSize: "0.9rem",
  color: "#64748B",
  marginTop: "12px",
  fontFamily: "'Lato', sans-serif",
});

const MetricValue = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: "600",
  color: "#2C3E50",
  marginTop: "8px",
  fontFamily: "'Lato', sans-serif",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "8px",
  "& svg": {
    width: "32px",
    height: "32px",
    color: "#6366F1",
  },
});

const NeighborhoodOverview = ({ communityData }) => {
  const metrics = [
    { icon: <Home />, label: "Housing Type", value: communityData?.housingType || "N/A" },
    { icon: <GraduationCap />, label: "School Rating", value: communityData?.schoolRating ? `${communityData.schoolRating}/10` : "N/A" },
    { icon: <Car />, label: "Transit Score", value: communityData?.transitScore ? `${communityData.transitScore}/100` : "N/A" },
    { icon: <TreePine />, label: "Parks Nearby", value: communityData?.parksCount || "N/A" },
    { icon: <ShoppingBag />, label: "Shopping Centers", value: communityData?.shoppingCenters || "N/A" },
    { icon: <Heart />, label: "Quality of Life", value: communityData?.qualityOfLife ? `${communityData.qualityOfLife}/10` : "N/A" },
  ];

  return (
    <OverviewContainer elevation={0}>
      <Title variant="h5">Neighborhood Overview</Title>
      <Grid container spacing={3}>
        {metrics.map((metric, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <MetricBox>
              <IconWrapper>{metric.icon}</IconWrapper>
              <MetricLabel>{metric.label}</MetricLabel>
              <MetricValue>{metric.value}</MetricValue>
            </MetricBox>
          </Grid>
        ))}
      </Grid>
    </OverviewContainer>
  );
};

NeighborhoodOverview.propTypes = {
  communityData: PropTypes.shape({
    housingType: PropTypes.string,
    schoolRating: PropTypes.number,
    transitScore: PropTypes.number,
    parksCount: PropTypes.number,
    shoppingCenters: PropTypes.number,
    qualityOfLife: PropTypes.number,
  }),
};

export default NeighborhoodOverview;
