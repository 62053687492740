import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormField from "./FormField";
import { updateUser } from "../../../redux/user/userSlice";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const ROLES = [
  "homeowner",
  "realEstateAgent",
  "realEstateBroker",
  "realEstateInvestor",
  "loanOfficer",
  "realEstateAttorney",
  "buyer",
  "other",
];

const PersonalInfoSection = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    company: "",
    role: "",
    about: "",
  });

  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [passwordLoading, setPasswordLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setFormData({
        username: currentUser.username || "",
        email: currentUser.email?.address || "",
        firstName: currentUser.firstName || "",
        lastName: currentUser.lastName || "",
        phone: currentUser.phone || "",
        company: currentUser.company || "",
        role: currentUser.role || "",
        about: currentUser.about || "",
      });
    }
  }, [currentUser]);

  const validateField = useCallback((name, value) => {
    switch (name) {
      case "username":
        return !value.trim()
          ? "Username is required"
          : value.length < 3
          ? "Username must be at least 3 characters"
          : "";
      case "email":
        return !value.trim()
          ? "Email is required"
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? "Invalid email format"
          : "";
      case "firstName":
        return !value.trim() ? "First name is required" : "";
      case "lastName":
        return !value.trim() ? "Last name is required" : "";
      case "phone":
        return value && !/^\+?[\d\s-()]{10,}$/.test(value)
          ? "Invalid phone format"
          : "";
      case "role":
        return value && !ROLES.includes(value) ? "Invalid role selected" : "";
      default:
        return "";
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    const error = validateField(name, value);
    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handlePasswordChange = async () => {
    if (!passwordData.currentPassword || !passwordData.newPassword) {
      notyf.error("Please fill in both password fields");
      return;
    }

    if (passwordData.newPassword.length < 8) {
      notyf.error("New password must be at least 8 characters");
      return;
    }

    setPasswordLoading(true);
    try {
      const response = await dispatch(
        updateUser({
          userId: currentUser._id,
          userData: {
            currentPassword: passwordData.currentPassword,
            newPassword: passwordData.newPassword,
          },
        })
      ).unwrap();

      if (response) {
        notyf.success("Password updated successfully");
        setPasswordData({ currentPassword: "", newPassword: "" });
      }
    } catch (error) {
      notyf.error(error.message || "Failed to update password");
    } finally {
      setPasswordLoading(false);
    }
  };

  const validateForm = useCallback(() => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, validateField]);

  const handleSubmit = async () => {
    if (!validateForm()) {
      notyf.error("Please correct the errors in the form");
      return;
    }

    setSaving(true);
    try {
      const updateData = {
        ...formData,
        email: {
          address: formData.email,
          isVerified: currentUser.email?.isVerified,
        },
      };

      await dispatch(
        updateUser({
          userId: currentUser._id,
          userData: updateData,
        })
      ).unwrap();

      notyf.success("Profile updated successfully");
      setIsEditing(false);
    } catch (error) {
      console.error("Update error:", error);
      notyf.error(error.message || "Failed to update profile");
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormData({
      username: currentUser.username || "",
      email: currentUser.email?.address || "",
      firstName: currentUser.firstName || "",
      lastName: currentUser.lastName || "",
      phone: currentUser.phone || "",
      company: currentUser.company || "",
      role: currentUser.role || "",
      about: currentUser.about || "",
    });
    setErrors({});
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Personal Information</h2>
        <div className="space-x-2">
          {!isEditing ? (
            <button
              className="inline-flex items-center px-4 py-2 border border-blue-500 text-blue-500 rounded-md hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => setIsEditing(true)}
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
              Edit
            </button>
          ) : (
            <>
              <button
                className={`inline-flex items-center px-4 py-2 border border-green-500 text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
                  saving ? "opacity-75 cursor-not-allowed" : ""
                }`}
                onClick={handleSubmit}
                disabled={saving}
              >
                {saving ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Saving...
                  </>
                ) : (
                  <>
                    <svg
                      className="w-4 h-4 mr-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    Save
                  </>
                )}
              </button>
              <button
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                onClick={handleCancel}
                disabled={saving}
              >
                <svg
                  className="w-4 h-4 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                Cancel
              </button>
            </>
          )}
        </div>
      </div>

      <div className="border-t border-gray-200 pt-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormField
            label="Username"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            error={errors.username}
            required
            placeholder="Enter username"
            disabled={!isEditing}
            helperText="This is your unique username for your profile"
          />

          <FormField
            label="Email"
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
            required
            placeholder="Enter email address"
            disabled={!isEditing}
            helperText={
              currentUser?.email?.isVerified
                ? "Email verified"
                : "Please verify your email"
            }
          />

          <FormField
            label="First Name"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            error={errors.firstName}
            required
            placeholder="Enter first name"
            disabled={!isEditing}
          />

          <FormField
            label="Last Name"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            error={errors.lastName}
            required
            placeholder="Enter last name"
            disabled={!isEditing}
          />

          <FormField
            label="Phone Number"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            error={errors.phone}
            placeholder="Enter phone number"
            disabled={!isEditing}
            helperText="Format: +1 (555) 555-5555"
          />

          <FormField
            label="Company"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            error={errors.company}
            placeholder="Enter company name"
            disabled={!isEditing}
          />

          <div className="md:col-span-2">
            <label
              htmlFor="role"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Role
            </label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md ${
                errors.role ? "border-red-500" : ""
              }`}
              disabled={!isEditing}
            >
              <option value="">Select a role</option>
              {ROLES.map((role) => (
                <option key={role} value={role}>
                  {role.charAt(0).toUpperCase() + role.slice(1)}
                </option>
              ))}
            </select>
            {errors.role && (
              <p className="mt-1 text-sm text-red-600">{errors.role}</p>
            )}
          </div>

          <div className="md:col-span-2">
            <FormField
              label="About"
              id="about"
              name="about"
              value={formData.about}
              onChange={handleChange}
              error={errors.about}
              multiline
              rows={4}
              placeholder="Tell us about yourself"
              helperText="Share your experience and expertise"
              maxLength={2500}
              disabled={!isEditing}
            />
          </div>

          {currentUser?.email?.isVerified === false && (
            <div className="md:col-span-2">
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.485 2.495c.873-1.512 3.157-1.512 4.03 0l6.28 10.875c.873 1.512-.218 3.375-2.015 3.375H4.22c-1.797 0-2.888-1.863-2.015-3.375L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      Your email is not verified. Please check your inbox for a
                      verification email or
                      <button
                        type="button"
                        className="ml-1 font-medium text-yellow-700 underline hover:text-yellow-600"
                        onClick={() => {
                          notyf.success("Verification email sent");
                        }}
                      >
                        click here to resend
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="md:col-span-2 mt-6 pt-6 border-t border-gray-200">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Change Password
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                label="Current Password"
                id="currentPassword"
                name="currentPassword"
                type="password"
                value={passwordData.currentPassword}
                onChange={(e) =>
                  setPasswordData((prev) => ({
                    ...prev,
                    currentPassword: e.target.value,
                  }))
                }
                disabled={!isEditing}
                placeholder="Enter current password"
              />

              <FormField
                label="New Password"
                id="newPassword"
                name="newPassword"
                type="password"
                value={passwordData.newPassword}
                onChange={(e) =>
                  setPasswordData((prev) => ({
                    ...prev,
                    newPassword: e.target.value,
                  }))
                }
                disabled={!isEditing}
                placeholder="Enter new password"
                helperText="Minimum 8 characters"
              />
            </div>
            <button
              className={`mt-4 inline-flex items-center px-4 py-2 border border-blue-500 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                !isEditing ||
                !passwordData.currentPassword ||
                !passwordData.newPassword
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={handlePasswordChange}
              disabled={
                !isEditing ||
                passwordLoading ||
                !passwordData.currentPassword ||
                !passwordData.newPassword
              }
            >
              {passwordLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Updating Password...
                </>
              ) : (
                "Update Password"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoSection;
