import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const DemographicDisplay = ({ demographics, economicData, housingStats }) => {
  if (!demographics || !economicData || !housingStats) return null;

  const educationData = [
    { name: "Bachelor's", value: demographics.education.bachelors },
    { name: 'Graduate', value: demographics.education.graduate }
  ];

  const incomeData = Object.entries(economicData.medianIncome).map(([range, percentage]) => ({
    range,
    percentage
  }));

  const housingData = [
    { name: 'Owner Occupied', value: housingStats.ownershipRate },
    { name: 'Renter Occupied', value: 100 - housingStats.ownershipRate }
  ];

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 font-lato">
      <h2 className="text-2xl font-semibold mb-6">Demographic Analysis</h2>

      {/* Key Statistics */}
      <div className="grid grid-cols-3 gap-4 mb-8">
        <StatCard
          title="Population"
          value={demographics.population.toLocaleString()}
          icon="👥"
          color="bg-blue-500"
        />
        <StatCard
          title="Median Age"
          value={demographics.medianAge}
          icon="📊"
          color="bg-green-500"
        />
        <StatCard
          title="Median Income"
          value={`$${economicData.medianIncome.toLocaleString()}`}
          icon="💰"
          color="bg-purple-500"
        />
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Education Chart */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-50 rounded-lg p-4"
        >
          <h3 className="text-lg font-semibold mb-4">Education Level</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={educationData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {educationData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </motion.div>

        {/* Income Distribution */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-50 rounded-lg p-4"
        >
          <h3 className="text-lg font-semibold mb-4">Income Distribution</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={incomeData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="range" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="percentage" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </motion.div>

        {/* Housing Statistics */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-50 rounded-lg p-4"
        >
          <h3 className="text-lg font-semibold mb-4">Housing Overview</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-600">Median Home Value</p>
              <p className="text-xl font-semibold">
                ${housingStats.medianHomeValue.toLocaleString()}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Median Rent</p>
              <p className="text-xl font-semibold">
                ${housingStats.medianRent.toLocaleString()}
              </p>
            </div>
          </div>
          <div className="h-48 mt-4">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={housingData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={60}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {housingData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </motion.div>

        {/* Economic Indicators */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-50 rounded-lg p-4"
        >
          <h3 className="text-lg font-semibold mb-4">Economic Indicators</h3>
          <div className="space-y-4">
            <div>
              <p className="text-sm text-gray-600">Employment Rate</p>
              <div className="relative pt-1">
                <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${economicData.employmentRate}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                  />
                </div>
                <span className="text-sm font-semibold">
                  {economicData.employmentRate}%
                </span>
              </div>
            </div>
            <div>
              <p className="text-sm text-gray-600">Poverty Rate</p>
              <div className="relative pt-1">
                <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${economicData.povertyRate}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                  />
                </div>
                <span className="text-sm font-semibold">
                  {economicData.povertyRate}%
                </span>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const StatCard = ({ title, value, icon, color }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    className="bg-white rounded-lg p-4 shadow-md"
  >
    <div className="flex items-center justify-between">
      <span className="text-2xl">{icon}</span>
      <span className={`${color} text-white rounded-full px-3 py-1 text-sm`}>
        {title}
      </span>
    </div>
    <p className="text-2xl font-bold mt-2">{value}</p>
  </motion.div>
);

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
  const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${name} ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

DemographicDisplay.propTypes = {
  demographics: PropTypes.shape({
    population: PropTypes.number.isRequired,
    medianAge: PropTypes.number.isRequired,
    education: PropTypes.shape({
      bachelors: PropTypes.number.isRequired,
      graduate: PropTypes.number.isRequired
    }).isRequired
  }),
  economicData: PropTypes.shape({
    medianIncome: PropTypes.number.isRequired,
    employmentRate: PropTypes.number.isRequired,
    povertyRate: PropTypes.number.isRequired
  }),
  housingStats: PropTypes.shape({
    medianHomeValue: PropTypes.number.isRequired,
    medianRent: PropTypes.number.isRequired,
    ownershipRate: PropTypes.number.isRequired
  })
};

StatCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default DemographicDisplay;
