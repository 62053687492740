import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Shield } from "lucide-react";

const AssessmentContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const RiskBox = styled(Box)({
  padding: "16px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  marginBottom: "16px",
  transition: "transform 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const RiskLabel = styled(Typography)({
  fontSize: "0.9rem",
  color: "#64748B",
  marginBottom: "8px",
  fontFamily: "'Lato', sans-serif",
});

const RiskValue = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: "600",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
  "& svg": {
    width: "48px",
    height: "48px",
    color: "#6366F1",
  },
});

const RiskAssessment = ({ propertyData, marketData, communityData }) => {
  return (
    <AssessmentContainer elevation={0}>
      <IconWrapper>
        <Shield />
      </IconWrapper>
      <Title variant="h5">Risk Assessment</Title>

      <RiskBox>
        <RiskLabel>Overall Risk Score</RiskLabel>
        <RiskValue>
          {propertyData?.riskScore 
            ? `${propertyData.riskScore}/100` 
            : "N/A"}
        </RiskValue>
      </RiskBox>

      <RiskBox>
        <RiskLabel>Market Volatility</RiskLabel>
        <RiskValue>
          {marketData?.volatility 
            ? marketData.volatility 
            : "N/A"}
        </RiskValue>
      </RiskBox>

      <RiskBox>
        <RiskLabel>Property Condition Risk</RiskLabel>
        <RiskValue>
          {propertyData?.conditionRisk 
            ? propertyData.conditionRisk 
            : "N/A"}
        </RiskValue>
      </RiskBox>

      <RiskBox>
        <RiskLabel>Neighborhood Stability</RiskLabel>
        <RiskValue>
          {communityData?.stability 
            ? `${communityData.stability}/10` 
            : "N/A"}
        </RiskValue>
      </RiskBox>
    </AssessmentContainer>
  );
};

RiskAssessment.propTypes = {
  propertyData: PropTypes.shape({
    riskScore: PropTypes.number,
    conditionRisk: PropTypes.string,
  }),
  marketData: PropTypes.shape({
    volatility: PropTypes.string,
  }),
  communityData: PropTypes.shape({
    stability: PropTypes.number,
  }),
};

export default RiskAssessment;
