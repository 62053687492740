import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Chip,
} from "@mui/material";
import {
  Home,
  KingBed,
  Bathtub,
  Garage,
  CalendarToday,
  SquareFoot,
  LocationOn,
  School,
  Park,
} from "@mui/icons-material";

const PropertyDetails = ({ listing }) => {
  const features = [
    {
      icon: <KingBed />,
      label: "Bedrooms",
      value: listing.bedrooms,
    },
    {
      icon: <Bathtub />,
      label: "Bathrooms",
      value: listing.bathrooms,
    },
    {
      icon: <Garage />,
      label: "Garage",
      value: `${listing.garage || 0} Car`,
    },
    {
      icon: <SquareFoot />,
      label: "Square Feet",
      value: listing.squareFeet?.toLocaleString(),
    },
    {
      icon: <CalendarToday />,
      label: "Year Built",
      value: listing.yearBuilt || "N/A",
    },
  ];

  return (
    <Card sx={{ mb: 4, borderRadius: 2 }}>
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontFamily: "Lato, sans-serif",
            fontWeight: 600,
            color: "primary.main",
          }}
        >
          <Home /> Property Overview
        </Typography>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={6} sm={4} key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "primary.light",
                    borderRadius: "50%",
                    p: 1,
                    mb: 1,
                    color: "primary.main",
                  }}
                >
                  {feature.icon}
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontFamily: "Lato, sans-serif" }}
                >
                  {feature.label}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Lato, sans-serif", fontWeight: 600 }}
                >
                  {feature.value}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontFamily: "Lato, sans-serif", fontWeight: 600 }}
          >
            Location Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <LocationOn color="primary" />
                <Typography sx={{ fontFamily: "Lato, sans-serif" }}>
                  {listing.address}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Chip
                icon={<School />}
                label="School District"
                sx={{ fontFamily: "Lato, sans-serif" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Chip
                icon={<Park />}
                label="Parks Nearby"
                sx={{ fontFamily: "Lato, sans-serif" }}
              />
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontFamily: "Lato, sans-serif", fontWeight: 600 }}
          >
            Property Description
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Lato, sans-serif",
              color: "text.secondary",
              lineHeight: 1.7,
            }}
          >
            {listing.description}
          </Typography>
        </Box>

        {listing.amenities && listing.amenities.length > 0 && (
          <>
            <Divider sx={{ my: 3 }} />
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontFamily: "Lato, sans-serif", fontWeight: 600 }}
              >
                Amenities
              </Typography>
              <Grid container spacing={1}>
                {listing.amenities.map((amenity, index) => (
                  <Grid item xs={6} sm={4} key={index}>
                    <Chip
                      label={amenity}
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        width: "100%",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

PropertyDetails.propTypes = {
  listing: PropTypes.shape({
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    garage: PropTypes.number,
    squareFeet: PropTypes.number,
    yearBuilt: PropTypes.number,
    address: PropTypes.string,
    description: PropTypes.string,
    amenities: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default PropertyDetails;
