import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from 'prop-types';
import {
  Bell,
  Home,
  Users,
  TrendingUp,
  AlertTriangle,
  Eye,
  Share2,
  BookmarkPlus,
} from "lucide-react";

// Demo notifications data with profile images from randomuser.me
const demoNotifications = [
  {
    _id: "1",
    type: "unread",
    title: "New Property View",
    message: "Your property listing '123 Main Street' has received 5 new views in the last hour.",
    read: false,
    createdAt: new Date(Date.now() - 1000 * 60 * 30).toISOString(),
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    user: "John Smith"
  },
  {
    _id: "2",
    type: "property",
    title: "Price Reduction Alert",
    message: "A property in your watchlist (456 Oak Avenue) has reduced its price by $25,000.",
    read: false,
    createdAt: new Date(Date.now() - 1000 * 60 * 60 * 2).toISOString(),
    image: "https://randomuser.me/api/portraits/women/2.jpg",
    user: "Emma Wilson"
  },
  {
    _id: "3",
    type: "market",
    title: "Market Update",
    message: "New market analysis shows a 5% increase in property values in your area. Click to view the detailed report.",
    read: false,
    createdAt: new Date(Date.now() - 1000 * 60 * 60 * 4).toISOString(),
    image: "https://randomuser.me/api/portraits/men/3.jpg",
    user: "Michael Brown"
  },
  {
    _id: "4",
    type: "network",
    title: "New Connection Request",
    message: "Sarah Johnson, a Real Estate Agent in your area, wants to connect with you.",
    read: false,
    createdAt: new Date(Date.now() - 1000 * 60 * 60 * 8).toISOString(),
    image: "https://randomuser.me/api/portraits/women/4.jpg",
    user: "Sarah Johnson"
  },
  {
    _id: "5",
    type: "property",
    title: "New Property Match",
    message: "A new property matching your search criteria has been listed: 789 Pine Street.",
    read: true,
    createdAt: new Date(Date.now() - 1000 * 60 * 60 * 24).toISOString(),
    image: "https://randomuser.me/api/portraits/men/5.jpg",
    user: "David Lee"
  },
  {
    _id: "6",
    type: "market",
    title: "Interest Rate Update",
    message: "Federal Reserve announces new interest rates. Impact analysis on real estate market available.",
    read: true,
    createdAt: new Date(Date.now() - 1000 * 60 * 60 * 48).toISOString(),
    image: "https://randomuser.me/api/portraits/women/6.jpg",
    user: "Rachel Green"
  }
];

// Notice Type Icons & Styles
const noticeTypes = {
  unread: {
    icon: Eye,
    bgColor: "bg-purple-50",
    textColor: "text-purple-600",
    borderColor: "border-purple-200",
  },
  property: {
    icon: Home,
    bgColor: "bg-green-50",
    textColor: "text-green-600",
    borderColor: "border-green-200",
  },
  network: {
    icon: Users,
    bgColor: "bg-blue-50",
    textColor: "text-blue-600",
    borderColor: "border-blue-200",
  },
  market: {
    icon: TrendingUp,
    bgColor: "bg-yellow-50",
    textColor: "text-yellow-600",
    borderColor: "border-yellow-200",
  },
  alert: {
    icon: AlertTriangle,
    bgColor: "bg-red-50",
    textColor: "text-red-600",
    borderColor: "border-red-200",
  },
};

// Quick Action Templates
const quickActions = {
  property: [
    { label: "View Listing", icon: Home },
    { label: "Save", icon: BookmarkPlus },
    { label: "Share", icon: Share2 },
  ],
  market: [
    { label: "View Analysis", icon: TrendingUp },
    { label: "Save Report", icon: BookmarkPlus },
    { label: "Share", icon: Share2 },
  ],
  network: [
    { label: "View Profile", icon: Users },
    { label: "Accept", icon: BookmarkPlus },
    { label: "Share", icon: Share2 },
  ],
  unread: [
    { label: "View Details", icon: Eye },
    { label: "Mark as Read", icon: BookmarkPlus },
    { label: "Share", icon: Share2 },
  ],
};

const NotificationItem = ({ notification, onMarkAsRead }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showQuickActions, setShowQuickActions] = useState(false);

  const noticeType = noticeTypes[notification.type] || noticeTypes.alert;

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={`relative ${
        notification.read ? "bg-gray-50" : "bg-white"
      } border rounded-lg mb-3 transition-all duration-200 hover:shadow-md`}
    >
      <div className="p-4">
        <div className="flex items-start gap-4">
          <div className="flex-shrink-0">
            <img
              src={notification.image || `https://randomuser.me/api/portraits/men/${Math.floor(Math.random() * 100)}.jpg`}
              alt={notification.user || "User"}
              className="w-12 h-12 rounded-full object-cover"
            />
          </div>

          <div className="flex-1">
            <div className="flex justify-between items-start">
              <div>
                <h4 className="font-semibold text-gray-900">
                  {notification.title}
                </h4>
                <p className="text-sm text-gray-600 mb-1">{notification.user || "User"}</p>
                <p
                  className={`text-sm ${
                    isExpanded ? "text-gray-700" : "text-gray-600 line-clamp-2"
                  }`}
                >
                  {notification.message}
                </p>
                {notification.message.length > 120 && (
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="text-blue-500 text-sm mt-1 hover:underline"
                  >
                    {isExpanded ? "Show less" : "Show more"}
                  </button>
                )}
              </div>
              <div className="flex flex-col items-end">
                <span className="text-xs text-gray-500">
                  {format(new Date(notification.createdAt), "PPpp")}
                </span>
                {!notification.read && (
                  <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2"></span>
                )}
              </div>
            </div>

            {/* Quick Actions */}
            <AnimatePresence>
              {showQuickActions && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  className="mt-3 flex gap-2"
                >
                  {quickActions[notification.type]?.map((action, index) => (
                    <motion.button
                      key={action.label}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      onClick={() => {
                        if (action.label === "Mark as Read") {
                          onMarkAsRead(notification._id);
                        }
                      }}
                      className={`flex items-center gap-1 px-3 py-1 rounded-full text-sm
                        ${noticeType.bgColor} ${noticeType.textColor} 
                        hover:bg-opacity-80 transition-colors`}
                    >
                      <action.icon className="w-4 h-4" />
                      <span>{action.label}</span>
                    </motion.button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>

      {/* Interaction Bar */}
      <div className="border-t px-4 py-2 flex justify-between items-center bg-gray-50">
        <button
          onClick={() => setShowQuickActions(!showQuickActions)}
          className="text-sm text-gray-600 hover:text-gray-900"
        >
          {showQuickActions ? "Hide Actions" : "Show Actions"}
        </button>
        {!notification.read && (
          <button
            onClick={() => onMarkAsRead(notification._id)}
            className="text-sm text-blue-600 hover:text-blue-700"
          >
            Mark as Read
          </button>
        )}
      </div>
    </motion.div>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    read: PropTypes.bool.isRequired,
    createdAt: PropTypes.string.isRequired,
    image: PropTypes.string,
    user: PropTypes.string,
  }).isRequired,
  onMarkAsRead: PropTypes.func.isRequired,
};

const NotificationFilters = ({ activeFilter, onFilterChange }) => {
  return (
    <div className="flex gap-2 mb-4 overflow-x-auto pb-2">
      {[
        { key: "all", label: "All", icon: Bell },
        { key: "unread", label: "Unread", icon: Eye },
        { key: "property", label: "Properties", icon: Home },
        { key: "market", label: "Market Updates", icon: TrendingUp },
        { key: "network", label: "Network", icon: Users },
      ].map((filter) => (
        <button
          key={filter.key}
          onClick={() => onFilterChange(filter.key)}
          className={`flex items-center gap-2 px-4 py-2 rounded-full text-sm 
            ${
              activeFilter === filter.key
                ? "bg-blue-500 text-white"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            } transition-colors`}
        >
          <filter.icon className="w-4 h-4" />
          <span>{filter.label}</span>
        </button>
      ))}
    </div>
  );
};

NotificationFilters.propTypes = {
  activeFilter: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeFilter, setActiveFilter] = useState("all");
  const currentUser = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!currentUser?._id) return;

      try {
        setLoading(true);
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/api/notifications?userId=${
            currentUser._id
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch notifications. Status: ${response.status}`
          );
        }

        const data = await response.json();
        // Enhance notifications with demo images if they don't have images
        const enhancedData = data.map(notification => ({
          ...notification,
          image: notification.image || `https://randomuser.me/api/portraits/men/${Math.floor(Math.random() * 100)}.jpg`
        }));
        setNotifications(enhancedData);
      } catch (err) {
        console.error("Error fetching notifications:", err);
        setError("Unable to fetch notifications. Showing demo content.");
        setNotifications(demoNotifications);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [currentUser, token]);

  const handleMarkAsRead = async (notificationId) => {
    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_BACKEND_URL
        }/api/notifications/${notificationId}/read`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to mark notification as read. Status: ${response.status}`
        );
      }

      setNotifications(
        notifications.map((notif) =>
          notif._id === notificationId ? { ...notif, read: true } : notif
        )
      );
    } catch (err) {
      console.error("Error marking notification as read:", err);
      setError("Failed to mark notification as read. Please try again.");
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/notifications/mark-all-read`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: currentUser._id }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to mark all notifications as read. Status: ${response.status}`
        );
      }

      setNotifications(
        notifications.map((notif) => ({ ...notif, read: true }))
      );
    } catch (err) {
      console.error("Error marking all notifications as read:", err);
      setError("Failed to mark all notifications as read. Please try again.");
    }
  };

  const filteredNotifications = notifications.filter((notif) => {
    if (activeFilter === "all") return true;
    if (activeFilter === "unread") return !notif.read;
    return notif.type === activeFilter;
  });

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Notifications</h1>
          <p className="text-gray-600">
            {notifications.filter((n) => !n.read).length} unread notifications
          </p>
        </div>
        {notifications.some((notif) => !notif.read) && (
          <button
            onClick={handleMarkAllAsRead}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Mark All as Read
          </button>
        )}
      </div>

      {/* Error Display */}
      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          <div className="flex items-center gap-2">
            <AlertTriangle className="w-5 h-5" />
            <span>{error}</span>
          </div>
        </div>
      )}

      {/* Filters */}
      <NotificationFilters
        activeFilter={activeFilter}
        onFilterChange={setActiveFilter}
      />

      {/* Notifications List */}
      <AnimatePresence>
        {filteredNotifications.length === 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="text-center py-8"
          >
            <Bell className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">No notifications to display.</p>
          </motion.div>
        ) : (
          <motion.div layout className="space-y-4">
            {filteredNotifications.map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                onMarkAsRead={handleMarkAsRead}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Back to Home */}
      <div className="mt-8 text-center">
        <Link
          to="/"
          className="text-blue-500 hover:text-blue-600 transition-colors"
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default Notifications;
