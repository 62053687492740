// Define the base URL with fallbacks
const BASE_URL =
  import.meta.env.VITE_BACKEND_URL ||
  (import.meta.env.MODE === "production"
    ? "https://homeleafs.com"
    : "http://localhost:3000");

const API_URL = import.meta.env.VITE_API_URL || `${BASE_URL}/api`;

const getAuthToken = () => {
  return localStorage.getItem("authToken");
};

const handleResponse = async (response) => {
  try {
    const data = await response.json();

    if (!response.ok) {
      if (response.status === 401 && getAuthToken()) {
        console.error("Token exists but unauthorized. Response:", data);
        throw new Error("Token invalid");
      }
      throw new Error(data.message || `HTTP error ${response.status}`);
    }

    return data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

const api = {
  request: async (url, options = {}) => {
    try {
      const fullUrl = url.startsWith("/api")
        ? `${BASE_URL}${url}`
        : `${API_URL}${url.startsWith("/") ? url : `/${url}`}`;

      const token = getAuthToken();
      console.log(
        "Using token for request:",
        token ? "Token exists" : "No token"
      );

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Origin: window.location.origin,
        ...(token && { Authorization: `Bearer ${token}` }),
        ...options.headers,
      };

      console.log("Making API request to:", fullUrl);

      const response = await fetch(fullUrl, {
        ...options,
        headers,
        credentials: "include",
        mode: "cors",
      });

      console.log("API Response status:", response.status);
      console.log(
        "API Response headers:",
        Object.fromEntries(response.headers)
      );

      return await handleResponse(response);
    } catch (error) {
      console.error("API request failed:", error);

      if (error.message === "Token invalid") {
        console.log("Token validation failed, clearing token");
        localStorage.removeItem("authToken");
        window.location.href = "/sign-in";
        return;
      }

      throw error;
    }
  },

  // Rest of your api methods remain the same
  get: (url) => api.request(url),

  post: (url, data) =>
    api.request(url, {
      method: "POST",
      body: JSON.stringify(data),
    }),

  put: (url, data) =>
    api.request(url, {
      method: "PUT",
      body: JSON.stringify(data),
    }),

  delete: (url) => api.request(url, { method: "DELETE" }),

  // Add new method for handling file uploads
  upload: async (url, formData, onProgress = null) => {
    const token = getAuthToken();
    const fullUrl = url.startsWith("/api")
      ? `${BASE_URL}${url}`
      : `${API_URL}${url.startsWith("/") ? url : `/${url}`}`;

    try {
      const response = await fetch(fullUrl, {
        method: "POST",
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          Origin: window.location.origin,
        },
        body: formData,
        credentials: "include",
      });

      return await handleResponse(response);
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  },

  // Add method for checking auth status
  checkAuth: async () => {
    try {
      const token = getAuthToken();
      if (!token) return false;

      const response = await api.get("/api/auth/verify");
      return response.isValid;
    } catch (error) {
      console.error("Auth check failed:", error);
      return false;
    }
  },

  // Add method for refreshing token
  refreshToken: async () => {
    try {
      const response = await api.post("/api/auth/refresh-token");
      if (response.token) {
        localStorage.setItem("authToken", response.token);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Token refresh failed:", error);
      return false;
    }
  },

  // Add method for handling retry logic
  retryRequest: async (url, options = {}, retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await api.request(url, options);
      } catch (error) {
        if (error.message === "Token invalid" && i < retries - 1) {
          const refreshed = await api.refreshToken();
          if (refreshed) continue;
        }
        throw error;
      }
    }
  },

  // Add websocket connection method if needed
  connectWebSocket: () => {
    const wsUrl =
      import.meta.env.MODE === "production"
        ? "wss://homeleafs.com"
        : "ws://localhost:3000";

    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      console.log("WebSocket connected");
      const token = getAuthToken();
      if (token) {
        ws.send(JSON.stringify({ type: "auth", token }));
      }
    };

    return ws;
  },
};

// Add response interceptor
const originalRequest = api.request;
api.request = async (url, options = {}) => {
  try {
    return await originalRequest(url, options);
  } catch (error) {
    if (error.message === "Token invalid") {
      const refreshed = await api.refreshToken();
      if (refreshed) {
        // Retry the original request with new token
        return await originalRequest(url, options);
      }
    }
    throw error;
  }
};

export default api;
