import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import axios from "axios";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL || "http://localhost:3000";

// Persist configuration
const persistConfig = {
  key: "user",
  storage,
  whitelist: ["currentUser", "role", "token"],
};

// Thunks
export const login = createAsyncThunk(
  "user/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/auth/login`,
        credentials
      );
      localStorage.setItem("authToken", response.data.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserByUsername = createAsyncThunk(
  "user/fetchUserByUsername",
  async (username, { getState, rejectWithValue }) => {
    console.log("Fetching user data for username:", username);
    try {
      const { token } = getState().user;
      if (!token) {
        return rejectWithValue("No authentication token available");
      }

      const response = await axios.get(
        `${BACKEND_URL}/api/user/profile/${username}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("API response:", response.data);

      if (response.data && response.data.user) {
        console.log("Fetched user data successfully:", response.data.user);
        return response.data.user;
      } else {
        console.error("API error: Invalid response format");
        return rejectWithValue("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error in fetchUserByUsername:", error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchConversations = createAsyncThunk(
  "user/fetchConversations",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      console.log("Fetching conversations for user:", userId);

      const response = await axios.get(
        `${BACKEND_URL}/api/conversations/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const conversations = response.data.map((conv) => ({
        ...conv,
        otherParticipant: conv.participants.find(
          (p) => p._id.toString() !== userId.toString()
        ),
        unreadCount: conv.unreadCount || {},
      }));

      return conversations;
    } catch (error) {
      console.error("Error fetching conversations:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch conversations"
      );
    }
  }
);

export const sendMessage = createAsyncThunk(
  "user/sendMessage",
  async ({ senderId, conversationId, content }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/api/messages`, {
        senderId,
        conversationId,
        content,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchMessages = createAsyncThunk(
  "user/searchMessages",
  async ({ conversationId, searchTerm }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      if (!token) {
        return rejectWithValue("No authentication token available");
      }

      const conversation = getState().user.conversations.find(
        (conv) => conv._id === conversationId
      );

      if (!conversation || !conversation.messages) {
        return [];
      }

      const results = conversation.messages.filter((message) =>
        message.content.toLowerCase().includes(searchTerm.toLowerCase())
      );

      return results;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "user/refreshToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/auth/refresh-token`,
        {},
        {
          withCredentials: true,
        }
      );

      if (response.status !== 200) {
        throw new Error("Token refresh failed");
      }

      return response.data.token;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ userId, userData }, { getState, dispatch, rejectWithValue }) => {
    const makeRequest = async (token) => {
      try {
        const { currentUser } = getState().user;
        if (!currentUser || currentUser._id !== userId) {
          throw new Error("User ID mismatch");
        }

        const response = await axios.post(
          `${BACKEND_URL}/api/user/update/${userId}`,
          userData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 403) {
          throw new Error("Token expired");
        }
        throw error;
      }
    };

    try {
      const { token } = getState().user;
      if (!token) {
        return rejectWithValue("No authentication token available");
      }
      return await makeRequest(token);
    } catch (error) {
      if (error.message === "Token expired") {
        try {
          const newToken = await dispatch(refreshToken()).unwrap();
          return await makeRequest(newToken);
        } catch (refreshError) {
          return rejectWithValue("Session expired. Please log in again.");
        }
      }

      console.error("Error in updateUser:", error);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unknown error occurred";
      return rejectWithValue(errorMessage.toString());
    }
  }
);

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const makeRequest = async (token) => {
      try {
        if (!token) {
          throw new Error("No authentication token found");
        }

        const response = await axios.get(`${BACKEND_URL}/api/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.data || !response.data.user) {
          throw new Error("Invalid response format from server");
        }

        return response.data.user;
      } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 403) {
          throw new Error("TOKEN_EXPIRED");
        }
        throw error;
      }
    };

    try {
      const { token } = getState().user;
      if (!token) {
        return rejectWithValue("No authentication token available");
      }
      return await makeRequest(token);
    } catch (error) {
      if (error.message === "TOKEN_EXPIRED") {
        try {
          const newToken = await dispatch(refreshToken()).unwrap();
          return await makeRequest(newToken);
        } catch (refreshError) {
          return rejectWithValue("Session expired. Please log in again.");
        }
      }
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch user data"
      );
    }
  }
);

export const fetchChats = createAsyncThunk(
  "user/fetchChats",
  async ({ userId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const response = await fetch(
        `${BACKEND_URL}/api/conversations/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch chats");
      }
      const data = await response.json();
      console.log("Fetched chats:", data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMessages = createAsyncThunk(
  "user/fetchMessages",
  async (conversationId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const response = await axios.get(
        `${BACKEND_URL}/api/messages/messages/${conversationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return {
        conversationId,
        messages: response.data,
      };
    } catch (error) {
      console.error("Error fetching messages:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch messages"
      );
    }
  }
);

export const fetchMessagesForChat = createAsyncThunk(
  "user/fetchMessagesForChat",
  async ({ chatId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const response = await fetch(
        `${BACKEND_URL}/api/message/messages/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch messages");
      const messages = await response.json();
      return { chatId, messages };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const checkNewMessages = createAsyncThunk(
  "user/checkNewMessages",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token, currentUser } = getState().user;
      if (!token || !currentUser) {
        return rejectWithValue({
          status: 401,
          message: "No authentication token or user found",
        });
      }

      const response = await axios.get(
        `${BACKEND_URL}/api/message/new?userId=${currentUser._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.data) {
        return rejectWithValue({
          status: 500,
          message: "Invalid response format",
        });
      }

      return {
        hasNewMessages: response.data.hasNewMessages || false,
        unreadCount: response.data.unreadCount || 0,
      };
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          message:
            error.response.data?.message || "Failed to check for new messages",
        });
      }
      return rejectWithValue({
        status: 500,
        message: error.message || "Failed to check for new messages",
      });
    }
  }
);

export const markConversationAsRead = createAsyncThunk(
  "user/markConversationAsRead",
  async ({ conversationId }, { getState, rejectWithValue }) => {
    try {
      console.log("Marking conversation as read:", conversationId);
      const { currentUser, token } = getState().user;

      if (!conversationId || !currentUser?._id) {
        console.error("Missing required data:", {
          conversationId,
          userId: currentUser?._id,
        });
        return rejectWithValue("Missing required data");
      }

      const response = await axios.post(
        `${BACKEND_URL}/api/conversations/mark-as-read`,
        {
          conversationId,
          userId: currentUser._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Mark as read response:", response.data);
      return {
        hasNewMessages: response.data.hasNewMessages || false,
        unreadCount: response.data.unreadCount || 0,
      };
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          message:
            error.response.data?.message ||
            "Failed to mark conversation as read",
        });
      }
      return rejectWithValue({
        status: 500,
        message: error.message || "Failed to mark conversation as read",
      });
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    profileUser: null,
    error: null,
    loading: false,
    token: null,
    chats: [],
    activeChat: null,
    onlineUsers: {},
    role: null,
    hasNewMessages: false,
    likedListings: [],
    conversations: [],
    activeConversation: null,
    messageSearchTerm: "",
    showMessageSearch: false,
    searchResults: [],
    markingRead: false,
    markReadError: null,
  },
  reducers: {
    signInStart: (state) => {
      state.loading = true;
    },
    signInFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    signInSuccess: (state, action) => {
      console.log(
        "signInSuccess Action: Raw user data from payload:",
        JSON.stringify(action.payload, null, 2)
      );
      let emailAddress = action.payload.user.email.address;
      if (
        typeof emailAddress === "string" &&
        emailAddress.startsWith("{") &&
        emailAddress.endsWith("}")
      ) {
        try {
          emailAddress = JSON.parse(emailAddress).address || emailAddress;
        } catch (e) {
          console.error("Failed to parse email address:", e);
        }
      }
      state.currentUser = {
        ...action.payload.user,
        email: {
          ...action.payload.user.email,
          address: emailAddress,
        },
        username: action.payload.user.username,
        avatar: action.payload.user.avatar,
      };
      state.token = action.payload.token;
      localStorage.setItem("authToken", action.payload.token);
      state.loading = false;
      state.error = null;
    },
    updateUserStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateUserSuccess: (state, action) => {
      if (state.currentUser && state.currentUser._id === action.payload._id) {
        state.currentUser = {
          ...state.currentUser,
          ...action.payload,
        };
        state.role = action.payload.role;
        state.loading = false;
        state.error = null;
      }
    },
    updateUserFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteUserStart: (state) => {
      state.loading = true;
    },
    deleteUserSuccess: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = null;
    },
    deleteUserFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    signOutStart: (state) => {
      state.loading = true;
    },
    signOutSuccess: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = null;
      state.token = null;
      state.chats = [];
      state.activeChat = null;
      state.onlineUsers = {};
      state.hasNewMessages = false;
    },
    signOutFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    forgotPasswordStart: (state) => {
      state.loading = true;
    },
    forgotPasswordSuccess: (state) => {
      state.loading = false;
      state.error = null;
    },
    forgotPasswordFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setConversations: (state, action) => {
      state.conversations = action.payload;
    },
    setActiveConversation: (state, action) => {
      state.activeConversation = action.payload;
    },
    updateConversationWithNewMessage: (state, action) => {
      const { conversationId, message } = action.payload;
      const conversation = state.conversations.find(
        (conv) => conv._id === conversationId
      );
      if (conversation) {
        if (!conversation.messages) {
          conversation.messages = [];
        }
        conversation.messages.push(message);
        conversation.lastMessage = message.content;
      } else {
        state.conversations.push({
          _id: conversationId,
          messages: [message],
          lastMessage: message.content,
          participants: [message.senderId, message.recipientId],
        });
      }
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("authToken", action.payload);
      console.log(
        "Token updated in Redux state and localStorage:",
        state.token
      );
    },
    clearToken: (state) => {
      state.token = null;
      localStorage.removeItem("authToken");
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    addChat: (state, action) => {
      state.chats.push(action.payload);
    },
    setActiveChat: (state, action) => {
      state.activeChat = action.payload;
    },
    setMessages: (state, action) => {
      const { conversationId, messages } = action.payload;
      const conversation = state.chats.find(
        (chat) => chat._id === conversationId
      );
      if (conversation) {
        conversation.messages = messages;
      }
    },
    addMessage: (state, action) => {
      const { chatId, message } = action.payload;
      const chat = state.chats.find((c) => c._id === chatId);
      if (chat) {
        if (!chat.messages) {
          chat.messages = [];
        }
        chat.messages.push(message);
        chat.lastMessage = message.content;
      }
    },
    addNewMessage: (state, action) => {
      const { conversationId, message } = action.payload;
      let conversation = state.conversations.find(
        (chat) => chat._id === conversationId
      );
      if (conversation) {
        if (!conversation.messages) {
          conversation.messages = [];
        }
        conversation.messages.push(message);
        conversation.lastMessage = message.content;
      } else {
        console.log("Conversation not found for new message:", conversationId);
        state.conversations.push({
          _id: conversationId,
          messages: [message],
          lastMessage: message.content,
          participants: [message.senderId, message.recipientId],
        });
      }
    },
    setLikedListings: (state, action) => {
      state.likedListings = action.payload;
    },
    addLikedListing: (state, action) => {
      state.likedListings.push(action.payload);
    },
    removeLikedListing: (state, action) => {
      state.likedListings = state.likedListings.filter(
        (id) => id !== action.payload
      );
    },
    setMessageSearch: (state, action) => {
      state.messageSearchTerm = action.payload;
    },
    toggleMessageSearch: (state) => {
      state.showMessageSearch = !state.showMessageSearch;
    },
    updateMessageReadStatus: (state, action) => {
      const { conversationId } = action.payload;
      const conversation = state.chats.find(
        (chat) => chat._id === conversationId
      );
      if (conversation) {
        conversation.unreadCount = 0;
        conversation.messages.forEach((message) => {
          if (message.recipientId === state.currentUser._id) {
            message.isRead = true;
          }
        });
      }
    },
    setOnlineUsers: (state, action) => {
      state.onlineUsers = action.payload;
    },
    updateUserOnlineStatus: (state, action) => {
      const { userId, status } = action.payload;
      state.onlineUsers[userId] = status;
    },
    clearNewMessagesFlag: (state) => {
      state.hasNewMessages = false;
    },
    setHasNewMessages: (state, action) => {
      state.hasNewMessages = action.payload;
    },
    resetMarkReadState: (state) => {
      state.markingRead = false;
      state.markReadError = null;
    },
    clearMarkReadError: (state) => {
      state.markReadError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.currentUser = action.payload.user;
        state.token = action.payload.token;
        localStorage.setItem("authToken", action.payload.token);
      })
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.error = null;
        console.log("fetchUserData: fulfilled", action.payload);
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch user data";
        console.log("fetchUserData: rejected", action.payload);
      })
      .addCase(fetchMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        const { conversationId, messages } = action.payload;
        const conversation = state.conversations.find(
          (conv) => conv._id === conversationId
        );
        if (conversation) {
          conversation.messages = messages;
        }
        state.loading = false;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchChats.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.loading = false;
        state.chats = action.payload || [];
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserByUsername.pending, (state) => {
        console.log("fetchUserByUsername: pending");
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserByUsername.fulfilled, (state, action) => {
        console.log("fetchUserByUsername: fulfilled", action.payload);
        state.loading = false;
        state.profileUser = action.payload;
      })
      .addCase(fetchUserByUsername.rejected, (state, action) => {
        console.log("fetchUserByUsername: rejected", action.payload);
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload;
        localStorage.setItem("authToken", action.payload);
      })
      .addCase(refreshToken.rejected, (state) => {
        state.token = null;
        state.currentUser = null;
        localStorage.removeItem("authToken");
      })
      .addCase(checkNewMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkNewMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.hasNewMessages = action.payload.hasNewMessages;
        state.error = null;
      })
      .addCase(checkNewMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(searchMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchMessages.fulfilled, (state, action) => {
        state.searchResults = action.payload;
        state.loading = false;
      })
      .addCase(searchMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchConversations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.loading = false;
        state.conversations = action.payload.map((conversation) => {
          let lastMessageContent = "No messages yet";
          if (conversation.lastMessage) {
            if (typeof conversation.lastMessage === "string") {
              lastMessageContent = conversation.lastMessage;
            } else if (conversation.lastMessage.content) {
              lastMessageContent = conversation.lastMessage.content;
            }
          }

          return {
            ...conversation,
            unreadCount: conversation.unreadCount || {},
            lastMessage: {
              content: lastMessageContent,
              createdAt:
                conversation.lastMessage?.createdAt || conversation.updatedAt,
              senderId: conversation.lastMessage?.senderId,
            },
          };
        });

        console.log("Processed conversations:", state.conversations);
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(markConversationAsRead.pending, (state) => {
        state.markingRead = true;
        state.markReadError = null;
      })
      .addCase(markConversationAsRead.fulfilled, (state, action) => {
        state.markingRead = false;
        const { conversationId, userId } = action.payload;

        const conversation = state.conversations.find(
          (conv) => conv._id === conversationId
        );

        if (conversation) {
          conversation.unreadCount = {
            ...conversation.unreadCount,
            [userId.toString()]: 0,
          };

          if (conversation.messages) {
            conversation.messages = conversation.messages.map((message) =>
              message.recipientId === userId
                ? { ...message, isRead: true }
                : message
            );
          }
        }
      })
      .addCase(markConversationAsRead.rejected, (state, action) => {
        state.markingRead = false;
        state.markReadError = action.payload;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        const { conversationId, message } = action.payload;
        const conversation = state.conversations.find(
          (conv) => conv._id === conversationId
        );

        if (conversation) {
          if (!conversation.messages) {
            conversation.messages = [];
          }
          conversation.messages.push(message);

          if (!conversation.unreadCount) {
            conversation.unreadCount = {};
          }
          const currentCount =
            conversation.unreadCount[message.recipientId] || 0;
          conversation.unreadCount = {
            ...conversation.unreadCount,
            [message.recipientId]: currentCount + 1,
          };

          conversation.lastMessage = {
            content: message.content,
            createdAt: message.createdAt,
          };
        }
      });
  },
});

export const {
  signInStart,
  signInSuccess,
  signInFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  setConversations,
  signOutStart,
  signOutSuccess,
  signOutFailure,
  setCurrentUser,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  setActiveConversation,
  setError,
  setToken,
  setMessages,
  clearToken,
  clearError,
  setChats,
  addChat,
  setActiveChat,
  updateConversationWithNewMessage,
  setHasNewMessages,
  addMessage,
  addNewMessage,
  updateMessageReadStatus,
  setOnlineUsers,
  updateUserOnlineStatus,
  clearNewMessagesFlag,
  addLikedListing,
  removeLikedListing,
  setLikedListings,
  setMessageSearch,
  toggleMessageSearch,
  clearMarkReadError,
  resetMarkReadState,
} = userSlice.actions;

const persistedReducer = persistReducer(persistConfig, userSlice.reducer);

export default persistedReducer;
