import React, { useState, useCallback, useEffect } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useLocation } from "react-router-dom";
import PropertySearch from "./rei/PropertySearch";
import SummaryComponent from "./rei/SummaryComponent";
import AIInvestmentAssistant from "./rei/AIInvestmentAssistant";
import CashFlowProjections from "./rei/CashFlowProjections";
import ComparativeMarketAnalysis from "./rei/ComparativeMarketAnalysis";
import ErrorBoundary from "./rei/ErrorBoundary";
import ExpenseBreakdown from "./rei/ExpenseBreakdown";
import ExpenseTracker from "./rei/ExpenseTracker";
import FinancialScenario from "./rei/FinancialScenario";
import LoadingIndicator from "./rei/LoadingIndicator";
import MarketTrends from "./rei/MarketTrends";
import NeighborhoodOverview from "./rei/NeighborhoodOverview";
import RentalIncome from "./rei/RentalIncome";
import RiskAssessment from "./rei/RiskAssessment";
import ROIAnalysis from "./rei/ROIAnalysis";
import QuickStats from "./rei/QuickStats";
import WalkScoreDisplay from "./rei/WalkScoreDisplay";
import PlacesDisplay from "./rei/PlacesDisplay";
import DemographicDisplay from "./rei/DemographicDisplay";
import SavePropertyModal from "./rei/SavePropertyModal";
import { usePropertyData } from "../hooks/usePropertyData";
import { useWalkScore } from "../hooks/useWalkScore";
import { usePlacesData } from "../hooks/usePlacesData";
import { useDemographicData } from "../hooks/useDemographicData";

export default function RealEstateDashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [mobileView, setMobileView] = useState('summary');
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState(false);

  // Property Data
  const {
    propertyData,
    loading: propertyLoading,
    error: propertyError,
    fetchPropertyData
  } = usePropertyData();

  // Location and demographic data hooks
  const {
    scores: walkScores,
    amenities: walkAmenities,
    loading: walkScoreLoading
  } = useWalkScore(propertyData?.location);

  const {
    amenities: placesAmenities,
    loading: placesLoading,
    fetchPlaceDetails
  } = usePlacesData(propertyData?.location);

  const {
    demographics,
    economicData,
    housingStats,
    loading: demographicLoading
  } = useDemographicData(propertyData?.address?.zipCode);

  // Expense Management
  const [expenses, setExpenses] = useState([
    { name: "Mortgage", value: 1500 },
    { name: "Property Tax", value: 500 },
    { name: "Insurance", value: 200 },
    { name: "Maintenance", value: 300 },
  ]);

  // Rental Analysis
  const [rentalInputs, setRentalInputs] = useState({
    longTermRent: 2500,
    shortTermADR: 150,
    shortTermOccupancy: 70,
  });

  // Define handlePropertySelect before it's used in useEffect
  const handlePropertySelect = useCallback(async (property) => {
    await fetchPropertyData(property.address, property.zipCode);
  }, [fetchPropertyData]);

  const handlePlaceSelect = useCallback((place) => {
    setSelectedPlace(place);
    if (place?.id) {
      fetchPlaceDetails(place.id);
    }
  }, [fetchPlaceDetails]);

  const handleSaveProperty = useCallback(() => {
    setShowSaveModal(true);
  }, []);

  // Handle incoming property data from Listing component
  useEffect(() => {
    if (location.state?.propertyData) {
      const incomingProperty = location.state.propertyData;
      // Process the incoming property data
      handlePropertySelect({
        address: incomingProperty.address,
        zipCode: incomingProperty.zipCode || incomingProperty.address.match(/\d{5}/)?.[0]
      });
      
      // Update expenses if available
      if (incomingProperty.expenses) {
        setExpenses(incomingProperty.expenses);
      }
      
      // Update rental inputs if available
      if (incomingProperty.rentalData) {
        setRentalInputs(prev => ({
          ...prev,
          longTermRent: incomingProperty.rentalData.monthlyRent || prev.longTermRent,
          shortTermADR: incomingProperty.rentalData.dailyRate || prev.shortTermADR,
          shortTermOccupancy: incomingProperty.rentalData.occupancyRate || prev.shortTermOccupancy,
        }));
      }

      // Clear the location state to prevent reloading on navigation
      window.history.replaceState({}, document.title);
    }
  }, [location.state, handlePropertySelect]);

  const handleSaveConfirm = useCallback(async (saveData) => {
    try {
      const savedPropertyData = {
        ...saveData,
        propertyDetails: propertyData,
        financialAnalysis: {
          expenses,
          rentalInputs,
          estimatedValue: propertyData?.financialData?.estimatedValue,
          cashFlow: {
            monthlyRent: rentalInputs.longTermRent,
            monthlyExpenses: expenses.reduce((total, exp) => total + exp.value, 0)
          }
        },
        locationAnalysis: {
          walkScore: walkScores,
          nearbyPlaces: placesAmenities,
          selectedPlace
        },
        marketAnalysis: {
          demographics,
          economicIndicators: economicData,
          housingMarket: housingStats,
          trends: propertyData?.marketTrends
        },
        timestamp: new Date().toISOString(),
        status: 'active'
      };

      const response = await fetch('/api/properties/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(savedPropertyData)
      });

      if (!response.ok) {
        throw new Error('Failed to save property');
      }

      const savedProperty = await response.json();
      
      setShowSaveModal(false);

      // Show success message with property ID for reference
      const successMessage = document.createElement('div');
      successMessage.className = 'fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg z-50 font-lato';
      successMessage.textContent = `Property saved successfully! (ID: ${savedProperty.id})`;
      document.body.appendChild(successMessage);
      
      setTimeout(() => {
        successMessage.remove();
      }, 3000);

    } catch (error) {
      console.error('Error saving property:', error);
      
      // Show error message with Lato font
      const errorMessage = document.createElement('div');
      errorMessage.className = 'fixed top-4 right-4 bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg z-50 font-lato';
      errorMessage.textContent = 'Failed to save property. Please try again.';
      document.body.appendChild(errorMessage);
      
      setTimeout(() => {
        errorMessage.remove();
      }, 3000);
    }
  }, [propertyData, expenses, rentalInputs, walkScores, placesAmenities, selectedPlace, demographics, economicData, housingStats]);

  const handleCreateListing = useCallback(() => {
    if (propertyData) {
      navigate('/create-listing', { state: { propertyData } });
    }
  }, [navigate, propertyData]);

  if (
    propertyLoading ||
    walkScoreLoading ||
    placesLoading ||
    demographicLoading
  ) {
    return <LoadingIndicator />;
  }

  if (propertyError) {
    return (
      <div className="min-h-screen p-8 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-gray-600">{propertyError}</p>
        </div>
      </div>
    );
  }

  // Mobile View
  if (isMobile) {
    return (
      <div
        className="min-h-screen p-4"
        style={{ backgroundColor: "#F1F5F1", fontFamily: "'Lato', sans-serif" }}
      >
        <nav className="mb-4">
          <select
            value={mobileView}
            onChange={(e) => setMobileView(e.target.value)}
            className="w-full p-2 rounded-lg"
          >
            <option value="summary">Summary</option>
            <option value="location">Location</option>
            <option value="demographics">Demographics</option>
            <option value="analysis">Analysis</option>
            <option value="expenses">Expenses</option>
            <option value="rental">Rental</option>
          </select>
        </nav>

        <PropertySearch
          onSearch={handlePropertySelect}
          isLoading={propertyLoading}
        />

        {propertyData && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="mt-4"
            >
              <SummaryComponent
                propertyData={propertyData}
                financialData={propertyData?.financialData}
                rentalInputs={rentalInputs}
                expenses={expenses}
              />
            </motion.div>

            <div className="flex gap-2 mt-4">
              <button
                onClick={handleSaveProperty}
                className="flex-1 bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                Save Property
              </button>
              <button
                onClick={handleCreateListing}
                className="flex-1 bg-green-600 text-white px-4 py-2 rounded-lg"
              >
                Create Listing
              </button>
            </div>
          </>
        )}

        {renderMobileView()}

        <SavePropertyModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          onSave={handleSaveConfirm}
          property={propertyData}
        />
      </div>
    );
  }

  // Desktop View
  return (
    <div
      className="min-h-screen p-8"
      style={{ backgroundColor: "#F1F5F1", fontFamily: "'Lato', sans-serif" }}
    >
      {/* Search Section */}
      <div className="max-w-6xl mx-auto mb-6">
        <div className="bg-white rounded-2xl shadow-md p-4">
          <ErrorBoundary>
            <PropertySearch
              onSearch={handlePropertySelect}
              isLoading={propertyLoading}
            />
          </ErrorBoundary>
        </div>
      </div>

      {propertyData && (
        <>
          {/* Summary Section */}
          <div className="max-w-6xl mx-auto bg-white rounded-3xl shadow-md overflow-hidden mb-6">
            <ErrorBoundary>
              <SummaryComponent
                propertyData={propertyData}
                financialData={propertyData?.financialData}
                rentalInputs={rentalInputs}
                expenses={expenses}
              />
            </ErrorBoundary>
          </div>

          {/* Action Buttons */}
          <div className="max-w-6xl mx-auto flex justify-end gap-4 mb-6">
            <button
              onClick={handleSaveProperty}
              className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Save Property
            </button>
            <button
              onClick={handleCreateListing}
              className="bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-700 transition-colors"
            >
              Create Listing
            </button>
          </div>

          {/* Quick Stats */}
          <div className="max-w-6xl mx-auto mb-6">
            <ErrorBoundary>
              <QuickStats property={propertyData} />
            </ErrorBoundary>
          </div>

          {/* Location Analysis */}
          <div className="max-w-6xl mx-auto grid grid-cols-12 gap-6 mb-6">
            <div className="col-span-6">
              <ErrorBoundary>
                <WalkScoreDisplay
                  scores={walkScores}
                  amenities={walkAmenities}
                  onAmenityClick={handlePlaceSelect}
                />
              </ErrorBoundary>
            </div>
            <div className="col-span-6">
              <ErrorBoundary>
                <PlacesDisplay
                  amenities={placesAmenities}
                  selectedPlace={selectedPlace}
                  onPlaceSelect={handlePlaceSelect}
                  location={propertyData.location}
                />
              </ErrorBoundary>
            </div>
          </div>

          {/* Demographic Analysis */}
          <div className="max-w-6xl mx-auto mb-6">
            <ErrorBoundary>
              <DemographicDisplay
                demographics={demographics}
                economicData={economicData}
                housingStats={housingStats}
              />
            </ErrorBoundary>
          </div>

          {/* Main Content Grid */}
          <div className="max-w-6xl mx-auto grid grid-cols-12 gap-6">
            {/* AI Investment Assistant */}
            <div className="col-span-4">
              <div className="bg-white rounded-2xl shadow-sm h-full">
                <ErrorBoundary>
                  <AIInvestmentAssistant
                    propertyData={propertyData}
                    financialData={propertyData?.financialData}
                    rentalInputs={rentalInputs}
                    marketTrends={propertyData?.marketTrends}
                  />
                </ErrorBoundary>
              </div>
            </div>

            {/* Cash Flow Projections */}
            <div className="col-span-4">
              <div className="bg-white rounded-2xl shadow-sm h-full">
                <ErrorBoundary>
                  <CashFlowProjections
                    rentalIncome={rentalInputs}
                    expenses={expenses}
                    propertyValue={propertyData?.financialData?.estimatedValue}
                  />
                </ErrorBoundary>
              </div>
            </div>

            {/* Risk Assessment */}
            <div className="col-span-4">
              <div className="bg-white rounded-2xl shadow-sm h-full">
                <ErrorBoundary>
                  <RiskAssessment
                    propertyData={propertyData}
                    marketData={propertyData?.marketTrends}
                    communityData={propertyData?.communityData}
                  />
                </ErrorBoundary>
              </div>
            </div>

            {/* Market Analysis Section */}
            <div className="col-span-6">
              <div className="bg-white rounded-2xl shadow-sm">
                <ErrorBoundary>
                  <ComparativeMarketAnalysis
                    propertyData={propertyData}
                    marketTrends={propertyData?.marketTrends}
                  />
                </ErrorBoundary>
              </div>
            </div>

            {/* Expense Management Section */}
            <div className="col-span-6">
              <div className="grid grid-cols-1 gap-6">
                <div className="bg-white rounded-2xl shadow-sm">
                  <ErrorBoundary>
                    <ExpenseBreakdown expenses={expenses} />
                  </ErrorBoundary>
                </div>
                <div className="bg-white rounded-2xl shadow-sm">
                  <ErrorBoundary>
                    <ExpenseTracker
                      expenses={expenses}
                      onAddExpense={(newExpense) =>
                        setExpenses([...expenses, newExpense])
                      }
                      onRemoveExpense={(index) =>
                        setExpenses(expenses.filter((_, i) => i !== index))
                      }
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>

            {/* Financial Scenario */}
            <div className="col-span-4">
              <div className="bg-white rounded-2xl shadow-sm">
                <ErrorBoundary>
                  <FinancialScenario
                    propertyValue={propertyData?.financialData?.estimatedValue}
                  />
                </ErrorBoundary>
              </div>
            </div>

            {/* Market Trends */}
            <div className="col-span-4">
              <div className="bg-white rounded-2xl shadow-sm">
                <ErrorBoundary>
                  <MarketTrends marketTrends={propertyData?.marketTrends} />
                </ErrorBoundary>
              </div>
            </div>

            {/* Rental Income Analysis */}
            <div className="col-span-4">
              <div className="bg-white rounded-2xl shadow-sm">
                <ErrorBoundary>
                  <RentalIncome
                    rentalInputs={rentalInputs}
                    onRentalInputChange={setRentalInputs}
                  />
                </ErrorBoundary>
              </div>
            </div>

            {/* Neighborhood Overview - Full Width */}
            <div className="col-span-12">
              <div className="bg-white rounded-2xl shadow-sm">
                <ErrorBoundary>
                  <NeighborhoodOverview
                    communityData={propertyData?.communityData}
                  />
                </ErrorBoundary>
              </div>
            </div>

            {/* ROI Analysis - Full Width */}
            <div className="col-span-12">
              <div className="bg-white rounded-2xl shadow-sm">
                <ErrorBoundary>
                  <ROIAnalysis
                    rentalInputs={rentalInputs}
                    financialData={propertyData?.financialData}
                  />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </>
      )}

      <SavePropertyModal
        isOpen={showSaveModal}
        onClose={() => setShowSaveModal(false)}
        onSave={handleSaveConfirm}
        property={propertyData}
      />
    </div>
  );

  function renderMobileView() {
    switch (mobileView) {
      case "location":
        return (
          <div className="space-y-4">
            <WalkScoreDisplay
              scores={walkScores}
              amenities={walkAmenities}
              onAmenityClick={handlePlaceSelect}
            />
            <PlacesDisplay
              amenities={placesAmenities}
              selectedPlace={selectedPlace}
              onPlaceSelect={handlePlaceSelect}
              location={propertyData?.location}
            />
          </div>
        );
      case "demographics":
        return (
          <DemographicDisplay
            demographics={demographics}
            economicData={economicData}
            housingStats={housingStats}
          />
        );
      case "analysis":
        return (
          <div className="space-y-4">
            <AIInvestmentAssistant
              propertyData={propertyData}
              marketTrends={propertyData?.marketTrends}
              demographics={demographics}
              economicData={economicData}
            />
            <CashFlowProjections
              rentalIncome={rentalInputs}
              expenses={expenses}
              propertyValue={propertyData?.financialData?.estimatedValue}
            />
            <RiskAssessment
              propertyData={propertyData}
              marketData={propertyData?.marketTrends}
              communityData={propertyData?.communityData}
            />
          </div>
        );
      case "expenses":
        return (
          <div className="space-y-4">
            <ExpenseBreakdown expenses={expenses} />
            <ExpenseTracker
              expenses={expenses}
              onAddExpense={(newExpense) =>
                setExpenses([...expenses, newExpense])
              }
              onRemoveExpense={(index) =>
                setExpenses(expenses.filter((_, i) => i !== index))
              }
            />
          </div>
        );
      case "rental":
        return (
          <div className="space-y-4">
            <RentalIncome
              rentalInputs={rentalInputs}
              onRentalInputChange={setRentalInputs}
            />
            <ROIAnalysis
              rentalInputs={rentalInputs}
              financialData={propertyData?.financialData}
            />
          </div>
        );
      default:
        return null;
    }
  }
}
