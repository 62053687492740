// dashboardActions.js

// Existing actions
export const setSelectedProperty = (property) => ({
  type: "SET_SELECTED_PROPERTY",
  payload: property,
});

export const clearSelectedProperty = () => ({
  type: "CLEAR_SELECTED_PROPERTY",
});

export const addToDashboard = (listing) => ({
  type: "ADD_TO_DASHBOARD",
  payload: listing,
});

export const removeFromDashboard = (listingId) => ({
  type: "REMOVE_FROM_DASHBOARD",
  payload: listingId,
});

// New fetchPropertyData action
export const fetchPropertyData = (address) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_PROPERTY_DATA_START" });
    try {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Dummy data
      const dummyData = {
        address,
        propertyData: {
          bedrooms: 3,
          bathrooms: 2,
          squareFeet: 1500,
          yearBuilt: 2000,
        },
        financialData: {
          estimatedValue: 300000,
          lastSoldPrice: 280000,
          lastSoldDate: "2020-01-01",
        },
        marketTrends: [
          { date: "2023-01", value: 290000 },
          { date: "2023-02", value: 295000 },
          { date: "2023-03", value: 300000 },
        ],
        communityData: {
          schoolRating: 8,
          crimeRate: "Low",
          walkScore: 75,
        },
      };

      dispatch({
        type: "FETCH_PROPERTY_DATA_SUCCESS",
        payload: dummyData,
      });
    } catch (error) {
      dispatch({
        type: "FETCH_PROPERTY_DATA_FAILURE",
        payload: error.message,
      });
    }
  };
};
