import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/user/userSlice";
import { storage } from "../../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import EditableSection from "./EditableSection";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const PhotoContent = ({
  isEditing,
  filePerc,
  fileUploadError,
  onFileChange,
  coverPhotoPreview,
  avatarPreview,
}) => {
  const coverPhotoRef = useRef(null);
  const avatarRef = useRef(null);

  return (
    <div className="space-y-6">
      {/* Cover Photo */}
      <div>
        <div className="relative h-48 bg-gray-100 rounded-lg overflow-hidden">
          {coverPhotoPreview ? (
            <img
              src={coverPhotoPreview}
              alt="Cover"
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center text-gray-500">
              No cover photo selected
            </div>
          )}
          {isEditing && (
            <div className="absolute bottom-4 right-4">
              <button
                onClick={() => coverPhotoRef.current?.click()}
                className="bg-white px-4 py-2 rounded-md shadow-sm hover:bg-gray-50"
                type="button"
              >
                Change Cover Photo
              </button>
              <input
                ref={coverPhotoRef}
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => onFileChange(e, "cover")}
              />
            </div>
          )}
        </div>
      </div>

      {/* Profile Photo */}
      <div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-100">
              {avatarPreview ? (
                <img
                  src={avatarPreview}
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center text-gray-500">
                  No photo
                </div>
              )}
            </div>
            {isEditing && (
              <>
                <button
                  onClick={() => avatarRef.current?.click()}
                  className="absolute bottom-0 right-0 bg-white p-2 rounded-full shadow-sm hover:bg-gray-50"
                  type="button"
                >
                  <span className="sr-only">Change avatar</span>
                  <svg
                    className="w-5 h-5 text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </button>
                <input
                  ref={avatarRef}
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => onFileChange(e, "avatar")}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {/* Upload Progress and Errors */}
      {fileUploadError && (
        <p className="text-red-500 text-sm">
          Error uploading image (image must be less than 2MB)
        </p>
      )}
      {filePerc > 0 && filePerc < 100 && (
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-blue-600 h-2 rounded-full transition-all"
            style={{ width: `${filePerc}%` }}
          ></div>
        </div>
      )}
    </div>
  );
};

PhotoContent.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  filePerc: PropTypes.number.isRequired,
  fileUploadError: PropTypes.bool.isRequired,
  onFileChange: PropTypes.func.isRequired,
  coverPhotoPreview: PropTypes.string,
  avatarPreview: PropTypes.string,
};

const ProfilePhotoSection = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [saving, setSaving] = useState(false);
  const [filePerc, setFilePerc] = useState(0);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [coverPhotoFile, setCoverPhotoFile] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const [coverPhotoPreview, setCoverPhotoPreview] = useState(
    currentUser?.coverPhoto
  );
  const [avatarPreview, setAvatarPreview] = useState(currentUser?.avatar);

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) {
      setFileUploadError(true);
      notyf.error("File size must be less than 2MB");
      return;
    }

    setFileUploadError(false);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (fileType === "cover") {
        setCoverPhotoFile(file);
        setCoverPhotoPreview(reader.result);
      } else {
        setAvatarFile(file);
        setAvatarPreview(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleFileUpload = async (file) => {
    try {
      const fileName = new Date().getTime() + file.name;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setFilePerc(Math.round(progress));
          },
          (error) => {
            setFileUploadError(true);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            } catch (error) {
              reject(error);
            }
          }
        );
      });
    } catch (error) {
      console.error("Error in handleFileUpload:", error);
      throw error;
    }
  };

  const handleSave = async () => {
    setSaving(true);
    setFileUploadError(false);
    try {
      const updates = {};

      if (avatarFile) {
        const avatarURL = await handleFileUpload(avatarFile);
        updates.avatar = avatarURL;
      }

      if (coverPhotoFile) {
        const coverPhotoURL = await handleFileUpload(coverPhotoFile);
        updates.coverPhoto = coverPhotoURL;
      }

      if (Object.keys(updates).length > 0) {
        await dispatch(
          updateUser({
            userId: currentUser._id,
            userData: updates,
          })
        ).unwrap();

        notyf.success("Profile photos updated successfully");
        setCoverPhotoFile(null);
        setAvatarFile(null);
        setFilePerc(0);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error saving photos:", error);
      setFileUploadError(true);
      notyf.error(error.message || "Failed to update profile photos");
      return false;
    } finally {
      setSaving(false);
    }
  };

  return (
    <EditableSection title="Profile Photos" onSave={handleSave} saving={saving}>
      <PhotoContent
        isEditing
        filePerc={filePerc}
        fileUploadError={fileUploadError}
        onFileChange={handleFileChange}
        coverPhotoPreview={coverPhotoPreview}
        avatarPreview={avatarPreview}
      />
    </EditableSection>
  );
};

export default ProfilePhotoSection;
