import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addLikedListing,
  removeLikedListing,
  setLikedListings,
} from "../redux/user/userSlice";
import api from "../api";

const useLikeListings = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const likedListings = useSelector((state) => state.user.likedListings);

  const toggleLike = async (listingId) => {
    if (!currentUser) {
      console.error("User is not logged in");
      throw new Error("User is not logged in");
    }

    try {
      const response = await api.post(`/api/like/toggle/${listingId}`, {
        userId: currentUser._id,
      });

      if (!response || typeof response.isLiked !== "boolean") {
        throw new Error("Invalid response from server");
      }

      if (response.isLiked) {
        dispatch(addLikedListing(listingId));
      } else {
        dispatch(removeLikedListing(listingId));
      }
      
      return response.isLiked;
    } catch (error) {
      console.error("Error in toggleLike:", error);
      // Rethrow with a more user-friendly message
      throw new Error("Failed to update like status. Please try again.");
    }
  };

  const fetchLikeStatus = async (listingId) => {
    if (!currentUser) {
      console.warn("User is not logged in - cannot fetch like status");
      return false;
    }

    if (!listingId) {
      console.warn("No listing ID provided to fetchLikeStatus");
      return false;
    }

    try {
      const response = await api.post(`/api/like/status`, {
        listingId,
        userId: currentUser._id
      });

      if (!response || typeof response.isLiked !== "boolean") {
        console.error("Invalid response format from like status endpoint:", response);
        throw new Error("Invalid response format from server");
      }

      return response.isLiked;
    } catch (error) {
      console.error("Error fetching like status:", error);
      // Don't silently fail - throw the error so the UI can handle it
      throw new Error("Failed to fetch like status. Please try again.");
    }
  };

  const fetchLikedListings = useCallback(async () => {
    if (!currentUser) {
      console.log("No current user, skipping fetchLikedListings");
      return;
    }

    try {
      console.log("Fetching liked listings for user:", currentUser._id);
      const response = await api.get(`/api/user/liked-listings/${currentUser._id}`);
      
      if (!response) {
        throw new Error("No response from server");
      }

      console.log("Raw liked listings response:", response);
      
      if (!Array.isArray(response.likedListings)) {
        console.error("Invalid response format for liked listings:", response);
        throw new Error("Invalid response format from server");
      }

      console.log("Parsed liked listings:", response.likedListings);
      dispatch(setLikedListings(response.likedListings));
    } catch (error) {
      console.error("Error fetching liked listings:", error);
      // Set empty array but also throw error for UI handling
      dispatch(setLikedListings([]));
      throw new Error("Failed to fetch liked listings. Please try again.");
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    let mounted = true;

    const loadLikedListings = async () => {
      if (currentUser && mounted) {
        try {
          console.log("Calling fetchLikedListings from useEffect");
          await fetchLikedListings();
        } catch (error) {
          console.error("Failed to load liked listings:", error);
        }
      }
    };

    loadLikedListings();

    return () => {
      mounted = false;
    };
  }, [currentUser, fetchLikedListings]);

  console.log("Current likedListings in hook:", likedListings);

  return {
    toggleLike,
    fetchLikeStatus,
    likedListings,
    fetchLikedListings,
  };
};

export { useLikeListings };
