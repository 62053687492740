export const properties = [
  {
    id: 1,
    title: "Cozy Suburban Home",
    price: 439000,
    address: "229 Amesbury Cir, Sun City Center, FL 33573",
    beds: 3,
    baths: 2,
    image: "https://picsum.photos/id/1018/400/300",
    status: "For Sale",
    size: 1800,
    type: "House",
    lat: 39.9526,
    lng: -75.1652,
  },
  {
    id: 2,
    title: "Spacious Family House",
    price: 489000,
    address: "11312 Bours Lane, Brook NY 10002",
    beds: 4,
    baths: 3,
    image: "https://picsum.photos/id/1019/400/300",
    status: "Quick Sale",
    size: 2200,
    type: "House",
    lat: 39.955,
    lng: -75.167,
  },
  {
    id: 3,
    title: "Modern City Apartment",
    price: 544900,
    address: "100 Engine9 Owner Ln, United States",
    beds: 2,
    baths: 2,
    image: "https://picsum.photos/id/1020/400/300",
    status: "Pre-Foreclosure",
    size: 1200,
    type: "Apartment",
    lat: 39.954,
    lng: -75.163,
  },
  {
    id: 4,
    title: "Rustic Country Retreat",
    price: 350000,
    address: "456 Rural Route, Countryside, ST 12345",
    beds: 3,
    baths: 2,
    image: "https://picsum.photos/id/1021/400/300",
    status: "Open to Options",
    size: 1600,
    type: "House",
    lat: 39.951,
    lng: -75.164,
  },
  {
    id: 5,
    title: "Beachfront Condo",
    price: 620000,
    address: "789 Ocean View, Seaside, ST , 67890",
    beds: 2,
    baths: 2,
    image: "https://picsum.photos/id/1022/400/300",
    status: "For Sale",
    size: 1400,
    type: "Condo",
    lat: 39.953,
    lng: -75.162,
  },
  {
    id: 6,
    title: "Mountain View Cabin",
    price: 275000,
    address: "101 Summit Trail, Mountain Town, ST 11111",
    beds: 2,
    baths: 1,
    image: "https://picsum.photos/id/1023/400/300",
    status: "Quick Sale",
    size: 1000,
    type: "Cabin",
    lat: 39.956,
    lng: -75.161,
  },
  {
    id: 7,
    title: "Downtown Loft",
    price: 399000,
    address: "202 Main Street, Metropolis, ST 22222",
    beds: 1,
    baths: 1,
    image: "https://picsum.photos/id/1024/400/300",
    status: "Pre-Foreclosure",
    size: 900,
    type: "Apartment",
    lat: 39.957,
    lng: -75.165,
  },
  {
    id: 8,
    title: "Suburban Family Home",
    price: 515000,
    address: "303 Maple Avenue, Suburbia, ST 33333",
    beds: 4,
    baths: 3,
    image: "https://picsum.photos/id/1025/400/300",
    status: "Open to Options",
    size: 2400,
    type: "House",
    lat: 39.952,
    lng: -75.166,
  },
  {
    id: 9,
    title: "Lakefront Property",
    price: 750000,
    address: "404 Lakeshore Drive, Watertown, ST 44444",
    beds: 3,
    baths: 2,
    image: "https://picsum.photos/id/1026/400/300",
    status: "For Sale",
    size: 2000,
    type: "House",
    lat: 39.95,
    lng: -75.168,
  },
  {
    id: 10,
    title: "Historic Townhouse",
    price: 585000,
    address: "505 Heritage Lane, Old Town, ST 55555",
    beds: 3,
    baths: 2,
    image: "https://picsum.photos/id/1027/400/300",
    status: "Quick Sale",
    size: 1800,
    type: "Townhouse",
    lat: 39.959,
    lng: -75.16,
  },
];
