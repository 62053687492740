import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  AccountBalance,
  Warning,
  TrendingUp,
  AttachMoney,
  Assessment,
  Calculate,
  HomeWork,
} from "@mui/icons-material";

// eslint-disable-next-line no-unused-vars
const FinancialDetails = ({ listing }) => {
  // ATTOM API Integration (commented out)
  /*
  const fetchFinancialData = async () => {
    try {
      const response = await fetch(
        `https://api.attomdata.com/property/finance?address=${listing.address}`,
        {
          headers: {
            'apikey': import.meta.env.VITE_ATTOM_API_KEY,
            'Accept': 'application/json'
          }
        }
      );
      const data = await response.json();
      
      const financialInfo = {
        mortgageDetails: {
          lender: data.property[0].mortgage.lenderName,
          amount: data.property[0].mortgage.amount,
          interestRate: data.property[0].mortgage.interestRate,
          type: data.property[0].mortgage.type,
          term: data.property[0].mortgage.term,
        },
        taxAssessment: {
          taxAmount: data.property[0].assessment.tax.taxamt,
          marketValue: data.property[0].assessment.market.mktttlvalue,
          assessedValue: data.property[0].assessment.assessed.assdttlvalue,
        },
        liens: data.property[0].liens,
        debtHistory: data.property[0].debtHistory,
      };

      return financialInfo;
    } catch (error) {
      console.error('Error fetching financial data:', error);
      return null;
    }
  };
  */

  // Demo financial data structure remains unchanged
  const financialData = {
    mortgageDetails: {
      outstandingBalance: 285000,
      originalAmount: 350000,
      interestRate: 4.25,
      monthlyPayment: 1720,
      type: "30-Year Fixed",
      lender: "First National Bank",
      originationDate: "2019-06-15",
      maturityDate: "2049-06-15",
      paymentHistory: {
        onTime: 85,
        late30: 2,
        late60: 1,
        late90: 0,
      },
    },
    propertyFinancials: {
      currentValue: 425000,
      purchasePrice: 350000,
      equity: 140000,
      appreciationRate: 5.2,
      costBasis: 362000,
      estimatedTaxes: 4800,
      insuranceCost: 2400,
      maintenanceCost: 3600,
    },
    debtMetrics: {
      loanToValue: 67,
      debtServiceRatio: 32,
      equityPercentage: 33,
      monthlyDebtService: 1720,
      totalDebts: 295000,
    },
    taxAssessment: {
      assessedValue: 395000,
      taxRate: 1.2,
      lastAssessmentDate: "2023-12-15",
      nextAssessmentDate: "2024-12-15",
      taxExemptions: ["Homestead"],
      annualTaxes: 4740,
    },
    liens: [
      {
        type: "Property Tax",
        amount: 0,
        status: "Clear",
      },
      {
        type: "Mechanic's Lien",
        amount: 0,
        status: "Clear",
      },
    ],
    cashflowMetrics: {
      grossRents: 2800,
      netOperatingIncome: 1950,
      operatingExpenses: 850,
      cashflowBeforeDebt: 1950,
      cashflowAfterDebt: 230,
      capRate: 5.5,
    },
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const getStatusColor = (value, threshold = 80) => {
    if (value >= threshold) return "#2e7d32"; // Darker success color
    if (value >= threshold * 0.6) return "#ed6c02"; // Darker warning color
    return "#d32f2f"; // Darker error color
  };

  return (
    <Card sx={{ mb: 4, borderRadius: 2, bgcolor: "#fff" }}>
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontFamily: "Lato, sans-serif",
            fontWeight: 600,
            color: "#1565c0", // Darker blue
            mb: 3,
          }}
        >
          <AccountBalance /> Financial Overview
        </Typography>

        {/* Mortgage Summary */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <HomeWork /> Mortgage Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#666", // Darker secondary text
                      }}
                    >
                      Outstanding Balance
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#1565c0", // Darker blue
                        fontWeight: 600,
                      }}
                    >
                      {formatCurrency(
                        financialData.mortgageDetails.outstandingBalance
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#666", // Darker secondary text
                      }}
                    >
                      Monthly Payment
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#1565c0", // Darker blue
                        fontWeight: 600,
                      }}
                    >
                      {formatCurrency(
                        financialData.mortgageDetails.monthlyPayment
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#666", // Darker secondary text
                      }}
                    >
                      Interest Rate
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#1565c0", // Darker blue
                        fontWeight: 600,
                      }}
                    >
                      {financialData.mortgageDetails.interestRate}%
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#666", // Darker secondary text
                      }}
                    >
                      Loan Type
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#1565c0", // Darker blue
                        fontWeight: 600,
                      }}
                    >
                      {financialData.mortgageDetails.type}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(46, 125, 50, 0.1)", // Lighter shade of darker success
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: "#666", // Darker secondary text
                  }}
                >
                  Payment History
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      mb: 1,
                      color: "#333", // Darker text
                    }}
                  >
                    On-Time Payments
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={financialData.mortgageDetails.paymentHistory.onTime}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      "& .MuiLinearProgress-bar": {
                        bgcolor: "#2e7d32", // Darker success color
                      },
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#2e7d32", // Darker success color
                      mt: 1,
                      textAlign: "right",
                    }}
                  >
                    {financialData.mortgageDetails.paymentHistory.onTime}%
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Property Financials */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <Calculate /> Property Financials
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(financialData.propertyFinancials).map(
              ([key, value]) => (
                <Grid item xs={12} sm={6} md={3} key={key}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                      borderRadius: 2,
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#666", // Darker secondary text
                      }}
                    >
                      {key
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                        .split(" ")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join(" ")}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#1565c0", // Darker blue
                        fontWeight: 600,
                      }}
                    >
                      {typeof value === "number"
                        ? value % 1 === 0
                          ? formatCurrency(value)
                          : `${value}%`
                        : value}
                    </Typography>
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Box>

        {/* Debt Metrics */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <Assessment /> Debt Metrics
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(financialData.debtMetrics).map(([key, value]) => (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#666", // Darker secondary text
                    }}
                  >
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .split(" ")
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                      )
                      .join(" ")}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: getStatusColor(value),
                      fontWeight: 600,
                    }}
                  >
                    {typeof value === "number"
                      ? value % 1 === 0
                        ? formatCurrency(value)
                        : `${value}%`
                      : value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Tax Assessment */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <AttachMoney /> Tax Assessment
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                }}
              >
                <Grid container spacing={2}>
                  {Object.entries(financialData.taxAssessment)
                    .filter(([key]) => key !== "taxExemptions")
                    .map(([key, value]) => (
                      <Grid item xs={6} key={key}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            color: "#666", // Darker secondary text
                          }}
                        >
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .trim()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            color: "#1565c0", // Darker blue
                            fontWeight: 600,
                          }}
                        >
                          {typeof value === "number"
                            ? value % 1 === 0
                              ? formatCurrency(value)
                              : `${value}%`
                            : value}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(46, 125, 50, 0.1)", // Lighter shade of darker success
                  borderRadius: 2,
                  height: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    mb: 2,
                    color: "#333", // Darker text
                  }}
                >
                  Tax Exemptions
                </Typography>
                {financialData.taxAssessment.taxExemptions.map((exemption) => (
                  <Chip
                    key={exemption}
                    label={exemption}
                    sx={{
                      m: 0.5,
                      bgcolor: "#2e7d32", // Darker success color
                      color: "#fff",
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Liens and Encumbrances */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <Warning /> Liens And Encumbrances
          </Typography>
          <Grid container spacing={2}>
            {financialData.liens.map((lien) => (
              <Grid item xs={12} sm={6} key={lien.type}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor:
                      lien.status === "Clear"
                        ? "rgba(46, 125, 50, 0.1)" // Lighter shade of darker success
                        : "rgba(211, 47, 47, 0.1)", // Lighter shade of darker error
                    borderRadius: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        fontWeight: 600,
                        color: "#333", // Darker text
                      }}
                    >
                      {lien.type}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Lato, sans-serif",
                        color: "#666", // Darker secondary text
                      }}
                    >
                      {formatCurrency(lien.amount)}
                    </Typography>
                  </Box>
                  <Chip
                    label={lien.status}
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      bgcolor: lien.status === "Clear" ? "#2e7d32" : "#d32f2f", // Darker success/error colors
                      color: "#fff",
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Cashflow Analysis */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <TrendingUp /> Cashflow Analysis
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                }}
              >
                <Grid container spacing={3}>
                  {Object.entries(financialData.cashflowMetrics)
                    .filter(([key]) => key !== "capRate")
                    .map(([key, value]) => (
                      <Grid item xs={12} sm={6} key={key}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            color: "#666", // Darker secondary text
                          }}
                        >
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .trim()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            color: value > 0 ? "#2e7d32" : "#d32f2f", // Darker success/error colors
                            fontWeight: 600,
                          }}
                        >
                          {formatCurrency(value)}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(21, 101, 192, 0.1)", // Lighter shade of darker blue
                  borderRadius: 2,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: "#666", // Darker secondary text
                  }}
                >
                  Cap Rate
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "Lato, sans-serif",
                    color: "#1565c0", // Darker blue
                    fontWeight: 600,
                    mt: 1,
                  }}
                >
                  {financialData.cashflowMetrics.capRate}%
                </Typography>
                <Tooltip title="Capitalization Rate">
                  <Box>{/* Content */}</Box>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

FinancialDetails.propTypes = {
  listing: PropTypes.shape({
    address: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
};

export default FinancialDetails;
