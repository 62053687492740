import React, { useState, useEffect, useRef } from "react";

const testimonials = [
  {
    content:
      "HomeLeafs gave me hope when I thought it was all lost. They were so supportive and helped me find a way to stay in my home.",
    name: "John Doe",
    title: "Homeowner",
  },
  {
    content:
      "The guidance and resources HomeLeafs provided were invaluable. I was able to work out a solution that saved my home.",
    name: "Jane Smith",
    title: "Real Estate Investor",
  },
  {
    content:
      "I can't thank HomeLeafs enough for their expertise and compassion. They turned a stressful situation into a manageable process.",
    name: "Mike Johnson",
    title: "First-time Homebuyer",
  },
  {
    content:
      "HomeLeafs' innovative approach to housing solutions made all the difference for my family. We're grateful for their support.",
    name: "Sarah Lee",
    title: "Family of Four",
  },
  {
    content:
      "As a real estate professional, I'm impressed by HomeLeafs' commitment to finding creative solutions for homeowners in need.",
    name: "David Chen",
    title: "Real Estate Agent",
  },
  {
    content:
      "HomeLeafs helped me navigate a complex financial situation with ease. Their expertise is unmatched in the industry.",
    name: "Emily Watson",
    title: "Satisfied Client",
  },
];

const TestimonialSlider = () => {
  const [profiles, setProfiles] = useState([]);
  const trackRef = useRef(null);
  const [currentTranslate, setCurrentTranslate] = useState(0);

  const fetchProfiles = async () => {
    const newProfiles = await Promise.all(
      testimonials.map(async () => {
        const response = await fetch("https://randomuser.me/api/");
        const data = await response.json();
        return data.results[0].picture.medium;
      })
    );
    setProfiles(newProfiles);
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  useEffect(() => {
    const itemWidth = 320;
    const moveTestimonials = () => {
      setCurrentTranslate((prevTranslate) => {
        const newTranslate = prevTranslate - 1;
        return newTranslate <= -itemWidth * testimonials.length
          ? 0
          : newTranslate;
      });
    };

    const intervalId = setInterval(moveTestimonials, 50);
    return () => clearInterval(intervalId);
  }, []);

  const sectionStyle = {
    padding: "4rem 2rem",
    overflow: "hidden",
    backgroundColor: "#f9f9f9",
  };

  const titleStyle = {
    textAlign: "center",
    color: "#2c3e50",
    fontSize: "2.5rem",
    marginBottom: "3rem",
  };

  const strikethroughStyle = {
    textDecoration: "line-through",
    opacity: 0.7,
  };

  const sliderStyle = {
    position: "relative",
    maxWidth: "1200px",
    margin: "0 auto",
    overflow: "hidden",
  };

  const trackStyle = {
    display: "flex",
    transition: "transform 0.5s ease",
    transform: `translateX(${currentTranslate}px)`,
  };

  const itemStyle = {
    flex: "0 0 300px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "1.5rem",
    marginRight: "20px",
    display: "flex",
    flexDirection: "column",
  };

  const quoteStyle = {
    fontSize: "2rem",
    color: "#3498db",
    marginBottom: "0.5rem",
  };

  const contentStyle = {
    fontStyle: "italic",
    color: "#34495e",
    marginBottom: "1rem",
    flexGrow: 1,
  };

  const authorStyle = {
    display: "flex",
    alignItems: "center",
  };

  const imageStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginRight: "0.75rem",
  };

  const authorInfoStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const authorNameStyle = {
    fontWeight: "bold",
    color: "#2c3e50",
  };

  const authorTitleStyle = {
    color: "#7f8c8d",
    fontSize: "0.8rem",
  };

  return (
    <section style={sectionStyle}>
      <h2 style={titleStyle}>
        Voices from <span style={strikethroughStyle}>the</span> Your Community
      </h2>
      <div style={sliderStyle}>
        <div style={trackStyle} ref={trackRef}>
          {testimonials
            .concat(testimonials.slice(0, 3))
            .map((testimonial, index) => (
              <div key={index} style={itemStyle}>
                <div style={quoteStyle}>&quot;</div>
                <p style={contentStyle}>{testimonial.content}</p>
                <div style={authorStyle}>
                  <img
                    src={profiles[index % testimonials.length]}
                    alt={testimonial.name}
                    style={imageStyle}
                  />
                  <div style={authorInfoStyle}>
                    <span style={authorNameStyle}>{testimonial.name}</span>
                    <span style={authorTitleStyle}>{testimonial.title}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;
