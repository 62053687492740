import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { 
  TrendingUp, 
  DollarSign, 
  Home, 
  Calendar,
  BarChart,
  Target
} from "lucide-react";

const SummaryContainer = styled(Paper)({
  padding: "32px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "2rem",
  fontWeight: "700",
  marginBottom: "32px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const MetricBox = styled(Box)({
  padding: "24px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  height: "100%",
  transition: "transform 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.02)",
    backgroundColor: "#EEF2FF",
  },
});

const MetricLabel = styled(Typography)({
  fontSize: "1rem",
  color: "#64748B",
  marginTop: "12px",
  fontFamily: "'Lato', sans-serif",
});

const MetricValue = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  color: "#2C3E50",
  marginTop: "8px",
  fontFamily: "'Lato', sans-serif",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "8px",
  "& svg": {
    width: "32px",
    height: "32px",
    color: "#6366F1",
  },
});

const SummaryComponent = ({ financialData, rentalInputs, expenses }) => {
  const calculateMonthlyIncome = () => {
    return rentalInputs?.longTermRent || 0;
  };

  const calculateMonthlyExpenses = () => {
    return expenses?.reduce((sum, expense) => sum + expense.value, 0) || 0;
  };

  const calculateNetIncome = () => {
    return calculateMonthlyIncome() - calculateMonthlyExpenses();
  };

  const metrics = [
    {
      icon: <Home />,
      label: "Property Value",
      value: financialData?.estimatedValue 
        ? `$${financialData.estimatedValue.toLocaleString()}` 
        : "N/A",
    },
    {
      icon: <DollarSign />,
      label: "Monthly Income",
      value: `$${calculateMonthlyIncome().toLocaleString()}`,
    },
    {
      icon: <Calendar />,
      label: "Monthly Expenses",
      value: `$${calculateMonthlyExpenses().toLocaleString()}`,
    },
    {
      icon: <TrendingUp />,
      label: "Net Monthly Income",
      value: `$${calculateNetIncome().toLocaleString()}`,
    },
    {
      icon: <BarChart />,
      label: "Cap Rate",
      value: financialData?.capRate 
        ? `${financialData.capRate}%` 
        : "N/A",
    },
    {
      icon: <Target />,
      label: "ROI",
      value: financialData?.roi 
        ? `${financialData.roi}%` 
        : "N/A",
    },
  ];

  return (
    <SummaryContainer elevation={0}>
      <Title>Investment Summary</Title>
      <Grid container spacing={3}>
        {metrics.map((metric, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <MetricBox>
              <IconWrapper>{metric.icon}</IconWrapper>
              <MetricLabel>{metric.label}</MetricLabel>
              <MetricValue>{metric.value}</MetricValue>
            </MetricBox>
          </Grid>
        ))}
      </Grid>
    </SummaryContainer>
  );
};

SummaryComponent.propTypes = {
  financialData: PropTypes.shape({
    estimatedValue: PropTypes.number,
    capRate: PropTypes.number,
    roi: PropTypes.number,
  }),
  rentalInputs: PropTypes.shape({
    longTermRent: PropTypes.number,
  }),
  expenses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

export default SummaryComponent;
