// FeedPost.jsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import {
  MessageCircle,
  Share2,
  Bookmark,
  Heart,
  MoreHorizontal,
  MapPin,
  Bed,
  Bath,
  Square,
} from "lucide-react";
import { Badge } from "../../components/ui/badge";
import ShareMenu from "./ShareMenu";
import { activityStatuses } from "./constants";

const UserBadge = ({ text }) => (
  <Badge variant="secondary" className="ml-2 text-xs">
    {text}
  </Badge>
);

UserBadge.propTypes = {
  text: PropTypes.string.isRequired
};

const ActivityStatus = ({ status }) => {
  const statusConfig = activityStatuses[status] || activityStatuses.offline;
  return (
    <div className="flex items-center">
      <div className={`w-2 h-2 rounded-full ${statusConfig.color} mr-1`} />
      <span className="text-xs text-gray-500">{statusConfig.text}</span>
    </div>
  );
};

ActivityStatus.propTypes = {
  status: PropTypes.string
};

ActivityStatus.defaultProps = {
  status: 'offline'
};

const ImageGallery = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  if (!images?.length) return null;

  return (
    <div className="relative rounded-lg overflow-hidden mb-4">
      <img
        src={images[activeIndex]}
        alt="Property"
        className="w-full h-96 object-cover"
      />
      {images.length > 1 && (
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => setActiveIndex(index)}
              className={`w-2 h-2 rounded-full transition-colors ${
                index === activeIndex ? "bg-white" : "bg-white/50"
              }`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
};

ImageGallery.defaultProps = {
  images: []
};

const PropertyDetails = ({ details }) => {
  if (!details) return null;

  const specs = details.specs?.split("•") || [];
  const [beds, baths, sqft] = specs.map(spec => spec?.trim()).filter(Boolean);

  return (
    <div className="bg-gray-50 rounded-lg p-4 mb-4">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-xl font-bold text-blue-600">{details.price}</h3>
        <Badge variant="outline">{details.type}</Badge>
      </div>
      <div className="flex items-center text-gray-600 mb-2">
        <MapPin className="w-4 h-4 mr-1" />
        <span>{details.location}</span>
      </div>
      {specs.length > 0 && (
        <div className="flex items-center gap-4 text-gray-600">
          {beds && (
            <div className="flex items-center">
              <Bed className="w-4 h-4 mr-1" />
              <span>{beds}</span>
            </div>
          )}
          {baths && (
            <div className="flex items-center">
              <Bath className="w-4 h-4 mr-1" />
              <span>{baths}</span>
            </div>
          )}
          {sqft && (
            <div className="flex items-center">
              <Square className="w-4 h-4 mr-1" />
              <span>{sqft}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

PropertyDetails.propTypes = {
  details: PropTypes.shape({
    price: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    specs: PropTypes.string,
  })
};

PropertyDetails.defaultProps = {
  details: null
};

const FeedPost = ({ post, onInteraction }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState(post.stats?.likes || 0);
  const [shareMenu, setShareMenu] = useState(false);

  const handleLike = () => {
    setIsLiked(!isLiked);
    setLikes(isLiked ? likes - 1 : likes + 1);
    onInteraction({
      type: "like",
      postId: post.id?.toString() || post._id,
      action: !isLiked ? "liked" : "unliked",
    });
  };

  const handleComment = () => {
    if (!newComment.trim()) return;

    onInteraction({
      type: "comment",
      postId: post.id?.toString() || post._id,
      content: newComment,
    });

    setNewComment("");
  };

  const handleShare = (platform) => {
    onInteraction({
      type: "share",
      postId: post.id?.toString() || post._id,
      platform,
    });
    setShareMenu(false);
  };

  const handleBookmark = () => {
    setIsBookmarked(!isBookmarked);
    onInteraction({
      type: "bookmark",
      postId: post.id?.toString() || post._id,
      action: !isBookmarked ? "saved" : "unsaved",
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-sm mb-4 overflow-hidden"
    >
      <div className="p-4">
        {/* Post Header */}
        <div className="flex justify-between items-start mb-4">
          <div className="flex items-center gap-3">
            <div className="relative">
              <Link to={`/profile/${post.user?.username}`}>
                <img
                  src={post.user?.avatar}
                  alt={post.user?.name}
                  className="w-10 h-10 rounded-full"
                />
              </Link>
              <div className="absolute -bottom-1 -right-1">
                <ActivityStatus status={post.user?.status} />
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <Link
                  to={`/profile/${post.user?.username}`}
                  className="font-semibold hover:underline"
                >
                  {post.user?.name}
                </Link>
                {post.user?.verified && (
                  <svg
                    className="w-4 h-4 text-blue-500 ml-1"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                  </svg>
                )}
                {post.user?.badges?.map((badge) => (
                  <UserBadge key={badge} text={badge} />
                ))}
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <span>{post.user?.role}</span>
                {post.user?.stats && (
                  <>
                    <span>•</span>
                    <span>{post.user.stats.deals} deals</span>
                    <span>•</span>
                    <span>⭐️ {post.user.stats.rating}</span>
                  </>
                )}
                <span>•</span>
                <span>{post.timestamp}</span>
              </div>
            </div>
          </div>
          <button className="p-2 hover:bg-gray-100 rounded-full">
            <MoreHorizontal className="w-5 h-5 text-gray-600" />
          </button>
        </div>

        {/* Post Content */}
        <div className="mb-4">
          <p className={`text-gray-800 ${!isExpanded && "line-clamp-4"}`}>
            {post.content}
          </p>
          {post.content?.length > 280 && (
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-blue-500 text-sm mt-2 hover:underline"
            >
              {isExpanded ? "Show less" : "Show more"}
            </button>
          )}
        </div>

        {/* Property Details */}
        {post.propertyDetails && (
          <PropertyDetails details={post.propertyDetails} />
        )}

        {/* Image Gallery */}
        {post.images && <ImageGallery images={post.images} />}

        {/* Post Actions */}
        <div className="flex items-center justify-between pt-3 border-t">
          <div className="flex gap-4">
            <button
              onClick={handleLike}
              className={`flex items-center gap-1 ${
                isLiked ? "text-red-500" : "text-gray-600"
              } hover:text-red-500 transition-colors`}
            >
              <Heart className={`w-5 h-5 ${isLiked ? "fill-current" : ""}`} />
              <span>{likes}</span>
            </button>
            <button
              onClick={() => setShowComments(!showComments)}
              className="flex items-center gap-1 text-gray-600 hover:text-blue-500 transition-colors"
            >
              <MessageCircle className="w-5 h-5" />
              <span>{post.stats?.comments}</span>
            </button>
            <div className="relative">
              <button
                onClick={() => setShareMenu(!shareMenu)}
                className="flex items-center gap-1 text-gray-600 hover:text-blue-500 transition-colors"
              >
                <Share2 className="w-5 h-5" />
                <span>{post.stats?.shares}</span>
              </button>
              <AnimatePresence>
                {shareMenu && (
                  <ShareMenu
                    onShare={handleShare}
                    onClose={() => setShareMenu(false)}
                  />
                )}
              </AnimatePresence>
            </div>
          </div>
          <button
            onClick={handleBookmark}
            className={`${
              isBookmarked ? "text-yellow-500" : "text-gray-600"
            } hover:text-yellow-500 transition-colors`}
          >
            <Bookmark className="w-5 h-5" />
          </button>
        </div>

        {/* Comments Section */}
        <AnimatePresence>
          {showComments && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="mt-4 border-t pt-4"
            >
              <div className="flex gap-3 mb-4">
                <img
                  src="/assets/avatar.png"
                  alt="User"
                  className="w-8 h-8 rounded-full"
                />
                <div className="flex-1">
                  <input
                    type="text"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Write a comment..."
                    className="w-full p-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
                <button
                  onClick={handleComment}
                  disabled={!newComment.trim()}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50"
                >
                  Post
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

FeedPost.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    _id: PropTypes.string,
    content: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string),
    timestamp: PropTypes.string.isRequired,
    propertyDetails: PropTypes.shape({
      price: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      specs: PropTypes.string,
      type: PropTypes.string.isRequired,
    }),
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      verified: PropTypes.bool,
      status: PropTypes.string,
      badges: PropTypes.arrayOf(PropTypes.string),
      stats: PropTypes.shape({
        deals: PropTypes.number,
        rating: PropTypes.number,
      }),
    }).isRequired,
    stats: PropTypes.shape({
      likes: PropTypes.number,
      comments: PropTypes.number,
      shares: PropTypes.number,
    }).isRequired,
  }).isRequired,
  onInteraction: PropTypes.func.isRequired,
};

export default FeedPost;
