import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ProfileCard,
  StatusUpdateForm,
  FeedPost,
  TrendingTopics,
  SuggestedConnections,
  QuickLinks,
} from "../components/Timeline";
import { feedPosts } from "../components/Timeline/constants";

// Enhanced demo posts with random user images
const enhancedFeedPosts = feedPosts.map((post, index) => ({
  ...post,
  user: {
    ...post.user,
    avatar: `https://randomuser.me/api/portraits/${index % 2 ? 'women' : 'men'}/${index + 1}.jpg`
  }
}));

const Timeline = () => {
  const [statusUpdates, setStatusUpdates] = useState([]);
  const [error, setError] = useState("");
  const [combinedFeed, setCombinedFeed] = useState([]);
  const [isBackendAvailable, setIsBackendAvailable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const currentUser = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    const checkBackendStatus = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/health`
        );
        setIsBackendAvailable(response.ok);
      } catch (err) {
        console.error("Backend health check failed:", err);
        setIsBackendAvailable(false);
      }
    };

    checkBackendStatus();
  }, []);

  useEffect(() => {
    const fetchStatusUpdates = async () => {
      if (!isBackendAvailable || !token) {
        setStatusUpdates([]);
        return;
      }

      try {
        setIsLoading(true);
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/api/status-updates`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        if (!data) throw new Error('No data received');
        
        // Enhance status updates with random user images if they don't have one
        const enhancedData = data.map((update, index) => ({
          ...update,
          user: {
            ...update.user,
            avatar: update.user?.avatar || `https://randomuser.me/api/portraits/${index % 2 ? 'women' : 'men'}/${Math.floor(Math.random() * 70)}.jpg`
          }
        }));
        
        setStatusUpdates(enhancedData);
      } catch (err) {
        console.error("Error fetching status updates:", err);
        // Don't show error for demo mode
        if (!isBackendAvailable) {
          setError(null);
        } else {
          setError("Unable to fetch updates. Showing demo content.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchStatusUpdates();
  }, [token, isBackendAvailable]);

  useEffect(() => {
    const combined = [
      ...statusUpdates,
      ...enhancedFeedPosts.map((post) => ({
        ...post,
        isDemo: true,
      })),
    ].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    setCombinedFeed(combined);
  }, [statusUpdates]);

  const createNotification = async (userId, type, title, message) => {
    if (!isBackendAvailable) return;

    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/notifications`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId,
            type,
            title,
            message,
            read: false,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to create notification. Status: ${response.status}`
        );
      }
    } catch (err) {
      console.error("Error creating notification:", err);
    }
  };

  const handlePost = async (content, attachments) => {
    if (!isBackendAvailable) {
      setError("Cannot post updates while offline. Please try again later.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("content", content);
      attachments.forEach((file) => {
        formData.append("attachments", file);
      });

      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/status-updates`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to post status update. Status: ${response.status}`
        );
      }

      const newStatusUpdate = await response.json();
      // Add random avatar if not provided
      const enhancedUpdate = {
        ...newStatusUpdate,
        user: {
          ...newStatusUpdate.user,
          avatar: newStatusUpdate.user?.avatar || `https://randomuser.me/api/portraits/${Math.random() > 0.5 ? 'women' : 'men'}/${Math.floor(Math.random() * 70)}.jpg`
        }
      };
      
      setStatusUpdates([enhancedUpdate, ...statusUpdates]);

      // Create notification for followers
      if (currentUser?.followers?.length > 0) {
        currentUser.followers.forEach((followerId) => {
          createNotification(
            followerId,
            "network",
            "New Status Update",
            `${currentUser.name} posted a new status update`
          );
        });
      }
    } catch (err) {
      setError("Failed to post status update. Please try again.");
      console.error("Error posting status update:", err);
    }
  };

  const handleInteraction = async ({ type, postId, action, content }) => {
    if (!currentUser?._id || !isBackendAvailable) return;

    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/post-interactions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            type,
            postId,
            userId: currentUser._id,
            action,
            content,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to record interaction. Status: ${response.status}`
        );
      }

      // Find the post owner to create notification
      const post = combinedFeed.find((p) => (p.id || p._id) === postId);
      if (!post || post.isDemo) return;

      // Create appropriate notification based on interaction type
      switch (type) {
        case "like":
          createNotification(
            post.user._id,
            "network",
            "New Like",
            `${currentUser.name} liked your post`
          );
          break;
        case "comment":
          createNotification(
            post.user._id,
            "network",
            "New Comment",
            `${currentUser.name} commented on your post: "${content.substring(
              0,
              50
            )}${content.length > 50 ? "..." : ""}"`
          );
          break;
        case "share":
          createNotification(
            post.user._id,
            "network",
            "Post Shared",
            `${currentUser.name} shared your post`
          );
          break;
        default:
          break;
      }
    } catch (err) {
      console.error("Error handling interaction:", err);
    }
  };

  const handleFollow = async (userId) => {
    if (!isBackendAvailable) return;
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/follow`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            followerId: currentUser._id,
            followingId: userId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to follow user. Status: ${response.status}`);
      }

      // Handle successful follow
      createNotification(
        userId,
        "network",
        "New Follower",
        `${currentUser.name} started following you`
      );
    } catch (err) {
      console.error("Error following user:", err);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-12 gap-6">
        {/* Left Sidebar */}
        <div className="col-span-3 space-y-4">
          <ProfileCard user={currentUser} />
          <QuickLinks />
        </div>

        {/* Main Feed */}
        <div className="col-span-6">
          {!isBackendAvailable && (
            <div className="bg-yellow-50 text-yellow-800 p-4 rounded-lg mb-6">
              You are currently offline. Some features may be limited.
            </div>
          )}

          <StatusUpdateForm onPost={handlePost} />

          {error && (
            <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
              {error}
            </div>
          )}

          {isLoading ? (
            <div className="text-center py-4">Loading...</div>
          ) : (
            <div className="space-y-6">
              {combinedFeed.map((post) => (
                <FeedPost
                  key={post.id || post._id}
                  post={post}
                  onInteraction={handleInteraction}
                />
              ))}
            </div>
          )}
        </div>

        {/* Right Sidebar */}
        <div className="col-span-3 space-y-4">
          <TrendingTopics />
          <SuggestedConnections onFollow={handleFollow} />
        </div>
      </div>
    </div>
  );
};

export default Timeline;
