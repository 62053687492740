import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Calculator } from "lucide-react";

const ScenarioContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const ScenarioBox = styled(Box)({
  padding: "16px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  marginBottom: "16px",
  transition: "transform 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const ScenarioLabel = styled(Typography)({
  fontSize: "0.9rem",
  color: "#64748B",
  marginBottom: "8px",
  fontFamily: "'Lato', sans-serif",
});

const ScenarioValue = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: "600",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
  "& svg": {
    width: "48px",
    height: "48px",
    color: "#6366F1",
  },
});

const FinancialScenario = ({ propertyValue }) => {
  const calculateMortgage = (downPaymentPercent) => {
    if (!propertyValue) return "N/A";
    const downPayment = propertyValue * (downPaymentPercent / 100);
    const loanAmount = propertyValue - downPayment;
    const monthlyPayment = (loanAmount * 0.045) / 12; // Assuming 4.5% interest rate
    return monthlyPayment.toLocaleString(undefined, { maximumFractionDigits: 0 });
  };

  return (
    <ScenarioContainer elevation={0}>
      <IconWrapper>
        <Calculator />
      </IconWrapper>
      <Title variant="h5">Financial Scenarios</Title>

      <ScenarioBox>
        <ScenarioLabel>20% Down Payment</ScenarioLabel>
        <ScenarioValue>
          ${calculateMortgage(20)}/month
        </ScenarioValue>
      </ScenarioBox>

      <ScenarioBox>
        <ScenarioLabel>25% Down Payment</ScenarioLabel>
        <ScenarioValue>
          ${calculateMortgage(25)}/month
        </ScenarioValue>
      </ScenarioBox>

      <ScenarioBox>
        <ScenarioLabel>30% Down Payment</ScenarioLabel>
        <ScenarioValue>
          ${calculateMortgage(30)}/month
        </ScenarioValue>
      </ScenarioBox>

      <ScenarioBox>
        <ScenarioLabel>Property Value</ScenarioLabel>
        <ScenarioValue>
          ${propertyValue ? propertyValue.toLocaleString() : "N/A"}
        </ScenarioValue>
      </ScenarioBox>
    </ScenarioContainer>
  );
};

FinancialScenario.propTypes = {
  propertyValue: PropTypes.number,
};

export default FinancialScenario;
