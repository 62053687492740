// StatusUpdateForm.jsx
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Image as ImageIcon, Link as LinkIcon, Smile } from "lucide-react";

const StatusUpdateForm = ({ onPost }) => {
  const [content, setContent] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const currentUser = useSelector((state) => state.user.currentUser);

  const handleSubmit = (e) => {
    e.preventDefault();
    onPost(content, attachments);
    setContent("");
    setAttachments([]);
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-4 mb-6">
      <form onSubmit={handleSubmit}>
        <div className="flex gap-3">
          <img
            src={currentUser?.avatar || "/assets/avatar.png"}
            alt="User"
            className="w-10 h-10 rounded-full"
          />
          <div className="flex-1">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="What's on your mind?"
              className="w-full p-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
              rows={3}
            />
            {attachments.length > 0 && (
              <div className="flex gap-2 mt-2">
                {attachments.map((file, index) => (
                  <div key={index} className="relative">
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Attachment"
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                    <button
                      onClick={() =>
                        setAttachments(
                          attachments.filter((_, i) => i !== index)
                        )
                      }
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div className="flex justify-between items-center mt-3">
              <div className="flex gap-2">
                <label className="p-2 hover:bg-gray-100 rounded-full cursor-pointer">
                  <ImageIcon className="w-5 h-5 text-gray-500" />
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    className="hidden"
                    onChange={(e) =>
                      setAttachments([...attachments, ...e.target.files])
                    }
                  />
                </label>
                <button
                  type="button"
                  className="p-2 hover:bg-gray-100 rounded-full"
                  onClick={() => setShowEmoji(!showEmoji)}
                >
                  <Smile className="w-5 h-5 text-gray-500" />
                </button>
                <button
                  type="button"
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <LinkIcon className="w-5 h-5 text-gray-500" />
                </button>
              </div>
              <button
                type="submit"
                disabled={!content.trim() && attachments.length === 0}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

StatusUpdateForm.propTypes = {
  onPost: PropTypes.func.isRequired,
};

export default StatusUpdateForm;
