import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, CircularProgress, Alert } from "@mui/material";
import useListing from "../hooks/useListing";
import useListingActions from "../hooks/useListingActions";
import { useLikeListings } from "../hooks/useLikeListings";
import { useSelector } from "react-redux";
import "mapbox-gl/dist/mapbox-gl.css";
import { defaultInvestmentMetrics } from "../constants/investmentMetrics";
import MessageModal from "../components/listings/MessageModal";

// Component Imports
import AuthModal from "../components/listings/AuthModal";
import QuickActions from "../components/listings/QuickActions";
import ListingHero from "../components/listings/ListingHero";
import ListingMainContent from "../components/listings/ListingMainContent";
import ListingSidebar from "../components/listings/ListingSidebar";
import ListingAnalysis from "../components/listings/ListingAnalysis";

const Listing = () => {
  const { listingId } = useParams();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { listing, loading, error, coordinates } = useListing(listingId);
  const { likedListings, toggleLike } = useLikeListings();

  const [messageModalOpen, setMessageModalOpen] = useState(false);

  const {
    isAuthModalOpen,
    setIsAuthModalOpen,
    handlePremiumFeatureClick,
    handleViewInvestmentAnalysis,
  } = useListingActions(listing);

  // Check if the current listing is liked
  const isLiked = likedListings?.some((id) => id === listingId);

  // Handle like toggle
  const handleLikeToggle = async () => {
    if (!currentUser) {
      setIsAuthModalOpen(true);
      return;
    }
    try {
      await toggleLike(listingId);
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  // Handle dashboard actions
  const handleDashboardAction = useCallback(async () => {
    if (!currentUser) {
      setIsAuthModalOpen(true);
      return;
    }
    try {
      // Implement dashboard toggle logic here
      console.log("Toggling dashboard status");
    } catch (error) {
      console.error("Error updating dashboard:", error);
    }
  }, [currentUser, setIsAuthModalOpen]);

  // Handle share action
  const handleShareAction = useCallback(() => {
    const url = window.location.href;
    if (navigator.share) {
      navigator
        .share({
          title: listing?.name || "Property Listing",
          text: `Check out this property: ${listing?.address}`,
          url: url,
        })
        .catch(console.error);
    } else {
      navigator.clipboard.writeText(url);
      // You could add a toast notification here
      console.log("URL copied to clipboard");
    }
  }, [listing]);

  // Handle message modal
  const handleMessageModalClose = () => {
    setMessageModalOpen(false);
  };

  const handleContactOwner = () => {
    if (!currentUser) {
      setIsAuthModalOpen(true);
      return;
    }
    setMessageModalOpen(true);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        sx={{ backgroundColor: "#f5f5f5" }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4} sx={{ backgroundColor: "#f5f5f5" }}>
        <Container maxWidth="lg">
          <Alert
            severity="error"
            variant="filled"
            sx={{
              fontFamily: "Lato, sans-serif",
              "& .MuiAlert-message": {
                fontSize: "1.1rem",
              },
            }}
          >
            {error}
          </Alert>
        </Container>
      </Box>
    );
  }

  if (!listing) {
    return (
      <Box p={4} sx={{ backgroundColor: "#f5f5f5" }}>
        <Container maxWidth="lg">
          <Alert
            severity="info"
            variant="filled"
            sx={{
              fontFamily: "Lato, sans-serif",
              "& .MuiAlert-message": {
                fontSize: "1.1rem",
              },
            }}
          >
            Listing not found. It may have been removed or is no longer
            available.
          </Alert>
        </Container>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        py: 4,
        fontFamily: "Lato, sans-serif",
      }}
    >
      <AuthModal
        open={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        isLoggedIn={!!currentUser}
      />

      <Container maxWidth="lg">
        {/* Hero Section */}
        <ListingHero
          listing={listing}
          liked={isLiked}
          inDashboard={false}
          onLike={handleLikeToggle}
          onShare={handleShareAction}
          onAddToDashboard={handleDashboardAction}
        />

        {/* Quick Actions */}
        <QuickActions
          onViewInvestmentAnalysis={() =>
            handleViewInvestmentAnalysis(defaultInvestmentMetrics)
          }
        />

        {/* Main Content */}
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={8}>
            <ListingMainContent
              listing={listing}
              onPremiumFeatureClick={handlePremiumFeatureClick}
            />

            <ListingAnalysis
              listing={listing}
              currentUser={currentUser}
              onPremiumFeatureClick={handlePremiumFeatureClick}
            />
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={4}>
            <ListingSidebar
              listing={listing}
              latitude={coordinates?.latitude}
              longitude={coordinates?.longitude}
              investmentMetrics={defaultInvestmentMetrics}
              onPremiumFeatureClick={handlePremiumFeatureClick}
              onContactOwner={handleContactOwner}
              currentUser={currentUser}
            />
          </Grid>
        </Grid>
      </Container>

      {/* Contact Owner Modal */}
      {messageModalOpen && (
        <MessageModal
          open={messageModalOpen}
          onClose={handleMessageModalClose}
          listing={listing}
          currentUser={currentUser}
        />
      )}
    </Box>
  );
};

export default Listing;
