import { useState, useEffect, useCallback } from 'react';
import { walkScoreService } from '../services/walkScoreService';

export function useWalkScore(initialLocation = null) {
  const [scores, setScores] = useState(null);
  const [amenities, setAmenities] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchWalkScoreData = useCallback(async (location) => {
    if (!location || !location.lat || !location.lon) {
      setError('Invalid location data');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Fetch Walk Score data and amenities in parallel
      const [scoresData, amenitiesData] = await Promise.all([
        walkScoreService.getLocationScores(location),
        walkScoreService.getDetailedAmenities(location)
      ]);

      setScores(scoresData);
      setAmenities(amenitiesData);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching Walk Score data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  // Initial fetch if location is provided
  useEffect(() => {
    if (initialLocation) {
      fetchWalkScoreData(initialLocation);
    }
  }, [initialLocation, fetchWalkScoreData]);

  const refreshData = useCallback((location) => {
    if (location) {
      fetchWalkScoreData(location);
    }
  }, [fetchWalkScoreData]);

  // Calculate convenience metrics
  const convenienceMetrics = useCallback(() => {
    if (!amenities || !amenities.categories) return null;

    return {
      walkability: calculateWalkabilityScore(amenities.categories),
      convenience: calculateConvenienceScore(amenities.categories),
      lifestyle: calculateLifestyleScore(amenities.categories)
    };
  }, [amenities]);

  return {
    scores,
    amenities,
    loading,
    error,
    fetchWalkScoreData,
    refreshData,
    convenienceMetrics: convenienceMetrics()
  };
}

// Helper functions for calculating convenience metrics
function calculateWalkabilityScore(categories) {
  const weights = {
    grocery: 0.3,
    restaurant: 0.2,
    park: 0.2,
    shopping: 0.15,
    coffee: 0.15
  };

  let score = 0;
  let totalWeight = 0;

  Object.entries(categories).forEach(([type, data]) => {
    if (weights[type]) {
      const typeScore = calculateTypeScore(data);
      score += typeScore * weights[type];
      totalWeight += weights[type];
    }
  });

  return (score / totalWeight).toFixed(1);
}

function calculateConvenienceScore(categories) {
  const essentials = ['grocery', 'bank', 'pharmacy', 'transit'];
  let score = 0;

  essentials.forEach(type => {
    if (categories[type]) {
      score += calculateTypeScore(categories[type]);
    }
  });

  return (score / essentials.length).toFixed(1);
}

function calculateLifestyleScore(categories) {
  const lifestyle = ['restaurant', 'coffee', 'park', 'gym', 'shopping'];
  let score = 0;

  lifestyle.forEach(type => {
    if (categories[type]) {
      score += calculateTypeScore(categories[type]);
    }
  });

  return (score / lifestyle.length).toFixed(1);
}

function calculateTypeScore(categoryData) {
  const COUNT_WEIGHT = 0.6;
  const DISTANCE_WEIGHT = 0.4;

  const countScore = Math.min(categoryData.count / 5, 1);
  const distanceScore = Math.max(0, 1 - (categoryData.averageDistance / 1.5));

  return (countScore * COUNT_WEIGHT) + (distanceScore * DISTANCE_WEIGHT);
}
