import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
} from "@mui/material";

const MessageModal = ({ open, onClose, listing, currentUser }) => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    // Implement send message logic here
    console.log("Sending message:", message);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontFamily: "Lato, sans-serif" }}>
        Contact Owner
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Property: {listing?.address}
          </Typography>
        </Box>
        <TextField
          autoFocus
          multiline
          rows={4}
          fullWidth
          label="Your Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSend} variant="contained" color="primary">
          Send Message
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MessageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  listing: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default MessageModal;
