import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = import.meta.env.VITE_MAP_TOKEN;

const MapView = ({ filteredProperties }) => {
  const [viewport, setViewport] = useState({
    latitude: 39.9526,
    longitude: -75.1652,
    zoom: 13,
    width: "100%",
    height: "400px",
  });

  const [selectedProperty, setSelectedProperty] = useState(null);

  return (
    <aside className="w-full md:w-1/3 bg-white rounded-lg shadow-md mt-4 md:mt-0">
      <ReactMapGL
        {...viewport}
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
        onClick={() => setSelectedProperty(null)}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxApiAccessToken={import.meta.env.REACT_APP_MAP_TOKEN}
      >
        {filteredProperties.map((property) => (
          <Marker
            key={property.id}
            latitude={property.lat}
            longitude={property.lng}
            onClick={(e) => {
              e.preventDefault();
              setSelectedProperty(property);
            }}
          >
            <div className="marker" />
          </Marker>
        ))}

        {selectedProperty && (
          <Popup
            latitude={selectedProperty.lat}
            longitude={selectedProperty.lng}
            onClose={() => setSelectedProperty(null)}
          >
            <div>
              <h3>{selectedProperty.title}</h3>
              <p>${selectedProperty.price.toLocaleString()}</p>
              <p>
                {selectedProperty.beds} beds, {selectedProperty.baths} baths
              </p>
              <p>{selectedProperty.size} ft²</p>
            </div>
          </Popup>
        )}
      </ReactMapGL>
    </aside>
  );
};

MapView.propTypes = {
  filteredProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      beds: PropTypes.number.isRequired,
      baths: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default MapView;
