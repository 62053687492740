import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { Assessment, Lock } from "@mui/icons-material";
import AIInsights from "./AIInsights";
import MortgageCalculator from "./MortgageCalculator";

const ListingAnalysis = ({ listing, currentUser, onPremiumFeatureClick }) => {
  return (
    <>
      {/* Mortgage Calculator Card */}
      <Card
        sx={{
          mb: 4,
          borderRadius: 4,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardContent>
          <MortgageCalculator propertyPrice={listing.price} />
          <Button
            variant="contained"
            color="primary"
            onClick={onPremiumFeatureClick}
            startIcon={<Lock />}
            sx={{
              mt: 2,
              borderRadius: 2,
              textTransform: "none",
              fontFamily: "'Lato', sans-serif",
              width: "100%",
            }}
          >
            View Advanced Financing Options
          </Button>
        </CardContent>
      </Card>

      {/* AI Insights Card */}
      <Card
        sx={{
          mb: 4,
          borderRadius: 4,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "'Lato', sans-serif",
              fontWeight: 600,
              mb: 3,
            }}
          >
            <Assessment sx={{ mr: 1, color: "primary.main" }} />
            AI Investment Insights
          </Typography>
          <AIInsights listing={listing} userProfile={currentUser} />
          <Button
            variant="contained"
            color="primary"
            onClick={onPremiumFeatureClick}
            startIcon={<Lock />}
            sx={{
              mt: 2,
              borderRadius: 2,
              textTransform: "none",
              fontFamily: "'Lato', sans-serif",
            }}
          >
            Access Full AI Analysis
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

ListingAnalysis.propTypes = {
  listing: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  onPremiumFeatureClick: PropTypes.func.isRequired,
};

export default ListingAnalysis;
