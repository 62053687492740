import React from 'react';
import { motion } from 'framer-motion';
import { formatCurrency } from '../../utils/formatters';

const QuickStats = ({ property, className = '' }) => {
  if (!property) return null;

  const stats = [
    {
      label: 'Estimated Value',
      value: formatCurrency(property.financialData?.estimatedValue || 0),
      icon: '💰',
      color: 'bg-blue-500',
    },
    {
      label: 'ROI',
      value: `${(property.financialData?.roi || 0).toFixed(2)}%`,
      icon: '📈',
      color: 'bg-green-500',
    },
    {
      label: 'Risk Score',
      value: `${(property.riskAssessment?.score || 0).toFixed(1)}/10`,
      icon: '⚠️',
      color: 'bg-yellow-500',
    },
    {
      label: 'Market Trend',
      value: property.marketTrends?.direction === 'up' ? 'Rising' : 'Declining',
      icon: property.marketTrends?.direction === 'up' ? '⬆️' : '⬇️',
      color: property.marketTrends?.direction === 'up' ? 'bg-green-500' : 'bg-red-500',
    }
  ];

  return (
    <div className={`grid grid-cols-4 gap-4 ${className}`}>
      {stats.map((stat, index) => (
        <motion.div
          key={stat.label}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="relative overflow-hidden rounded-xl bg-white shadow-lg p-4"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500">{stat.label}</p>
              <p className="text-xl font-bold mt-1">{stat.value}</p>
            </div>
            <div className={`${stat.color} w-10 h-10 rounded-full flex items-center justify-center text-white`}>
              {stat.icon}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-200">
            <motion.div
              className={`h-full ${stat.color}`}
              initial={{ width: 0 }}
              animate={{ width: '100%' }}
              transition={{ duration: 1 }}
            />
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default QuickStats;
