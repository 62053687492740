import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const PeopleSlider = ({ people }) => {
  const [currentTranslate, setCurrentTranslate] = useState(0);
  const trackRef = useRef(null);

  useEffect(() => {
    const itemWidth = 320;
    const moveSlider = () => {
      setCurrentTranslate((prevTranslate) => {
        const newTranslate = prevTranslate - 1;
        return newTranslate <= -itemWidth * people.length ? 0 : newTranslate;
      });
    };

    const intervalId = setInterval(moveSlider, 50);
    return () => clearInterval(intervalId);
  }, [people.length]);

  return (
    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 overflow-hidden">
      <div
        ref={trackRef}
        className="flex transition-transform duration-500"
        style={{ transform: `translateX(${currentTranslate}px)` }}
      >
        {people.concat(people.slice(0, 3)).map((person, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-80 bg-white rounded-lg shadow-md overflow-hidden m-4"
          >
            <img
              src={person.image}
              alt={person.name}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h3 className="text-lg font-semibold text-gray-800">
                {person.name}
              </h3>
              <p className="text-sm text-gray-600">{person.title}</p>
              <div className="mt-2 flex flex-wrap">
                {person.tags.map((tag, tagIndex) => (
                  <span
                    key={tagIndex}
                    className="mr-1 mb-1 px-2 py-1 bg-green-100 text-green-700 rounded-full text-xs"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

PeopleSlider.propTypes = {
  people: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};

export default PeopleSlider;
