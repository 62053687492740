import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DollarSign } from "lucide-react";

const IncomeContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const SliderContainer = styled(Box)({
  padding: "20px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  marginBottom: "16px",
});

const SliderLabel = styled(Typography)({
  fontSize: "0.9rem",
  color: "#64748B",
  marginBottom: "12px",
  fontFamily: "'Lato', sans-serif",
});

const SliderValue = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: "600",
  color: "#2C3E50",
  marginTop: "12px",
  fontFamily: "'Lato', sans-serif",
});

const StyledSlider = styled(Slider)({
  color: "#6366F1",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#6366F1",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
  "& svg": {
    width: "48px",
    height: "48px",
    color: "#6366F1",
  },
});

const RentalIncome = ({ rentalInputs, onRentalInputChange }) => {
  const handleLongTermRentChange = (_, value) => {
    onRentalInputChange({ ...rentalInputs, longTermRent: value });
  };

  const handleShortTermADRChange = (_, value) => {
    onRentalInputChange({ ...rentalInputs, shortTermADR: value });
  };

  const handleOccupancyChange = (_, value) => {
    onRentalInputChange({ ...rentalInputs, shortTermOccupancy: value });
  };

  return (
    <IncomeContainer elevation={0}>
      <IconWrapper>
        <DollarSign />
      </IconWrapper>
      <Title variant="h5">Rental Income Analysis</Title>

      <SliderContainer>
        <SliderLabel>Long Term Monthly Rent</SliderLabel>
        <StyledSlider
          value={rentalInputs.longTermRent}
          onChange={handleLongTermRentChange}
          min={500}
          max={10000}
          step={100}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `$${value}`}
        />
        <SliderValue>${rentalInputs.longTermRent}</SliderValue>
      </SliderContainer>

      <SliderContainer>
        <SliderLabel>Short Term Daily Rate (ADR)</SliderLabel>
        <StyledSlider
          value={rentalInputs.shortTermADR}
          onChange={handleShortTermADRChange}
          min={50}
          max={1000}
          step={10}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `$${value}`}
        />
        <SliderValue>${rentalInputs.shortTermADR}</SliderValue>
      </SliderContainer>

      <SliderContainer>
        <SliderLabel>Short Term Occupancy Rate</SliderLabel>
        <StyledSlider
          value={rentalInputs.shortTermOccupancy}
          onChange={handleOccupancyChange}
          min={0}
          max={100}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
        />
        <SliderValue>{rentalInputs.shortTermOccupancy}%</SliderValue>
      </SliderContainer>
    </IncomeContainer>
  );
};

RentalIncome.propTypes = {
  rentalInputs: PropTypes.shape({
    longTermRent: PropTypes.number.isRequired,
    shortTermADR: PropTypes.number.isRequired,
    shortTermOccupancy: PropTypes.number.isRequired,
  }).isRequired,
  onRentalInputChange: PropTypes.func.isRequired,
};

export default RentalIncome;
