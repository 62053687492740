import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { Error } from "@mui/icons-material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    // You can also log the error to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      return (
        <Card sx={{ borderRadius: 4, boxShadow: 3, bgcolor: "#FFF3F3" }}>
          <CardContent>
            <Typography
              variant="h6"
              color="error"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Error sx={{ mr: 1 }} /> An error occurred
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              {this.state.error && this.state.error.toString()}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() =>
                this.setState({ hasError: false, error: null, errorInfo: null })
              }
            >
              Try again
            </Button>
          </CardContent>
        </Card>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
