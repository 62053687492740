import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const PropertyGrid = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperties = async () => {
      const options = {
        method: "GET",
        headers: {
          "X-RapidAPI-Key":
            "2a5a4af300msh7c9c88c6749e9e5p1d1f9djsn3b4db5e0571f",
          "X-RapidAPI-Host": "bayut.p.rapidapi.com",
        },
      };

      try {
        const response = await fetch(
          "https://bayut.p.rapidapi.com/properties/list?locationExternalIDs=5002%2C6020&purpose=for-sale&hitsPerPage=10&page=0&lang=en&sort=city-level-score&rentFrequency=monthly&categoryExternalID=4",
          options
        );
        const data = await response.json();

        // Transform the API data to match our property structure
        const transformedProperties = data.hits.map((property) => ({
          id: property.id || Math.random().toString(),
          title: property.title || "Beautiful Property",
          price: property.price || 499000,
          address:
            property.location?.join(", ") || "Location Available Upon Request",
          beds: property.rooms || 3,
          baths: property.baths || 2,
          image:
            property.coverPhoto?.url ||
            `https://picsum.photos/seed/${Math.random()}/400/300`,
          status: getRandomStatus(),
        }));

        setProperties(transformedProperties);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching properties:", err);
        // Fallback to generating random properties if API fails
        const fallbackProperties = generateRandomProperties();
        setProperties(fallbackProperties);
        setError("Using sample properties due to API limitation");
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  const getRandomStatus = () => {
    const statuses = [
      "For Sale",
      "Quick Sale",
      "Pre-Foreclosure",
      "Open to Options",
    ];
    return statuses[Math.floor(Math.random() * statuses.length)];
  };

  const generateRandomProperties = () => {
    return Array.from({ length: 10 }, (_, index) => ({
      id: index + 1,
      title: `${
        ["Luxurious", "Modern", "Cozy", "Spacious", "Elegant"][
          Math.floor(Math.random() * 5)
        ]
      } ${
        ["Home", "Villa", "Apartment", "Condo", "Estate"][
          Math.floor(Math.random() * 5)
        ]
      }`,
      price: Math.floor(Math.random() * (1000000 - 200000) + 200000),
      address: `${Math.floor(Math.random() * 9999)} ${
        ["Maple", "Oak", "Pine", "Cedar", "Elm"][Math.floor(Math.random() * 5)]
      } ${
        ["Street", "Avenue", "Boulevard", "Road", "Lane"][
          Math.floor(Math.random() * 5)
        ]
      }`,
      beds: Math.floor(Math.random() * 4) + 2,
      baths: Math.floor(Math.random() * 3) + 1,
      image: `https://picsum.photos/seed/${index}/400/300`,
      status: getRandomStatus(),
    }));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "For Sale":
        return "rgba(173, 216, 230, 0.8)"; // Pastel Blue
      case "Quick Sale":
        return "rgba(255, 253, 208, 0.8)"; // Pastel Yellow
      case "Pre-Foreclosure":
        return "rgba(255, 204, 203, 0.8)"; // Pastel Red
      case "Open to Options":
        return "rgba(193, 225, 193, 0.8)"; // Pastel Green
      default:
        return "rgba(220, 220, 220, 0.8)"; // Pastel Gray
    }
  };

  const containerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "1rem",
    padding: "2rem",
  };

  const cardStyle = {
    backgroundColor: "white",
    borderRadius: "0.5rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    cursor: "pointer",
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
    },
  };

  const imageStyle = {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  };

  const contentStyle = {
    padding: "1rem",
    flex: 1,
  };

  const titleStyle = {
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  };

  const addressStyle = {
    fontSize: "0.875rem",
    color: "#666",
    marginBottom: "0.5rem",
  };

  const priceStyle = {
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "#4a5568",
    marginBottom: "0.5rem",
  };

  const detailsStyle = {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.875rem",
    color: "#4a5568",
    marginBottom: "0.5rem",
  };

  const buttonStyle = (status) => ({
    width: "100%",
    padding: "0.5rem",
    border: "none",
    borderRadius: "0.25rem",
    backgroundColor: getStatusColor(status),
    color: "#4a5568",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  });

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "2rem" }}>
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
        <p className="mt-4">Loading properties...</p>
      </div>
    );
  }

  return (
    <>
      {error && (
        <div
          style={{
            backgroundColor: "#FEF2F2",
            color: "#991B1B",
            padding: "0.75rem",
            marginBottom: "1rem",
            borderRadius: "0.375rem",
            textAlign: "center",
          }}
        >
          {error}
        </div>
      )}
      <div style={containerStyle}>
        {properties.map((property) => (
          <Link
            key={property.id}
            to="/listing/655158b7725e0d18edb26503"
            style={cardStyle}
          >
            <img src={property.image} alt={property.title} style={imageStyle} />
            <div style={contentStyle}>
              <h3 style={titleStyle}>{property.title}</h3>
              <p style={addressStyle}>{property.address}</p>
              <p style={priceStyle}>${property.price.toLocaleString()}</p>
              <div style={detailsStyle}>
                <span>{property.beds} Beds</span>
                <span>{property.baths} Baths</span>
              </div>
              <button style={buttonStyle(property.status)}>
                {property.status}
              </button>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default PropertyGrid;
