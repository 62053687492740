export const baseUrl = "http://localhost:3000/api"; //removed /api

export const postRequest = async (url, body) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });

    const contentType = response.headers.get("content-type");

    if (!contentType || !contentType.includes("application/json")) {
      // If the response is not in JSON format
      const errorMessage = await response.text();
      return { error: true, status: response.status, message: errorMessage };
    }

    const data = await response.json();

    if (!response.ok) {
      return {
        error: true,
        status: response.status,
        message: data.message || "Unknown error",
      };
    }

    return data;
  } catch (error) {
    console.error("Error in postRequest:", error);
    return { error: true, status: 500, message: "Internal Server Error" };
  }
};
export const getRequest = async (url) => {
  try {
    const response = await fetch(url);

    const contentType = response.headers.get("content-type");

    if (!contentType || !contentType.includes("application/json")) {
      // If the response is not in JSON format
      const errorMessage = await response.text();
      return { error: true, status: response.status, message: errorMessage };
    }

    const data = await response.json();

    if (!response.ok) {
      return {
        error: true,
        status: response.status,
        message: data.message || "Unknown error",
      };
    }

    return data;
  } catch (error) {
    console.error("Error in getRequest:", error);
    return { error: true, status: 500, message: "Internal Server Error" };
  }
};
