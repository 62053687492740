import React, { useCallback, useEffect, useState } from "react";

import { Notyf } from "notyf";
import "notyf/notyf.min.css";
const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const useEditMode = (options = {}) => {
  const {
    onSave,
    onCancel,
    initialEditMode = false,
    confirmCancel = true,
    confirmMessage = "You have unsaved changes. Are you sure you want to cancel?",
  } = options;

  const [isEditing, setIsEditing] = useState(initialEditMode);
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [initialData, setInitialData] = useState(null);

  // Store initial data when entering edit mode
  useEffect(() => {
    if (isEditing && !initialData) {
      setInitialData(options.initialData);
    }
  }, [isEditing, initialData, options.initialData]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
    setIsDirty(false);
    setInitialData(options.initialData);
  }, [options.initialData]);

  const handleCancel = useCallback(async () => {
    if (!isDirty || !confirmCancel || window.confirm(confirmMessage)) {
      setIsEditing(false);
      setIsDirty(false);
      if (onCancel) {
        await onCancel(initialData);
      }
    }
  }, [isDirty, confirmCancel, confirmMessage, onCancel, initialData]);

  const handleSave = useCallback(async () => {
    if (!isDirty) {
      notyf.error("No changes to save");
      return;
    }

    setSaving(true);
    try {
      await onSave();
      setIsEditing(false);
      setIsDirty(false);
      setInitialData(null);
      notyf.success("Changes saved successfully");
    } catch (error) {
      console.error("Save error:", error);
      notyf.error(error.message || "Failed to save changes");
    } finally {
      setSaving(false);
    }
  }, [isDirty, onSave]);

  const markDirty = useCallback(() => {
    setIsDirty(true);
  }, []);

  return {
    isEditing,
    isDirty,
    saving,
    handleEdit,
    handleCancel,
    handleSave,
    markDirty,
    setIsEditing,
    setIsDirty,
  };
};
export default useEditMode;
