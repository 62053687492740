import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LineChart } from "lucide-react";

const TrendsContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const TrendBox = styled(Box)({
  padding: "16px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  marginBottom: "16px",
  transition: "transform 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const TrendLabel = styled(Typography)({
  fontSize: "0.9rem",
  color: "#64748B",
  marginBottom: "8px",
  fontFamily: "'Lato', sans-serif",
});

const TrendValue = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: "600",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
  "& svg": {
    width: "48px",
    height: "48px",
    color: "#6366F1",
  },
});

const MarketTrends = ({ marketTrends }) => {
  return (
    <TrendsContainer elevation={0}>
      <IconWrapper>
        <LineChart />
      </IconWrapper>
      <Title variant="h5">Market Trends</Title>

      <TrendBox>
        <TrendLabel>Price Appreciation (YoY)</TrendLabel>
        <TrendValue>
          {marketTrends?.priceAppreciation 
            ? `${marketTrends.priceAppreciation}%` 
            : "N/A"}
        </TrendValue>
      </TrendBox>

      <TrendBox>
        <TrendLabel>Rental Growth Rate</TrendLabel>
        <TrendValue>
          {marketTrends?.rentalGrowth 
            ? `${marketTrends.rentalGrowth}%` 
            : "N/A"}
        </TrendValue>
      </TrendBox>

      <TrendBox>
        <TrendLabel>Market Demand Score</TrendLabel>
        <TrendValue>
          {marketTrends?.demandScore 
            ? `${marketTrends.demandScore}/100` 
            : "N/A"}
        </TrendValue>
      </TrendBox>

      <TrendBox>
        <TrendLabel>Inventory Level</TrendLabel>
        <TrendValue>
          {marketTrends?.inventoryLevel 
            ? marketTrends.inventoryLevel 
            : "N/A"}
        </TrendValue>
      </TrendBox>
    </TrendsContainer>
  );
};

MarketTrends.propTypes = {
  marketTrends: PropTypes.shape({
    priceAppreciation: PropTypes.number,
    rentalGrowth: PropTypes.number,
    demandScore: PropTypes.number,
    inventoryLevel: PropTypes.string,
  }),
};

export default MarketTrends;
