import { useState, useEffect, useCallback } from 'react';
import { attomService } from '../services/attomService';

export function usePropertyData(initialAddress = '', initialZipCode = '') {
  const [propertyData, setPropertyData] = useState(null);
  const [salesHistory, setSalesHistory] = useState([]);
  const [marketTrends, setMarketTrends] = useState(null);
  const [valuation, setValuation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPropertyData = useCallback(async (address, zipCode) => {
    setLoading(true);
    setError(null);
    
    try {
      // Fetch property details
      const details = await attomService.getPropertyDetails(address, zipCode);
      setPropertyData(details);

      // Fetch additional data in parallel
      const [history, trends, value] = await Promise.all([
        attomService.getPropertySalesHistory(details.id),
        attomService.getMarketTrends(zipCode),
        attomService.getPropertyValue(details.id)
      ]);

      setSalesHistory(history);
      setMarketTrends(trends);
      setValuation(value);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching property data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  // Initial fetch if address and zip code are provided
  useEffect(() => {
    if (initialAddress && initialZipCode) {
      fetchPropertyData(initialAddress, initialZipCode);
    }
  }, [initialAddress, initialZipCode, fetchPropertyData]);

  const refreshData = useCallback(() => {
    if (propertyData) {
      fetchPropertyData(
        propertyData.address.street,
        propertyData.address.zipCode
      );
    }
  }, [propertyData, fetchPropertyData]);

  return {
    propertyData,
    salesHistory,
    marketTrends,
    valuation,
    loading,
    error,
    fetchPropertyData,
    refreshData
  };
}
