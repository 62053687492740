import React from "react";
import HeroSection from "../components/HeroSection";
import PropTypes from "prop-types";
import PropertyGrid from "../components/PropertyGrid";
import TestimonialSlider from "../components/TestimonialSlider";
import PeopleSlider from "../components/PeopleSlider";

const thepeople = [
  {
    name: "Vladimir Gruev",
    title: "Real Estate Agent",
    image: "https://picsum.photos/seed/realtor1/240/240",
    tags: ["Listing Specialist", "Home Valuation", "Property Marketing"],
  },
  {
    name: "Chris Owens",
    title: "Property Investor",
    image: "https://picsum.photos/seed/investor1/240/240",
    tags: ["Flip Properties", "Rental Investment", "Market Analysis"],
  },
  {
    name: "Daniele Buffa",
    title: "Finance Specialist",
    image: "https://picsum.photos/seed/finance1/240/240",
    tags: ["Refinancing", "Mortgage Broker", "Loan Modification"],
  },
  {
    name: "Jesse Showalter",
    title: "Real Estate Attorney",
    image: "https://picsum.photos/seed/attorney1/240/240",
    tags: ["Property Law", "Foreclosure", "Lien Resolution"],
  },
  {
    name: "Elif Karneoglu",
    title: "Real Estate Agent",
    image: "https://picsum.photos/seed/realtor2/240/240",
    tags: ["Buyer's Agent", "Relocation Specialist", "Luxury Properties"],
  },
  {
    name: "Alex Johnson",
    title: "Property Manager",
    image: "https://picsum.photos/seed/manager1/240/240",
    tags: ["Tenant Screening", "Property Maintenance", "Rent Collection"],
  },
  {
    name: "Mia Chen",
    title: "Mortgage Specialist",
    image: "https://picsum.photos/seed/mortgage1/240/240",
    tags: ["FHA Loans", "VA Loans", "Conventional Mortgages"],
  },
  {
    name: "Liam O'Connor",
    title: "Real Estate Appraiser",
    image: "https://picsum.photos/seed/appraiser1/240/240",
    tags: [
      "Residential Appraisal",
      "Commercial Appraisal",
      "Property Valuation",
    ],
  },
  {
    name: "Sofia Rodriguez",
    title: "Real Estate Marketing Specialist",
    image: "https://picsum.photos/seed/marketing1/240/240",
    tags: ["Digital Marketing", "Social Media Strategy", "Virtual Tours"],
  },
  {
    name: "Raj Patel",
    title: "Real Estate Broker",
    image: "https://picsum.photos/seed/broker1/240/240",
    tags: [
      "Commercial Real Estate",
      "Investment Properties",
      "Business Brokerage",
    ],
  },
];

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [items.length]);

  const visibleItems = [
    ...items.slice(currentIndex),
    ...items.slice(0, currentIndex),
  ].slice(0, 5);

  return (
    <div className="flex justify-center items-center space-x-4 overflow-hidden">
      {visibleItems.map((item, index) => (
        <div
          key={index}
          className="flex-shrink-0 w-56 bg-white rounded-lg shadow-md overflow-hidden transition-all duration-500"
        >
          <img
            src={item.image}
            alt={item.name}
            className="w-full h-40 object-cover"
          />
          <div className="p-4">
            <h3 className="text-lg font-semibold text-gray-800">{item.name}</h3>
            <p className="text-sm text-gray-600">{item.title}</p>
            <div className="mt-2 flex flex-wrap">
              {item.tags.map((tag, tagIndex) => (
                <span
                  key={tagIndex}
                  className="mr-1 mb-1 px-2 py-1 bg-green-100 text-green-700 rounded-full text-xs"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};

const Home = () => {
  return (
    <div className="font-sans" style={{ fontFamily: "Lato, sans-serif" }}>
      <HeroSection />

      {/* ThePeople Slider */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* <h2 className="text-3xl font-semi-bold text-gray-900 text-center mb-12">
            Support/Option Providers in Your Community////Make Slider //Testimonials
          </h2> */}
        <PeopleSlider people={thepeople} />
      </div>

      {/* Why Choose HomeLeafs Section - Version 2 */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-12">
            <h2 className="text-4xl font-semibold text-gray-900 mb-4">
              Why Choose HomeLeafs?
            </h2>
            <p className="text-gray-600">
              Bridging the Gap Between Homeowners and Housing Professionals
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 mb-16">
            {/* Homeowners Side */}
            <div className="relative">
              <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                <span className="inline-flex items-center px-6 py-2 rounded-full text-base font-medium bg-blue-100 text-blue-800">
                  For Homeowners
                </span>
              </div>
              <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                <div className="p-8">
                  {[
                    {
                      title: "Privacy Shield",
                      description:
                        "Your information, your control. Share your information only with professionals you choose, avoiding unwanted solicitation and protecting your privacy.",
                      icon: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-7 h-7"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Verified Protection",
                      description:
                        "Every professional is thoroughly verified for your safety.",
                      icon: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-7 h-7"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Complete Control",
                      description:
                        "Explore solutions at your pace, without pressure or unwanted contact. Take charge of your situation early on - you know your needs best, and our platform helps you address potential challenges before they escalate into formal notices or bank communications.",
                      icon: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-7 h-7"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                          />
                        </svg>
                      ),
                    },
                  ].map((item, index) => (
                    <div
                      key={index}
                      className="group p-6 bg-blue-50 rounded-xl mb-4 last:mb-0 transition-all duration-300 hover:bg-blue-100"
                    >
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 text-blue-600 group-hover:bg-blue-200">
                            {item.icon}
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-xl font-semibold text-gray-900 mb-2">
                            {item.title}
                          </h4>
                          <p className="text-gray-600">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Service Providers Side */}
            <div className="relative">
              <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                <span className="inline-flex items-center px-6 py-2 rounded-full text-base font-medium bg-green-100 text-green-800">
                  For Service Providers
                </span>
              </div>
              <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                <div className="p-8">
                  {[
                    {
                      title: "Direct Access",
                      description:
                        "Connect with homeowners actively seeking assistance, reducing time and resources spent on lead generation. No more cold calls, bulk cold letters, and blast emails, expensive CPC and CPA campaigns.",
                      icon: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-7 h-7"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Market Edge",
                      description:
                        "Access exclusive opportunities before they become public record, giving you a significant market advantage.",
                      icon: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-7 h-7"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Brand Trust & Credibility",
                      description:
                        "Build trust credibility as a verified professional on our trusted platform.",
                      icon: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-7 h-7"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                          />
                        </svg>
                      ),
                    },
                  ].map((item, index) => (
                    <div
                      key={index}
                      className="group p-6 bg-green-50 rounded-xl mb-4 last:mb-0 transition-all duration-300 hover:bg-green-100"
                    >
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-full bg-green-100 text-green-600 group-hover:bg-green-200">
                            {item.icon}
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-xl font-semibold text-gray-900 mb-2">
                            {item.title}
                          </h4>
                          <p className="text-gray-600">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Community Section */}
          <div className="mt-16">
            <div className="text-center max-w-3xl mx-auto mb-12">
              <h3 className="text-3xl font-semibold text-gray-900 mb-4">
                Your Online Growing Community
              </h3>
              <p className="text-gray-600">
                Connecting professionals with homeowners across all situations
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Insights Card */}
              <div className="relative">
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <span className="inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                    Research Based
                  </span>
                </div>
                <div className="bg-white rounded-2xl shadow-xl p-6">
                  <div className="flex flex-col items-center">
                    <div className="text-blue-600 mb-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-12 w-12"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </div>
                    <div className="text-4xl font-bold text-gray-900 mb-2">
                      1,500+
                    </div>
                    <div className="text-gray-600">Homeowner Insights</div>
                  </div>
                </div>
              </div>

              {/* Homeowner Situations Card */}
              <div className="relative">
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <span className="inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                    We're Here to Help
                  </span>
                </div>
                <div className="bg-white rounded-2xl shadow-xl p-6">
                  <div className="text-gray-600 mb-4 text-center text-sm">
                    Supporting homeowners through situations like:
                  </div>
                  <div className="space-y-2 text-sm">
                    {[
                      "Foreclosure Prevention",
                      "Financial Hardship Assistance",
                      "Divorce Property Solutions",
                      "Senior Property Transitions",
                      "Inherited Property Management",
                      "Relocation Support",
                      "Tax Lien & Legal Issues",
                      "Major Repairs & Damages",
                      "Tenant Dispute Resolution",
                    ].map((situation, index) => (
                      <div key={index} className="flex items-center">
                        <div className="w-2 h-2 bg-blue-500 rounded-full mr-2"></div>
                        <div>{situation}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <button className="mt-6 w-full py-3 px-4 rounded-xl font-semibold text-white transition-all duration-300 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 focus:ring-4 focus:ring-green-200">
                  Get Started
                </button>
              </div>

              {/* Service Providers Card */}
              <div className="relative">
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <span className="inline-flex items-center px-4 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                    Verified Professionals
                  </span>
                </div>
                <div className="bg-white rounded-2xl shadow-xl p-6">
                  <div className="text-gray-600 mb-4 text-center text-sm">
                    Some of our trusted service providers include:
                  </div>
                  <div className="space-y-2 text-sm">
                    {[
                      "Real Estate Agents & Brokers",
                      "Property Investors & Buyers",
                      "Loan Officers & Mortgage Specialists",
                      "Real Estate Attorneys",
                      "Title Companies & Escrow Services",
                      "Housing Counselors & Credit Advisors",
                      "Property Management Companies",
                      "Home Insurance Providers",
                      "& Many More!",
                    ].map((provider, index) => (
                      <div key={index} className="flex items-center">
                        <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
                        <div>{provider}</div>
                      </div>
                    ))}
                  </div>
                </div>{" "}
                <button className="mt-6 w-full py-3 px-4 rounded-xl font-semibold text-white transition-all duration-300 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 focus:ring-4 focus:ring-green-200">
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Properties Seeking Assistance */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-semi-bold text-gray-900 text-center mb-12">
            Some Properties Seeking Assistance
          </h2>
          <PropertyGrid />
        </div>
      </section>

      {/* Testimonials Section */}
      <TestimonialSlider />
    </div>
  );
};

export default Home;
