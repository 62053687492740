import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLikeListings } from "../../hooks/useLikeListings";
import { useDispatch } from "react-redux";
import { removeLikedListing } from "../../redux/user/userSlice";
import { XCircleIcon } from "@heroicons/react/24/outline";
const RecentLikedListings = () => {
  const { likedListings, isLoading, error, fetchLikedListings, toggleLike } =
    useLikeListings();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("RecentLikedListings component mounted");
    fetchLikedListings();
  }, [fetchLikedListings]);

  useEffect(() => {
    console.log("Liked listings in component:", likedListings);
  }, [likedListings]);

  if (isLoading) {
    console.log("Loading liked listings...");
    return <div>Loading liked listings...</div>;
  }

  if (error) {
    console.error("Error fetching liked listings:", error);
    return <div>Error fetching liked listings: {error.message || error}</div>;
  }

  if (!likedListings) {
    console.log("Liked listings is undefined or null");
    return (
      <div>Unable to retrieve liked listings. Please try again later.</div>
    );
  }

  if (likedListings.length === 0) {
    console.log("No liked listings found");
    return (
      <div className="bg-white rounded-lg shadow-md p-4 mb-6">
        <h3 className="text-lg font-semibold mb-4">Recent Liked Listings</h3>
        <p className="text-gray-500">No liked listings yet.</p>
      </div>
    );
  }

  console.log("Rendering liked listings:", likedListings);

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-6">
      <h3 className="text-lg font-semibold mb-4">Recent Liked Listings</h3>
      <ul className="space-y-2">
        {likedListings.slice(0, 5).map((listing) => (
          <li key={listing._id} className="flex items-center justify-between">
            <div className="flex items-center">
              {listing.imageUrls && listing.imageUrls.length > 0 && (
                <img
                  src={listing.imageUrls[0]}
                  alt={listing.name}
                  className="w-10 h-10 object-cover rounded-md mr-3"
                />
              )}
              <div>
                <Link
                  to={`/listing/${listing._id}`}
                  className="text-blue-600 hover:underline"
                >
                  {listing.name || "Unnamed Listing"}
                </Link>
                {listing.price && (
                  <p className="text-sm text-gray-500">
                    ${listing.price.toLocaleString()}
                  </p>
                )}
              </div>
            </div>
            <button
              onClick={() => {
                toggleLike(listing._id);
                dispatch(removeLikedListing(listing._id));
              }}
              className="text-red-500 hover:text-red-600"
              title="Remove from liked listings"
            >
              <XCircleIcon className="h-6 w-6" />
            </button>
          </li>
        ))}
      </ul>
      <Link
        to="/my-saves"
        className="mt-4 inline-block text-blue-600 hover:underline"
      >
        View all liked listings
      </Link>
    </div>
  );
};

export default RecentLikedListings;
