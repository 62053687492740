import React from "react";
import PropTypes from "prop-types";

const SortOptions = ({
  sortOption,
  sortOrder,
  setSortOption,
  setSortOrder,
}) => {
  const handleSortChange = (e) => {
    const [option, order] = e.target.value.split("-");
    setSortOption(option);
    setSortOrder(order);
  };

  return (
    <select
      value={`${sortOption}-${sortOrder}`}
      onChange={handleSortChange}
      className="p-2 border rounded"
    >
      <option value="price-asc">Price: Low to High</option>
      <option value="price-desc">Price: High to Low</option>
      {/* Add more sorting options here if needed */}
    </select>
  );
};

SortOptions.propTypes = {
  sortOption: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  setSortOption: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
};

export default SortOptions;
