import axios from 'axios';
import { API_KEYS, API_ENDPOINTS } from '../config/apiConfig';

const walkScoreAxios = axios.create({
  baseURL: API_ENDPOINTS.WALK_SCORE.BASE_URL,
  params: {
    wsapikey: API_KEYS.WALK_SCORE_API_KEY,
    format: 'json'
  }
});

export const walkScoreService = {
  /**
   * Get Walk Score data for a location
   * @param {Object} location - Location coordinates and address
   * @param {number} location.lat - Latitude
   * @param {number} location.lon - Longitude
   * @param {string} location.address - Street address
   */
  async getLocationScores(location) {
    try {
      const response = await walkScoreAxios.get('', {
        params: {
          lat: location.lat,
          lon: location.lon,
          address: location.address
        }
      });

      return transformWalkScoreData(response.data);
    } catch (error) {
      console.error('Walk Score API Error:', error);
      return generateDummyWalkScore(location);
    }
  },

  /**
   * Get detailed neighborhood amenities
   * @param {Object} location - Location coordinates
   * @param {number} location.lat - Latitude
   * @param {number} location.lon - Longitude
   */
  async getDetailedAmenities(location) {
    try {
      const response = await walkScoreAxios.get('/amenities', {
        params: {
          lat: location.lat,
          lon: location.lon
        }
      });

      return transformAmenitiesData(response.data);
    } catch (error) {
      console.error('Walk Score API Error:', error);
      return generateDummyAmenities();
    }
  }
};

// Data transformation functions
function transformWalkScoreData(rawData) {
  try {
    return {
      walkScore: {
        score: rawData.walkscore,
        description: rawData.description,
        link: rawData.ws_link
      },
      transitScore: {
        score: rawData.transit?.score || 0,
        description: rawData.transit?.description || '',
        summary: rawData.transit?.summary || ''
      },
      bikeScore: {
        score: rawData.bike?.score || 0,
        description: rawData.bike?.description || ''
      }
    };
  } catch (error) {
    console.error('Error transforming Walk Score data:', error);
    return generateDummyWalkScore();
  }
}

function transformAmenitiesData(rawData) {
  try {
    return {
      nearby: rawData.amenities.map(amenity => ({
        type: amenity.type,
        name: amenity.name,
        distance: amenity.distance,
        duration: amenity.duration,
        location: {
          lat: amenity.location.latitude,
          lon: amenity.location.longitude
        }
      })),
      categories: processAmenityCategories(rawData.amenities)
    };
  } catch (error) {
    console.error('Error transforming amenities data:', error);
    return generateDummyAmenities();
  }
}

// Helper functions
function processAmenityCategories(amenities) {
  const categories = {};
  
  amenities.forEach(amenity => {
    if (!categories[amenity.type]) {
      categories[amenity.type] = {
        count: 0,
        averageDistance: 0,
        items: []
      };
    }
    
    categories[amenity.type].count++;
    categories[amenity.type].averageDistance += amenity.distance;
    categories[amenity.type].items.push(amenity);
  });

  // Calculate averages
  Object.keys(categories).forEach(type => {
    categories[type].averageDistance /= categories[type].count;
  });

  return categories;
}

// Dummy data generators
function generateDummyWalkScore(location) {
  // Use location to generate somewhat realistic scores based on coordinates
  const randomFactor = location ? 
    (Math.abs(location.lat * location.lon) % 100) / 100 : 
    Math.random();

  return {
    walkScore: {
      score: Math.floor(60 + (randomFactor * 40)),
      description: 'Very Walkable',
      link: 'https://www.walkscore.com'
    },
    transitScore: {
      score: Math.floor(50 + (randomFactor * 40)),
      description: 'Good Transit',
      summary: 'Multiple public transportation options available'
    },
    bikeScore: {
      score: Math.floor(55 + (randomFactor * 40)),
      description: 'Very Bikeable'
    }
  };
}

function generateDummyAmenities() {
  const amenityTypes = [
    'grocery', 'restaurant', 'shopping', 'coffee', 
    'bank', 'park', 'school', 'library'
  ];

  const nearby = [];
  const categories = {};

  amenityTypes.forEach(type => {
    const count = 2 + Math.floor(Math.random() * 5);
    categories[type] = {
      count,
      averageDistance: 0,
      items: []
    };

    for (let i = 0; i < count; i++) {
      const distance = 0.1 + (Math.random() * 0.9);
      const amenity = {
        type,
        name: `${type.charAt(0).toUpperCase() + type.slice(1)} ${i + 1}`,
        distance,
        duration: Math.floor(distance * 20),
        location: {
          lat: 37.7749 + (Math.random() * 0.02 - 0.01),
          lon: -122.4194 + (Math.random() * 0.02 - 0.01)
        }
      };

      nearby.push(amenity);
      categories[type].items.push(amenity);
      categories[type].averageDistance += distance;
    }

    categories[type].averageDistance /= count;
  });

  return {
    nearby,
    categories
  };
}
