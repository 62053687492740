import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TrendingUp } from "lucide-react";

const ProjectionsContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const ProjectionBox = styled(Box)({
  padding: "16px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  marginBottom: "16px",
  transition: "transform 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const ProjectionLabel = styled(Typography)({
  fontSize: "0.9rem",
  color: "#64748B",
  marginBottom: "8px",
  fontFamily: "'Lato', sans-serif",
});

const ProjectionValue = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: "600",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
  "& svg": {
    width: "48px",
    height: "48px",
    color: "#6366F1",
  },
});

const CashFlowProjections = ({ rentalIncome, expenses, propertyValue }) => {
  const calculateMonthlyCashFlow = () => {
    const totalExpenses = expenses.reduce((sum, expense) => sum + expense.value, 0);
    return rentalIncome.longTermRent - totalExpenses;
  };

  const calculateAnnualCashFlow = () => {
    return calculateMonthlyCashFlow() * 12;
  };

  const calculateCashOnCash = () => {
    const downPayment = propertyValue * 0.2; // Assuming 20% down payment
    const annualCashFlow = calculateAnnualCashFlow();
    return ((annualCashFlow / downPayment) * 100).toFixed(2);
  };

  return (
    <ProjectionsContainer elevation={0}>
      <IconWrapper>
        <TrendingUp />
      </IconWrapper>
      <Title variant="h5">Cash Flow Projections</Title>

      <ProjectionBox>
        <ProjectionLabel>Monthly Cash Flow</ProjectionLabel>
        <ProjectionValue>
          ${calculateMonthlyCashFlow().toLocaleString()}
        </ProjectionValue>
      </ProjectionBox>

      <ProjectionBox>
        <ProjectionLabel>Annual Cash Flow</ProjectionLabel>
        <ProjectionValue>
          ${calculateAnnualCashFlow().toLocaleString()}
        </ProjectionValue>
      </ProjectionBox>

      <ProjectionBox>
        <ProjectionLabel>Cash on Cash Return</ProjectionLabel>
        <ProjectionValue>
          {calculateCashOnCash()}%
        </ProjectionValue>
      </ProjectionBox>

      <ProjectionBox>
        <ProjectionLabel>Total Monthly Expenses</ProjectionLabel>
        <ProjectionValue>
          ${expenses.reduce((sum, expense) => sum + expense.value, 0).toLocaleString()}
        </ProjectionValue>
      </ProjectionBox>
    </ProjectionsContainer>
  );
};

CashFlowProjections.propTypes = {
  rentalIncome: PropTypes.shape({
    longTermRent: PropTypes.number,
  }),
  expenses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  propertyValue: PropTypes.number,
};

export default CashFlowProjections;
