import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "flowbite-react";
import { PencilIcon } from "@heroicons/react/24/outline";

// Separate ContentWrapper component
const ContentWrapper = ({ children, isEditing }) => {
  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { isEditing });
        }
        return child;
      })}
    </>
  );
};

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

const EditableSection = ({
  title,
  children,
  onSave,
  showEditButton = true,
  saving = false,
  className = "",
  error = null,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      const result = await onSave();
      if (result) {
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error saving section:", error);
    }
  };

  return (
    <div className={`bg-white rounded-lg shadow-sm p-6 ${className}`}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
        {showEditButton && (
          <div className="flex space-x-2">
            {!isEditing ? (
              <Button
                size="sm"
                color="light"
                onClick={handleEdit}
                className="flex items-center space-x-2"
              >
                <PencilIcon className="h-4 w-4" />
                <span>Edit</span>
              </Button>
            ) : (
              <>
                <Button size="sm" onClick={handleSave} disabled={saving}>
                  {saving ? "Saving..." : "Save"}
                </Button>
                <Button
                  size="sm"
                  color="light"
                  onClick={handleCancel}
                  disabled={saving}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        )}
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded text-red-700 text-sm">
          {error}
        </div>
      )}

      <ContentWrapper isEditing={isEditing}>{children}</ContentWrapper>
    </div>
  );
};

EditableSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired,
  showEditButton: PropTypes.bool,
  saving: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
};

export default EditableSection;
