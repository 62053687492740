import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditableSection from "./EditableSection";
import FormField from "./FormField";
import { Notyf } from "notyf";
import { updateUser } from "../../../redux/user/userSlice";
import "notyf/notyf.min.css";
const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const COUNTRIES = [
  { code: "US", name: "United States" },
  { code: "CA", name: "Canada" },
  { code: "GB", name: "United Kingdom" },
  // Add more countries as needed
];

const AddressSection = () => {
  const dispatch = useDispatch();
  const { currentUser, loading } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    streetAddress: "",
    unit: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    taxId: "",
  });

  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (currentUser?.address) {
      setFormData({
        streetAddress: currentUser.address.streetAddress || "",
        unit: currentUser.address.unit || "",
        city: currentUser.address.city || "",
        state: currentUser.address.state || "",
        country: currentUser.address.country || "",
        postalCode: currentUser.address.postalCode || "",
        taxId: currentUser.address.taxId || "",
      });
    }
  }, [currentUser]);

  const validatePostalCode = (postalCode, country) => {
    const patterns = {
      US: /^\d{5}(-\d{4})?$/,
      CA: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
      GB: /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
      // Add more country patterns as needed
    };

    if (!country) return true; // Skip validation if no country selected
    if (!postalCode) return false; // Required if country is selected

    const pattern = patterns[country];
    return pattern ? pattern.test(postalCode) : true;
  };

  const validateField = (name, value) => {
    switch (name) {
      case "streetAddress":
        return !value.trim() ? "Street address is required" : "";
      case "city":
        return !value.trim() ? "City is required" : "";
      case "postalCode":
        return !validatePostalCode(value, formData.country)
          ? "Invalid postal code format"
          : "";
      case "taxId":
        return value && !/^[A-Z0-9-]+$/i.test(value)
          ? "Invalid Tax ID format"
          : "";
      default:
        return "";
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    const error = validateField(name, value);
    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSubmit = async () => {
    // Validate all fields
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      notyf.error("Please correct the errors in the form");
      return false;
    }

    setSaving(true);
    try {
      await dispatch(
        updateUser({
          userId: currentUser._id,
          userData: { address: formData },
        })
      ).unwrap();

      notyf.success("Address updated successfully");
      return true;
    } catch (error) {
      console.error("Update error:", error);
      notyf.error(error.message || "Failed to update address");
      return false;
    } finally {
      setSaving(false);
    }
  };

  return (
    <EditableSection
      title="Address Information"
      onSave={handleSubmit}
      saving={saving}
    >
      <div className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="md:col-span-2">
            <FormField
              label="Street Address"
              id="streetAddress"
              name="streetAddress"
              value={formData.streetAddress}
              onChange={handleChange}
              error={errors.streetAddress}
              required
              placeholder="Enter street address"
            />
          </div>

          <FormField
            label="Apartment/Suite/Unit"
            id="unit"
            name="unit"
            value={formData.unit}
            onChange={handleChange}
            error={errors.unit}
            placeholder="Enter unit number (optional)"
          />

          <FormField
            label="City"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            error={errors.city}
            required
            placeholder="Enter city"
          />

          <div className="flex space-x-4">
            <div className="flex-1">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Country
              </label>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="">Select a country</option>
                {COUNTRIES.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <p className="mt-1 text-sm text-red-600">{errors.country}</p>
              )}
            </div>

            <div className="flex-1">
              <FormField
                label="State/Province"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                error={errors.state}
                placeholder="Enter state"
              />
            </div>
          </div>

          <FormField
            label="Postal Code"
            id="postalCode"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            error={errors.postalCode}
            required
            placeholder="Enter postal code"
            helperText="Format depends on country selection"
          />

          <FormField
            label="Tax ID (Optional)"
            id="taxId"
            name="taxId"
            value={formData.taxId}
            onChange={handleChange}
            error={errors.taxId}
            placeholder="Enter tax ID"
            helperText="For business purposes only"
          />
        </div>
      </div>
    </EditableSection>
  );
};

export default AddressSection;
