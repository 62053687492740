import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { persistor, store } from "./redux/store.js";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// Configure global error handling
window.onerror = function(message, source, lineno, colno, error) {
  if (import.meta.env.PROD) {
    // In production, you might want to send this to an error tracking service
    console.error('Global error:', {
      message: message?.toString(),
      source: source?.toString(),
      lineno: lineno?.toString(),
      colno: colno?.toString(),
      error: error?.message || error?.toString()
    });
  } else {
    console.error('Development error:', {
      message: message?.toString(),
      source: source?.toString(),
      lineno: lineno?.toString(),
      colno: colno?.toString(),
      error: error?.message || error?.toString()
    });
  }
};

// Prevent verbose logging in production
if (import.meta.env.PROD) {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
}

// Initialize performance monitoring
if (import.meta.env.PROD && 'performance' in window) {
  window.performance.mark('app-start');
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// Report initial load performance in production
if (import.meta.env.PROD && 'performance' in window) {
  window.addEventListener('load', () => {
    window.performance.mark('app-loaded');
    const measure = window.performance.measure('app-load-time', 'app-start', 'app-loaded');
    console.warn(`App Load Time: ${measure.duration}ms`);
  });
}
