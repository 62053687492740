import React, { useState, useEffect, useCallback } from "react";
import { fetchUserByUsername, addNewMessage } from "../redux/user/userSlice";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  FaEnvelope,
  FaLinkedin,
  FaMapMarkerAlt,
  FaChevronDown,
  FaUserPlus,
  FaPaperPlane,
  FaStar,
  FaAward,
  FaHandshake,
  FaChartLine,
  FaChevronLeft,
  FaChevronRight,
  FaUserFriends,
  FaUserCheck,
  FaTimes
} from "react-icons/fa";
import { Modal, Button, TextField } from "@mui/material";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const demoFAQs = [
  {
    question: "What areas do you specialize in?",
    answer:
      "I specialize in residential properties in the Greater New York area, including Manhattan, Brooklyn, and Queens.",
  },
  {
    question: "How long does the buying process typically take?",
    answer:
      "The buying process can vary, but on average, it takes about 2-3 months from start to finish. This includes property search, offer negotiation, inspections, and closing.",
  },
  {
    question: "What should I look for during a home inspection?",
    answer:
      "During a home inspection, pay attention to the condition of the roof, foundation, electrical systems, plumbing, and HVAC. Also, look for signs of water damage or pest infestations.",
  },
];

const demoTestimonials = [
  {
    author: "Sarah Johnson",
    role: "First-time Homebuyer",
    avatar: "https://randomuser.me/api/portraits/women/1.jpg",
    content:
      "John was instrumental in helping us find our first home. His knowledge of the local market and negotiation skills saved us thousands!",
    date: "2 weeks ago",
    rating: 5,
  },
  {
    author: "Michael Chen",
    role: "Property Investor",
    avatar: "https://randomuser.me/api/portraits/men/2.jpg",
    content:
      "Working with John was a pleasure. He made the selling process smooth and got us a great price for our property.",
    date: "1 month ago",
    rating: 5,
  },
  {
    author: "Emily Rodriguez",
    role: "Luxury Home Buyer",
    avatar: "https://randomuser.me/api/portraits/women/3.jpg",
    content:
      "John's expertise in the luxury market was evident. He found us a stunning penthouse that exceeded our expectations.",
    date: "2 months ago",
    rating: 5,
  },
  {
    author: "David Wilson",
    role: "Real Estate Developer",
    avatar: "https://randomuser.me/api/portraits/men/4.jpg",
    content:
      "Outstanding market analysis and professional service. John's insights helped us make informed decisions on our development projects.",
    date: "3 months ago",
    rating: 5,
  },
  {
    author: "Lisa Thompson",
    role: "Commercial Property Owner",
    avatar: "https://randomuser.me/api/portraits/women/5.jpg",
    content:
      "John's understanding of commercial real estate is exceptional. He helped us secure prime locations for our business expansion.",
    date: "3 months ago",
    rating: 5,
  },
];

const expertiseTags = [
  "Residential Real Estate",
  "Commercial Properties",
  "Investment Analysis",
  "Property Management",
  "Market Research",
  "Luxury Properties",
  "Development Projects",
  "Property Valuation",
  "Contract Negotiation",
  "Real Estate Law",
];

const achievements = [
  {
    icon: <FaStar className="text-yellow-400" />,
    title: "Top Producer 2023",
    description: "Ranked #1 in sales volume",
  },
  {
    icon: <FaAward className="text-blue-500" />,
    title: "Certified Expert",
    description: "Advanced Real Estate Certification",
  },
  {
    icon: <FaHandshake className="text-green-500" />,
    title: "Client Satisfaction",
    description: "98% positive feedback",
  },
  {
    icon: <FaChartLine className="text-purple-500" />,
    title: "Growth Record",
    description: "200+ successful transactions",
  },
];

function Profile() {
  const { username } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileUser = useSelector((state) => state.user.profileUser);
  const currentUser = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.user.loading);
  const error = useSelector((state) => state.user.error);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [expandedFAQ, setExpandedFAQ] = useState(null);
  const [hasInitialFetch, setHasInitialFetch] = useState(false);
  const [followStatus, setFollowStatus] = useState('none'); // 'none', 'following', 'pending'
  const [pendingFollowCount, setPendingFollowCount] = useState(0);

  // Check follow status on component mount
  useEffect(() => {
    const checkFollowStatus = async () => {
      if (!token || !currentUser?._id || !profileUser?._id) return;

      try {
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/api/follow/status/${currentUser._id}/${profileUser._id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch follow status');
        }

        setFollowStatus(data.status);
      } catch (err) {
        console.error('Error checking follow status:', err);
        notyf.error(err.message || 'Error checking follow status');
      }
    };

    checkFollowStatus();
  }, [token, currentUser?._id, profileUser?._id]);

  // Fetch pending follow count for admin
  useEffect(() => {
    const fetchPendingFollowCount = async () => {
      if (!token || !currentUser?.isAdmin) return;

      try {
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/api/follow/pending/count/${currentUser._id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch pending follow count');
        }

        const { count } = await response.json();
        setPendingFollowCount(count);
      } catch (err) {
        console.error('Error fetching pending follow count:', err);
      }
    };

    fetchPendingFollowCount();
  }, [token, currentUser]);

  const handleFollowClick = useCallback(async () => {
    if (!token || !currentUser) {
      notyf.error('Please sign in to follow users');
      navigate("/sign-in");
      return;
    }

    try {
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/follow`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          followerId: currentUser._id,
          followingId: profileUser._id
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send follow request');
      }

      setFollowStatus('pending');
      notyf.success('Follow request sent successfully!');
    } catch (err) {
      notyf.error('Failed to send follow request. Please try again.');
      console.error('Error sending follow request:', err);
    }
  }, [token, currentUser, profileUser, navigate]);

  const handleUnfollowClick = useCallback(async () => {
    if (!token || !currentUser) {
      notyf.error('Please sign in to unfollow users');
      navigate("/sign-in");
      return;
    }

    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/follow/${currentUser._id}/${profileUser._id}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to unfollow user');
      }

      setFollowStatus('none');
      notyf.success('Unfollowed successfully');
    } catch (err) {
      notyf.error('Failed to unfollow. Please try again.');
      console.error('Error unfollowing:', err);
    }
  }, [token, currentUser, profileUser, navigate]);

  const handleCancelRequest = useCallback(async () => {
    if (!token || !currentUser) {
      notyf.error('Please sign in to cancel follow request');
      navigate("/sign-in");
      return;
    }

    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/follow/${currentUser._id}/${profileUser._id}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to cancel follow request');
      }

      setFollowStatus('none');
      notyf.success('Follow request cancelled');
    } catch (err) {
      notyf.error('Failed to cancel request. Please try again.');
      console.error('Error cancelling follow request:', err);
    }
  }, [token, currentUser, profileUser, navigate]);

  const handleMessageClick = useCallback(() => {
    if (!token || !currentUser) {
      notyf.error('Please sign in to send messages');
      navigate("/sign-in");
      return;
    }
    setIsModalOpen(true);
  }, [token, currentUser, navigate]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setMessage("");
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (!currentUser?._id || !profileUser?._id || !token) {
      notyf.error("Please sign in to send messages");
      navigate("/sign-in");
      return;
    }

    try {
      const response = await fetch("/api/message/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          senderId: currentUser._id,
          recipientId: profileUser._id,
          content: message,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || response.statusText);
      }

      const data = await response.json();
      dispatch(
        addNewMessage({
          conversationId: data.message.conversationId,
          message: data.message,
        })
      );

      notyf.success("Message sent successfully!");
      handleCloseModal();
    } catch (error) {
      notyf.error(error.message || "Failed to send message");
      if (error.message.includes("Authentication")) {
        navigate("/sign-in");
      }
    }
  }, [
    currentUser?._id,
    profileUser?._id,
    token,
    message,
    dispatch,
    navigate,
    handleCloseModal,
  ]);

  useEffect(() => {
    if (!username || hasInitialFetch || !dispatch) return;

    const fetchUserData = async () => {
      try {
        await dispatch(fetchUserByUsername(username)).unwrap();
        setHasInitialFetch(true);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    fetchUserData();
  }, [username, dispatch, hasInitialFetch]);

  useEffect(() => {
    if (profileUser?.username) {
      document.title = `${profileUser.username}'s Profile | Real Estate Professional`;
    }
    return () => {
      document.title = "Profile";
    };
  }, [profileUser?.username]);

  const renderFollowButton = () => {
    if (!currentUser || currentUser._id === profileUser._id) {
      return null;
    }

    switch (followStatus) {
      case 'accepted':
        return (
          <button
            onClick={handleUnfollowClick}
            className="flex items-center gap-2 bg-gray-100 hover:bg-gray-200 text-gray-700 font-semibold py-2 px-4 rounded transition-colors duration-300"
          >
            <FaUserCheck className="text-sm" />
            Following
          </button>
        );
      case 'pending':
        return (
          <button
            onClick={handleCancelRequest}
            className="flex items-center gap-2 bg-gray-100 hover:bg-gray-200 text-gray-700 font-semibold py-2 px-4 rounded transition-colors duration-300"
          >
            <FaTimes className="text-sm" />
            Cancel Request
          </button>
        );
      default:
        return (
          <button
            onClick={handleFollowClick}
            className="flex items-center gap-2 bg-white hover:bg-gray-50 text-blue-500 font-semibold py-2 px-4 rounded border border-blue-500 transition-colors duration-300"
          >
            <FaUserPlus className="text-sm" />
            Follow
          </button>
        );
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    const errorMessage = typeof error === 'object' && error !== null 
      ? error.message || JSON.stringify(error)
      : String(error);
    
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="bg-red-50 text-red-600 p-6 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold mb-2">Error Loading Profile</h2>
          <p>{errorMessage}</p>
          <button
            onClick={() => {
              setHasInitialFetch(false);
              window.location.reload();
            }}
            className="mt-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (!profileUser) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-gray-500">No user data available</div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto bg-gray-100 p-4">
      {/* Cover Photo and Profile Info */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden mb-4">
        <div className="relative h-48 bg-gray-300">
          {profileUser.coverPhoto && (
            <img
              src={profileUser.coverPhoto}
              alt="Cover"
              className="w-full h-full object-cover"
            />
          )}
          <div className="absolute bottom-0 left-4 -mb-16">
            <div className="relative">
              <img
                src={profileUser.avatar || ""}
                alt="Profile"
                className="w-32 h-32 rounded-full border-4 border-white object-cover transform hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute -bottom-2 -right-2 bg-green-500 w-4 h-4 rounded-full border-2 border-white"></div>
            </div>
          </div>
        </div>
        <div className="p-4 pt-20">
          <div className="flex justify-between items-start">
            <div>
              <div className="flex items-center gap-3 mb-1">
                <h1 className="text-2xl font-bold">
                  {profileUser.username}
                </h1>
                <span className="bg-blue-50 text-blue-600 px-2 py-0.5 rounded-full text-sm border border-blue-100">
                  Verified Professional ✓
                </span>
              </div>
              <p className="text-gray-600">{profileUser.role}</p>
            </div>
            <div className="flex space-x-3">
              <button
                onClick={handleMessageClick}
                className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition-all duration-300 hover:scale-105"
              >
                <FaPaperPlane className="text-sm" />
                Message
              </button>
              {renderFollowButton()}
              {currentUser?.isAdmin && (
                <Link
                  to="/pending-follows"
                  className="flex items-center gap-2 bg-gray-100 hover:bg-gray-200 text-gray-700 font-semibold py-2 px-4 rounded transition-colors duration-300"
                >
                  <FaUserFriends className="text-sm" />
                  <span className="relative">
                    Pending Follows
                    {pendingFollowCount > 0 && (
                      <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                        {pendingFollowCount}
                      </span>
                    )}
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Left Column */}
        <div className="md:col-span-2 space-y-6">
          {/* About Section */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">About</h2>
            <div className="prose max-w-none text-gray-600">
              {profileUser.about?.split("\n").map((paragraph, index) => (
                <p key={index} className="mb-4">
                  {paragraph}
                </p>
              ))}
            </div>
          </div>

          {/* Achievements */}
          <div className="grid grid-cols-2 gap-4">
            {achievements.map((achievement, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow"
              >
                <div className="flex items-center gap-4">
                  <div className="w-12 h-12 rounded-lg bg-gray-50 flex items-center justify-center">
                    {achievement.icon}
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">
                      {achievement.title}
                    </h3>
                    <p className="text-sm text-gray-500">
                      {achievement.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Testimonials */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Client Testimonials</h2>
              <div className="flex gap-2">
                <button className="p-2 rounded-lg hover:bg-gray-100 transition-colors">
                  <FaChevronLeft className="w-5 h-5 text-gray-500" />
                </button>
                <button className="p-2 rounded-lg hover:bg-gray-100 transition-colors">
                  <FaChevronRight className="w-5 h-5 text-gray-500" />
                </button>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {demoTestimonials.slice(0, 4).map((testimonial, index) => (
                <div
                  key={index}
                  className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors"
                >
                  <div className="flex items-center gap-3 mb-3">
                    <img
                      src={testimonial.avatar}
                      alt={testimonial.author}
                      className="w-12 h-12 rounded-full"
                    />
                    <div>
                      <h3 className="font-semibold">{testimonial.author}</h3>
                      <p className="text-sm text-gray-500">
                        {testimonial.role}
                      </p>
                    </div>
                  </div>
                  <p className="text-gray-600 mb-3">{testimonial.content}</p>
                  <div className="flex items-center justify-between">
                    <div className="flex gap-1">
                      {[...Array(testimonial.rating)].map((_, i) => (
                        <FaStar key={i} className="text-yellow-400 w-4 h-4" />
                      ))}
                    </div>
                    <span className="text-sm text-gray-400">
                      {testimonial.date}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-6">
          {/* Contact Card */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
            <div className="space-y-4">
              {[
                {
                  icon: <FaEnvelope className="text-blue-500" />,
                  label: "Email",
                  value: profileUser.email?.address,
                  link: `mailto:${profileUser.email?.address}`,
                },
                {
                  icon: <FaMapMarkerAlt className="text-blue-500" />,
                  label: "Location",
                  value: profileUser.location,
                },
                {
                  icon: <FaLinkedin className="text-blue-500" />,
                  label: "LinkedIn",
                  value: "View Profile",
                  link: profileUser.socialLinks?.linkedin,
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  <div className="w-10 h-10 rounded-lg bg-blue-50 flex items-center justify-center">
                    {item.icon}
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">{item.label}</div>
                    {item.link ? (
                      <a
                        href={item.link}
                        className="text-blue-600 hover:underline"
                      >
                        {item.value}
                      </a>
                    ) : (
                      <div className="text-gray-900">{item.value}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Expertise */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Areas of Expertise</h2>
            <div className="flex flex-wrap gap-2">
              {expertiseTags.map((tag, index) => (
                <span
                  key={index}
                  className="px-3 py-1 bg-blue-50 text-blue-600 rounded-lg text-sm font-medium hover:bg-blue-100 transition-colors"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>

          {/* FAQ Section */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">
              Frequently Asked Questions
            </h2>
            <div className="space-y-4">
              {demoFAQs.map((faq, index) => (
                <div
                  key={index}
                  className="border border-gray-100 rounded-lg overflow-hidden hover:border-blue-100 transition-colors"
                >
                  <button
                    className="w-full px-4 py-3 text-left focus:outline-none"
                    onClick={() =>
                      setExpandedFAQ(expandedFAQ === index ? null : index)
                    }
                  >
                    <div className="flex justify-between items-center">
                      <h3 className="font-medium text-gray-900">
                        {faq.question}
                      </h3>
                      <div
                        className={`text-blue-500 transition-transform duration-300 ${
                          expandedFAQ === index ? "rotate-180" : ""
                        }`}
                      >
                        <FaChevronDown />
                      </div>
                    </div>
                  </button>
                  <div
                    className={`transition-all duration-300 ease-in-out ${
                      expandedFAQ === index
                        ? "max-h-48 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    <div className="px-4 pb-4 text-gray-600">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Message Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="message-modal"
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[480px] bg-white rounded-2xl shadow-2xl p-8">
          <h2 className="text-2xl font-semibold mb-6">
            Message {profileUser.username}
          </h2>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message here..."
            className="mb-6"
          />
          <div className="flex justify-end gap-3">
            <Button
              onClick={handleCloseModal}
              className="text-gray-600 hover:bg-gray-100"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSendMessage}
              variant="contained"
              className="bg-blue-600 hover:bg-blue-700"
              startIcon={<FaPaperPlane />}
            >
              Send Message
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Profile;
