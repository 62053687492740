import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import { baseUrl, getRequest } from "../pages/utils/service";
import { useDispatch } from "react-redux";
import { signInSuccess } from "../redux/user/userSlice";

const VerifyEmail = () => {
  const { setUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const emailToken = searchParams.get("token");

    const verifyEmailRequest = async () => {
      if (emailToken) {
        try {
          setIsLoading(true);
          const verificationResponse = await getRequest(
            `${baseUrl}/user/verify-email?token=${emailToken}`
          );
          console.log("Verification Response:", verificationResponse);

          setIsLoading(false);
          if (verificationResponse.success) {
            setUser(verificationResponse.user);
            dispatch(
              signInSuccess({
                user: verificationResponse.user,
                token: verificationResponse.token,
              })
            );
            setTimeout(() => {
              navigate("/");
            }, 3000);
          } else {
            setError(
              verificationResponse.error ||
                "An error occurred during email verification"
            );
            console.error(
              "Error during email verification:",
              verificationResponse.error
            );
          }
        } catch (error) {
          setIsLoading(false);
          setError("An error occurred during email verification.");
          console.error("Error during email verification:", error);
        }
      } else {
        setError("Email token is missing from the URL.");
      }
    };

    verifyEmailRequest();
  }, [searchParams, navigate, dispatch, setUser]);

  return (
    <div>
      {isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {!error ? (
            <Alert severity="success">
              Email successfully verified. You will be redirected to the
              homepage in a few seconds.
            </Alert>
          ) : (
            <div>
              {console.log("Error:", error)}
              <Alert severity="error">{String(error)}</Alert>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
