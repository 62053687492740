import { Label, TextInput, Textarea } from "flowbite-react";

import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";
import React from "react";

const FormField = ({
  label,
  id,
  name,
  value,
  onChange,
  onBlur,
  disabled = false,
  error = null,
  type = "text",
  required = false,
  multiline = false,
  rows = 3,
  className = "",
  placeholder = "",
  helperText = null,
  maxLength,
  min,
  max,
  pattern,
  autoComplete = "off",
}) => {
  const inputProps = {
    id,
    name,
    value: value || "",
    onChange,
    onBlur,
    disabled,
    placeholder,
    required,
    maxLength,
    min,
    max,
    pattern,
    autoComplete,
    className: `${error ? "border-red-500" : ""} ${className}`,
    "aria-invalid": error ? "true" : "false",
    "aria-describedby": error ? `${id}-error` : undefined,
  };

  const InputComponent = multiline ? Textarea : TextInput;

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-1">
        <Label
          htmlFor={id}
          value={label}
          className={
            required
              ? 'after:content-["*"] after:ml-0.5 after:text-red-500'
              : ""
          }
        />
        {maxLength && (
          <span className="text-xs text-gray-500">
            {value?.length || 0}/{maxLength}
          </span>
        )}
      </div>

      <div className="relative">
        <InputComponent
          {...inputProps}
          type={type}
          rows={multiline ? rows : undefined}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
      </div>

      {(error || helperText) && (
        <p
          className={`mt-1 text-sm ${error ? "text-red-600" : "text-gray-500"}`}
          id={error ? `${id}-error` : undefined}
        >
          {error || helperText}
        </p>
      )}
    </div>
  );
};

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pattern: PropTypes.string,
  autoComplete: PropTypes.string,
};

export default FormField;
