import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from "recharts";
import EditableSection from "./EditableSection";
import { Notyf } from "notyf";
import ProfileCard from "./ProfileCard";
import { updateUser } from "../../../redux/user/userSlice";
import "notyf/notyf.min.css";
const notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
});

const StatsSection = () => {
  const dispatch = useDispatch();
  const { currentUser, loading } = useSelector((state) => state.user);

  const [stats, setStats] = useState({
    listings: {
      total: 0,
      active: 0,
      sold: 0,
      pending: 0,
    },
    interactions: {
      views: 0,
      likes: 0,
      shares: 0,
      inquiries: 0,
    },
    ratings: {
      average: 0,
      total: 0,
      breakdown: {
        5: 0,
        4: 0,
        3: 0,
        2: 0,
        1: 0,
      },
    },
    achievements: [],
  });

  useEffect(() => {
    if (currentUser?.stats) {
      setStats(currentUser.stats);
    }
  }, [currentUser]);

  // Calculate percentages for various metrics
  const calculatePercentages = () => {
    const totalListings = stats.listings.total || 1; // Prevent division by zero
    const listingPercentages = {
      active: (stats.listings.active / totalListings) * 100,
      sold: (stats.listings.sold / totalListings) * 100,
      pending: (stats.listings.pending / totalListings) * 100,
    };

    return listingPercentages;
  };

  // Prepare data for charts
  const chartData = {
    listings: [
      { name: "Active", value: stats.listings.active },
      { name: "Sold", value: stats.listings.sold },
      { name: "Pending", value: stats.listings.pending },
    ],
    ratings: Object.entries(stats.ratings.breakdown).map(([rating, count]) => ({
      rating: Number(rating),
      count,
    })),
  };

  return (
    <div className="space-y-6">
      {/* Overview Stats */}
      <ProfileCard title="Performance Overview">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {/* Total Listings */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-gray-500">
              Total Listings
            </h3>
            <p className="mt-2 text-3xl font-semibold text-gray-900">
              {stats.listings.total}
            </p>
            <div className="mt-2">
              <div className="flex space-x-2 text-sm">
                <span className="text-green-600">
                  {stats.listings.active} Active
                </span>
                <span className="text-blue-600">
                  {stats.listings.pending} Pending
                </span>
                <span className="text-gray-600">
                  {stats.listings.sold} Sold
                </span>
              </div>
            </div>
          </div>

          {/* Engagement */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-gray-500">Engagement</h3>
            <p className="mt-2 text-3xl font-semibold text-gray-900">
              {stats.interactions.views}
            </p>
            <div className="mt-2">
              <div className="flex space-x-2 text-sm">
                <span className="text-blue-600">
                  {stats.interactions.likes} Likes
                </span>
                <span className="text-green-600">
                  {stats.interactions.shares} Shares
                </span>
              </div>
            </div>
          </div>

          {/* Rating */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-gray-500">
              Average Rating
            </h3>
            <div className="flex items-center mt-2">
              <p className="text-3xl font-semibold text-gray-900">
                {stats.ratings.average.toFixed(1)}
              </p>
              <div className="ml-2 flex text-yellow-400">
                {[...Array(5)].map((_, i) => (
                  <svg
                    key={i}
                    className={`h-5 w-5 ${
                      i < Math.round(stats.ratings.average)
                        ? "text-yellow-400"
                        : "text-gray-300"
                    }`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
              <span className="ml-2 text-sm text-gray-500">
                ({stats.ratings.total} reviews)
              </span>
            </div>
          </div>

          {/* Inquiries */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-gray-500">Inquiries</h3>
            <p className="mt-2 text-3xl font-semibold text-gray-900">
              {stats.interactions.inquiries}
            </p>
            <p className="mt-2 text-sm text-gray-500">Last 30 days</p>
          </div>
        </div>
      </ProfileCard>

      {/* Achievements */}
      <ProfileCard title="Achievements">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {stats.achievements.map((achievement, index) => (
            <div
              key={index}
              className="p-4 bg-white border border-gray-200 rounded-lg text-center"
            >
              <div className="inline-block p-3 bg-blue-50 rounded-full">
                {achievement.icon}
              </div>
              <h4 className="mt-2 font-medium text-gray-900">
                {achievement.title}
              </h4>
              <p className="text-sm text-gray-500">{achievement.description}</p>
            </div>
          ))}
        </div>
      </ProfileCard>

      {/* Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Listings Distribution */}
        <ProfileCard title="Listings Distribution">
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={chartData.listings}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {chartData.listings.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={["#4F46E5", "#10B981", "#F59E0B"][index % 3]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </ProfileCard>

        {/* Rating Distribution */}
        <ProfileCard title="Rating Distribution">
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData.ratings}>
                <XAxis dataKey="rating" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill="#4F46E5">
                  {chartData.ratings.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={`hsl(${240 + index * 10}, 70%, 60%)`}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </ProfileCard>
      </div>
    </div>
  );
};

export default StatsSection;
