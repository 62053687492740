import api from "../api";

const API_URL = "/saved-search";

export const saveSearch = async (name, criteria, userId) => {
  try {
    const response = await api.post(`${API_URL}/save`, {
      name,
      criteria,
      userId,
    });
    return response;
  } catch (error) {
    console.error("Error saving search:", error);
    throw error;
  }
};

export const getSavedSearches = async (userId) => {
  try {
    const response = await api.get(`${API_URL}/get?userId=${userId}`);
    return Array.isArray(response.savedSearches) ? response.savedSearches : [];
  } catch (error) {
    console.error("Error fetching saved searches:", error);
    return [];
  }
};

export const applySavedSearch = async (searchId, userId) => {
  try {
    const response = await api.get(
      `${API_URL}/apply/${searchId}?userId=${userId}`
    );
    return response.criteria;
  } catch (error) {
    console.error("Error applying saved search:", error);
    throw error;
  }
};

export const deleteSavedSearch = async (searchId, userId) => {
  try {
    const response = await api.delete(
      `${API_URL}/delete/${searchId}?userId=${userId}`
    );
    return response;
  } catch (error) {
    console.error("Error deleting saved search:", error);
    throw error;
  }
};
