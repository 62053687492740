import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Receipt, Plus, Trash2 } from "lucide-react";

const TrackerContainer = styled(Paper)({
  padding: "24px",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  background: "#ffffff",
  textAlign: "center",
  fontFamily: "'Lato', sans-serif",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)",
  },
});

const Title = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "20px",
  color: "#2C3E50",
  fontFamily: "'Lato', sans-serif",
});

const FormContainer = styled(Box)({
  display: "flex",
  gap: "16px",
  marginBottom: "24px",
  alignItems: "center",
  justifyContent: "center",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    fontFamily: "'Lato', sans-serif",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "'Lato', sans-serif",
  },
});

const AddButton = styled(Button)({
  backgroundColor: "#6366F1",
  color: "#ffffff",
  fontFamily: "'Lato', sans-serif",
  padding: "8px 24px",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "#4F46E5",
  },
});

const ExpenseItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px",
  backgroundColor: "#F8FAFC",
  borderRadius: "12px",
  marginBottom: "12px",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const ExpenseText = styled(Typography)({
  fontFamily: "'Lato', sans-serif",
  color: "#2C3E50",
});

const DeleteButton = styled(Button)({
  minWidth: "auto",
  padding: "8px",
  color: "#EF4444",
  "&:hover": {
    backgroundColor: "rgba(239, 68, 68, 0.1)",
  },
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
  "& svg": {
    width: "48px",
    height: "48px",
    color: "#6366F1",
  },
});

const ExpenseTracker = ({ expenses, onAddExpense, onRemoveExpense }) => {
  const [newExpense, setNewExpense] = useState({ name: "", value: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newExpense.name && newExpense.value) {
      onAddExpense({
        name: newExpense.name,
        value: parseFloat(newExpense.value),
      });
      setNewExpense({ name: "", value: "" });
    }
  };

  return (
    <TrackerContainer elevation={0}>
      <IconWrapper>
        <Receipt />
      </IconWrapper>
      <Title variant="h5">Expense Tracker</Title>

      <form onSubmit={handleSubmit}>
        <FormContainer>
          <StyledTextField
            label="Expense Name"
            value={newExpense.name}
            onChange={(e) => setNewExpense({ ...newExpense, name: e.target.value })}
            size="small"
          />
          <StyledTextField
            label="Amount"
            type="number"
            value={newExpense.value}
            onChange={(e) => setNewExpense({ ...newExpense, value: e.target.value })}
            size="small"
          />
          <AddButton type="submit" startIcon={<Plus size={20} />}>
            Add
          </AddButton>
        </FormContainer>
      </form>

      <Box>
        {expenses.map((expense, index) => (
          <ExpenseItem key={index}>
            <ExpenseText>
              {expense.name}: ${expense.value.toLocaleString()}
            </ExpenseText>
            <DeleteButton onClick={() => onRemoveExpense(index)}>
              <Trash2 size={20} />
            </DeleteButton>
          </ExpenseItem>
        ))}
      </Box>
    </TrackerContainer>
  );
};

ExpenseTracker.propTypes = {
  expenses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  onAddExpense: PropTypes.func.isRequired,
  onRemoveExpense: PropTypes.func.isRequired,
};

export default ExpenseTracker;
