import axios from 'axios';
import { API_KEYS, API_ENDPOINTS } from '../config/apiConfig';

const censusAxios = axios.create({
  baseURL: API_ENDPOINTS.CENSUS.BASE_URL,
  params: {
    key: API_KEYS.CENSUS_API_KEY
  }
});

// Census API Variables
const CENSUS_VARIABLES = {
  TOTAL_POPULATION: 'B01003_001E',
  MEDIAN_HOUSEHOLD_INCOME: 'B19013_001E',
  MEDIAN_AGE: 'B01002_001E',
  EDUCATION_BACHELORS: 'B15003_022E',
  EDUCATION_GRADUATE: 'B15003_023E',
  HOUSING_UNITS: 'B25001_001E',
  MEDIAN_HOME_VALUE: 'B25077_001E',
  MEDIAN_RENT: 'B25064_001E',
  EMPLOYMENT_RATE: 'B23025_004E',
  POVERTY_RATE: 'B17001_002E',
  COMMUTE_TIME: 'B08013_001E',
  OWNER_OCCUPIED: 'B25003_002E'
};

export const censusService = {
  /**
   * Get demographic data for a specific ZIP code
   * @param {string} zipCode - Area ZIP code
   */
  async getDemographicsByZip(zipCode) {
    try {
      const response = await censusAxios.get(`${API_ENDPOINTS.CENSUS.DEMOGRAPHIC}`, {
        params: {
          get: Object.values(CENSUS_VARIABLES).join(','),
          for: `zip code tabulation area:${zipCode}`
        }
      });

      return transformDemographicData(response.data);
    } catch (error) {
      console.error('Census API Error:', error);
      return generateDummyDemographics(zipCode);
    }
  },

  /**
   * Get economic indicators for a specific area
   * @param {string} zipCode - Area ZIP code
   */
  async getEconomicIndicators(zipCode) {
    try {
      const response = await censusAxios.get(`${API_ENDPOINTS.CENSUS.ECONOMIC}`, {
        params: {
          get: [
            CENSUS_VARIABLES.MEDIAN_HOUSEHOLD_INCOME,
            CENSUS_VARIABLES.EMPLOYMENT_RATE,
            CENSUS_VARIABLES.POVERTY_RATE
          ].join(','),
          for: `zip code tabulation area:${zipCode}`
        }
      });

      return transformEconomicData(response.data);
    } catch (error) {
      console.error('Census API Error:', error);
      return generateDummyEconomicData(zipCode);
    }
  },

  /**
   * Get housing market statistics
   * @param {string} zipCode - Area ZIP code
   */
  async getHousingStats(zipCode) {
    try {
      const response = await censusAxios.get(`${API_ENDPOINTS.CENSUS.DEMOGRAPHIC}`, {
        params: {
          get: [
            CENSUS_VARIABLES.HOUSING_UNITS,
            CENSUS_VARIABLES.MEDIAN_HOME_VALUE,
            CENSUS_VARIABLES.MEDIAN_RENT,
            CENSUS_VARIABLES.OWNER_OCCUPIED
          ].join(','),
          for: `zip code tabulation area:${zipCode}`
        }
      });

      return transformHousingData(response.data);
    } catch (error) {
      console.error('Census API Error:', error);
      return generateDummyHousingStats(zipCode);
    }
  }
};

// Data transformation functions
function transformDemographicData(rawData) {
  try {
    const data = rawData[1]; // First row contains headers
    const variables = rawData[0];
    
    return {
      population: parseInt(data[variables.indexOf(CENSUS_VARIABLES.TOTAL_POPULATION)]),
      medianAge: parseFloat(data[variables.indexOf(CENSUS_VARIABLES.MEDIAN_AGE)]),
      education: {
        bachelors: calculatePercentage(
          parseInt(data[variables.indexOf(CENSUS_VARIABLES.EDUCATION_BACHELORS)]),
          parseInt(data[variables.indexOf(CENSUS_VARIABLES.TOTAL_POPULATION)])
        ),
        graduate: calculatePercentage(
          parseInt(data[variables.indexOf(CENSUS_VARIABLES.EDUCATION_GRADUATE)]),
          parseInt(data[variables.indexOf(CENSUS_VARIABLES.TOTAL_POPULATION)])
        )
      },
      commuteTime: parseFloat(data[variables.indexOf(CENSUS_VARIABLES.COMMUTE_TIME)])
    };
  } catch (error) {
    console.error('Error transforming demographic data:', error);
    return generateDummyDemographics();
  }
}

function transformEconomicData(rawData) {
  try {
    const data = rawData[1];
    const variables = rawData[0];

    return {
      medianIncome: parseInt(data[variables.indexOf(CENSUS_VARIABLES.MEDIAN_HOUSEHOLD_INCOME)]),
      employmentRate: calculatePercentage(
        parseInt(data[variables.indexOf(CENSUS_VARIABLES.EMPLOYMENT_RATE)]),
        parseInt(data[variables.indexOf(CENSUS_VARIABLES.TOTAL_POPULATION)])
      ),
      povertyRate: calculatePercentage(
        parseInt(data[variables.indexOf(CENSUS_VARIABLES.POVERTY_RATE)]),
        parseInt(data[variables.indexOf(CENSUS_VARIABLES.TOTAL_POPULATION)])
      )
    };
  } catch (error) {
    console.error('Error transforming economic data:', error);
    return generateDummyEconomicData();
  }
}

function transformHousingData(rawData) {
  try {
    const data = rawData[1];
    const variables = rawData[0];

    return {
      totalUnits: parseInt(data[variables.indexOf(CENSUS_VARIABLES.HOUSING_UNITS)]),
      medianHomeValue: parseInt(data[variables.indexOf(CENSUS_VARIABLES.MEDIAN_HOME_VALUE)]),
      medianRent: parseInt(data[variables.indexOf(CENSUS_VARIABLES.MEDIAN_RENT)]),
      ownershipRate: calculatePercentage(
        parseInt(data[variables.indexOf(CENSUS_VARIABLES.OWNER_OCCUPIED)]),
        parseInt(data[variables.indexOf(CENSUS_VARIABLES.HOUSING_UNITS)])
      )
    };
  } catch (error) {
    console.error('Error transforming housing data:', error);
    return generateDummyHousingStats();
  }
}

// Helper functions
function calculatePercentage(value, total) {
  return ((value / total) * 100).toFixed(1);
}

// Dummy data generators
function generateDummyDemographics(zipCode) {
  const randomFactor = parseInt(zipCode) % 100 / 100;
  
  return {
    population: Math.floor(50000 + (randomFactor * 50000)),
    medianAge: 35 + (randomFactor * 10),
    education: {
      bachelors: 30 + (randomFactor * 20),
      graduate: 15 + (randomFactor * 15)
    },
    commuteTime: 25 + (randomFactor * 15)
  };
}

function generateDummyEconomicData(zipCode) {
  const randomFactor = parseInt(zipCode) % 100 / 100;
  
  return {
    medianIncome: Math.floor(60000 + (randomFactor * 40000)),
    employmentRate: 94 + (randomFactor * 4),
    povertyRate: 8 - (randomFactor * 4)
  };
}

function generateDummyHousingStats(zipCode) {
  const randomFactor = parseInt(zipCode) % 100 / 100;
  
  return {
    totalUnits: Math.floor(20000 + (randomFactor * 30000)),
    medianHomeValue: Math.floor(300000 + (randomFactor * 400000)),
    medianRent: Math.floor(1500 + (randomFactor * 1500)),
    ownershipRate: 65 + (randomFactor * 20)
  };
}
