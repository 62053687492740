import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Send, Users, CheckCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  fetchConversations,
  markConversationAsRead,
  fetchMessages,
} from "../redux/user/userSlice";
import ConversationList from "../components/ConversationList";
import MessageList from "../components/MessageList";
import { useSocketConnection } from "../hooks/useSocketConnection";

// Demo conversations data
const demoConversations = [
  {
    _id: "1",
    participants: [
      {
        _id: "agent1",
        username: "Sarah Johnson",
        role: "Real Estate Agent",
        avatar: "https://randomuser.me/api/portraits/women/1.jpg",
        verified: true,
      },
    ],
    lastMessage: {
      content: "I understand your situation and I'm here to help...",
      createdAt: new Date(Date.now() - 1000 * 60 * 30).toISOString(),
      senderId: "agent1",
    },
    unreadCount: { "demo-user": 1 },
  },
  {
    _id: "2",
    participants: [
      {
        _id: "lender1",
        username: "Michael Chen",
        role: "Loan Officer",
        avatar: "https://randomuser.me/api/portraits/men/2.jpg",
        verified: true,
      },
    ],
    lastMessage: {
      content: "Let's discuss your refinancing options...",
      createdAt: new Date(Date.now() - 1000 * 60 * 60).toISOString(),
      senderId: "lender1",
    },
    unreadCount: { "demo-user": 2 },
  },
  {
    _id: "3",
    participants: [
      {
        _id: "investor1",
        username: "David Wilson",
        role: "Real Estate Investor",
        avatar: "https://randomuser.me/api/portraits/men/3.jpg",
        verified: true,
      },
    ],
    lastMessage: {
      content: "I can make you a fair cash offer on your property...",
      createdAt: new Date(Date.now() - 1000 * 60 * 120).toISOString(),
      senderId: "investor1",
    },
    unreadCount: { "demo-user": 0 },
  },
  {
    _id: "4",
    participants: [
      {
        _id: "attorney1",
        username: "Emily Rodriguez",
        role: "Real Estate Attorney",
        avatar: "https://randomuser.me/api/portraits/women/4.jpg",
        verified: true,
      },
    ],
    lastMessage: {
      content: "Let me explain your legal options...",
      createdAt: new Date(Date.now() - 1000 * 60 * 180).toISOString(),
      senderId: "attorney1",
    },
    unreadCount: { "demo-user": 1 },
  },
];

// Demo messages for each conversation
const demoMessages = {
  1: [
    // Real Estate Agent conversation
    {
      _id: "a1",
      senderId: "agent1",
      content:
        "Hello! I'm Sarah Johnson, a licensed real estate agent with Century 21. I noticed you're exploring options regarding your property, and I wanted to reach out to offer my assistance.",
      createdAt: new Date(Date.now() - 1000 * 60 * 180).toISOString(),
    },
    {
      _id: "a2",
      senderId: "agent1",
      content:
        "I specialize in helping homeowners in challenging situations find the best solutions for their specific needs. I've helped many families in similar situations successfully navigate through pre-foreclosure.",
      createdAt: new Date(Date.now() - 1000 * 60 * 179).toISOString(),
    },
    {
      _id: "a3",
      senderId: "agent1",
      content:
        "I can provide a free, no-obligation market analysis of your property and discuss various options available to you, including potential short sales if necessary. Would you be interested in scheduling a confidential consultation?",
      createdAt: new Date(Date.now() - 1000 * 60 * 30).toISOString(),
    },
  ],
  2: [
    // Loan Officer conversation
    {
      _id: "l1",
      senderId: "lender1",
      content:
        "Hi there, I'm Michael Chen from HomeFirst Lending. I understand you're facing some challenges with your current mortgage, and I'd like to help explore some potential solutions.",
      createdAt: new Date(Date.now() - 1000 * 60 * 120).toISOString(),
    },
    {
      _id: "l2",
      senderId: "lender1",
      content:
        "We have several refinancing programs specifically designed for homeowners in pre-foreclosure situations, including loan modification options and potential rate reductions.",
      createdAt: new Date(Date.now() - 1000 * 60 * 119).toISOString(),
    },
    {
      _id: "l3",
      senderId: "lender1",
      content:
        "I'd be happy to review your current situation and discuss programs you might qualify for. Many of our clients have successfully avoided foreclosure through our refinancing options. Would you like to schedule a call to discuss this further?",
      createdAt: new Date(Date.now() - 1000 * 60 * 60).toISOString(),
    },
  ],
  3: [
    {
      _id: "i1",
      senderId: "investor1",
      content:
        "Hello, I'm David Wilson, a local real estate investor. I understand you might be looking for options regarding your property, and I wanted to introduce myself.",
      createdAt: new Date(Date.now() - 1000 * 60 * 240).toISOString(),
    },
    {
      _id: "i2",
      senderId: "investor1",
      content:
        "I work with homeowners to provide quick, hassle-free solutions when they need to sell their properties. I can offer a fair cash price and close on your timeline, which can help prevent foreclosure and protect your credit.",
      createdAt: new Date(Date.now() - 1000 * 60 * 239).toISOString(),
    },
    {
      _id: "i3",
      senderId: "investor1",
      content:
        "If you're interested, I can make you a no-obligation cash offer within 24 hours. We can also discuss other potential solutions that might work better for your situation. Would you like to learn more?",
      createdAt: new Date(Date.now() - 1000 * 60 * 120).toISOString(),
    },
  ],
  4: [
    // Real Estate Attorney conversation
    {
      _id: "att1",
      senderId: "attorney1",
      content:
        "Hello, I'm Emily Rodriguez, a real estate attorney specializing in foreclosure prevention. I noticed you're exploring options regarding your property situation.",
      createdAt: new Date(Date.now() - 1000 * 60 * 300).toISOString(),
    },
    {
      _id: "att2",
      senderId: "attorney1",
      content:
        "I want you to know that you have legal rights and options available. My firm has helped numerous homeowners successfully navigate pre-foreclosure situations through loan modifications, short sales, or other legal alternatives.",
      createdAt: new Date(Date.now() - 1000 * 60 * 299).toISOString(),
    },
    {
      _id: "att3",
      senderId: "attorney1",
      content:
        "I offer a free initial consultation to review your situation and explain your legal options. Would you be interested in scheduling a confidential discussion to explore how I might be able to help?",
      createdAt: new Date(Date.now() - 1000 * 60 * 180).toISOString(),
    },
  ],
};

const Messages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const { sendMessage } = useSocketConnection();
  const [searchTerm, setSearchTerm] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [localMessages, setLocalMessages] = useState([]);
  const [isBackendAvailable, setIsBackendAvailable] = useState(false);
  const [conversations, setConversations] = useState(demoConversations);
  const [activeConversation, setActiveConversation] = useState(null);

  useEffect(() => {
    const checkBackendAndFetchData = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/api/health`
        );
        const isAvailable = response.ok;
        setIsBackendAvailable(isAvailable);

        if (!isAvailable) {
          console.log("Backend unavailable, using demo data");
          setConversations([...demoConversations]);
          return;
        }

        if (currentUser?._id) {
          const result = await dispatch(fetchConversations(currentUser._id));
          if (result.payload) {
            setConversations(
              Array.isArray(result.payload) ? result.payload : demoConversations
            );
          } else {
            setConversations(demoConversations);
          }
        } else {
          setConversations(demoConversations);
        }
      } catch (err) {
        console.error("Backend check failed:", err);
        setIsBackendAvailable(false);
        setConversations(demoConversations);
      }
    };

    checkBackendAndFetchData();
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (!activeConversation?._id) return;

    if (isBackendAvailable && currentUser?._id) {
      dispatch(fetchMessages(activeConversation._id)).then((action) => {
        if (action.payload?.messages) {
          setLocalMessages(action.payload.messages);
        } else {
          setLocalMessages(demoMessages[activeConversation._id] || []);
        }
      });
    } else {
      setLocalMessages(demoMessages[activeConversation._id] || []);
    }
  }, [activeConversation?._id, dispatch, isBackendAvailable, currentUser]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const renderRole = (participant) => {
    if (!participant?.role) {
      console.log("No role found for participant:", participant);
      return null;
    }
    return (
      <span className="text-xs px-2 py-1 bg-blue-100 text-blue-800 rounded-full">
        {participant.role}
      </span>
    );
  };

  useEffect(() => {
    if (!activeConversation?._id) return;

    if (isBackendAvailable && currentUser?._id) {
      dispatch(fetchMessages(activeConversation._id)).then((action) => {
        if (action.payload?.messages) {
          setLocalMessages(action.payload.messages);
        } else {
          setLocalMessages(demoMessages[activeConversation._id] || []);
        }
      });
    } else {
      setLocalMessages(demoMessages[activeConversation._id] || []);
    }
  }, [activeConversation?._id, dispatch, isBackendAvailable, currentUser]);

  const handleSendMessage = useCallback(async () => {
    if (!newMessage.trim() || !activeConversation) return;

    try {
      const messageData = {
        senderId: currentUser?._id || "demo-user",
        recipientId: activeConversation.participants?.[0]?._id,
        conversationId: activeConversation._id,
        content: newMessage.trim(),
      };

      if (isBackendAvailable && sendMessage && currentUser?._id) {
        sendMessage(messageData);
      }

      const newLocalMessage = {
        _id: Date.now().toString(),
        ...messageData,
        createdAt: new Date().toISOString(),
        status: "sent",
      };
      setLocalMessages((prev) => [...prev, newLocalMessage]);
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }, [
    newMessage,
    activeConversation,
    currentUser,
    sendMessage,
    isBackendAvailable,
  ]);

  const handleConversationSelect = (conversationId) => {
    const conversation = conversations.find((c) => c._id === conversationId);
    if (conversation) {
      setActiveConversation(conversation);
      if (isBackendAvailable && currentUser?._id) {
        dispatch(markConversationAsRead({ conversationId }));
      }
      setLocalMessages(demoMessages[conversationId] || []);
      setIsMobileMenuOpen(true);
    }
  };

  const handleUsernameClick = (username) => {
    // Update to use the specified profile link
    navigate("/profile/wesnerm+one@gmail.com");
  };

  const getOtherParticipant = (conversation) => {
    return conversation?.participants?.[0] || null;
  };
  return (
    <div className="flex h-screen bg-gray-50 font-lato">
      {!isBackendAvailable && (
        <div className="absolute top-0 left-0 right-0 bg-yellow-50 text-yellow-800 p-2 text-center">
          Demo Mode: Showing example conversations
        </div>
      )}
      <div
        className={`${
          isMobileMenuOpen ? "hidden" : "w-full"
        } md:w-80 md:block border-r border-gray-200 bg-white`}
      >
        <ConversationList
          conversations={Array.isArray(conversations) ? conversations : []}
          onSelectConversation={handleConversationSelect}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          currentUser={currentUser || { _id: "demo-user", role: "Homeowner" }}
          onUsernameClick={handleUsernameClick}
        />
      </div>

      <div
        className={`${
          isMobileMenuOpen ? "block" : "hidden"
        } md:block flex-1 flex flex-col`}
      >
        {activeConversation ? (
          <>
            <div className="p-4 border-b border-gray-200 bg-white">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2 mb-2">
                  <Users className="w-5 h-5 text-gray-500" />
                  <span className="text-sm font-medium text-gray-700">
                    Conversation Participants
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div className="relative">
                      <img
                        src={getOtherParticipant(activeConversation)?.avatar}
                        alt=""
                        className="w-10 h-10 rounded-full object-cover border-2 border-white shadow-md"
                      />
                      <span className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full"></span>
                    </div>
                    <div>
                      <div className="flex items-center gap-2">
                        <h3
                          className="font-medium text-gray-900 cursor-pointer hover:text-blue-600 hover:underline"
                          onClick={() => handleUsernameClick()}
                        >
                          {getOtherParticipant(activeConversation)?.username}
                        </h3>
                        <CheckCircle className="w-4 h-4 text-blue-500" />
                        {renderRole(getOtherParticipant(activeConversation))}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <div>
                      <div className="flex items-center gap-2">
                        <h3
                          className="font-medium text-gray-900 cursor-pointer hover:text-blue-600 hover:underline"
                          onClick={() => handleUsernameClick()}
                        >
                          {currentUser?.username || "Demo User"}
                        </h3>
                        <CheckCircle className="w-4 h-4 text-blue-500" />
                        {renderRole(currentUser || { role: "Homeowner" })}
                      </div>
                    </div>
                    <div className="relative">
                      <img
                        src={
                          currentUser?.avatar ||
                          "https://randomuser.me/api/portraits/men/0.jpg"
                        }
                        alt=""
                        className="w-10 h-10 rounded-full object-cover border-2 border-white shadow-md"
                      />
                      <span className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 overflow-y-auto p-4 bg-gray-50">
              <MessageList
                messages={localMessages}
                currentUser={
                  currentUser || { _id: "demo-user", role: "Homeowner" }
                }
                otherParticipant={getOtherParticipant(activeConversation)}
                onUsernameClick={handleUsernameClick}
              />
            </div>

            <div className="p-4 bg-white border-t border-gray-200">
              <div className="flex items-center gap-2">
                <textarea
                  rows="1"
                  placeholder="Type your message..."
                  className="flex-1 p-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <button
                  className="p-2 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors disabled:bg-gray-400"
                  onClick={handleSendMessage}
                  disabled={!newMessage.trim()}
                >
                  <Send className="w-5 h-5 text-white" />
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex-1 flex items-center justify-center bg-gray-50">
            <p className="text-gray-500">
              Select a conversation to start messaging
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
