import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import {
  Warning,
  CheckCircle,
  Timeline,
  AccountBalance,
  Event,
  Assignment,
} from "@mui/icons-material";

// eslint-disable-next-line no-unused-vars
const ForeclosureInfo = ({ listing }) => {
  // ATTOM API Integration (commented out)
  /*
  const fetchForeclosureData = async () => {
    try {
      const response = await fetch(
        `https://api.attomdata.com/property/foreclosure?address=${listing.address}`,
        {
          headers: {
            'apikey': import.meta.env.VITE_ATTOM_API_KEY,
            'Accept': 'application/json'
          }
        }
      );
      const data = await response.json();
      
      // Process foreclosure data
      const foreclosureInfo = {
        status: data.property[0].foreclosure.status,
        filingDate: data.property[0].foreclosure.filingDate,
        auctionDate: data.property[0].foreclosure.auctionDate,
        judgmentAmount: data.property[0].foreclosure.judgmentAmount,
        lenderName: data.property[0].foreclosure.lenderName,
        preforeclosureAmount: data.property[0].foreclosure.preforeclosureAmount,
      };

      return foreclosureInfo;
    } catch (error) {
      console.error('Error fetching foreclosure data:', error);
      return null;
    }
  };
  */
  // Demo foreclosure data
  const foreclosureData = {
    status: {
      stage: "Pre-Foreclosure",
      daysInProcess: 45,
      riskLevel: "Moderate",
      nextStage: "Notice Of Default",
      timelinePosition: 30, // percentage through process
    },
    financial: {
      defaultAmount: 15000,
      totalDebt: 285000,
      estimatedEquity: 65000,
      monthlyPayments: 2200,
      missedPayments: 3,
    },
    legalStatus: {
      filingDate: "2024-01-15",
      nextHearing: "2024-03-20",
      lenderName: "First National Bank",
      attorneyAssigned: true,
      mediationStatus: "Scheduled",
    },
    options: {
      shortSale: {
        eligible: true,
        probability: 85,
        estimatedTimeline: "3-4 Months",
      },
      loanModification: {
        eligible: true,
        probability: 75,
        type: "Rate And Term",
      },
      redemptionPeriod: {
        available: true,
        daysRemaining: 90,
        amount: 295000,
      },
    },
    timeline: [
      { stage: "Missed Payment", date: "2023-11-15", completed: true },
      { stage: "Notice Of Default", date: "2024-01-15", completed: true },
      { stage: "Pre-Foreclosure", date: "2024-02-01", completed: false },
      { stage: "Auction Notice", date: "2024-04-15", completed: false },
      { stage: "Foreclosure Sale", date: "2024-05-15", completed: false },
    ],
  };

  return (
    <Card sx={{ mb: 4, borderRadius: 2 }}>
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontFamily: "Lato, sans-serif",
            fontWeight: 600,
            color: "#1565c0", // Darker blue
            mb: 3,
          }}
        >
          <Warning /> Foreclosure Status
        </Typography>

        {/* Status Overview */}
        <Box sx={{ mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(211, 47, 47, 0.1)", // Lighter shade of error
                  borderRadius: 2,
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#d32f2f", // Darker error color
                    fontFamily: "Lato, sans-serif",
                    mb: 1,
                  }}
                >
                  {foreclosureData.status.stage}
                </Typography>
                <Typography
                  sx={{ 
                    color: "#333", // Darker text color
                    fontFamily: "Lato, sans-serif" 
                  }}
                >
                  {foreclosureData.status.daysInProcess} Days In Process
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={foreclosureData.status.timelinePosition}
                  sx={{
                    mt: 2,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: "rgba(255,255,255,0.3)",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#d32f2f", // Darker error color
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "grey.100",
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ 
                    fontFamily: "Lato, sans-serif",
                    mb: 2,
                    color: "#333", // Darker text color
                  }}
                >
                  Key Deadlines
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Tooltip title="Date Of Next Legal Hearing">
                      <Box sx={{ textAlign: "center" }}>
                        <Event sx={{ color: "#1565c0", mb: 1 }} /> {/* Darker blue */}
                        <Typography
                          variant="body2"
                          sx={{ 
                            fontFamily: "Lato, sans-serif",
                            color: "#333", // Darker text color
                          }}
                        >
                          Next Hearing
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            color: "#1565c0", // Darker blue
                          }}
                        >
                          {foreclosureData.legalStatus.nextHearing}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip title="Days Remaining In Redemption Period">
                      <Box sx={{ textAlign: "center" }}>
                        <Timeline sx={{ color: "#ed6c02", mb: 1 }} /> {/* Darker warning color */}
                        <Typography
                          variant="body2"
                          sx={{ 
                            fontFamily: "Lato, sans-serif",
                            color: "#333", // Darker text color
                          }}
                        >
                          Redemption Period
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            color: "#ed6c02", // Darker warning color
                          }}
                        >
                          {foreclosureData.options.redemptionPeriod.daysRemaining} Days
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Financial Details */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <AccountBalance /> Financial Status
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(foreclosureData.financial).map(([key, value]) => (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "grey.100",
                    borderRadius: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#333", // Darker text color
                    }}
                  >
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .split(" ")
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                      .join(" ")}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#1565c0", // Darker blue
                      fontWeight: 600,
                    }}
                  >
                    {typeof value === "number"
                      ? value.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                        })
                      : value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Available Options */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <Assignment /> Available Options
          </Typography>
          <Grid container spacing={3}>
            {Object.entries(foreclosureData.options).map(([key, data]) => (
              <Grid item xs={12} md={4} key={key}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: data.eligible 
                      ? "rgba(46, 125, 50, 0.1)" // Lighter shade of success
                      : "rgba(211, 47, 47, 0.1)", // Lighter shade of error
                    borderRadius: 2,
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: data.eligible 
                        ? "#2e7d32" // Darker success color
                        : "#d32f2f", // Darker error color
                      fontWeight: 600,
                      mb: 1,
                    }}
                  >
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .split(" ")
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                      .join(" ")}
                  </Typography>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{ 
                        fontFamily: "Lato, sans-serif",
                        mb: 1,
                        color: "#333", // Darker text color
                      }}
                    >
                      Success Probability
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={data.probability || 0}
                      sx={{
                        height: 6,
                        borderRadius: 3,
                        backgroundColor: "rgba(255,255,255,0.3)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: data.eligible
                            ? "#2e7d32" // Darker success color
                            : "#d32f2f", // Darker error color
                        },
                      }}
                    />
                  </Box>
                  {Object.entries(data).map(
                    ([subKey, value]) =>
                      subKey !== "probability" && (
                        <Typography
                          key={subKey}
                          variant="body2"
                          sx={{
                            fontFamily: "Lato, sans-serif",
                            color: "#333", // Darker text color
                            mt: 1,
                          }}
                        >
                          {subKey
                            .replace(/([A-Z])/g, " $1")
                            .trim()
                            .split(" ")
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(" ")}: {value.toString()}
                        </Typography>
                      )
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Timeline */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: "Lato, sans-serif",
              mb: 2,
              color: "#1565c0", // Darker blue
            }}
          >
            <Timeline /> Foreclosure Timeline
          </Typography>
          <Box sx={{ position: "relative" }}>
            {foreclosureData.timeline.map((stage, index) => (
              <Box
                key={stage.stage}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: index === foreclosureData.timeline.length - 1 ? 0 : 3,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: "50%",
                    bgcolor: stage.completed ? "#2e7d32" : "grey.300", // Darker success color
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mr: 2,
                  }}
                >
                  {stage.completed ? (
                    <CheckCircle sx={{ color: "white", fontSize: 16 }} />
                  ) : (
                    <Timeline sx={{ color: "grey.500", fontSize: 16 }} />
                  )}
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ 
                      fontFamily: "Lato, sans-serif",
                      fontWeight: 600,
                      color: "#333", // Darker text color
                    }}
                  >
                    {stage.stage}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Lato, sans-serif",
                      color: "#666", // Darker secondary text color
                    }}
                  >
                    {stage.date}
                  </Typography>
                </Box>
                {index !== foreclosureData.timeline.length - 1 && (
                  <Box
                    sx={{
                      position: "absolute",
                      left: 12,
                      top: 24,
                      bottom: -24,
                      width: 1,
                      bgcolor: stage.completed ? "#2e7d32" : "grey.300", // Darker success color
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

ForeclosureInfo.propTypes = {
  listing: PropTypes.shape({
    address: PropTypes.string.isRequired,
    currentStatus: PropTypes.string.isRequired,
  }).isRequired,
};

export default ForeclosureInfo;
